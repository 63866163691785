import React, { createContext, useContext } from 'react'
import { CombinedDimensions } from './helpers'
import styled from 'styled-components'

const ChartContext = createContext({})

export function useChartDimensions() {
  return useContext(ChartContext)
}

const ChartContainer = styled.svg`
  overflow: visible;
  & text {
    fill: #95a5a6;
  }
`

export interface ChartProps {
  dimensions: CombinedDimensions
  children: JSX.Element[] | JSX.Element
}

export function Chart({ dimensions, children }: ChartProps) {
  return (
    <ChartContext.Provider value={dimensions}>
      <ChartContainer className="chart" width={dimensions.width} height={dimensions.height}>
        <g transform={`translate(${dimensions.marginLeft}, ${dimensions.marginTop})`}>{children}</g>
      </ChartContainer>
    </ChartContext.Provider>
  )
}
