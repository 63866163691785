import { epochsToSamples, transpose, calcContactQuality, MUSE_EEG_CHANNELS, BiometricEpoch, INSAI_EEG_SCALING_FACTOR_WITHOUT_GAIN } from 'biolink'
import { LineChart, CHARTCOLORS, RawChannelsProps } from './base'
import styled from 'styled-components'

export function parseEpoch(epoch: BiometricEpoch): BiometricEpoch {
  // remove NaN values
  return {
    ...epoch,
    data: epoch.data.map((d) => d.map((s) => (isNaN(s) ? 0 : s))),
  }
}

const Container = styled.div`
  padding: 10px 20px;
  border: 1px solid #ddd;
  margin: 5px;
`

export function RawChannels({ epochs, chartProperties, gain, channelNames = MUSE_EEG_CHANNELS }: RawChannelsProps) {
  const mostRecentEpoch = epochs[epochs.length - 1]

  const channelLoss = mostRecentEpoch.data
    .map((data, _) => data.map((d) => isNaN(d)).filter(Boolean).length)
    .map((x) => (x > 0 ? { name: 'poor', color: 'red' } : { name: 'good', color: 'green' }))

  const packetLoss = (mostRecentEpoch.packetLoss.length / (mostRecentEpoch.data.length + mostRecentEpoch.packetLoss.length)) * 100

  const parsedEpochs = epochs.map(parseEpoch)
  const samples = epochsToSamples(parsedEpochs)
  const timestamps = samples.map((s) => s.timestamp)
  const dataSamples = transpose(samples.map((s) => s.data))
  const contactQuality = calcContactQuality(parsedEpochs[0])

  const datasets = dataSamples.map((channelData: any, channel_ind: number) =>
    channelData.map((d: any, channel_index: number) => {
      const gainMultiplier = gain?.length ? gain[channel_ind] : 1
      const sample = (d * INSAI_EEG_SCALING_FACTOR_WITHOUT_GAIN * 1e6) / gainMultiplier
      //console.log("s:",sample,"y:",yDomain,"gain multiplier",gainMultiplier)
      if (chartProperties?.yDomain && Math.abs(sample) > chartProperties.yDomain[1])
        return { data: Math.sign(sample) * chartProperties.yDomain[1], timestamp: timestamps[channel_index] }
      else {
        return { data: sample, timestamp: timestamps[channel_index] }
      }
    }),
  )
  return (
    <>
      <div>{`Packet loss: ${packetLoss.toFixed(2)}%`}</div>
      {datasets.map((d: any, i: number) => (
        <Container key={i}>
          <div style={{ display: 'flex' }}>
            <div>{`${channelNames[i]}`}</div>
            <div style={{ color: contactQuality[i].color }}>{`Contact: ${contactQuality[i].name}`}</div>
            <div style={{ color: channelLoss[i].color }}>{`Signal quality: ${channelLoss[i].name}`}</div>
          </div>
          <LineChart dataset={d} color={CHARTCOLORS[i].color} chartProperties={chartProperties} />
        </Container>
      ))}
    </>
  )
}
