import { useEffect, useState, useContext } from 'react'
import { BiometricEpoch, plot } from 'biolink'

import { RawChannels } from '../Charts/RawChannels'
import PowerBandsChart from '../Charts/PowerBandsChart'
import FFTChart from '../Charts/FFTChart'

import { DeviceContext, getDeviceInfo } from 'store/device-context'

type StreamType = 'EEG' | 'PPG' | 'ACCEL' | 'GYRO' | 'BANDS' | 'FFT'

export function useBuffer(stream?: BiometricEpoch, maxBufferSize: number = 20) {
  const [buffer, setBuffer] = useState<BiometricEpoch[]>([])
  useEffect(() => {
    if (!stream) return
    if (buffer.length < maxBufferSize) {
      if (stream) buffer.push(stream)
      setBuffer([...buffer])
    } else {
      buffer.shift()
      setBuffer(buffer)
    }
  }, [stream, buffer, maxBufferSize])
  return { buffer }
}

interface SensorProps {
  live: boolean
  epoch?: BiometricEpoch
}

const defaultProps: SensorProps = {
  live: true,
}

export function EEG({ live, epoch }: SensorProps) {
  // TODO: refactor for reuse
  const { eegFiltered, device } = useContext(DeviceContext)!
  const data = live ? eegFiltered : epoch
  const { buffer } = useBuffer(data)

  const { name } = getDeviceInfo(device)
  const { yDomain, channelNames } = plot.getEEGConfig(name!)

  if (buffer.length < 2) return null
  return <RawChannels epochs={buffer} channelNames={channelNames} />
}

EEG.defaultProps = defaultProps

export function PowerBands({ live, epoch }: SensorProps) {
  const { eegFiltered } = useContext(DeviceContext)!
  const data = live ? eegFiltered : epoch
  const { buffer } = useBuffer(data)

  if (buffer.length < 2) return null
  return <PowerBandsChart epochs={buffer} />
}

PowerBands.defaultProps = defaultProps

export function FFT({ live, epoch }: SensorProps) {
  const { eegFiltered } = useContext(DeviceContext)!
  const data = live ? eegFiltered : epoch
  const { buffer } = useBuffer(data)

  if (buffer.length < 2) return null

  return <FFTChart epochs={buffer} />
}
FFT.defaultProps = defaultProps

export function PPG({ live, epoch }: SensorProps) {
  const { ppg, device } = useContext(DeviceContext)!
  const data = live ? ppg : epoch
  const { buffer } = useBuffer(data)
  const { name } = getDeviceInfo(device)
  const { yDomain, channelNames } = plot.getPPGConfig(name!)
  if (buffer.length < 2) return null

  return <RawChannels epochs={buffer} chartProperties={{ yDomain }} channelNames={channelNames} />
}

PPG.defaultProps = defaultProps

export function Accelerometer({ live, epoch }: SensorProps) {
  const { accel, device } = useContext(DeviceContext)!
  const data = live ? accel : epoch
  const { buffer } = useBuffer(data)
  const { name } = getDeviceInfo(device)
  const { yDomain, channelNames } = plot.getAccelConfig(name!)
  if (buffer.length < 2) return null

  return <RawChannels epochs={buffer} chartProperties={{ yDomain }} channelNames={channelNames} />
}

Accelerometer.defaultProps = defaultProps

export function Gyroscope({ live, epoch }: SensorProps) {
  const { gyro, device } = useContext(DeviceContext)!

  const { buffer } = useBuffer(gyro)
  const { name } = getDeviceInfo(device)
  const { yDomain, channelNames } = plot.getGyroConfig(name!)
  if (buffer.length < 2) return null

  return <RawChannels epochs={buffer} chartProperties={{ yDomain }} channelNames={channelNames} />
}

Gyroscope.defaultProps = defaultProps
