import React, { useEffect, useState } from 'react'
import * as d3 from 'd3'
import { PowerBands, EEGEpoch } from 'biolink'
import { Chart, Axis, Bars, CHANNELS } from './base'
import { useChartDimensions } from './base/helpers'
import { fft, powerByBand } from '@neurosity/pipes'
import { from } from 'rxjs'

interface PowerBandsChartProps {
  powerbands: PowerBands
}

export function PowerBandsChart({ powerbands }: PowerBandsChartProps) {
  const [ref, dimensions] = useChartDimensions()

  const labels: string[] = Object.keys(powerbands)
  const powers: number[][] = Object.values(powerbands)
  const channels = CHANNELS.map((d) => d.label)
  const colors = CHANNELS.map((d) => d.color)
  const xAccessor = (i: string) => i
  const yAccessor = (d: number) => d

  const x0Scale = d3.scaleBand().domain(labels).rangeRound([0, dimensions.boundedWidth]).padding(0.1)

  const x1Scale = d3.scaleBand().domain(channels).rangeRound([0, x0Scale.bandwidth()]).padding(0.05)

  const yScale = d3
    .scaleLinear()
    // @ts-ignore
    .domain(d3.extent(powers.flat(), xAccessor))
    .range([dimensions.boundedHeight, 0])
    .nice()

  const x0AccessorScaled = (i: number) => x0Scale(xAccessor(labels[i]))!

  const x1AccessorScaled = (i: number) => x1Scale(xAccessor(channels[i]))!

  const yAccessorScaled = (d: number) => yScale(yAccessor(d))!
  const heightAccessorScaled = (d: number) =>
    // @ts-ignore
    dimensions.boundedHeight - yScale(yAccessor(d))

  return (
    <div ref={ref} style={{ height: 200 }}>
      <Chart dimensions={dimensions}>
        <Axis dimension="x" scale={x0Scale} formatTick={(d: any) => d} />
        <Axis dimension="y" scale={yScale} formatTick={(d: any) => d} label={'Relative power (%)'} />
        <>
          {powers.map((d, i) => (
            <Bars
              key={i}
              data={d}
              colors={colors}
              xAccessor={(idx) => x1AccessorScaled(idx) + x0AccessorScaled(i)}
              yAccessor={yAccessorScaled}
              heightAccessor={heightAccessorScaled}
            />
          ))}
        </>
      </Chart>
    </div>
  )
}

interface PowerBandsProps {
  epochs: EEGEpoch[]
}

export default function PowerBandsData({ epochs }: PowerBandsProps) {
  const [powers, setPowers] = useState<PowerBands | null>(null)
  useEffect(() => {
    from(epochs)
      .pipe(fft({ bins: 256 }), powerByBand())
      .toPromise()
      .then((p) => setPowers(p))
  }, [epochs])
  return powers && <PowerBandsChart powerbands={powers} />
}
