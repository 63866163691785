import React, { useState, useContext } from 'react'
import OfflineBolt from '@material-ui/icons/OfflineBolt'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import CircularProgress from '@material-ui/core/CircularProgress'
import { ble } from 'components/Live/Session'
import { DeviceContext } from 'store/device-context'

export function ConnectionWidget() {
  const { device, setDevice, startStreams, active, stopStreams } = useContext(DeviceContext)!
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [loading, setLoading] = useState(false)
  const open = Boolean(anchorEl)
  function handleConnectionMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget)
  }
  function handleClose() {
    setAnchorEl(null)
  }
  async function handleConnect() {
    try {
      setLoading(true)
      const device = await ble.startScan(true)
      if (!device) return
      setDevice(device)
    } catch (e) {
      console.log(e)
    }
    setLoading(false)
  }
  async function handleDisconnect() {
    ble.disconnect()
  }
  return (
    <>
      {/* stylelint-disable-line */}
      <IconButton onClick={device ? handleConnectionMenuOpen : handleConnect} style={{ color: device ? '#023E73' : '' }}>
        {loading ? <CircularProgress color="secondary" size={25} /> : <OfflineBolt />}
      </IconButton>
      <Menu
        id="connect-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
      >
        {active ? <MenuItem onClick={stopStreams}>Stop</MenuItem> : <MenuItem onClick={startStreams}>Start</MenuItem>}
        <MenuItem>SQ: X</MenuItem>
        <MenuItem onClick={handleDisconnect}>Disconnect</MenuItem>
      </Menu>
    </>
  )
}

export default ConnectionWidget
