import { colors } from '@material-ui/core'
import { MUSE_CHANNEL_NAMES } from 'biolink'

export const CHANNELS = [
  {
    label: MUSE_CHANNEL_NAMES[0],
    color: colors.red[500],
  },
  {
    label: MUSE_CHANNEL_NAMES[1],
    color: colors.blue[500],
  },
  {
    label: MUSE_CHANNEL_NAMES[2],
    color: colors.green[500],
  },
  {
    label: MUSE_CHANNEL_NAMES[3],
    color: colors.orange[500],
  },
  {
    label: MUSE_CHANNEL_NAMES[4],
    color: colors.blueGrey[500],
  },
]
export const CHARTCOLORS = [
  {
    color: colors.red[500],
  },
  {
    color: colors.blue[500],
  },
  {
    color: colors.green[500],
  },
  {
    color: colors.orange[500],
  },
  {
    color: colors.blueGrey[500],
  },
  {
    color: colors.brown[500],
  },
  {
    color: colors.purple[500],
  },
  {
    color: colors.teal[500],
  },
  {
    color: colors.yellow[500],
  },
]

export const COGNITIVE = [
  {
    label: 'calm',
    color: colors.green[500],
  },
  {
    label: 'focus',
    color: colors.blue[500],
  },
  {
    label: 'engagement',
    color: colors.orange[500],
  },
  {
    label: 'fatigue',
    color: colors.red[500],
  },
]
