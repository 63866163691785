import React from 'react'
import { AccessorProps } from './helpers'
import styled from 'styled-components'

const Bar = styled.rect`
  /* transition: all 0.3s ease-out; */
`

interface BarsProps {
  data: number[]
  colors: string[]
  xAccessor: AccessorProps
  yAccessor: AccessorProps
  heightAccessor: AccessorProps
}

export function Bars({ data, xAccessor, yAccessor, heightAccessor, colors }: BarsProps) {
  return (
    <React.Fragment>
      {data.map((d, i) => (
        <Bar key={d} x={xAccessor(i)} y={yAccessor(d)} width="20" height={heightAccessor(d)} fill={colors[i]} />
      ))}
    </React.Fragment>
  )
}
