import cookies from 'js-cookie'

export function getUserFromCookie() {
  const cookie = cookies.get('insai')
  if (!cookie) return
  return JSON.parse(cookie)
}

export interface UserCookie {
  id: string
  name: string | null
  email: string
  token: string | null
  organization?: {
    id: string
    name: string
  }
}

export async function setUserCookie(user: UserCookie, remember: boolean) {
  try {
    // default: firebase id tokens expire in one hour
    const options = {
      expires: remember ? 5 : undefined,
    }
    await cookies.set('insai', user, options)
  } catch (error) {
    console.log('Cookie error: ', error)
  }
}

export function removeUserCookie() {
  cookies.remove('insai')
}
