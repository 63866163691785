import { BleDevice } from '@capacitor-community/bluetooth-le'
import { useState, useEffect } from 'react'
import { BiometricEpoch, musePipes, MuseDevice } from 'biolink'
import { SocketClient } from 'biolink/dist/lib/socket'
import { useStartRecordingMutation } from 'generated/graphql'
import { Device } from '../types'
import { useLocalStorage } from 'components/shared/local-storage'

// TODO: move and use process.env
export const socket = new SocketClient({ uri: process.env.REACT_APP_SOCKET_URL })

const muse = new MuseDevice()

export interface SensingContextProps {
  startStreams: () => void
  stopStreams: () => void
  saveSession: (type: string, program?: string) => Promise<void>
  gyro: BiometricEpoch | undefined
  accel: BiometricEpoch | undefined
  ppg: BiometricEpoch | undefined
  eeg: BiometricEpoch | undefined
  eegFiltered: BiometricEpoch | undefined
  telemetry: number | undefined
  active: boolean
}

// TODO: use redux || useReducer?
export function MuseStore(device: Device | null): SensingContextProps {
  const [saveRecording, { loading }] = useStartRecordingMutation()
  const [, setSession] = useLocalStorage('session', undefined)

  const [active, setActive] = useState(false)
  const [telemetry, setTelemetry] = useState<number | undefined>(undefined)
  const [eeg, setEEG] = useState<BiometricEpoch | undefined>(undefined)
  const [eegFiltered, setEEGFiltered] = useState<BiometricEpoch | undefined>(undefined)
  const [accel, setAccel] = useState<BiometricEpoch | undefined>(undefined)
  const [gyro, setGyro] = useState<BiometricEpoch | undefined>(undefined)
  const [ppg, setPPG] = useState<BiometricEpoch | undefined>(undefined)
  const [metric, setMetric] = useState('')

  function startStreams() {
    if (!device) {
      throw Error('Connect Muse device')
    }
    try {
      muse.startStreams(device)
    } catch (e) {
      console.log(e)
    }
  }

  function stopStreams() {
    if (!device) {
      throw Error('Connect Muse device')
    }
    try {
      muse.stopStreams(device)
    } catch (e) {
      console.log(e)
    }
  }

  async function saveSession(type: string) {
    console.log('Start saving')
    const startTime = JSON.stringify(new Date())
    console.log(device)
    const { data } = await saveRecording({
      variables: {
        type,
        startTime,
        deviceType: device?.type,
      },
    })
    if (data?.startSession) {
      const { id, metric } = data.startSession
      setSession(JSON.stringify(id))
      setMetric(metric?.id!)
    }
    // setMetric(metricId)
  }

  // TODO: refactor and reuse with nexus
  // Component States
  useEffect(() => {
    console.log('Update component states')
    const { eeg, telemetry, active, accel, gyro, ppg } = muse
    const $eeg = eeg.subscribe((e) => setEEG(e))
    const $eegF = musePipes.eegFilter(eeg).subscribe((e) => setEEGFiltered(e))
    const $active = active.subscribe((a) => setActive(a))
    const $tele = telemetry?.subscribe((t) => setTelemetry(t.batteryLevel))
    const $accel = accel.subscribe((a) => setAccel(a))
    const $gyro = gyro.subscribe((g) => setGyro(g))
    const $ppg = ppg.subscribe((p) => setPPG(p))

    return () => {
      $eeg.unsubscribe()
      $eegF.unsubscribe()
      $active.unsubscribe()
      $tele?.unsubscribe()
      $accel.unsubscribe()
      $gyro.unsubscribe()
      $ppg.unsubscribe()
    }
  }, [])

  // Store data
  // TODO: refactor for reuse with nexus
  useEffect(() => {
    if (!active || !metric) return
    console.log('Stream to Database')
    const { eeg, ppg, accel, gyro } = muse
    const $eeg = eeg.subscribe((e) => socket.sendEpoch(metric, e))
    const $ppg = ppg.subscribe((p) => socket.sendPPG(metric, p))
    const $accel = accel.subscribe((a) => socket.sendAccel(metric, a))
    const $gyro = gyro.subscribe((g) => socket.sendGyro(metric, g))

    return () => {
      $eeg.unsubscribe()
      $ppg.unsubscribe()
      $accel.unsubscribe()
      $gyro.unsubscribe()
    }
  }, [active, metric])
  return { active, startStreams, eeg, eegFiltered, saveSession, telemetry, stopStreams, accel, gyro, ppg }
}
