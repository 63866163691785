import { useState, useContext } from 'react'
import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import { Paper } from '@material-ui/core'
import { DeviceContext } from 'store/device-context'
import { EEG, PPG, Accelerometer, Gyroscope, PowerBands, FFT } from '../BioMetric/Sensors'

export interface Selections {
  [key: string]: boolean
}

export function LiveSensors() {
  const [selection, setSelection] = useState<Selections>({
    eeg: true,
    ppg: false,
    accelerometer: false,
    gyroscope: false,
    bands: false,
    fft: false,
  })
  const { eeg, ppg, accelerometer, gyroscope, bands, fft } = selection

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    setSelection({
      ...{ eeg: false, ppg: false, accelerometer: false, gyroscope: false, bands: false, fft: false },
      [event.target.name]: event.target.checked,
    })
  }

  return (
    <>
      <Paper>
        <FormControlLabel control={<Checkbox checked={eeg} onChange={handleChange} name="eeg" />} label="EEG" />
        <FormControlLabel control={<Checkbox checked={ppg} onChange={handleChange} name="ppg" />} label="PPG" />
        <FormControlLabel control={<Checkbox checked={accelerometer} onChange={handleChange} name="accelerometer" />} label="Accelerometer" />
        <FormControlLabel control={<Checkbox checked={gyroscope} onChange={handleChange} name="gyroscope" />} label="Gyroscope" />
        <FormControlLabel control={<Checkbox checked={bands} onChange={handleChange} name="bands" />} label="Powerbands" />
        <FormControlLabel control={<Checkbox checked={fft} onChange={handleChange} name="fft" />} label="FFT" />
        <Live selection={selection} />
      </Paper>
    </>
  )
}

export function Live({ selection }: { selection: Selections }) {
  const { startStreams } = useContext(DeviceContext)!
  const { telemetry, eeg, ppg, accelerometer, gyroscope, bands, fft } = selection

  return (
    <div>
      <Button variant="contained" color="primary" onClick={startStreams} className="m-8">
        Start
      </Button>

      {telemetry}
      {eeg && <EEG />}
      {ppg && <PPG />}
      {accelerometer && <Accelerometer />}
      {gyroscope && <Gyroscope />}
      {bands && <PowerBands />}
      {fft && <FFT />}
    </div>
  )
}
