import React from 'react'
import * as d3 from 'd3'
import { AccessorProps } from './helpers'
import styled from 'styled-components'

const LinePath = styled.path`
  /* transition: all 0.3s ease-out; */
  fill: none;
  /* stroke: steelblue; */
  stroke-width: 2px;
  stroke-linecap: round;
`

const AreaPath = styled.path``

interface LineProps {
  type?: 'line' | 'area'
  data: any[]
  color?: string
  xAccessor: AccessorProps
  yAccessor: AccessorProps
  y0Accessor?: AccessorProps
  interpolation?: d3.CurveFactory | d3.CurveFactoryLineOnly
}
export function Line({ type = 'line', data, xAccessor, yAccessor, y0Accessor, interpolation = d3.curveMonotoneX, ...props }: LineProps) {
  // @ts-ignore
  const lineGenerator = d3[type]().x(xAccessor).y(yAccessor).curve(interpolation)

  if (type === 'area') {
    // @ts-ignore
    lineGenerator.y0(y0Accessor).y1(yAccessor)
  }

  return <LinePath {...props} className={`line-${type}`} d={lineGenerator(data)!} stroke={props.color} />
}
