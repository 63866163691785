import { ReactNode } from 'react'
import { createContext, useState } from 'react'
import { BleDevice } from '@capacitor-community/bluetooth-le'
import { MuseStore, SensingContextProps } from './muse-store'
import { NeoRhythmStore, StimulationContextProps } from './neorhythm-store'
import { NexusStore } from './nexus-store'
import { DeviceType as DeviceName } from 'biolink'
import { useGetDevicesQuery, DeviceType } from 'generated/graphql'
import { Device } from '../types'

export interface DeviceContextProps extends Partial<SensingContextProps>, Partial<StimulationContextProps> {
  device: Device | null
  setDevice: (device: Device | null) => void
}
export const DeviceContext = createContext<DeviceContextProps | undefined>(undefined)

interface DeviceProviderProps {
  children: ReactNode
}

export function getDeviceInfo(device: BleDevice | null): { name?: DeviceName; type?: DeviceType } {
  if (device?.name?.slice(0, 4) === 'Muse') return { name: 'Muse', type: DeviceType.Muse }
  if (device?.name === 'Insai_TMS') return { name: 'Insai_TMS', type: DeviceType.Nexus }
  if (device?.name === 'NeoRhythm') return { name: 'NeoRhythm', type: DeviceType.Neorhythm }
  return {}
}

export function DeviceProvider({ children }: DeviceProviderProps) {
  const [device, setDevice] = useState<Device | null>(null)
  const { data } = useGetDevicesQuery()
  const { type } = getDeviceInfo(device)

  function handleSetDevice(device: BleDevice | null) {
    if (device) {
      const { type } = getDeviceInfo(device)
      // merge ble device info with server meta
      const info = data?.devices.find((d) => d.type === type)
      setDevice({ ...device, id: info?.id, type: type })
    } else {
      setDevice(null)
    }
  }

  // hooks in store implementation needs non-conditional render
  const muse = MuseStore(device)
  const neorhythm = NeoRhythmStore(device)
  const nexus = NexusStore(device)

  // selects connected device store and adds props
  let context: DeviceContextProps = { device, setDevice: handleSetDevice }
  if (type === DeviceType.Muse) {
    context = { ...context, ...muse }
  } else if (type === DeviceType.Neorhythm) {
    context = { ...context, ...neorhythm }
  } else if (type === DeviceType.Nexus) {
    context = { ...context, ...nexus }
  }
  return <DeviceContext.Provider value={context}>{children}</DeviceContext.Provider>
}
