import * as React from 'react'
import styled from 'styled-components/macro'
import { Power } from 'react-feather'

import { Tooltip, Menu, MenuItem, IconButton as MuiIconButton } from '@material-ui/core'
import { useUser } from 'utils/auth/useUser'

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`

function UserDropdown() {
  const { logout } = useUser()
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null)
  // const dispatch = useDispatch();

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget)
  }

  const closeMenu = () => {
    setAnchorMenu(null)
  }

  const handleSignOut = async () => {
    await logout()
  }

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton aria-owns={Boolean(anchorMenu) ? 'menu-appbar' : undefined} aria-haspopup="true" onClick={toggleMenu} color="inherit">
          <Power />
        </IconButton>
      </Tooltip>
      <Menu id="menu-appbar" anchorEl={anchorMenu} open={Boolean(anchorMenu)} onClose={closeMenu}>
        <MenuItem onClick={closeMenu}>Profile</MenuItem>
        <MenuItem onClick={handleSignOut}>Sign out</MenuItem>
      </Menu>
    </React.Fragment>
  )
}

export default UserDropdown
