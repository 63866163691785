import { useState, useContext } from 'react'
import Button from '@material-ui/core/Button'
import { CenterContainer } from '../shared/styles'
import { BLEDevice } from 'biolink'
import { DeviceContext, getDeviceInfo } from 'store/device-context'
import { DeviceType } from 'generated/graphql'
import { LiveSensors } from './Sensors'
import { Stimulation } from './Stimulation'

// TODO: move
export const ble = new BLEDevice()

export function LiveSession() {
  const { setDevice, active, saveSession, device } = useContext(DeviceContext)!

  async function hanleSave() {
    try {
      saveSession!('LiveTest')
    } catch (error) {
      // @ts-ignore
      setError(error.message)
    }
  }
  const [show, setShow] = useState(false)
  async function handleConnect() {
    try {
      const device = await ble.startScan(true)
      if (!device) return
      setDevice(device)
      setShow(true)
    } catch (e) {
      console.log(e)
    }
  }

  function renderSession() {
    const { type } = getDeviceInfo(device)
    if (show && type === DeviceType.Muse) {
      return <LiveSensors />
    }
    if (show && type === DeviceType.Neorhythm) {
      return <Stimulation />
    }
    if (show && type === DeviceType.Nexus) {
      return (
        <div>
          <Stimulation />
          <LiveSensors />
        </div>
      )
    }
  }
  return (
    <div>
      {!show && (
        <CenterContainer>
          <Button variant="contained" color="primary" onClick={handleConnect} className="m-8">
            Connect
          </Button>
        </CenterContainer>
      )}
      {active && <Button onClick={hanleSave}>Save</Button>}
      {renderSession()}
    </div>
  )
}
