import styled from 'styled-components'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'

export const DataCard = styled(Card)`
  margin: 10px;
  padding: 5px;
`

export const TaskCard = styled(Card)`
  width: 500px;
  /* height: 500px; */
  margin: 20px;
  padding: 10px;
`
export const TaskContent = styled(CardContent)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 400px;
  & h1 {
    font-size: 136px;
  }
  & h2 {
    font-size: 40px;
  }
  & h4 {
    font-size: 80px;
  }
`

export const CenterContainer = styled.section`
  display: flex;
  justify-content: center;
`
