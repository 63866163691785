import { createHttpLink, ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { setContext } from '@apollo/client/link/context'

import { getUserFromCookie, removeUserCookie } from 'utils/auth/cookies'

const uri = process.env.REACT_APP_GRAPHQL_URL

const httpLink = () =>
  createHttpLink({
    uri,
    // includes cookies in header
    credentials: 'include',
  })

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const { token, organization } = getUserFromCookie() || ''
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
      organization: organization ? organization.id : '',
    },
  }
})

const errorLink = onError((error) => {
  const { response } = error
  if (response?.errors?.map((r) => r.message).includes('Unauthenticated user!')) {
    removeUserCookie()
    client.resetStore()
    window.location.replace('/auth/sign-in')
  }
})

const link = ApolloLink.from([errorLink, authLink, httpLink()])

export const client = new ApolloClient({ link, cache: new InMemoryCache() })
