import React, { useEffect, useState } from 'react'
import { useChartDimensions } from './base/helpers'
import { Line, Chart, Axis, CHARTCOLORS } from './base'
import * as d3 from 'd3'
import { FFT, EEGEpoch } from 'biolink'
import { transpose } from 'biolink/dist/utils'
import { fft } from '@neurosity/pipes'
import { from } from 'rxjs'

export interface FFTPChartProps {
  fft: FFT
}

export interface FFTDatum {
  freq: number
  psd: number[]
}

interface FFTProps {
  epochs: EEGEpoch[]
}

export function FFTChart({ fft }: FFTPChartProps) {
  const [ref, dimensions] = useChartDimensions()
  const { freqs, psd } = fft
  const channels = new Array(psd.length).fill(0)
  const psdPerIndex: number[][] = transpose(psd)
  const dataset: FFTDatum[] = freqs.map((f, i) => ({
    freq: f,
    psd: psdPerIndex[i],
  }))

  const xAccessor = (d: FFTDatum) => d.freq

  const xScale = d3
    .scaleLinear()
    // @ts-ignore
    .domain(d3.extent(dataset, xAccessor))
    .range([0, dimensions.boundedWidth])

  const yScale = d3
    .scaleLinear()
    // TODO: index into psd?? and add padding to domain
    // @ts-ignore
    .domain(d3.extent(dataset, (d) => d.psd[0] * 2))
    .range([dimensions.boundedHeight, 0])
    .nice()

  const xAccessorScaled = (d: FFTDatum) => xScale(xAccessor(d))!

  return (
    <div ref={ref} style={{ height: 200 }}>
      <Chart dimensions={dimensions}>
        <Axis dimension="x" scale={xScale} formatTick={(d: any) => d} />
        <Axis dimension="y" scale={yScale} formatTick={(d: any) => d} />
        <>
          {channels.map((_, i) => (
            <Line
              key={`fft-${i}`}
              data={dataset}
              xAccessor={xAccessorScaled}
              yAccessor={(d: FFTDatum) => yScale(d.psd[i])!}
              color={CHARTCOLORS[i].color}
            />
          ))}
        </>
      </Chart>
    </div>
  )
}

function parseFFT(fft: FFT): FFT {
  // remove NaN values
  const size = Math.floor(fft.freqs.length / 2)
  return {
    ...fft,
    //data: epoch.data.map((d) => d.map((s) => (isNaN(s) ? 0 : s))),
    freqs: fft.freqs.slice(5, 40),
    psd: [fft.psd[0].slice(5, 40)],
  }
}

export default function FFTData({ epochs }: FFTProps) {
  const [fftState, setFFT] = useState<FFT | null>(null)
  useEffect(() => {
    from(epochs)
      .pipe(fft({ bins: 128 }))
      .toPromise()
      .then((f) => setFFT(f))
  }, [epochs])

  return fftState && <FFTChart fft={parseFFT(fftState)} />
}
