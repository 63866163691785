import { useRef, useState, useEffect } from 'react'
import ResizeObserver from 'resize-observer-polyfill'

export type AccessorProps = (d: any) => number | number

export interface Dimensions {
  height: number
  width: number
  marginTop?: number
  marginRight?: number
  marginBottom?: number
  marginLeft?: number
}

export interface CombinedDimensions extends Dimensions {
  boundedHeight: number
  boundedWidth: number
}

export function combineChartDimensions(dimensions: Dimensions): CombinedDimensions {
  const _dimensions = {
    // height: 200,
    // width: 1000,
    marginTop: 40,
    marginRight: 30,
    marginBottom: 40,
    marginLeft: 70,
    ...dimensions,
  }

  return {
    ..._dimensions,
    boundedHeight: Math.max(_dimensions.height - _dimensions.marginTop - _dimensions.marginBottom, 0),
    boundedWidth: Math.max(_dimensions.width - _dimensions.marginLeft - _dimensions.marginRight, 0),
  }
}

export function useChartDimensions(dimensions?: Dimensions): [React.MutableRefObject<any>, CombinedDimensions] {
  const ref = useRef()
  // const _dimensions = combineChartDimensions(dimensions)

  const [width, setWidth] = useState(0)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    if (dimensions && dimensions.width && dimensions.height) return

    const element = ref.current
    const resizeObserver = new ResizeObserver((entries: any) => {
      if (!Array.isArray(entries)) return
      if (!entries.length) return

      const entry = entries[0]

      if (width !== entry.contentRect.width) setWidth(entry.contentRect.width)
      if (height !== entry.contentRect.height) setHeight(entry.contentRect.height)
    })

    resizeObserver.observe(element!)

    return () => resizeObserver.unobserve(element!)
  }, [height, width, dimensions])

  const newDimensions = combineChartDimensions({
    ...dimensions,
    width: dimensions ? dimensions.width : width,
    height: dimensions ? dimensions.height : height,
  })

  return [ref, newDimensions]
}
