import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { Helmet } from 'react-helmet'
import DateFnsUtils from '@date-io/date-fns'

import { ThemeProvider } from 'styled-components/macro'
import { create } from 'jss'

import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import { StylesProvider, ThemeProvider as MuiThemeProvider, jssPreset } from '@material-ui/core/styles'

import createTheme from '../theme'
import Routes from '../routes/Routes'
import { client } from '../services/apollo'
import { DeviceProvider } from '../store/device-context'

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById('jss-insertion-point')!,
})

const App: React.FC = () => (
  <ApolloProvider client={client}>
    <Helmet titleTemplate="%s | Insai" defaultTitle="Insai - Dashboard" />
    <StylesProvider jss={jss}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <MuiThemeProvider theme={createTheme('DEFAULT')}>
          <ThemeProvider theme={createTheme('DEFAULT')}>
            <DeviceProvider>
              <Routes />
            </DeviceProvider>
          </ThemeProvider>
        </MuiThemeProvider>
      </MuiPickersUtilsProvider>
    </StylesProvider>
  </ApolloProvider>
)

export default App
