import React from 'react'
import * as d3 from 'd3'
import { CombinedDimensions } from './helpers'
import { useChartDimensions } from './Chart'
import { ScaleLinear, ScaleBand } from 'd3-scale'
import styled from 'styled-components'

const axisComponentsByDimension = {
  x: AxisHorizontal,
  y: AxisVertical,
}

interface AxisBase {
  scale: ScaleLinear<number, number> | ScaleBand<string>
  label?: string
  formatTick: Function
}

// TODO: add default props
export interface AxisProps extends AxisBase {
  dimension: 'x' | 'y'
}

export function Axis({ dimension, ...props }: AxisProps) {
  // @ts-ignore
  const dimensions: CombinedDimensions = useChartDimensions()
  const Component = axisComponentsByDimension[dimension]
  if (!Component) return null

  return <Component dimensions={dimensions} {...props} />
}

interface ChildAxisProps extends AxisBase {
  dimensions: CombinedDimensions
}

const AxisLine = styled.line`
  stroke: #bdc3c7;
`

const AxisTick = styled.text`
  font-size: 0.8em;
  transition: all 0.3s ease-out;
`
//@ts-ignore
const AxisTickHorizontal = styled(AxisTick)`
  text-anchor: middle;
`

const AxisLabel = styled.text`
  text-anchor: middle;
  font-size: 0.9em;
  letter-spacing: 0.01em;
`

function AxisHorizontal({ dimensions, label, formatTick, scale, ...props }: ChildAxisProps) {
  const numberOfTicks = dimensions.boundedWidth < 600 ? dimensions.boundedWidth / 100 : dimensions.boundedWidth / 250
  //const numberOfTicks = 20
  let ticks: number[] = []
  if ('ticks' in scale) {
    ticks = scale.ticks(numberOfTicks)
  }

  return (
    <g className="axis-horizontal" transform={`translate(0, ${dimensions.boundedHeight})`} {...props}>
      <AxisLine className="axis-line" x2={dimensions.boundedWidth} />
      {ticks.map((tick, i) => (
        <AxisTickHorizontal
          key={tick + i}
          className="axis-tick"
          // @ts-ignore
          transform={`translate(${scale(tick)}, 25)`}
        >
          {formatTick(tick)}
        </AxisTickHorizontal>
      ))}
      {label && (
        <AxisLabel className="axis-label" transform={`translate(${dimensions.boundedWidth / 2}, 0)`}>
          {label}
        </AxisLabel>
      )}
    </g>
  )
}

function AxisVertical({ dimensions, label, formatTick, scale, ...props }: ChildAxisProps) {
  const numberOfTicks = dimensions.boundedHeight / 30

  let ticks: number[] = []
  if ('ticks' in scale) {
    ticks = scale.ticks(numberOfTicks)
  }

  return (
    <g className="axis-vertical" {...props}>
      <AxisLine className="axis-line" y2={dimensions.boundedHeight} />
      {ticks.map((tick, i) => (
        <AxisTick
          key={tick}
          className="axis-tick"
          // @ts-ignore
          transform={`translate(-30, ${scale(tick)})`}
        >
          {formatTick(tick)}
        </AxisTick>
      ))}
      {label && (
        <AxisLabel
          className="axis-label"
          style={{
            transform: `translate(-56px, ${dimensions.boundedHeight / 2}px) rotate(-90deg)`,
          }}
        >
          {label}
        </AxisLabel>
      )}
    </g>
  )
}
