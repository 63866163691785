import async from '../components/MaterialUI/Async'

import BarChartIcon from '@material-ui/icons/BarChart'
import Assignment from '@material-ui/icons/Assignment'

const ProtectedPage = async(() => import('../pages/protected/ProtectedPage'))
const AuthGuard = async(() => import('../components/MaterialUI/AuthGuard'))

const CognitivePage = async(() => import('../pages/protected/CognitivePage'))
const NBackPage = async(() => import('../pages/protected/NBackPage'))
const NBackSessionPage = async(() => import('../pages/protected/NBackSessionPage'))
const DigitSpanPage = async(() => import('../pages/protected/DigitSpanPage'))
const DigitSpanSessionPage = async(() => import('../pages/protected/DigitSpanSessionPage'))
const SternbergPage = async(() => import('../pages/protected/SternbergPage'))
const SternbergSessionPage = async(() => import('../pages/protected/SternbergSessionPage'))
const RestSessionPage = async(() => import('../pages/protected/RestSessionPage'))
const TaskDetailPage = async(() => import('../pages/protected/TaskDetailPage'))
const BatteryPage = async(() => import('../pages/protected/BatteryPage'))
const BatterySessionPage = async(() => import('../pages/protected/BatterySessionPage'))
const BatteryTemplatePage = async(() => import('../pages/protected/BatteryTemplatePage'))
const SharedBatterySessionPage = async(() => import('../pages/shared/BatterySessionPage'))
const SharedNBackSessionPage = async(() => import('../pages/shared/NBackSessionPage'))
const SharedDigitSpanSessionPage = async(() => import('../pages/shared/DigitSpanSessionPage'))
const SharedSternbergSessionPage = async(() => import('../pages/shared/SternbergSessionPage'))
const SharedRestSessionPage = async(() => import('../pages/shared/RestSessionPage'))

export const nbackRoutes = {
  id: 'Nback',
  path: '/nback',
  component: NBackPage,
  children: null,
}

export const nbackSessionRoutes = {
  id: 'Nback Session',
  path: '/nback/session/:id/:battery?',
  component: NBackSessionPage,
  children: null,
}

export const digitspanRoutes = {
  id: 'digitspan',
  path: '/digitspan',
  component: DigitSpanPage,
  children: null,
}

export const digitspanSessionRoutes = {
  id: 'Digitspan Session',
  path: '/digitspan/session/:id',
  component: DigitSpanSessionPage,
  children: null,
}

export const sternbergRoutes = {
  id: 'Sternberg',
  path: '/sternberg',
  component: SternbergPage,
  children: null,
}

export const sternbergSessionRoutes = {
  id: 'Sternberg Session',
  path: '/sternberg/session/:id',
  component: SternbergSessionPage,
  children: null,
}

export const restSessionRoutes = {
  id: 'Rest Session',
  path: '/rest/session/:id/:battery?',
  component: RestSessionPage,
  children: null,
}

export const taskDetailRoutes = {
  id: 'Task Detail',
  path: '/task/:id',
  component: TaskDetailPage,
  children: null,
}

export const assessmentRoutes = {
  id: 'Assessment',
  path: '/tasks',
  icon: <BarChartIcon />,
  component: null,
  children: [
    {
      name: 'Cognitive Tasks',
      path: '/tasks',
      component: CognitivePage,
    },
    {
      name: 'Experiments',
      path: '/battery',
      component: BatteryPage,
    },
  ],
}

export const batteryRoutes = {
  id: 'Experiments',
  path: '/battery',
  icon: <Assignment />,
  component: BatteryPage,
  children: null,
}

export const batterySessionRoutes = {
  id: 'Battery Session',
  path: '/battery/session/:id',
  icon: <Assignment />,
  component: BatterySessionPage,
  children: null,
}

export const batteryTemplateRoutes = {
  id: 'Battery Template',
  path: '/battery/template/:id',
  icon: <Assignment />,
  component: BatteryTemplatePage,
  children: null,
}

export const sharedBatteryRoutes = {
  id: 'Shared Battery',
  path: '/shared',
  icon: <Assignment />,
  component: ProtectedPage,
  children: [
    { path: '/shared/battery/:id', component: SharedBatterySessionPage, name: 'Shared Battery', guard: AuthGuard },
    { path: '/shared/nback/:id/:battery?', component: SharedNBackSessionPage, name: 'Shared NBack' },
    { path: '/shared/digitspan/:id/:battery?', component: SharedDigitSpanSessionPage, name: 'Shared DigitSpan' },
    { path: '/shared/sternberg/:id/:battery?', component: SharedSternbergSessionPage, name: 'Shared Sternberg' },
    { path: '/shared/rest/:id/:battery?', component: SharedRestSessionPage, name: 'Shared Rest' },
  ],
}
