import { useState } from 'react'

export function useLocalStorage(key: string, initialValue: string | undefined) {
  const [storedValue, setStoredValue] = useState(() => {
    try {
      const item = window.localStorage.getItem(key)
      return item ? JSON.parse(item) : initialValue
    } catch (error) {
      console.log(error)
      return initialValue
    }
  })
  const setValue = (value: Function | string) => {
    try {
      const valueToStore =
        value instanceof Function ? value(storedValue) : value
      setStoredValue(valueToStore)
      window.localStorage.setItem(key, valueToStore)
    } catch (error) {
      console.log(error)
    }
  }
  return [storedValue, setValue]
}
