import { BleDevice } from '@capacitor-community/bluetooth-le'
import { useState, useEffect } from 'react'
import { BiometricEpoch, nexusPipes, NexusDevice } from 'biolink'
import { SensingContextProps, socket } from './muse-store'
import { StimulationContextProps } from './neorhythm-store'
import { useStartSessionMutation } from 'generated/graphql'
import { Device } from '../types'
import { useLocalStorage } from 'components/shared/local-storage'

const nexus = new NexusDevice()

interface BCIContextProps extends Partial<SensingContextProps>, Partial<StimulationContextProps> {}

export function NexusStore(device: Device | null): BCIContextProps {
  const [saveRecording, { loading }] = useStartSessionMutation()
  const [, setSession] = useLocalStorage('session', undefined)
  const [active, setActive] = useState(false)
  const [eeg, setEEG] = useState<BiometricEpoch | undefined>(undefined)
  const [eegFiltered, setEEGFiltered] = useState<BiometricEpoch | undefined>(undefined)
  const [telemetry, setTelemetry] = useState<number | undefined>(undefined)
  const [accel, setAccel] = useState<BiometricEpoch | undefined>(undefined)
  const [gyro, setGyro] = useState<BiometricEpoch | undefined>(undefined)
  const [ppg, setPPG] = useState<BiometricEpoch | undefined>(undefined)
  const [metric, setMetric] = useState('')

  function startStreams() {
    if (!device) throw Error('Connect Nexus device')
    try {
      nexus.startStreams(device)
    } catch (e) {
      console.log(e)
    }
  }

  function stopStreams() {
    if (!device) throw Error('Connect Nexus device')
    try {
      // stop streams
    } catch (e) {
      console.log(e)
    }
  }
  async function saveSession(type: string, program?: string) {
    // TODO: fix
    const PROGRAM = ''
    console.log('start saving')
    const startTime = JSON.stringify(new Date())
    const { data } = await saveRecording({
      variables: {
        type,
        program: program || PROGRAM,
        startTime,
        deviceType: device?.type,
      },
    })
    if (data?.startSession) {
      const { id, metric } = data.startSession
      setSession(JSON.stringify(id))
      setMetric(metric?.id!)
    }
  }

  // TODO: share Muse & Nexus effect-hook
  // Component States
  useEffect(() => {
    const { eeg, telemetry, accel, gyro, ppg } = nexus
    const $eeg = eeg.subscribe((e) => setEEG(e))
    const $eegF = nexusPipes.eegFilter(eeg).subscribe((e) => setEEGFiltered(e))
    const $tele = telemetry.subscribe((t) => setTelemetry(t.batteryLevel))
    const $accel = accel.subscribe((a) => setAccel(a))
    const $gyro = gyro.subscribe((g) => setGyro(g))
    const $ppg = ppg.subscribe((p) => setPPG(p))
    const $active = nexus.active.subscribe((a) => setActive(a))
    return () => {
      $eeg.unsubscribe()
      $eegF.unsubscribe()
      $active.unsubscribe()
      $tele.unsubscribe()
      $accel.unsubscribe()
      $gyro.unsubscribe()
      $ppg.unsubscribe()
    }
  }, [active])

  useEffect(() => {
    if (!active || !metric) return
    console.log('Stream to Database')
    const { eeg, ppg, accel, gyro } = nexus
    const $eeg = eeg.subscribe((e) => socket.sendEpoch(metric, e))
    const $ppg = ppg.subscribe((p) => socket.sendPPG(metric, p))
    const $accel = accel.subscribe((a) => socket.sendAccel(metric, a))
    const $gyro = gyro.subscribe((g) => socket.sendGyro(metric, g))

    return () => {
      $eeg.unsubscribe()
      $ppg.unsubscribe()
      $accel.unsubscribe()
      $gyro.unsubscribe()
    }
  }, [active, metric])
  return { startStreams, stopStreams, eeg, eegFiltered, telemetry, saveSession, active, accel, gyro, ppg }
}
