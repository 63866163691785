import async from '../components/MaterialUI/Async'
import SportsEsports from '@material-ui/icons/SportsEsports'
import Assignment from '@material-ui/icons/Assignment'

const GamesPage = async(() => import('../pages/protected/GamesPage'))
const GameDetailPage = async(() => import('../pages/protected/GameDetailPage'))

export const gameRoutes = {
  id: 'Games',
  path: '/csgo',
  icon: <SportsEsports />,
  component: GamesPage,
  children: null,
}

export const gameDetailRoutes = {
  id: 'CSGO Detail',
  path: '/csgo/:id',
  icon: <Assignment />,
  component: GameDetailPage,
  children: null,
}
