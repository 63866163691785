import { useEffect, useState } from 'react'
// import firebase from 'firebase/app'
import 'firebase/auth'
import { useHistory } from 'react-router'

import initFirebase from '../auth/initFirebase'
import { getUserFromCookie, removeUserCookie, UserCookie } from './cookies'
import { client } from 'services/apollo'

initFirebase()

interface useUserType {
  user?: UserCookie
  logout(): void
}

const useUser = (): useUserType => {
  const history = useHistory()
  const [user, setUser] = useState<UserCookie>()

  const logout = async () => {
    await removeUserCookie()
    await client.resetStore()
    history.push('/auth/sign-in')
  }

  useEffect(() => {
    const userFromCookie = getUserFromCookie()
    if (!userFromCookie) {
      history.push('/')
      return
    }
    setUser(userFromCookie)
  }, [])

  return { user, logout }
}

export { useUser }
