import { useState, useEffect, useContext } from 'react'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import CircularProgress from '@material-ui/core/CircularProgress'
import Button from '@material-ui/core/Button'
import Alert from '@material-ui/lab/Alert'
import { InputField, FormButton } from '../shared/Form'
import { useStartRecordingMutation } from '../../generated/graphql'
import { DialogProps } from '../../types'
import { useLocalStorage } from '../shared/local-storage'
import { DeviceContext } from 'store/device-context'
import { ble } from '../Live/Session'

export function RecordDialog(props: DialogProps) {
  const { device, setDevice, startStreams, saveSession, active } = useContext(DeviceContext)!
  const [type, setType] = useState('')
  const [message, setMessage] = useState<string | null>(null)
  const [connecting, setConnecting] = useState(false)
  const [, setRecording] = useLocalStorage('session', undefined)
  const [error, setError] = useState<string | null>(null)
  const [startRecording, { loading }] = useStartRecordingMutation()
  useEffect(() => {
    if (!device) {
      setMessage('Headset not connected')
    } else {
      setMessage(null)
    }
  }, [device])

  function handleCancel() {
    props.handleClose()
  }
  async function handleConnect() {
    try {
      const device = await ble.startScan(true)
      if (!device) return
      setDevice(device)
      setMessage(null)
    } catch (e) {
      console.log(e)
    }
    setConnecting(false)
  }

  async function handleSave() {
    try {
      saveSession!(type)
      props.handleClose()
    } catch (error) {
      // @ts-ignore
      setError(error.message)
    }
  }

  return (
    <Dialog open={props.open} onClose={props.handleClose}>
      {error && (
        <Alert severity="error" onClose={() => setError(null)}>
          {error}
        </Alert>
      )}
      {message && (
        <Alert
          severity="warning"
          action={
            connecting ? (
              <CircularProgress color="secondary" size={25} />
            ) : (
              <Button size="small" onClick={handleConnect}>
                Connect
              </Button>
            )
          }
        >
          {message}
        </Alert>
      )}
      <DialogTitle>Start Recording</DialogTitle>
      <DialogContent>
        <InputField label="Task" required value={type} onChange={(e) => setType(e.target.value)} />
      </DialogContent>
      <DialogActions>
        <FormButton onClick={handleCancel} text="Cancel" variant="text" />
        {/* TODO: not good UX */}
        {!active ? (
          <FormButton text="Stream" onClick={startStreams} />
        ) : (
          <FormButton onClick={handleSave} text="Record" color="primary" variant="text" loading={loading} disabled={!!message} />
        )}
      </DialogActions>
    </Dialog>
  )
}
