import React from 'react'
import * as d3 from 'd3'
import { useChartDimensions } from './helpers'
import { Line, Chart, Axis } from '.'
import styled from 'styled-components'
import { ChartProperties } from '.'

const ChartContainer = styled.div`
  height: 200px;
`
interface Datum {
  data: number
  timestamp: number
  index: number
}

interface LineChartProps {
  dataset: Datum[]
  label?: string
  loading?: boolean
  color?: string
  chartProperties?: ChartProperties
}

export function LineChart({ dataset, label, loading, color, chartProperties }: LineChartProps) {
  const [ref, dimensions] = useChartDimensions()
  const xAccessor = (d: Datum) => d.timestamp
  const yAccessor = (d: Datum) => d.data
  const formatDate = d3.timeFormat('%I:%M:%S')

  // @ts-ignore
  const xScale = d3.scaleTime().domain(d3.extent(dataset, xAccessor)).range([0, dimensions.boundedWidth])
  // @ts-ignore
  const yScale = d3
    .scaleLinear()
    // @ts-ignore
    .domain(chartProperties?.yDomain ? chartProperties.yDomain : d3.extent(dataset, yAccessor))
    .range([dimensions.boundedHeight, 0])

  const xAccessorScaled = (d: Datum) => xScale(xAccessor(d))!
  const yAccessorScaled = (d: Datum) => yScale(yAccessor(d))!

  // TODO: doesn't show loading state
  if (loading) {
    return <ChartContainer ref={ref}>Loading</ChartContainer>
  }

  return (
    <ChartContainer ref={ref}>
      {label}
      <Chart dimensions={dimensions}>
        <Axis
          dimension="x"
          // @ts-ignore // TODO: fix
          scale={xScale}
          formatTick={formatDate}
          label="Time (ms)"
        />
        <Axis dimension="y" scale={yScale} formatTick={(d: any) => d} label={chartProperties?.ylabel} />
        <Line data={dataset} xAccessor={xAccessorScaled} yAccessor={yAccessorScaled} color={color ? color : 'black'} />
      </Chart>
    </ChartContainer>
  )
}
