import React from 'react'
import TextField, { TextFieldProps } from '@material-ui/core/TextField'
import Button, { ButtonProps } from '@material-ui/core/Button'
import Card from '@material-ui/core/Card'
import CircularProgress from '@material-ui/core/CircularProgress'
import styled from 'styled-components'

const Input = styled(TextField)`
  margin: 5px 0px !important;
`

export function InputField(props: TextFieldProps) {
  return <Input size="small" variant="outlined" fullWidth {...props} />
}

// TODO: refactor to pass text as props and enable loading (circularProgress)
interface FormButtonProps extends ButtonProps {
  loading?: boolean
  text?: string
}
export function FormButton(props: FormButtonProps) {
  const { loading, text, ..._props } = props
  return (
    <Button variant="contained" {..._props}>
      {loading ? <CircularProgress size={20} /> : text}
    </Button>
  )
}

interface CardProps {
  children: React.ReactNode
}

export const CenterContainer = styled.section`
  margin-top: 100px;
  display: flex;
  justify-content: center;
`

const FCard = styled(Card)`
  width: 500px;
`
export function FormCard(props: CardProps) {
  return (
    <CenterContainer>
      <form noValidate autoComplete="off">
        <FCard>{props.children}</FCard>
      </form>
    </CenterContainer>
  )
}

export const Header = styled.div`
  height: 50px;
  padding: 5px;
`
