import firebase from 'firebase/app'
import 'firebase/auth'
// import 'firebase/firestore' // If you need it
// import 'firebase/storage' // If you need it
import 'firebase/analytics' // If you need it

const clientCredentials = {
  apiKey: process.env.REACT_APP_PUBLIC_FIREBASE_PUBLIC_API_KEY,
  authDomain: process.env.REACT_APP_PUBLIC_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_PUBLIC_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_PUBLIC_FIREBASE_PROJECT_ID,
  // storageBucket: process.env.REACT_APP_PUBLIC_FIREBASE_STORAGE_BUCKET,
  // messagingSenderId: process.env.REACT_APP_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  // appId: process.env.REACT_APP_PUBLIC_FIREBASE_APP_ID,
}

export default function initFirebase() {
  if (!firebase.apps.length) {
    firebase.initializeApp(clientCredentials)
    // To enable analytics. https://firebase.google.com/docs/analytics/get-started
    if ('measurementId' in clientCredentials) firebase.analytics()
  }
}
