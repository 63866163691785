import React, { useEffect, useState, useMemo, useContext } from 'react'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import IconButton from '@material-ui/core/IconButton'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import MoreVert from '@material-ui/icons/MoreVert'
import styled from 'styled-components'
import { useGetRecordingQuery, useStopRecordingMutation } from '../../generated/graphql'
import { RecordDialog } from '../Recording/StartRecording'
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled'
import { DeviceContext } from 'store/device-context'
// import { ConnectionContext } from '../context'

const RecordingContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
  margin-top: 5px;
  border-radius: 4px;
`

const Time = styled.div`
  /* width: 50px; */
  display: flex;
  justify-content: center;
  color: #000;
`

export function Recording({ id }: { id: string }) {
  const { stopStreams, active } = useContext(DeviceContext)!
  const [elapsedTime, setElapsedTime] = useState<number[] | null>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { data } = useGetRecordingQuery({
    variables: { id },
  })
  const [stopRecording] = useStopRecordingMutation()

  let startTime = useMemo(() => new Date(), [])
  if (data?.session?.startTime) {
    startTime = new Date(JSON.parse(data.session.startTime))
  }

  useEffect(() => {
    const updateInterval = setInterval(() => {
      const now = new Date()
      const ms_elapsed = now.getTime() - startTime.getTime()
      const seconds = Math.floor((ms_elapsed / 1000) % 60)
      const mins = Math.floor(ms_elapsed / 1000 / 60)
      setElapsedTime([mins, seconds])
    }, 1000)

    if (!active) clearInterval(updateInterval)
  }, [startTime, active])

  function handleClose() {
    setAnchorEl(null)
  }
  function handleRecordMenuOpen(event: React.MouseEvent<HTMLElement>) {
    setAnchorEl(event.currentTarget)
  }

  function handleStop() {
    const stopTime = new Date().toJSON()
    stopRecording({
      variables: {
        id,
        stopTime,
      },
    })
    // fetch recordings
    localStorage.removeItem('session')
    stopStreams && stopStreams()
  }

  if (!elapsedTime) {
    return null
  }

  return (
    <RecordingContainer>
      <div className="flex py-1 space-x-2 items-center rounded-md">
        <div className="text-primary px-2">
          <span className="inline-block w-2 h-2 rounded-full bg-green-500 mr-2" />
          Recording: <span className="font-bold">{data?.session?.type}</span>
        </div>
        <Time>
          <Chip className="mr-1" label={'min'} avatar={<Avatar>{elapsedTime[0]}</Avatar>} variant="outlined" color="primary" size="small" />
          <Chip label={'sec'} avatar={<Avatar>{elapsedTime[1]}</Avatar>} variant="outlined" color="primary" size="small" />
        </Time>
      </div>
      <IconButton size="small" onClick={handleRecordMenuOpen} color="inherit" className="text-black mr-1">
        <MoreVert />
      </IconButton>
      <Menu
        id="recording-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleStop}>Stop</MenuItem>
      </Menu>
    </RecordingContainer>
  )
}

export function RecordingWidget() {
  const [recording, setRecording] = useState<string | null>(null)
  useEffect(() => {
    const interval = setInterval(() => {
      const _recording = JSON.parse(localStorage.getItem('session')!)
      if (recording !== _recording) {
        setRecording(_recording)
      }
    }, 1000)
    return () => {
      clearInterval(interval)
    }
  })
  if (!recording) {
    return null
  }
  return <Recording id={recording} />
}

export function RecordingButton() {
  const [record, setRecord] = useState(false)
  function handleOpen() {
    setRecord(true)
  }
  return (
    <React.Fragment>
      <IconButton onClick={handleOpen} color="inherit">
        <PlayCircleFilledIcon />
      </IconButton>
      <RecordDialog open={record} handleClose={() => setRecord(false)} />
    </React.Fragment>
  )
}
