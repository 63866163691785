import { gql } from '@apollo/client';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03, compliant with the `full-date` format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  Date: any;
  DateTime: any;
};

export type Accelerometer = {
  __typename?: 'Accelerometer';
  id: Scalars['String'];
  metric?: Maybe<BioMetric>;
  samples: Array<AccelerometerSample>;
  createdAt: Scalars['DateTime'];
  startTime?: Maybe<Scalars['Float']>;
  samplingRate?: Maybe<Scalars['Float']>;
  bufferDuration: Scalars['Int'];
  bufferInterval: Scalars['Int'];
  packetLossIndex: Array<Scalars['Int']>;
  packetLossSkipped: Array<Scalars['Int']>;
};


export type AccelerometerSamplesArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<AccelerometerSampleWhereUniqueInput>;
};

export type AccelerometerCreateManyMetricInput = {
  id?: Maybe<Scalars['String']>;
  bufferDuration: Scalars['Int'];
  bufferInterval: Scalars['Int'];
  samplingRate?: Maybe<Scalars['Float']>;
  startTime?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  packetLossIndex?: Maybe<AccelerometerCreateManypacketLossIndexInput>;
  packetLossSkipped?: Maybe<AccelerometerCreateManypacketLossSkippedInput>;
};

export type AccelerometerCreateManyMetricInputEnvelope = {
  data?: Maybe<Array<AccelerometerCreateManyMetricInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccelerometerCreateManypacketLossIndexInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type AccelerometerCreateManypacketLossSkippedInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type AccelerometerCreateNestedManyWithoutMetricInput = {
  create?: Maybe<Array<AccelerometerCreateWithoutMetricInput>>;
  connectOrCreate?: Maybe<Array<AccelerometerCreateOrConnectWithoutMetricInput>>;
  createMany?: Maybe<AccelerometerCreateManyMetricInputEnvelope>;
  connect?: Maybe<Array<AccelerometerWhereUniqueInput>>;
};

export type AccelerometerCreateOrConnectWithoutMetricInput = {
  where: AccelerometerWhereUniqueInput;
  create: AccelerometerCreateWithoutMetricInput;
};

export type AccelerometerCreateWithoutMetricInput = {
  id?: Maybe<Scalars['String']>;
  bufferDuration: Scalars['Int'];
  bufferInterval: Scalars['Int'];
  samplingRate?: Maybe<Scalars['Float']>;
  startTime?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  packetLossIndex?: Maybe<AccelerometerCreatepacketLossIndexInput>;
  packetLossSkipped?: Maybe<AccelerometerCreatepacketLossSkippedInput>;
  samples?: Maybe<AccelerometerSampleCreateNestedManyWithoutAccelerometerInput>;
};

export type AccelerometerCreatepacketLossIndexInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type AccelerometerCreatepacketLossSkippedInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type AccelerometerListRelationFilter = {
  every?: Maybe<AccelerometerWhereInput>;
  some?: Maybe<AccelerometerWhereInput>;
  none?: Maybe<AccelerometerWhereInput>;
};

export type AccelerometerOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
};

export type AccelerometerOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  metricId?: Maybe<SortOrder>;
  bufferDuration?: Maybe<SortOrder>;
  bufferInterval?: Maybe<SortOrder>;
  packetLossIndex?: Maybe<SortOrder>;
  packetLossSkipped?: Maybe<SortOrder>;
  samplingRate?: Maybe<SortOrder>;
  startTime?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  metric?: Maybe<BioMetricOrderByWithRelationInput>;
  samples?: Maybe<AccelerometerSampleOrderByRelationAggregateInput>;
};

export type AccelerometerSample = {
  __typename?: 'AccelerometerSample';
  id: Scalars['String'];
  channel: Scalars['Int'];
  data: Array<Scalars['Float']>;
};

export type AccelerometerSampleCreateManyAccelerometerInput = {
  id?: Maybe<Scalars['String']>;
  channel: Scalars['Int'];
  data?: Maybe<AccelerometerSampleCreateManydataInput>;
};

export type AccelerometerSampleCreateManyAccelerometerInputEnvelope = {
  data?: Maybe<Array<AccelerometerSampleCreateManyAccelerometerInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type AccelerometerSampleCreateManydataInput = {
  set?: Maybe<Array<Scalars['Float']>>;
};

export type AccelerometerSampleCreateNestedManyWithoutAccelerometerInput = {
  create?: Maybe<Array<AccelerometerSampleCreateWithoutAccelerometerInput>>;
  connectOrCreate?: Maybe<Array<AccelerometerSampleCreateOrConnectWithoutAccelerometerInput>>;
  createMany?: Maybe<AccelerometerSampleCreateManyAccelerometerInputEnvelope>;
  connect?: Maybe<Array<AccelerometerSampleWhereUniqueInput>>;
};

export type AccelerometerSampleCreateOrConnectWithoutAccelerometerInput = {
  where: AccelerometerSampleWhereUniqueInput;
  create: AccelerometerSampleCreateWithoutAccelerometerInput;
};

export type AccelerometerSampleCreateWithoutAccelerometerInput = {
  id?: Maybe<Scalars['String']>;
  channel: Scalars['Int'];
  data?: Maybe<AccelerometerSampleCreatedataInput>;
};

export type AccelerometerSampleCreatedataInput = {
  set?: Maybe<Array<Scalars['Float']>>;
};

export type AccelerometerSampleListRelationFilter = {
  every?: Maybe<AccelerometerSampleWhereInput>;
  some?: Maybe<AccelerometerSampleWhereInput>;
  none?: Maybe<AccelerometerSampleWhereInput>;
};

export type AccelerometerSampleOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
};

export type AccelerometerSampleScalarWhereInput = {
  AND?: Maybe<Array<AccelerometerSampleScalarWhereInput>>;
  OR?: Maybe<Array<AccelerometerSampleScalarWhereInput>>;
  NOT?: Maybe<Array<AccelerometerSampleScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  channel?: Maybe<IntFilter>;
  accelerometerId?: Maybe<StringNullableFilter>;
  data?: Maybe<FloatNullableListFilter>;
};

export type AccelerometerSampleUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  channel?: Maybe<IntFieldUpdateOperationsInput>;
  data?: Maybe<AccelerometerSampleUpdatedataInput>;
};

export type AccelerometerSampleUpdateManyWithWhereWithoutAccelerometerInput = {
  where: AccelerometerSampleScalarWhereInput;
  data: AccelerometerSampleUpdateManyMutationInput;
};

export type AccelerometerSampleUpdateManyWithoutAccelerometerInput = {
  create?: Maybe<Array<AccelerometerSampleCreateWithoutAccelerometerInput>>;
  connectOrCreate?: Maybe<Array<AccelerometerSampleCreateOrConnectWithoutAccelerometerInput>>;
  upsert?: Maybe<Array<AccelerometerSampleUpsertWithWhereUniqueWithoutAccelerometerInput>>;
  createMany?: Maybe<AccelerometerSampleCreateManyAccelerometerInputEnvelope>;
  connect?: Maybe<Array<AccelerometerSampleWhereUniqueInput>>;
  set?: Maybe<Array<AccelerometerSampleWhereUniqueInput>>;
  disconnect?: Maybe<Array<AccelerometerSampleWhereUniqueInput>>;
  delete?: Maybe<Array<AccelerometerSampleWhereUniqueInput>>;
  update?: Maybe<Array<AccelerometerSampleUpdateWithWhereUniqueWithoutAccelerometerInput>>;
  updateMany?: Maybe<Array<AccelerometerSampleUpdateManyWithWhereWithoutAccelerometerInput>>;
  deleteMany?: Maybe<Array<AccelerometerSampleScalarWhereInput>>;
};

export type AccelerometerSampleUpdateWithWhereUniqueWithoutAccelerometerInput = {
  where: AccelerometerSampleWhereUniqueInput;
  data: AccelerometerSampleUpdateWithoutAccelerometerInput;
};

export type AccelerometerSampleUpdateWithoutAccelerometerInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  channel?: Maybe<IntFieldUpdateOperationsInput>;
  data?: Maybe<AccelerometerSampleUpdatedataInput>;
};

export type AccelerometerSampleUpdatedataInput = {
  set?: Maybe<Array<Scalars['Float']>>;
  push?: Maybe<Scalars['Float']>;
};

export type AccelerometerSampleUpsertWithWhereUniqueWithoutAccelerometerInput = {
  where: AccelerometerSampleWhereUniqueInput;
  update: AccelerometerSampleUpdateWithoutAccelerometerInput;
  create: AccelerometerSampleCreateWithoutAccelerometerInput;
};

export type AccelerometerSampleWhereInput = {
  AND?: Maybe<Array<AccelerometerSampleWhereInput>>;
  OR?: Maybe<Array<AccelerometerSampleWhereInput>>;
  NOT?: Maybe<Array<AccelerometerSampleWhereInput>>;
  id?: Maybe<StringFilter>;
  channel?: Maybe<IntFilter>;
  accelerometerId?: Maybe<StringNullableFilter>;
  data?: Maybe<FloatNullableListFilter>;
  accelerometer?: Maybe<AccelerometerWhereInput>;
};

export type AccelerometerSampleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type AccelerometerScalarWhereInput = {
  AND?: Maybe<Array<AccelerometerScalarWhereInput>>;
  OR?: Maybe<Array<AccelerometerScalarWhereInput>>;
  NOT?: Maybe<Array<AccelerometerScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  metricId?: Maybe<StringNullableFilter>;
  bufferDuration?: Maybe<IntFilter>;
  bufferInterval?: Maybe<IntFilter>;
  packetLossIndex?: Maybe<IntNullableListFilter>;
  packetLossSkipped?: Maybe<IntNullableListFilter>;
  samplingRate?: Maybe<FloatNullableFilter>;
  startTime?: Maybe<FloatNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type AccelerometerUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  bufferDuration?: Maybe<IntFieldUpdateOperationsInput>;
  bufferInterval?: Maybe<IntFieldUpdateOperationsInput>;
  samplingRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  startTime?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  packetLossIndex?: Maybe<AccelerometerUpdatepacketLossIndexInput>;
  packetLossSkipped?: Maybe<AccelerometerUpdatepacketLossSkippedInput>;
};

export type AccelerometerUpdateManyWithWhereWithoutMetricInput = {
  where: AccelerometerScalarWhereInput;
  data: AccelerometerUpdateManyMutationInput;
};

export type AccelerometerUpdateManyWithoutMetricInput = {
  create?: Maybe<Array<AccelerometerCreateWithoutMetricInput>>;
  connectOrCreate?: Maybe<Array<AccelerometerCreateOrConnectWithoutMetricInput>>;
  upsert?: Maybe<Array<AccelerometerUpsertWithWhereUniqueWithoutMetricInput>>;
  createMany?: Maybe<AccelerometerCreateManyMetricInputEnvelope>;
  connect?: Maybe<Array<AccelerometerWhereUniqueInput>>;
  set?: Maybe<Array<AccelerometerWhereUniqueInput>>;
  disconnect?: Maybe<Array<AccelerometerWhereUniqueInput>>;
  delete?: Maybe<Array<AccelerometerWhereUniqueInput>>;
  update?: Maybe<Array<AccelerometerUpdateWithWhereUniqueWithoutMetricInput>>;
  updateMany?: Maybe<Array<AccelerometerUpdateManyWithWhereWithoutMetricInput>>;
  deleteMany?: Maybe<Array<AccelerometerScalarWhereInput>>;
};

export type AccelerometerUpdateWithWhereUniqueWithoutMetricInput = {
  where: AccelerometerWhereUniqueInput;
  data: AccelerometerUpdateWithoutMetricInput;
};

export type AccelerometerUpdateWithoutMetricInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  bufferDuration?: Maybe<IntFieldUpdateOperationsInput>;
  bufferInterval?: Maybe<IntFieldUpdateOperationsInput>;
  samplingRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  startTime?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  packetLossIndex?: Maybe<AccelerometerUpdatepacketLossIndexInput>;
  packetLossSkipped?: Maybe<AccelerometerUpdatepacketLossSkippedInput>;
  samples?: Maybe<AccelerometerSampleUpdateManyWithoutAccelerometerInput>;
};

export type AccelerometerUpdatepacketLossIndexInput = {
  set?: Maybe<Array<Scalars['Int']>>;
  push?: Maybe<Scalars['Int']>;
};

export type AccelerometerUpdatepacketLossSkippedInput = {
  set?: Maybe<Array<Scalars['Int']>>;
  push?: Maybe<Scalars['Int']>;
};

export type AccelerometerUpsertWithWhereUniqueWithoutMetricInput = {
  where: AccelerometerWhereUniqueInput;
  update: AccelerometerUpdateWithoutMetricInput;
  create: AccelerometerCreateWithoutMetricInput;
};

export type AccelerometerWhereInput = {
  AND?: Maybe<Array<AccelerometerWhereInput>>;
  OR?: Maybe<Array<AccelerometerWhereInput>>;
  NOT?: Maybe<Array<AccelerometerWhereInput>>;
  id?: Maybe<StringFilter>;
  metricId?: Maybe<StringNullableFilter>;
  bufferDuration?: Maybe<IntFilter>;
  bufferInterval?: Maybe<IntFilter>;
  packetLossIndex?: Maybe<IntNullableListFilter>;
  packetLossSkipped?: Maybe<IntNullableListFilter>;
  samplingRate?: Maybe<FloatNullableFilter>;
  startTime?: Maybe<FloatNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  metric?: Maybe<BioMetricWhereInput>;
  samples?: Maybe<AccelerometerSampleListRelationFilter>;
};

export type AccelerometerWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type AuthPayload = {
  __typename?: 'AuthPayload';
  accessToken?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
};

export enum BatteryType {
  Template = 'TEMPLATE',
  Active = 'ACTIVE'
}

export type BioMetric = {
  __typename?: 'BioMetric';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  epochs: Array<Eeg>;
  events: Array<Event>;
  user: User;
  task?: Maybe<Task>;
  accelerometer: Array<Accelerometer>;
  gyroscope: Array<Gyroscope>;
  telemetry: Array<Telemetry>;
  ppg: Array<Ppg>;
  session?: Maybe<Session>;
  organization: Organization;
  cognitive?: Maybe<Array<Maybe<Cognitive>>>;
};


export type BioMetricEpochsArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<EegWhereUniqueInput>;
};


export type BioMetricEventsArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<EventWhereUniqueInput>;
};


export type BioMetricAccelerometerArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<AccelerometerWhereUniqueInput>;
};


export type BioMetricGyroscopeArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<GyroscopeWhereUniqueInput>;
};


export type BioMetricTelemetryArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<TelemetryWhereUniqueInput>;
};


export type BioMetricPpgArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<PpgWhereUniqueInput>;
};

export type BioMetricCreateManyOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type BioMetricCreateManyOrganizationInputEnvelope = {
  data?: Maybe<Array<BioMetricCreateManyOrganizationInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BioMetricCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId: Scalars['String'];
};

export type BioMetricCreateManyUserInputEnvelope = {
  data?: Maybe<Array<BioMetricCreateManyUserInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type BioMetricCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<BioMetricCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<BioMetricCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<BioMetricCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<BioMetricWhereUniqueInput>>;
};

export type BioMetricCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<BioMetricCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<BioMetricCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<BioMetricCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<BioMetricWhereUniqueInput>>;
};

export type BioMetricCreateNestedOneWithoutSessionInput = {
  create?: Maybe<BioMetricCreateWithoutSessionInput>;
  connectOrCreate?: Maybe<BioMetricCreateOrConnectWithoutSessionInput>;
  connect?: Maybe<BioMetricWhereUniqueInput>;
};

export type BioMetricCreateNestedOneWithoutTaskInput = {
  create?: Maybe<BioMetricCreateWithoutTaskInput>;
  connectOrCreate?: Maybe<BioMetricCreateOrConnectWithoutTaskInput>;
  connect?: Maybe<BioMetricWhereUniqueInput>;
};

export type BioMetricCreateOrConnectWithoutOrganizationInput = {
  where: BioMetricWhereUniqueInput;
  create: BioMetricCreateWithoutOrganizationInput;
};

export type BioMetricCreateOrConnectWithoutSessionInput = {
  where: BioMetricWhereUniqueInput;
  create: BioMetricCreateWithoutSessionInput;
};

export type BioMetricCreateOrConnectWithoutTaskInput = {
  where: BioMetricWhereUniqueInput;
  create: BioMetricCreateWithoutTaskInput;
};

export type BioMetricCreateOrConnectWithoutUserInput = {
  where: BioMetricWhereUniqueInput;
  create: BioMetricCreateWithoutUserInput;
};

export type BioMetricCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user: UserCreateNestedOneWithoutMetricInput;
  accelerometer?: Maybe<AccelerometerCreateNestedManyWithoutMetricInput>;
  cognitive?: Maybe<CognitiveCreateNestedManyWithoutMetricInput>;
  epochs?: Maybe<EegCreateNestedManyWithoutMetricInput>;
  events?: Maybe<EventCreateNestedManyWithoutMetricInput>;
  gyroscope?: Maybe<GyroscopeCreateNestedManyWithoutMetricInput>;
  ppg?: Maybe<PpgCreateNestedManyWithoutMetricInput>;
  session?: Maybe<SessionCreateNestedOneWithoutMetricInput>;
  task?: Maybe<TaskCreateNestedOneWithoutMetricInput>;
  telemetry?: Maybe<TelemetryCreateNestedManyWithoutMetricInput>;
};

export type BioMetricCreateWithoutSessionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization: OrganizationCreateNestedOneWithoutBioMetricInput;
  user: UserCreateNestedOneWithoutMetricInput;
  accelerometer?: Maybe<AccelerometerCreateNestedManyWithoutMetricInput>;
  cognitive?: Maybe<CognitiveCreateNestedManyWithoutMetricInput>;
  epochs?: Maybe<EegCreateNestedManyWithoutMetricInput>;
  events?: Maybe<EventCreateNestedManyWithoutMetricInput>;
  gyroscope?: Maybe<GyroscopeCreateNestedManyWithoutMetricInput>;
  ppg?: Maybe<PpgCreateNestedManyWithoutMetricInput>;
  task?: Maybe<TaskCreateNestedOneWithoutMetricInput>;
  telemetry?: Maybe<TelemetryCreateNestedManyWithoutMetricInput>;
};

export type BioMetricCreateWithoutTaskInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization: OrganizationCreateNestedOneWithoutBioMetricInput;
  user: UserCreateNestedOneWithoutMetricInput;
  accelerometer?: Maybe<AccelerometerCreateNestedManyWithoutMetricInput>;
  cognitive?: Maybe<CognitiveCreateNestedManyWithoutMetricInput>;
  epochs?: Maybe<EegCreateNestedManyWithoutMetricInput>;
  events?: Maybe<EventCreateNestedManyWithoutMetricInput>;
  gyroscope?: Maybe<GyroscopeCreateNestedManyWithoutMetricInput>;
  ppg?: Maybe<PpgCreateNestedManyWithoutMetricInput>;
  session?: Maybe<SessionCreateNestedOneWithoutMetricInput>;
  telemetry?: Maybe<TelemetryCreateNestedManyWithoutMetricInput>;
};

export type BioMetricCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization: OrganizationCreateNestedOneWithoutBioMetricInput;
  accelerometer?: Maybe<AccelerometerCreateNestedManyWithoutMetricInput>;
  cognitive?: Maybe<CognitiveCreateNestedManyWithoutMetricInput>;
  epochs?: Maybe<EegCreateNestedManyWithoutMetricInput>;
  events?: Maybe<EventCreateNestedManyWithoutMetricInput>;
  gyroscope?: Maybe<GyroscopeCreateNestedManyWithoutMetricInput>;
  ppg?: Maybe<PpgCreateNestedManyWithoutMetricInput>;
  session?: Maybe<SessionCreateNestedOneWithoutMetricInput>;
  task?: Maybe<TaskCreateNestedOneWithoutMetricInput>;
  telemetry?: Maybe<TelemetryCreateNestedManyWithoutMetricInput>;
};

export type BioMetricListRelationFilter = {
  every?: Maybe<BioMetricWhereInput>;
  some?: Maybe<BioMetricWhereInput>;
  none?: Maybe<BioMetricWhereInput>;
};

export type BioMetricOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
};

export type BioMetricOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  organization?: Maybe<OrganizationOrderByWithRelationInput>;
  user?: Maybe<UserOrderByWithRelationInput>;
  accelerometer?: Maybe<AccelerometerOrderByRelationAggregateInput>;
  cognitive?: Maybe<CognitiveOrderByRelationAggregateInput>;
  epochs?: Maybe<EegOrderByRelationAggregateInput>;
  events?: Maybe<EventOrderByRelationAggregateInput>;
  gyroscope?: Maybe<GyroscopeOrderByRelationAggregateInput>;
  ppg?: Maybe<PpgOrderByRelationAggregateInput>;
  session?: Maybe<SessionOrderByWithRelationInput>;
  task?: Maybe<TaskOrderByWithRelationInput>;
  telemetry?: Maybe<TelemetryOrderByRelationAggregateInput>;
};

export type BioMetricScalarWhereInput = {
  AND?: Maybe<Array<BioMetricScalarWhereInput>>;
  OR?: Maybe<Array<BioMetricScalarWhereInput>>;
  NOT?: Maybe<Array<BioMetricScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  organizationId?: Maybe<StringFilter>;
};

export type BioMetricUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutBioMetricInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutMetricInput>;
  accelerometer?: Maybe<AccelerometerUpdateManyWithoutMetricInput>;
  cognitive?: Maybe<CognitiveUpdateManyWithoutMetricInput>;
  epochs?: Maybe<EegUpdateManyWithoutMetricInput>;
  events?: Maybe<EventUpdateManyWithoutMetricInput>;
  gyroscope?: Maybe<GyroscopeUpdateManyWithoutMetricInput>;
  ppg?: Maybe<PpgUpdateManyWithoutMetricInput>;
  session?: Maybe<SessionUpdateOneWithoutMetricInput>;
  task?: Maybe<TaskUpdateOneWithoutMetricInput>;
  telemetry?: Maybe<TelemetryUpdateManyWithoutMetricInput>;
};

export type BioMetricUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type BioMetricUpdateManyWithWhereWithoutOrganizationInput = {
  where: BioMetricScalarWhereInput;
  data: BioMetricUpdateManyMutationInput;
};

export type BioMetricUpdateManyWithWhereWithoutUserInput = {
  where: BioMetricScalarWhereInput;
  data: BioMetricUpdateManyMutationInput;
};

export type BioMetricUpdateManyWithoutOrganizationInput = {
  create?: Maybe<Array<BioMetricCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<BioMetricCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<BioMetricUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<BioMetricCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<BioMetricWhereUniqueInput>>;
  set?: Maybe<Array<BioMetricWhereUniqueInput>>;
  disconnect?: Maybe<Array<BioMetricWhereUniqueInput>>;
  delete?: Maybe<Array<BioMetricWhereUniqueInput>>;
  update?: Maybe<Array<BioMetricUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<BioMetricUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<BioMetricScalarWhereInput>>;
};

export type BioMetricUpdateManyWithoutUserInput = {
  create?: Maybe<Array<BioMetricCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<BioMetricCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<BioMetricUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<BioMetricCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<BioMetricWhereUniqueInput>>;
  set?: Maybe<Array<BioMetricWhereUniqueInput>>;
  disconnect?: Maybe<Array<BioMetricWhereUniqueInput>>;
  delete?: Maybe<Array<BioMetricWhereUniqueInput>>;
  update?: Maybe<Array<BioMetricUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<BioMetricUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<BioMetricScalarWhereInput>>;
};

export type BioMetricUpdateOneWithoutSessionInput = {
  create?: Maybe<BioMetricCreateWithoutSessionInput>;
  connectOrCreate?: Maybe<BioMetricCreateOrConnectWithoutSessionInput>;
  upsert?: Maybe<BioMetricUpsertWithoutSessionInput>;
  connect?: Maybe<BioMetricWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BioMetricUpdateWithoutSessionInput>;
};

export type BioMetricUpdateOneWithoutTaskInput = {
  create?: Maybe<BioMetricCreateWithoutTaskInput>;
  connectOrCreate?: Maybe<BioMetricCreateOrConnectWithoutTaskInput>;
  upsert?: Maybe<BioMetricUpsertWithoutTaskInput>;
  connect?: Maybe<BioMetricWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<BioMetricUpdateWithoutTaskInput>;
};

export type BioMetricUpdateWithWhereUniqueWithoutOrganizationInput = {
  where: BioMetricWhereUniqueInput;
  data: BioMetricUpdateWithoutOrganizationInput;
};

export type BioMetricUpdateWithWhereUniqueWithoutUserInput = {
  where: BioMetricWhereUniqueInput;
  data: BioMetricUpdateWithoutUserInput;
};

export type BioMetricUpdateWithoutOrganizationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutMetricInput>;
  accelerometer?: Maybe<AccelerometerUpdateManyWithoutMetricInput>;
  cognitive?: Maybe<CognitiveUpdateManyWithoutMetricInput>;
  epochs?: Maybe<EegUpdateManyWithoutMetricInput>;
  events?: Maybe<EventUpdateManyWithoutMetricInput>;
  gyroscope?: Maybe<GyroscopeUpdateManyWithoutMetricInput>;
  ppg?: Maybe<PpgUpdateManyWithoutMetricInput>;
  session?: Maybe<SessionUpdateOneWithoutMetricInput>;
  task?: Maybe<TaskUpdateOneWithoutMetricInput>;
  telemetry?: Maybe<TelemetryUpdateManyWithoutMetricInput>;
};

export type BioMetricUpdateWithoutSessionInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutBioMetricInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutMetricInput>;
  accelerometer?: Maybe<AccelerometerUpdateManyWithoutMetricInput>;
  cognitive?: Maybe<CognitiveUpdateManyWithoutMetricInput>;
  epochs?: Maybe<EegUpdateManyWithoutMetricInput>;
  events?: Maybe<EventUpdateManyWithoutMetricInput>;
  gyroscope?: Maybe<GyroscopeUpdateManyWithoutMetricInput>;
  ppg?: Maybe<PpgUpdateManyWithoutMetricInput>;
  task?: Maybe<TaskUpdateOneWithoutMetricInput>;
  telemetry?: Maybe<TelemetryUpdateManyWithoutMetricInput>;
};

export type BioMetricUpdateWithoutTaskInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutBioMetricInput>;
  user?: Maybe<UserUpdateOneRequiredWithoutMetricInput>;
  accelerometer?: Maybe<AccelerometerUpdateManyWithoutMetricInput>;
  cognitive?: Maybe<CognitiveUpdateManyWithoutMetricInput>;
  epochs?: Maybe<EegUpdateManyWithoutMetricInput>;
  events?: Maybe<EventUpdateManyWithoutMetricInput>;
  gyroscope?: Maybe<GyroscopeUpdateManyWithoutMetricInput>;
  ppg?: Maybe<PpgUpdateManyWithoutMetricInput>;
  session?: Maybe<SessionUpdateOneWithoutMetricInput>;
  telemetry?: Maybe<TelemetryUpdateManyWithoutMetricInput>;
};

export type BioMetricUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  organization?: Maybe<OrganizationUpdateOneRequiredWithoutBioMetricInput>;
  accelerometer?: Maybe<AccelerometerUpdateManyWithoutMetricInput>;
  cognitive?: Maybe<CognitiveUpdateManyWithoutMetricInput>;
  epochs?: Maybe<EegUpdateManyWithoutMetricInput>;
  events?: Maybe<EventUpdateManyWithoutMetricInput>;
  gyroscope?: Maybe<GyroscopeUpdateManyWithoutMetricInput>;
  ppg?: Maybe<PpgUpdateManyWithoutMetricInput>;
  session?: Maybe<SessionUpdateOneWithoutMetricInput>;
  task?: Maybe<TaskUpdateOneWithoutMetricInput>;
  telemetry?: Maybe<TelemetryUpdateManyWithoutMetricInput>;
};

export type BioMetricUpsertWithWhereUniqueWithoutOrganizationInput = {
  where: BioMetricWhereUniqueInput;
  update: BioMetricUpdateWithoutOrganizationInput;
  create: BioMetricCreateWithoutOrganizationInput;
};

export type BioMetricUpsertWithWhereUniqueWithoutUserInput = {
  where: BioMetricWhereUniqueInput;
  update: BioMetricUpdateWithoutUserInput;
  create: BioMetricCreateWithoutUserInput;
};

export type BioMetricUpsertWithoutSessionInput = {
  update: BioMetricUpdateWithoutSessionInput;
  create: BioMetricCreateWithoutSessionInput;
};

export type BioMetricUpsertWithoutTaskInput = {
  update: BioMetricUpdateWithoutTaskInput;
  create: BioMetricCreateWithoutTaskInput;
};

export type BioMetricWhereInput = {
  AND?: Maybe<Array<BioMetricWhereInput>>;
  OR?: Maybe<Array<BioMetricWhereInput>>;
  NOT?: Maybe<Array<BioMetricWhereInput>>;
  id?: Maybe<StringFilter>;
  userId?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  organizationId?: Maybe<StringFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  user?: Maybe<UserWhereInput>;
  accelerometer?: Maybe<AccelerometerListRelationFilter>;
  cognitive?: Maybe<CognitiveListRelationFilter>;
  epochs?: Maybe<EegListRelationFilter>;
  events?: Maybe<EventListRelationFilter>;
  gyroscope?: Maybe<GyroscopeListRelationFilter>;
  ppg?: Maybe<PpgListRelationFilter>;
  session?: Maybe<SessionWhereInput>;
  task?: Maybe<TaskWhereInput>;
  telemetry?: Maybe<TelemetryListRelationFilter>;
};

export type BioMetricWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type BoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type BoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type BoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type CsGame = {
  __typename?: 'CSGame';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  player: CsPlayer;
  states: Array<CsGameState>;
  mapName: Scalars['String'];
  gameMode: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};


export type CsGameStatesArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<CsGameStateWhereUniqueInput>;
};

export type CsGameCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gameMode: Scalars['String'];
  mapName: Scalars['String'];
  organization?: Maybe<OrganizationCreateNestedOneWithoutGamesInput>;
  player: CsPlayerCreateNestedOneWithoutGamesInput;
  user?: Maybe<UserCreateNestedOneWithoutGameInput>;
  states?: Maybe<CsGameStateCreateNestedManyWithoutGameInput>;
};

export type CsGameCreateManyOrganizationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  playerId: Scalars['String'];
  gameMode: Scalars['String'];
  mapName: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
};

export type CsGameCreateManyOrganizationInputEnvelope = {
  data?: Maybe<Array<CsGameCreateManyOrganizationInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CsGameCreateManyPlayerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gameMode: Scalars['String'];
  mapName: Scalars['String'];
  userId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
};

export type CsGameCreateManyPlayerInputEnvelope = {
  data?: Maybe<Array<CsGameCreateManyPlayerInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CsGameCreateManyUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  playerId: Scalars['String'];
  gameMode: Scalars['String'];
  mapName: Scalars['String'];
  organizationId?: Maybe<Scalars['String']>;
};

export type CsGameCreateManyUserInputEnvelope = {
  data?: Maybe<Array<CsGameCreateManyUserInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CsGameCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<CsGameCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CsGameCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<CsGameCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<CsGameWhereUniqueInput>>;
};

export type CsGameCreateNestedManyWithoutPlayerInput = {
  create?: Maybe<Array<CsGameCreateWithoutPlayerInput>>;
  connectOrCreate?: Maybe<Array<CsGameCreateOrConnectWithoutPlayerInput>>;
  createMany?: Maybe<CsGameCreateManyPlayerInputEnvelope>;
  connect?: Maybe<Array<CsGameWhereUniqueInput>>;
};

export type CsGameCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<CsGameCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<CsGameCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<CsGameCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<CsGameWhereUniqueInput>>;
};

export type CsGameCreateNestedOneWithoutStatesInput = {
  create?: Maybe<CsGameCreateWithoutStatesInput>;
  connectOrCreate?: Maybe<CsGameCreateOrConnectWithoutStatesInput>;
  connect?: Maybe<CsGameWhereUniqueInput>;
};

export type CsGameCreateOrConnectWithoutOrganizationInput = {
  where: CsGameWhereUniqueInput;
  create: CsGameCreateWithoutOrganizationInput;
};

export type CsGameCreateOrConnectWithoutPlayerInput = {
  where: CsGameWhereUniqueInput;
  create: CsGameCreateWithoutPlayerInput;
};

export type CsGameCreateOrConnectWithoutStatesInput = {
  where: CsGameWhereUniqueInput;
  create: CsGameCreateWithoutStatesInput;
};

export type CsGameCreateOrConnectWithoutUserInput = {
  where: CsGameWhereUniqueInput;
  create: CsGameCreateWithoutUserInput;
};

export type CsGameCreateWithoutOrganizationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gameMode: Scalars['String'];
  mapName: Scalars['String'];
  player: CsPlayerCreateNestedOneWithoutGamesInput;
  user?: Maybe<UserCreateNestedOneWithoutGameInput>;
  states?: Maybe<CsGameStateCreateNestedManyWithoutGameInput>;
};

export type CsGameCreateWithoutPlayerInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gameMode: Scalars['String'];
  mapName: Scalars['String'];
  organization?: Maybe<OrganizationCreateNestedOneWithoutGamesInput>;
  user?: Maybe<UserCreateNestedOneWithoutGameInput>;
  states?: Maybe<CsGameStateCreateNestedManyWithoutGameInput>;
};

export type CsGameCreateWithoutStatesInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gameMode: Scalars['String'];
  mapName: Scalars['String'];
  organization?: Maybe<OrganizationCreateNestedOneWithoutGamesInput>;
  player: CsPlayerCreateNestedOneWithoutGamesInput;
  user?: Maybe<UserCreateNestedOneWithoutGameInput>;
};

export type CsGameCreateWithoutUserInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  gameMode: Scalars['String'];
  mapName: Scalars['String'];
  organization?: Maybe<OrganizationCreateNestedOneWithoutGamesInput>;
  player: CsPlayerCreateNestedOneWithoutGamesInput;
  states?: Maybe<CsGameStateCreateNestedManyWithoutGameInput>;
};

export type CsGameListRelationFilter = {
  every?: Maybe<CsGameWhereInput>;
  some?: Maybe<CsGameWhereInput>;
  none?: Maybe<CsGameWhereInput>;
};

export type CsGameOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
};

export type CsGameOrderByWithRelationInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  playerId?: Maybe<SortOrder>;
  gameMode?: Maybe<SortOrder>;
  mapName?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  organization?: Maybe<OrganizationOrderByWithRelationInput>;
  player?: Maybe<CsPlayerOrderByWithRelationInput>;
  user?: Maybe<UserOrderByWithRelationInput>;
  states?: Maybe<CsGameStateOrderByRelationAggregateInput>;
};

export type CsGameScalarWhereInput = {
  AND?: Maybe<Array<CsGameScalarWhereInput>>;
  OR?: Maybe<Array<CsGameScalarWhereInput>>;
  NOT?: Maybe<Array<CsGameScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  playerId?: Maybe<StringFilter>;
  gameMode?: Maybe<StringFilter>;
  mapName?: Maybe<StringFilter>;
  userId?: Maybe<StringNullableFilter>;
  organizationId?: Maybe<StringNullableFilter>;
};

export type CsGameState = {
  __typename?: 'CSGameState';
  id: Scalars['String'];
  game: CsGame;
  player: CsPlayer;
  timestamp?: Maybe<Scalars['Float']>;
  team: Scalars['String'];
  state?: Maybe<CsPlayerState>;
  stats?: Maybe<CsMatchStats>;
};

export type CsGameStateCreateManyGameInput = {
  id?: Maybe<Scalars['String']>;
  playerId: Scalars['String'];
  team: Scalars['String'];
  timestamp?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
};

export type CsGameStateCreateManyGameInputEnvelope = {
  data?: Maybe<Array<CsGameStateCreateManyGameInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CsGameStateCreateManyPlayerInput = {
  gameId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  team: Scalars['String'];
  timestamp?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['String']>;
};

export type CsGameStateCreateManyPlayerInputEnvelope = {
  data?: Maybe<Array<CsGameStateCreateManyPlayerInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CsGameStateCreateManyUserInput = {
  gameId: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  playerId: Scalars['String'];
  team: Scalars['String'];
  timestamp?: Maybe<Scalars['Float']>;
};

export type CsGameStateCreateManyUserInputEnvelope = {
  data?: Maybe<Array<CsGameStateCreateManyUserInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CsGameStateCreateNestedManyWithoutGameInput = {
  create?: Maybe<Array<CsGameStateCreateWithoutGameInput>>;
  connectOrCreate?: Maybe<Array<CsGameStateCreateOrConnectWithoutGameInput>>;
  createMany?: Maybe<CsGameStateCreateManyGameInputEnvelope>;
  connect?: Maybe<Array<CsGameStateWhereUniqueInput>>;
};

export type CsGameStateCreateNestedManyWithoutPlayerInput = {
  create?: Maybe<Array<CsGameStateCreateWithoutPlayerInput>>;
  connectOrCreate?: Maybe<Array<CsGameStateCreateOrConnectWithoutPlayerInput>>;
  createMany?: Maybe<CsGameStateCreateManyPlayerInputEnvelope>;
  connect?: Maybe<Array<CsGameStateWhereUniqueInput>>;
};

export type CsGameStateCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<CsGameStateCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<CsGameStateCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<CsGameStateCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<CsGameStateWhereUniqueInput>>;
};

export type CsGameStateCreateOrConnectWithoutGameInput = {
  where: CsGameStateWhereUniqueInput;
  create: CsGameStateCreateWithoutGameInput;
};

export type CsGameStateCreateOrConnectWithoutPlayerInput = {
  where: CsGameStateWhereUniqueInput;
  create: CsGameStateCreateWithoutPlayerInput;
};

export type CsGameStateCreateOrConnectWithoutUserInput = {
  where: CsGameStateWhereUniqueInput;
  create: CsGameStateCreateWithoutUserInput;
};

export type CsGameStateCreateWithoutGameInput = {
  id?: Maybe<Scalars['String']>;
  team: Scalars['String'];
  timestamp?: Maybe<Scalars['Float']>;
  player: CsPlayerCreateNestedOneWithoutStatesInput;
  user?: Maybe<UserCreateNestedOneWithoutGameStateInput>;
  stats?: Maybe<CsMatchStatsCreateNestedOneWithoutGameStateInput>;
  state?: Maybe<CsPlayerStateCreateNestedOneWithoutGameStateInput>;
};

export type CsGameStateCreateWithoutPlayerInput = {
  id?: Maybe<Scalars['String']>;
  team: Scalars['String'];
  timestamp?: Maybe<Scalars['Float']>;
  game: CsGameCreateNestedOneWithoutStatesInput;
  user?: Maybe<UserCreateNestedOneWithoutGameStateInput>;
  stats?: Maybe<CsMatchStatsCreateNestedOneWithoutGameStateInput>;
  state?: Maybe<CsPlayerStateCreateNestedOneWithoutGameStateInput>;
};

export type CsGameStateCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  team: Scalars['String'];
  timestamp?: Maybe<Scalars['Float']>;
  game: CsGameCreateNestedOneWithoutStatesInput;
  player: CsPlayerCreateNestedOneWithoutStatesInput;
  stats?: Maybe<CsMatchStatsCreateNestedOneWithoutGameStateInput>;
  state?: Maybe<CsPlayerStateCreateNestedOneWithoutGameStateInput>;
};

export type CsGameStateListRelationFilter = {
  every?: Maybe<CsGameStateWhereInput>;
  some?: Maybe<CsGameStateWhereInput>;
  none?: Maybe<CsGameStateWhereInput>;
};

export type CsGameStateOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
};

export type CsGameStateScalarWhereInput = {
  AND?: Maybe<Array<CsGameStateScalarWhereInput>>;
  OR?: Maybe<Array<CsGameStateScalarWhereInput>>;
  NOT?: Maybe<Array<CsGameStateScalarWhereInput>>;
  gameId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  playerId?: Maybe<StringFilter>;
  team?: Maybe<StringFilter>;
  timestamp?: Maybe<FloatNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export type CsGameStateUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  team?: Maybe<StringFieldUpdateOperationsInput>;
  timestamp?: Maybe<NullableFloatFieldUpdateOperationsInput>;
};

export type CsGameStateUpdateManyWithWhereWithoutGameInput = {
  where: CsGameStateScalarWhereInput;
  data: CsGameStateUpdateManyMutationInput;
};

export type CsGameStateUpdateManyWithWhereWithoutPlayerInput = {
  where: CsGameStateScalarWhereInput;
  data: CsGameStateUpdateManyMutationInput;
};

export type CsGameStateUpdateManyWithWhereWithoutUserInput = {
  where: CsGameStateScalarWhereInput;
  data: CsGameStateUpdateManyMutationInput;
};

export type CsGameStateUpdateManyWithoutGameInput = {
  create?: Maybe<Array<CsGameStateCreateWithoutGameInput>>;
  connectOrCreate?: Maybe<Array<CsGameStateCreateOrConnectWithoutGameInput>>;
  upsert?: Maybe<Array<CsGameStateUpsertWithWhereUniqueWithoutGameInput>>;
  createMany?: Maybe<CsGameStateCreateManyGameInputEnvelope>;
  connect?: Maybe<Array<CsGameStateWhereUniqueInput>>;
  set?: Maybe<Array<CsGameStateWhereUniqueInput>>;
  disconnect?: Maybe<Array<CsGameStateWhereUniqueInput>>;
  delete?: Maybe<Array<CsGameStateWhereUniqueInput>>;
  update?: Maybe<Array<CsGameStateUpdateWithWhereUniqueWithoutGameInput>>;
  updateMany?: Maybe<Array<CsGameStateUpdateManyWithWhereWithoutGameInput>>;
  deleteMany?: Maybe<Array<CsGameStateScalarWhereInput>>;
};

export type CsGameStateUpdateManyWithoutPlayerInput = {
  create?: Maybe<Array<CsGameStateCreateWithoutPlayerInput>>;
  connectOrCreate?: Maybe<Array<CsGameStateCreateOrConnectWithoutPlayerInput>>;
  upsert?: Maybe<Array<CsGameStateUpsertWithWhereUniqueWithoutPlayerInput>>;
  createMany?: Maybe<CsGameStateCreateManyPlayerInputEnvelope>;
  connect?: Maybe<Array<CsGameStateWhereUniqueInput>>;
  set?: Maybe<Array<CsGameStateWhereUniqueInput>>;
  disconnect?: Maybe<Array<CsGameStateWhereUniqueInput>>;
  delete?: Maybe<Array<CsGameStateWhereUniqueInput>>;
  update?: Maybe<Array<CsGameStateUpdateWithWhereUniqueWithoutPlayerInput>>;
  updateMany?: Maybe<Array<CsGameStateUpdateManyWithWhereWithoutPlayerInput>>;
  deleteMany?: Maybe<Array<CsGameStateScalarWhereInput>>;
};

export type CsGameStateUpdateManyWithoutUserInput = {
  create?: Maybe<Array<CsGameStateCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<CsGameStateCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<CsGameStateUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<CsGameStateCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<CsGameStateWhereUniqueInput>>;
  set?: Maybe<Array<CsGameStateWhereUniqueInput>>;
  disconnect?: Maybe<Array<CsGameStateWhereUniqueInput>>;
  delete?: Maybe<Array<CsGameStateWhereUniqueInput>>;
  update?: Maybe<Array<CsGameStateUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<CsGameStateUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<CsGameStateScalarWhereInput>>;
};

export type CsGameStateUpdateWithWhereUniqueWithoutGameInput = {
  where: CsGameStateWhereUniqueInput;
  data: CsGameStateUpdateWithoutGameInput;
};

export type CsGameStateUpdateWithWhereUniqueWithoutPlayerInput = {
  where: CsGameStateWhereUniqueInput;
  data: CsGameStateUpdateWithoutPlayerInput;
};

export type CsGameStateUpdateWithWhereUniqueWithoutUserInput = {
  where: CsGameStateWhereUniqueInput;
  data: CsGameStateUpdateWithoutUserInput;
};

export type CsGameStateUpdateWithoutGameInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  team?: Maybe<StringFieldUpdateOperationsInput>;
  timestamp?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  player?: Maybe<CsPlayerUpdateOneRequiredWithoutStatesInput>;
  user?: Maybe<UserUpdateOneWithoutGameStateInput>;
  stats?: Maybe<CsMatchStatsUpdateOneWithoutGameStateInput>;
  state?: Maybe<CsPlayerStateUpdateOneWithoutGameStateInput>;
};

export type CsGameStateUpdateWithoutPlayerInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  team?: Maybe<StringFieldUpdateOperationsInput>;
  timestamp?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  game?: Maybe<CsGameUpdateOneRequiredWithoutStatesInput>;
  user?: Maybe<UserUpdateOneWithoutGameStateInput>;
  stats?: Maybe<CsMatchStatsUpdateOneWithoutGameStateInput>;
  state?: Maybe<CsPlayerStateUpdateOneWithoutGameStateInput>;
};

export type CsGameStateUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  team?: Maybe<StringFieldUpdateOperationsInput>;
  timestamp?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  game?: Maybe<CsGameUpdateOneRequiredWithoutStatesInput>;
  player?: Maybe<CsPlayerUpdateOneRequiredWithoutStatesInput>;
  stats?: Maybe<CsMatchStatsUpdateOneWithoutGameStateInput>;
  state?: Maybe<CsPlayerStateUpdateOneWithoutGameStateInput>;
};

export type CsGameStateUpsertWithWhereUniqueWithoutGameInput = {
  where: CsGameStateWhereUniqueInput;
  update: CsGameStateUpdateWithoutGameInput;
  create: CsGameStateCreateWithoutGameInput;
};

export type CsGameStateUpsertWithWhereUniqueWithoutPlayerInput = {
  where: CsGameStateWhereUniqueInput;
  update: CsGameStateUpdateWithoutPlayerInput;
  create: CsGameStateCreateWithoutPlayerInput;
};

export type CsGameStateUpsertWithWhereUniqueWithoutUserInput = {
  where: CsGameStateWhereUniqueInput;
  update: CsGameStateUpdateWithoutUserInput;
  create: CsGameStateCreateWithoutUserInput;
};

export type CsGameStateWhereInput = {
  AND?: Maybe<Array<CsGameStateWhereInput>>;
  OR?: Maybe<Array<CsGameStateWhereInput>>;
  NOT?: Maybe<Array<CsGameStateWhereInput>>;
  gameId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  playerId?: Maybe<StringFilter>;
  team?: Maybe<StringFilter>;
  timestamp?: Maybe<FloatNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  game?: Maybe<CsGameWhereInput>;
  player?: Maybe<CsPlayerWhereInput>;
  user?: Maybe<UserWhereInput>;
  stats?: Maybe<CsMatchStatsWhereInput>;
  state?: Maybe<CsPlayerStateWhereInput>;
};

export type CsGameStateWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CsGameUpdateInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  gameMode?: Maybe<StringFieldUpdateOperationsInput>;
  mapName?: Maybe<StringFieldUpdateOperationsInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutGamesInput>;
  player?: Maybe<CsPlayerUpdateOneRequiredWithoutGamesInput>;
  user?: Maybe<UserUpdateOneWithoutGameInput>;
  states?: Maybe<CsGameStateUpdateManyWithoutGameInput>;
};

export type CsGameUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  gameMode?: Maybe<StringFieldUpdateOperationsInput>;
  mapName?: Maybe<StringFieldUpdateOperationsInput>;
};

export type CsGameUpdateManyWithWhereWithoutOrganizationInput = {
  where: CsGameScalarWhereInput;
  data: CsGameUpdateManyMutationInput;
};

export type CsGameUpdateManyWithWhereWithoutPlayerInput = {
  where: CsGameScalarWhereInput;
  data: CsGameUpdateManyMutationInput;
};

export type CsGameUpdateManyWithWhereWithoutUserInput = {
  where: CsGameScalarWhereInput;
  data: CsGameUpdateManyMutationInput;
};

export type CsGameUpdateManyWithoutOrganizationInput = {
  create?: Maybe<Array<CsGameCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<CsGameCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<CsGameUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<CsGameCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<CsGameWhereUniqueInput>>;
  set?: Maybe<Array<CsGameWhereUniqueInput>>;
  disconnect?: Maybe<Array<CsGameWhereUniqueInput>>;
  delete?: Maybe<Array<CsGameWhereUniqueInput>>;
  update?: Maybe<Array<CsGameUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<CsGameUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<CsGameScalarWhereInput>>;
};

export type CsGameUpdateManyWithoutPlayerInput = {
  create?: Maybe<Array<CsGameCreateWithoutPlayerInput>>;
  connectOrCreate?: Maybe<Array<CsGameCreateOrConnectWithoutPlayerInput>>;
  upsert?: Maybe<Array<CsGameUpsertWithWhereUniqueWithoutPlayerInput>>;
  createMany?: Maybe<CsGameCreateManyPlayerInputEnvelope>;
  connect?: Maybe<Array<CsGameWhereUniqueInput>>;
  set?: Maybe<Array<CsGameWhereUniqueInput>>;
  disconnect?: Maybe<Array<CsGameWhereUniqueInput>>;
  delete?: Maybe<Array<CsGameWhereUniqueInput>>;
  update?: Maybe<Array<CsGameUpdateWithWhereUniqueWithoutPlayerInput>>;
  updateMany?: Maybe<Array<CsGameUpdateManyWithWhereWithoutPlayerInput>>;
  deleteMany?: Maybe<Array<CsGameScalarWhereInput>>;
};

export type CsGameUpdateManyWithoutUserInput = {
  create?: Maybe<Array<CsGameCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<CsGameCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<CsGameUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<CsGameCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<CsGameWhereUniqueInput>>;
  set?: Maybe<Array<CsGameWhereUniqueInput>>;
  disconnect?: Maybe<Array<CsGameWhereUniqueInput>>;
  delete?: Maybe<Array<CsGameWhereUniqueInput>>;
  update?: Maybe<Array<CsGameUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<CsGameUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<CsGameScalarWhereInput>>;
};

export type CsGameUpdateOneRequiredWithoutStatesInput = {
  create?: Maybe<CsGameCreateWithoutStatesInput>;
  connectOrCreate?: Maybe<CsGameCreateOrConnectWithoutStatesInput>;
  upsert?: Maybe<CsGameUpsertWithoutStatesInput>;
  connect?: Maybe<CsGameWhereUniqueInput>;
  update?: Maybe<CsGameUpdateWithoutStatesInput>;
};

export type CsGameUpdateWithWhereUniqueWithoutOrganizationInput = {
  where: CsGameWhereUniqueInput;
  data: CsGameUpdateWithoutOrganizationInput;
};

export type CsGameUpdateWithWhereUniqueWithoutPlayerInput = {
  where: CsGameWhereUniqueInput;
  data: CsGameUpdateWithoutPlayerInput;
};

export type CsGameUpdateWithWhereUniqueWithoutUserInput = {
  where: CsGameWhereUniqueInput;
  data: CsGameUpdateWithoutUserInput;
};

export type CsGameUpdateWithoutOrganizationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  gameMode?: Maybe<StringFieldUpdateOperationsInput>;
  mapName?: Maybe<StringFieldUpdateOperationsInput>;
  player?: Maybe<CsPlayerUpdateOneRequiredWithoutGamesInput>;
  user?: Maybe<UserUpdateOneWithoutGameInput>;
  states?: Maybe<CsGameStateUpdateManyWithoutGameInput>;
};

export type CsGameUpdateWithoutPlayerInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  gameMode?: Maybe<StringFieldUpdateOperationsInput>;
  mapName?: Maybe<StringFieldUpdateOperationsInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutGamesInput>;
  user?: Maybe<UserUpdateOneWithoutGameInput>;
  states?: Maybe<CsGameStateUpdateManyWithoutGameInput>;
};

export type CsGameUpdateWithoutStatesInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  gameMode?: Maybe<StringFieldUpdateOperationsInput>;
  mapName?: Maybe<StringFieldUpdateOperationsInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutGamesInput>;
  player?: Maybe<CsPlayerUpdateOneRequiredWithoutGamesInput>;
  user?: Maybe<UserUpdateOneWithoutGameInput>;
};

export type CsGameUpdateWithoutUserInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  gameMode?: Maybe<StringFieldUpdateOperationsInput>;
  mapName?: Maybe<StringFieldUpdateOperationsInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutGamesInput>;
  player?: Maybe<CsPlayerUpdateOneRequiredWithoutGamesInput>;
  states?: Maybe<CsGameStateUpdateManyWithoutGameInput>;
};

export type CsGameUpsertWithWhereUniqueWithoutOrganizationInput = {
  where: CsGameWhereUniqueInput;
  update: CsGameUpdateWithoutOrganizationInput;
  create: CsGameCreateWithoutOrganizationInput;
};

export type CsGameUpsertWithWhereUniqueWithoutPlayerInput = {
  where: CsGameWhereUniqueInput;
  update: CsGameUpdateWithoutPlayerInput;
  create: CsGameCreateWithoutPlayerInput;
};

export type CsGameUpsertWithWhereUniqueWithoutUserInput = {
  where: CsGameWhereUniqueInput;
  update: CsGameUpdateWithoutUserInput;
  create: CsGameCreateWithoutUserInput;
};

export type CsGameUpsertWithoutStatesInput = {
  update: CsGameUpdateWithoutStatesInput;
  create: CsGameCreateWithoutStatesInput;
};

export type CsGameWhereInput = {
  AND?: Maybe<Array<CsGameWhereInput>>;
  OR?: Maybe<Array<CsGameWhereInput>>;
  NOT?: Maybe<Array<CsGameWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  playerId?: Maybe<StringFilter>;
  gameMode?: Maybe<StringFilter>;
  mapName?: Maybe<StringFilter>;
  userId?: Maybe<StringNullableFilter>;
  organizationId?: Maybe<StringNullableFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  player?: Maybe<CsPlayerWhereInput>;
  user?: Maybe<UserWhereInput>;
  states?: Maybe<CsGameStateListRelationFilter>;
};

export type CsGameWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type CsMatchStats = {
  __typename?: 'CSMatchStats';
  id: Scalars['String'];
  kills: Scalars['Int'];
  assists: Scalars['Int'];
  deaths: Scalars['Int'];
  mvps: Scalars['Int'];
  score: Scalars['Int'];
};

export type CsMatchStatsCreateNestedOneWithoutGameStateInput = {
  create?: Maybe<CsMatchStatsCreateWithoutGameStateInput>;
  connectOrCreate?: Maybe<CsMatchStatsCreateOrConnectWithoutGameStateInput>;
  connect?: Maybe<CsMatchStatsWhereUniqueInput>;
};

export type CsMatchStatsCreateOrConnectWithoutGameStateInput = {
  where: CsMatchStatsWhereUniqueInput;
  create: CsMatchStatsCreateWithoutGameStateInput;
};

export type CsMatchStatsCreateWithoutGameStateInput = {
  assists: Scalars['Int'];
  deaths: Scalars['Int'];
  id?: Maybe<Scalars['String']>;
  kills: Scalars['Int'];
  mvps: Scalars['Int'];
  score: Scalars['Int'];
};

export type CsMatchStatsUpdateOneWithoutGameStateInput = {
  create?: Maybe<CsMatchStatsCreateWithoutGameStateInput>;
  connectOrCreate?: Maybe<CsMatchStatsCreateOrConnectWithoutGameStateInput>;
  upsert?: Maybe<CsMatchStatsUpsertWithoutGameStateInput>;
  connect?: Maybe<CsMatchStatsWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CsMatchStatsUpdateWithoutGameStateInput>;
};

export type CsMatchStatsUpdateWithoutGameStateInput = {
  assists?: Maybe<IntFieldUpdateOperationsInput>;
  deaths?: Maybe<IntFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  kills?: Maybe<IntFieldUpdateOperationsInput>;
  mvps?: Maybe<IntFieldUpdateOperationsInput>;
  score?: Maybe<IntFieldUpdateOperationsInput>;
};

export type CsMatchStatsUpsertWithoutGameStateInput = {
  update: CsMatchStatsUpdateWithoutGameStateInput;
  create: CsMatchStatsCreateWithoutGameStateInput;
};

export type CsMatchStatsWhereInput = {
  AND?: Maybe<Array<CsMatchStatsWhereInput>>;
  OR?: Maybe<Array<CsMatchStatsWhereInput>>;
  NOT?: Maybe<Array<CsMatchStatsWhereInput>>;
  assists?: Maybe<IntFilter>;
  deaths?: Maybe<IntFilter>;
  id?: Maybe<StringFilter>;
  kills?: Maybe<IntFilter>;
  mvps?: Maybe<IntFilter>;
  score?: Maybe<IntFilter>;
  gameStateId?: Maybe<StringNullableFilter>;
  gameState?: Maybe<CsGameStateWhereInput>;
};

export type CsMatchStatsWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  gameStateId?: Maybe<Scalars['String']>;
};

export type CsMetricComparison = {
  __typename?: 'CSMetricComparison';
  stats?: Maybe<CsStatsComparison>;
  cognitive?: Maybe<CognitiveComparison>;
};

export type CsMetrics = {
  __typename?: 'CSMetrics';
  states?: Maybe<Array<Maybe<CsGameState>>>;
  endState?: Maybe<CsGameState>;
  cognitive?: Maybe<Array<Maybe<Cognitive>>>;
};

export type CsMetricsSummary = {
  __typename?: 'CSMetricsSummary';
  player?: Maybe<CsPlayer>;
  startTime?: Maybe<Scalars['DateTime']>;
  endTime?: Maybe<Scalars['DateTime']>;
  endState?: Maybe<CsGameState>;
  summaries?: Maybe<Array<Maybe<MetricSummary>>>;
};

export type CsPlayer = {
  __typename?: 'CSPlayer';
  id: Scalars['String'];
  games: Array<CsGame>;
  steamId: Scalars['String'];
  name: Scalars['String'];
  states: Array<CsGameState>;
};


export type CsPlayerGamesArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<CsGameWhereUniqueInput>;
};


export type CsPlayerStatesArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<CsGameStateWhereUniqueInput>;
};

export type CsPlayerCreateNestedOneWithoutGamesInput = {
  create?: Maybe<CsPlayerCreateWithoutGamesInput>;
  connectOrCreate?: Maybe<CsPlayerCreateOrConnectWithoutGamesInput>;
  connect?: Maybe<CsPlayerWhereUniqueInput>;
};

export type CsPlayerCreateNestedOneWithoutStatesInput = {
  create?: Maybe<CsPlayerCreateWithoutStatesInput>;
  connectOrCreate?: Maybe<CsPlayerCreateOrConnectWithoutStatesInput>;
  connect?: Maybe<CsPlayerWhereUniqueInput>;
};

export type CsPlayerCreateOrConnectWithoutGamesInput = {
  where: CsPlayerWhereUniqueInput;
  create: CsPlayerCreateWithoutGamesInput;
};

export type CsPlayerCreateOrConnectWithoutStatesInput = {
  where: CsPlayerWhereUniqueInput;
  create: CsPlayerCreateWithoutStatesInput;
};

export type CsPlayerCreateWithoutGamesInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  steamId: Scalars['String'];
  states?: Maybe<CsGameStateCreateNestedManyWithoutPlayerInput>;
};

export type CsPlayerCreateWithoutStatesInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  steamId: Scalars['String'];
  games?: Maybe<CsGameCreateNestedManyWithoutPlayerInput>;
};

export type CsPlayerOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  steamId?: Maybe<SortOrder>;
  games?: Maybe<CsGameOrderByRelationAggregateInput>;
  states?: Maybe<CsGameStateOrderByRelationAggregateInput>;
};

export type CsPlayerState = {
  __typename?: 'CSPlayerState';
  id: Scalars['String'];
  health: Scalars['Int'];
  armor: Scalars['Int'];
  helmet: Scalars['Boolean'];
  flashed: Scalars['Int'];
  smoked: Scalars['Int'];
  burning: Scalars['Int'];
  money: Scalars['Int'];
  roundKills: Scalars['Int'];
  roundKillsHeadshot: Scalars['Int'];
  equipValue: Scalars['Int'];
};

export type CsPlayerStateCreateNestedOneWithoutGameStateInput = {
  create?: Maybe<CsPlayerStateCreateWithoutGameStateInput>;
  connectOrCreate?: Maybe<CsPlayerStateCreateOrConnectWithoutGameStateInput>;
  connect?: Maybe<CsPlayerStateWhereUniqueInput>;
};

export type CsPlayerStateCreateOrConnectWithoutGameStateInput = {
  where: CsPlayerStateWhereUniqueInput;
  create: CsPlayerStateCreateWithoutGameStateInput;
};

export type CsPlayerStateCreateWithoutGameStateInput = {
  armor: Scalars['Int'];
  burning: Scalars['Int'];
  equipValue: Scalars['Int'];
  flashed: Scalars['Int'];
  health: Scalars['Int'];
  helmet: Scalars['Boolean'];
  id?: Maybe<Scalars['String']>;
  money: Scalars['Int'];
  roundKills: Scalars['Int'];
  roundKillsHeadshot: Scalars['Int'];
  smoked: Scalars['Int'];
};

export type CsPlayerStateUpdateOneWithoutGameStateInput = {
  create?: Maybe<CsPlayerStateCreateWithoutGameStateInput>;
  connectOrCreate?: Maybe<CsPlayerStateCreateOrConnectWithoutGameStateInput>;
  upsert?: Maybe<CsPlayerStateUpsertWithoutGameStateInput>;
  connect?: Maybe<CsPlayerStateWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<CsPlayerStateUpdateWithoutGameStateInput>;
};

export type CsPlayerStateUpdateWithoutGameStateInput = {
  armor?: Maybe<IntFieldUpdateOperationsInput>;
  burning?: Maybe<IntFieldUpdateOperationsInput>;
  equipValue?: Maybe<IntFieldUpdateOperationsInput>;
  flashed?: Maybe<IntFieldUpdateOperationsInput>;
  health?: Maybe<IntFieldUpdateOperationsInput>;
  helmet?: Maybe<BoolFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  money?: Maybe<IntFieldUpdateOperationsInput>;
  roundKills?: Maybe<IntFieldUpdateOperationsInput>;
  roundKillsHeadshot?: Maybe<IntFieldUpdateOperationsInput>;
  smoked?: Maybe<IntFieldUpdateOperationsInput>;
};

export type CsPlayerStateUpsertWithoutGameStateInput = {
  update: CsPlayerStateUpdateWithoutGameStateInput;
  create: CsPlayerStateCreateWithoutGameStateInput;
};

export type CsPlayerStateWhereInput = {
  AND?: Maybe<Array<CsPlayerStateWhereInput>>;
  OR?: Maybe<Array<CsPlayerStateWhereInput>>;
  NOT?: Maybe<Array<CsPlayerStateWhereInput>>;
  armor?: Maybe<IntFilter>;
  burning?: Maybe<IntFilter>;
  equipValue?: Maybe<IntFilter>;
  flashed?: Maybe<IntFilter>;
  health?: Maybe<IntFilter>;
  helmet?: Maybe<BoolFilter>;
  id?: Maybe<StringFilter>;
  money?: Maybe<IntFilter>;
  roundKills?: Maybe<IntFilter>;
  roundKillsHeadshot?: Maybe<IntFilter>;
  smoked?: Maybe<IntFilter>;
  gameStateId?: Maybe<StringFilter>;
  gameState?: Maybe<CsGameStateWhereInput>;
};

export type CsPlayerStateWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  gameStateId?: Maybe<Scalars['String']>;
};

export type CsPlayerUpdateOneRequiredWithoutGamesInput = {
  create?: Maybe<CsPlayerCreateWithoutGamesInput>;
  connectOrCreate?: Maybe<CsPlayerCreateOrConnectWithoutGamesInput>;
  upsert?: Maybe<CsPlayerUpsertWithoutGamesInput>;
  connect?: Maybe<CsPlayerWhereUniqueInput>;
  update?: Maybe<CsPlayerUpdateWithoutGamesInput>;
};

export type CsPlayerUpdateOneRequiredWithoutStatesInput = {
  create?: Maybe<CsPlayerCreateWithoutStatesInput>;
  connectOrCreate?: Maybe<CsPlayerCreateOrConnectWithoutStatesInput>;
  upsert?: Maybe<CsPlayerUpsertWithoutStatesInput>;
  connect?: Maybe<CsPlayerWhereUniqueInput>;
  update?: Maybe<CsPlayerUpdateWithoutStatesInput>;
};

export type CsPlayerUpdateWithoutGamesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  steamId?: Maybe<StringFieldUpdateOperationsInput>;
  states?: Maybe<CsGameStateUpdateManyWithoutPlayerInput>;
};

export type CsPlayerUpdateWithoutStatesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  steamId?: Maybe<StringFieldUpdateOperationsInput>;
  games?: Maybe<CsGameUpdateManyWithoutPlayerInput>;
};

export type CsPlayerUpsertWithoutGamesInput = {
  update: CsPlayerUpdateWithoutGamesInput;
  create: CsPlayerCreateWithoutGamesInput;
};

export type CsPlayerUpsertWithoutStatesInput = {
  update: CsPlayerUpdateWithoutStatesInput;
  create: CsPlayerCreateWithoutStatesInput;
};

export type CsPlayerWhereInput = {
  AND?: Maybe<Array<CsPlayerWhereInput>>;
  OR?: Maybe<Array<CsPlayerWhereInput>>;
  NOT?: Maybe<Array<CsPlayerWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  steamId?: Maybe<StringFilter>;
  games?: Maybe<CsGameListRelationFilter>;
  states?: Maybe<CsGameStateListRelationFilter>;
};

export type CsPlayerWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  steamId?: Maybe<Scalars['String']>;
};

export type CsStatsComparison = {
  __typename?: 'CSStatsComparison';
  n?: Maybe<Scalars['Int']>;
  stats?: Maybe<MatchStatsComp>;
};

export type Cognitive = {
  __typename?: 'Cognitive';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  calm?: Maybe<Scalars['Float']>;
  focus?: Maybe<Scalars['Float']>;
  fatigue?: Maybe<Scalars['Float']>;
  engagement?: Maybe<Scalars['Float']>;
  timestamp?: Maybe<Scalars['Float']>;
};

export type CognitiveComparison = {
  __typename?: 'CognitiveComparison';
  n?: Maybe<Scalars['Int']>;
  cognitive?: Maybe<Cognitive>;
};

export type CognitiveCreateManyMetricInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  model?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Float']>;
  calm?: Maybe<Scalars['Float']>;
  focus?: Maybe<Scalars['Float']>;
  fatigue?: Maybe<Scalars['Float']>;
  engagement?: Maybe<Scalars['Float']>;
};

export type CognitiveCreateManyMetricInputEnvelope = {
  data?: Maybe<Array<CognitiveCreateManyMetricInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type CognitiveCreateNestedManyWithoutMetricInput = {
  create?: Maybe<Array<CognitiveCreateWithoutMetricInput>>;
  connectOrCreate?: Maybe<Array<CognitiveCreateOrConnectWithoutMetricInput>>;
  createMany?: Maybe<CognitiveCreateManyMetricInputEnvelope>;
  connect?: Maybe<Array<CognitiveWhereUniqueInput>>;
};

export type CognitiveCreateOrConnectWithoutMetricInput = {
  where: CognitiveWhereUniqueInput;
  create: CognitiveCreateWithoutMetricInput;
};

export type CognitiveCreateWithoutMetricInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  model?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Float']>;
  calm?: Maybe<Scalars['Float']>;
  focus?: Maybe<Scalars['Float']>;
  fatigue?: Maybe<Scalars['Float']>;
  engagement?: Maybe<Scalars['Float']>;
};

export type CognitiveListRelationFilter = {
  every?: Maybe<CognitiveWhereInput>;
  some?: Maybe<CognitiveWhereInput>;
  none?: Maybe<CognitiveWhereInput>;
};

export type CognitiveMetric = {
  __typename?: 'CognitiveMetric';
  calm?: Maybe<Array<Maybe<Scalars['Float']>>>;
  focus?: Maybe<Array<Maybe<Scalars['Float']>>>;
  engagement?: Maybe<Array<Maybe<Scalars['Float']>>>;
  fatigue?: Maybe<Array<Maybe<Scalars['Float']>>>;
  timestamps?: Maybe<Array<Maybe<Scalars['Float']>>>;
};

export type CognitiveOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
};

export type CognitiveOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  metricId?: Maybe<SortOrder>;
  model?: Maybe<SortOrder>;
  timestamp?: Maybe<SortOrder>;
  calm?: Maybe<SortOrder>;
  focus?: Maybe<SortOrder>;
  fatigue?: Maybe<SortOrder>;
  engagement?: Maybe<SortOrder>;
  metric?: Maybe<BioMetricOrderByWithRelationInput>;
};

export type CognitiveScalarWhereInput = {
  AND?: Maybe<Array<CognitiveScalarWhereInput>>;
  OR?: Maybe<Array<CognitiveScalarWhereInput>>;
  NOT?: Maybe<Array<CognitiveScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  metricId?: Maybe<StringFilter>;
  model?: Maybe<StringNullableFilter>;
  timestamp?: Maybe<FloatNullableFilter>;
  calm?: Maybe<FloatNullableFilter>;
  focus?: Maybe<FloatNullableFilter>;
  fatigue?: Maybe<FloatNullableFilter>;
  engagement?: Maybe<FloatNullableFilter>;
};

export type CognitiveUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  model?: Maybe<NullableStringFieldUpdateOperationsInput>;
  timestamp?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  calm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  focus?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  fatigue?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  engagement?: Maybe<NullableFloatFieldUpdateOperationsInput>;
};

export type CognitiveUpdateManyWithWhereWithoutMetricInput = {
  where: CognitiveScalarWhereInput;
  data: CognitiveUpdateManyMutationInput;
};

export type CognitiveUpdateManyWithoutMetricInput = {
  create?: Maybe<Array<CognitiveCreateWithoutMetricInput>>;
  connectOrCreate?: Maybe<Array<CognitiveCreateOrConnectWithoutMetricInput>>;
  upsert?: Maybe<Array<CognitiveUpsertWithWhereUniqueWithoutMetricInput>>;
  createMany?: Maybe<CognitiveCreateManyMetricInputEnvelope>;
  connect?: Maybe<Array<CognitiveWhereUniqueInput>>;
  set?: Maybe<Array<CognitiveWhereUniqueInput>>;
  disconnect?: Maybe<Array<CognitiveWhereUniqueInput>>;
  delete?: Maybe<Array<CognitiveWhereUniqueInput>>;
  update?: Maybe<Array<CognitiveUpdateWithWhereUniqueWithoutMetricInput>>;
  updateMany?: Maybe<Array<CognitiveUpdateManyWithWhereWithoutMetricInput>>;
  deleteMany?: Maybe<Array<CognitiveScalarWhereInput>>;
};

export type CognitiveUpdateWithWhereUniqueWithoutMetricInput = {
  where: CognitiveWhereUniqueInput;
  data: CognitiveUpdateWithoutMetricInput;
};

export type CognitiveUpdateWithoutMetricInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  model?: Maybe<NullableStringFieldUpdateOperationsInput>;
  timestamp?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  calm?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  focus?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  fatigue?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  engagement?: Maybe<NullableFloatFieldUpdateOperationsInput>;
};

export type CognitiveUpsertWithWhereUniqueWithoutMetricInput = {
  where: CognitiveWhereUniqueInput;
  update: CognitiveUpdateWithoutMetricInput;
  create: CognitiveCreateWithoutMetricInput;
};

export type CognitiveWhereInput = {
  AND?: Maybe<Array<CognitiveWhereInput>>;
  OR?: Maybe<Array<CognitiveWhereInput>>;
  NOT?: Maybe<Array<CognitiveWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  metricId?: Maybe<StringFilter>;
  model?: Maybe<StringNullableFilter>;
  timestamp?: Maybe<FloatNullableFilter>;
  calm?: Maybe<FloatNullableFilter>;
  focus?: Maybe<FloatNullableFilter>;
  fatigue?: Maybe<FloatNullableFilter>;
  engagement?: Maybe<FloatNullableFilter>;
  metric?: Maybe<BioMetricWhereInput>;
};

export type CognitiveWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};



export type DateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type DateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type DateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type Device = {
  __typename?: 'Device';
  id: Scalars['String'];
  type: DeviceType;
  createdAt: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  firmware?: Maybe<Scalars['String']>;
};

export type DeviceCreateNestedOneWithoutSessionInput = {
  create?: Maybe<DeviceCreateWithoutSessionInput>;
  connectOrCreate?: Maybe<DeviceCreateOrConnectWithoutSessionInput>;
  connect?: Maybe<DeviceWhereUniqueInput>;
};

export type DeviceCreateOrConnectWithoutSessionInput = {
  where: DeviceWhereUniqueInput;
  create: DeviceCreateWithoutSessionInput;
};

export type DeviceCreateWithoutSessionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  firmware?: Maybe<Scalars['String']>;
  type: DeviceType;
};

export type DeviceOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  firmware?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  Session?: Maybe<SessionOrderByRelationAggregateInput>;
};

export enum DeviceType {
  Nexus = 'NEXUS',
  Muse = 'MUSE',
  Neorhythm = 'NEORHYTHM'
}

export type DeviceUpdateOneWithoutSessionInput = {
  create?: Maybe<DeviceCreateWithoutSessionInput>;
  connectOrCreate?: Maybe<DeviceCreateOrConnectWithoutSessionInput>;
  upsert?: Maybe<DeviceUpsertWithoutSessionInput>;
  connect?: Maybe<DeviceWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<DeviceUpdateWithoutSessionInput>;
};

export type DeviceUpdateWithoutSessionInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  firmware?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumDeviceTypeFieldUpdateOperationsInput>;
};

export type DeviceUpsertWithoutSessionInput = {
  update: DeviceUpdateWithoutSessionInput;
  create: DeviceCreateWithoutSessionInput;
};

export type DeviceWhereInput = {
  AND?: Maybe<Array<DeviceWhereInput>>;
  OR?: Maybe<Array<DeviceWhereInput>>;
  NOT?: Maybe<Array<DeviceWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  name?: Maybe<StringNullableFilter>;
  firmware?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumDeviceTypeFilter>;
  Session?: Maybe<SessionListRelationFilter>;
};

export type DeviceWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Eeg = {
  __typename?: 'EEG';
  id: Scalars['String'];
  metric: BioMetric;
  samples: Array<EegSample>;
  createdAt?: Maybe<Scalars['DateTime']>;
  startTime?: Maybe<Scalars['Float']>;
  samplingRate?: Maybe<Scalars['Float']>;
  bufferDuration: Scalars['Int'];
  bufferInterval: Scalars['Int'];
  packetLossIndex: Array<Scalars['Int']>;
  packetLossSkipped: Array<Scalars['Int']>;
};


export type EegSamplesArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<EegSampleWhereUniqueInput>;
};

export type EegCreateManyMetricInput = {
  id?: Maybe<Scalars['String']>;
  samplingRate?: Maybe<Scalars['Float']>;
  startTime?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  bufferDuration: Scalars['Int'];
  bufferInterval: Scalars['Int'];
  packetLossIndex?: Maybe<EegCreateManypacketLossIndexInput>;
  packetLossSkipped?: Maybe<EegCreateManypacketLossSkippedInput>;
};

export type EegCreateManyMetricInputEnvelope = {
  data?: Maybe<Array<EegCreateManyMetricInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EegCreateManypacketLossIndexInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type EegCreateManypacketLossSkippedInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type EegCreateNestedManyWithoutMetricInput = {
  create?: Maybe<Array<EegCreateWithoutMetricInput>>;
  connectOrCreate?: Maybe<Array<EegCreateOrConnectWithoutMetricInput>>;
  createMany?: Maybe<EegCreateManyMetricInputEnvelope>;
  connect?: Maybe<Array<EegWhereUniqueInput>>;
};

export type EegCreateOrConnectWithoutMetricInput = {
  where: EegWhereUniqueInput;
  create: EegCreateWithoutMetricInput;
};

export type EegCreateWithoutMetricInput = {
  id?: Maybe<Scalars['String']>;
  samplingRate?: Maybe<Scalars['Float']>;
  startTime?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  bufferDuration: Scalars['Int'];
  bufferInterval: Scalars['Int'];
  packetLossIndex?: Maybe<EegCreatepacketLossIndexInput>;
  packetLossSkipped?: Maybe<EegCreatepacketLossSkippedInput>;
  samples?: Maybe<EegSampleCreateNestedManyWithoutEegInput>;
};

export type EegCreatepacketLossIndexInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type EegCreatepacketLossSkippedInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type EegListRelationFilter = {
  every?: Maybe<EegWhereInput>;
  some?: Maybe<EegWhereInput>;
  none?: Maybe<EegWhereInput>;
};

export type EegOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
};

export type EegOrderByWithRelationInput = {
  metricId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  samplingRate?: Maybe<SortOrder>;
  startTime?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  bufferDuration?: Maybe<SortOrder>;
  bufferInterval?: Maybe<SortOrder>;
  packetLossIndex?: Maybe<SortOrder>;
  packetLossSkipped?: Maybe<SortOrder>;
  metric?: Maybe<BioMetricOrderByWithRelationInput>;
  samples?: Maybe<EegSampleOrderByRelationAggregateInput>;
};

export type EegSample = {
  __typename?: 'EEGSample';
  id: Scalars['String'];
  channel: Scalars['Int'];
  eeg: Eeg;
  data: Array<Scalars['Float']>;
};

export type EegSampleCreateManyEegInput = {
  id?: Maybe<Scalars['String']>;
  channel: Scalars['Int'];
  data?: Maybe<EegSampleCreateManydataInput>;
};

export type EegSampleCreateManyEegInputEnvelope = {
  data?: Maybe<Array<EegSampleCreateManyEegInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EegSampleCreateManydataInput = {
  set?: Maybe<Array<Scalars['Float']>>;
};

export type EegSampleCreateNestedManyWithoutEegInput = {
  create?: Maybe<Array<EegSampleCreateWithoutEegInput>>;
  connectOrCreate?: Maybe<Array<EegSampleCreateOrConnectWithoutEegInput>>;
  createMany?: Maybe<EegSampleCreateManyEegInputEnvelope>;
  connect?: Maybe<Array<EegSampleWhereUniqueInput>>;
};

export type EegSampleCreateOrConnectWithoutEegInput = {
  where: EegSampleWhereUniqueInput;
  create: EegSampleCreateWithoutEegInput;
};

export type EegSampleCreateWithoutEegInput = {
  id?: Maybe<Scalars['String']>;
  channel: Scalars['Int'];
  data?: Maybe<EegSampleCreatedataInput>;
};

export type EegSampleCreatedataInput = {
  set?: Maybe<Array<Scalars['Float']>>;
};

export type EegSampleListRelationFilter = {
  every?: Maybe<EegSampleWhereInput>;
  some?: Maybe<EegSampleWhereInput>;
  none?: Maybe<EegSampleWhereInput>;
};

export type EegSampleOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
};

export type EegSampleScalarWhereInput = {
  AND?: Maybe<Array<EegSampleScalarWhereInput>>;
  OR?: Maybe<Array<EegSampleScalarWhereInput>>;
  NOT?: Maybe<Array<EegSampleScalarWhereInput>>;
  data?: Maybe<FloatNullableListFilter>;
  id?: Maybe<StringFilter>;
  channel?: Maybe<IntFilter>;
  eegId?: Maybe<StringFilter>;
};

export type EegSampleUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  channel?: Maybe<IntFieldUpdateOperationsInput>;
  data?: Maybe<EegSampleUpdatedataInput>;
};

export type EegSampleUpdateManyWithWhereWithoutEegInput = {
  where: EegSampleScalarWhereInput;
  data: EegSampleUpdateManyMutationInput;
};

export type EegSampleUpdateManyWithoutEegInput = {
  create?: Maybe<Array<EegSampleCreateWithoutEegInput>>;
  connectOrCreate?: Maybe<Array<EegSampleCreateOrConnectWithoutEegInput>>;
  upsert?: Maybe<Array<EegSampleUpsertWithWhereUniqueWithoutEegInput>>;
  createMany?: Maybe<EegSampleCreateManyEegInputEnvelope>;
  connect?: Maybe<Array<EegSampleWhereUniqueInput>>;
  set?: Maybe<Array<EegSampleWhereUniqueInput>>;
  disconnect?: Maybe<Array<EegSampleWhereUniqueInput>>;
  delete?: Maybe<Array<EegSampleWhereUniqueInput>>;
  update?: Maybe<Array<EegSampleUpdateWithWhereUniqueWithoutEegInput>>;
  updateMany?: Maybe<Array<EegSampleUpdateManyWithWhereWithoutEegInput>>;
  deleteMany?: Maybe<Array<EegSampleScalarWhereInput>>;
};

export type EegSampleUpdateWithWhereUniqueWithoutEegInput = {
  where: EegSampleWhereUniqueInput;
  data: EegSampleUpdateWithoutEegInput;
};

export type EegSampleUpdateWithoutEegInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  channel?: Maybe<IntFieldUpdateOperationsInput>;
  data?: Maybe<EegSampleUpdatedataInput>;
};

export type EegSampleUpdatedataInput = {
  set?: Maybe<Array<Scalars['Float']>>;
  push?: Maybe<Scalars['Float']>;
};

export type EegSampleUpsertWithWhereUniqueWithoutEegInput = {
  where: EegSampleWhereUniqueInput;
  update: EegSampleUpdateWithoutEegInput;
  create: EegSampleCreateWithoutEegInput;
};

export type EegSampleWhereInput = {
  AND?: Maybe<Array<EegSampleWhereInput>>;
  OR?: Maybe<Array<EegSampleWhereInput>>;
  NOT?: Maybe<Array<EegSampleWhereInput>>;
  data?: Maybe<FloatNullableListFilter>;
  id?: Maybe<StringFilter>;
  channel?: Maybe<IntFilter>;
  eegId?: Maybe<StringFilter>;
  eeg?: Maybe<EegWhereInput>;
};

export type EegSampleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type EegScalarWhereInput = {
  AND?: Maybe<Array<EegScalarWhereInput>>;
  OR?: Maybe<Array<EegScalarWhereInput>>;
  NOT?: Maybe<Array<EegScalarWhereInput>>;
  metricId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  samplingRate?: Maybe<FloatNullableFilter>;
  startTime?: Maybe<FloatNullableFilter>;
  createdAt?: Maybe<DateTimeNullableFilter>;
  updatedAt?: Maybe<DateTimeNullableFilter>;
  bufferDuration?: Maybe<IntFilter>;
  bufferInterval?: Maybe<IntFilter>;
  packetLossIndex?: Maybe<IntNullableListFilter>;
  packetLossSkipped?: Maybe<IntNullableListFilter>;
};

export type EegUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  samplingRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  startTime?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  bufferDuration?: Maybe<IntFieldUpdateOperationsInput>;
  bufferInterval?: Maybe<IntFieldUpdateOperationsInput>;
  packetLossIndex?: Maybe<EegUpdatepacketLossIndexInput>;
  packetLossSkipped?: Maybe<EegUpdatepacketLossSkippedInput>;
};

export type EegUpdateManyWithWhereWithoutMetricInput = {
  where: EegScalarWhereInput;
  data: EegUpdateManyMutationInput;
};

export type EegUpdateManyWithoutMetricInput = {
  create?: Maybe<Array<EegCreateWithoutMetricInput>>;
  connectOrCreate?: Maybe<Array<EegCreateOrConnectWithoutMetricInput>>;
  upsert?: Maybe<Array<EegUpsertWithWhereUniqueWithoutMetricInput>>;
  createMany?: Maybe<EegCreateManyMetricInputEnvelope>;
  connect?: Maybe<Array<EegWhereUniqueInput>>;
  set?: Maybe<Array<EegWhereUniqueInput>>;
  disconnect?: Maybe<Array<EegWhereUniqueInput>>;
  delete?: Maybe<Array<EegWhereUniqueInput>>;
  update?: Maybe<Array<EegUpdateWithWhereUniqueWithoutMetricInput>>;
  updateMany?: Maybe<Array<EegUpdateManyWithWhereWithoutMetricInput>>;
  deleteMany?: Maybe<Array<EegScalarWhereInput>>;
};

export type EegUpdateWithWhereUniqueWithoutMetricInput = {
  where: EegWhereUniqueInput;
  data: EegUpdateWithoutMetricInput;
};

export type EegUpdateWithoutMetricInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  samplingRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  startTime?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<NullableDateTimeFieldUpdateOperationsInput>;
  bufferDuration?: Maybe<IntFieldUpdateOperationsInput>;
  bufferInterval?: Maybe<IntFieldUpdateOperationsInput>;
  packetLossIndex?: Maybe<EegUpdatepacketLossIndexInput>;
  packetLossSkipped?: Maybe<EegUpdatepacketLossSkippedInput>;
  samples?: Maybe<EegSampleUpdateManyWithoutEegInput>;
};

export type EegUpdatepacketLossIndexInput = {
  set?: Maybe<Array<Scalars['Int']>>;
  push?: Maybe<Scalars['Int']>;
};

export type EegUpdatepacketLossSkippedInput = {
  set?: Maybe<Array<Scalars['Int']>>;
  push?: Maybe<Scalars['Int']>;
};

export type EegUpsertWithWhereUniqueWithoutMetricInput = {
  where: EegWhereUniqueInput;
  update: EegUpdateWithoutMetricInput;
  create: EegCreateWithoutMetricInput;
};

export type EegWhereInput = {
  AND?: Maybe<Array<EegWhereInput>>;
  OR?: Maybe<Array<EegWhereInput>>;
  NOT?: Maybe<Array<EegWhereInput>>;
  metricId?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  samplingRate?: Maybe<FloatNullableFilter>;
  startTime?: Maybe<FloatNullableFilter>;
  createdAt?: Maybe<DateTimeNullableFilter>;
  updatedAt?: Maybe<DateTimeNullableFilter>;
  bufferDuration?: Maybe<IntFilter>;
  bufferInterval?: Maybe<IntFilter>;
  packetLossIndex?: Maybe<IntNullableListFilter>;
  packetLossSkipped?: Maybe<IntNullableListFilter>;
  metric?: Maybe<BioMetricWhereInput>;
  samples?: Maybe<EegSampleListRelationFilter>;
};

export type EegWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type EnumBatteryTypeFieldUpdateOperationsInput = {
  set?: Maybe<BatteryType>;
};

export type EnumBatteryTypeFilter = {
  equals?: Maybe<BatteryType>;
  in?: Maybe<Array<BatteryType>>;
  notIn?: Maybe<Array<BatteryType>>;
  not?: Maybe<NestedEnumBatteryTypeFilter>;
};

export type EnumDeviceTypeFieldUpdateOperationsInput = {
  set?: Maybe<DeviceType>;
};

export type EnumDeviceTypeFilter = {
  equals?: Maybe<DeviceType>;
  in?: Maybe<Array<DeviceType>>;
  notIn?: Maybe<Array<DeviceType>>;
  not?: Maybe<NestedEnumDeviceTypeFilter>;
};

export type EnumRoleFieldUpdateOperationsInput = {
  set?: Maybe<Role>;
};

export type EnumRoleFilter = {
  equals?: Maybe<Role>;
  in?: Maybe<Array<Role>>;
  notIn?: Maybe<Array<Role>>;
  not?: Maybe<NestedEnumRoleFilter>;
};

export type EnumTaskTypeFieldUpdateOperationsInput = {
  set?: Maybe<TaskType>;
};

export type EnumTaskTypeFilter = {
  equals?: Maybe<TaskType>;
  in?: Maybe<Array<TaskType>>;
  notIn?: Maybe<Array<TaskType>>;
  not?: Maybe<NestedEnumTaskTypeFilter>;
};

export type EnumTrialTypeNullableFilter = {
  equals?: Maybe<TrialType>;
  in?: Maybe<Array<TrialType>>;
  notIn?: Maybe<Array<TrialType>>;
  not?: Maybe<NestedEnumTrialTypeNullableFilter>;
};

export type EnumTrialWidthNullableFilter = {
  equals?: Maybe<TrialWidth>;
  in?: Maybe<Array<TrialWidth>>;
  notIn?: Maybe<Array<TrialWidth>>;
  not?: Maybe<NestedEnumTrialWidthNullableFilter>;
};

export type Event = {
  __typename?: 'Event';
  id: Scalars['String'];
  timestamp: Scalars['Float'];
  value: Scalars['String'];
  metric: BioMetric;
};

export type EventCreateManyMetricInput = {
  eventId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  timestamp: Scalars['Float'];
  value: Scalars['String'];
};

export type EventCreateManyMetricInputEnvelope = {
  data?: Maybe<Array<EventCreateManyMetricInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type EventCreateNestedManyWithoutMetricInput = {
  create?: Maybe<Array<EventCreateWithoutMetricInput>>;
  connectOrCreate?: Maybe<Array<EventCreateOrConnectWithoutMetricInput>>;
  createMany?: Maybe<EventCreateManyMetricInputEnvelope>;
  connect?: Maybe<Array<EventWhereUniqueInput>>;
};

export type EventCreateOrConnectWithoutMetricInput = {
  where: EventWhereUniqueInput;
  create: EventCreateWithoutMetricInput;
};

export type EventCreateWithoutMetricInput = {
  eventId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  timestamp: Scalars['Float'];
  value: Scalars['String'];
};

export type EventListRelationFilter = {
  every?: Maybe<EventWhereInput>;
  some?: Maybe<EventWhereInput>;
  none?: Maybe<EventWhereInput>;
};

export type EventOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
};

export type EventOrderByWithRelationInput = {
  metricId?: Maybe<SortOrder>;
  eventId?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  timestamp?: Maybe<SortOrder>;
  value?: Maybe<SortOrder>;
  metric?: Maybe<BioMetricOrderByWithRelationInput>;
};

export type EventScalarWhereInput = {
  AND?: Maybe<Array<EventScalarWhereInput>>;
  OR?: Maybe<Array<EventScalarWhereInput>>;
  NOT?: Maybe<Array<EventScalarWhereInput>>;
  metricId?: Maybe<StringFilter>;
  eventId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  timestamp?: Maybe<FloatFilter>;
  value?: Maybe<StringFilter>;
};

export type EventUpdateManyMutationInput = {
  eventId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  timestamp?: Maybe<FloatFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type EventUpdateManyWithWhereWithoutMetricInput = {
  where: EventScalarWhereInput;
  data: EventUpdateManyMutationInput;
};

export type EventUpdateManyWithoutMetricInput = {
  create?: Maybe<Array<EventCreateWithoutMetricInput>>;
  connectOrCreate?: Maybe<Array<EventCreateOrConnectWithoutMetricInput>>;
  upsert?: Maybe<Array<EventUpsertWithWhereUniqueWithoutMetricInput>>;
  createMany?: Maybe<EventCreateManyMetricInputEnvelope>;
  connect?: Maybe<Array<EventWhereUniqueInput>>;
  set?: Maybe<Array<EventWhereUniqueInput>>;
  disconnect?: Maybe<Array<EventWhereUniqueInput>>;
  delete?: Maybe<Array<EventWhereUniqueInput>>;
  update?: Maybe<Array<EventUpdateWithWhereUniqueWithoutMetricInput>>;
  updateMany?: Maybe<Array<EventUpdateManyWithWhereWithoutMetricInput>>;
  deleteMany?: Maybe<Array<EventScalarWhereInput>>;
};

export type EventUpdateWithWhereUniqueWithoutMetricInput = {
  where: EventWhereUniqueInput;
  data: EventUpdateWithoutMetricInput;
};

export type EventUpdateWithoutMetricInput = {
  eventId?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  timestamp?: Maybe<FloatFieldUpdateOperationsInput>;
  value?: Maybe<StringFieldUpdateOperationsInput>;
};

export type EventUpsertWithWhereUniqueWithoutMetricInput = {
  where: EventWhereUniqueInput;
  update: EventUpdateWithoutMetricInput;
  create: EventCreateWithoutMetricInput;
};

export type EventWhereInput = {
  AND?: Maybe<Array<EventWhereInput>>;
  OR?: Maybe<Array<EventWhereInput>>;
  NOT?: Maybe<Array<EventWhereInput>>;
  metricId?: Maybe<StringFilter>;
  eventId?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  timestamp?: Maybe<FloatFilter>;
  value?: Maybe<StringFilter>;
  metric?: Maybe<BioMetricWhereInput>;
};

export type EventWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type FloatFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  decrement?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
};

export type FloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type FloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type FloatNullableListFilter = {
  equals?: Maybe<Array<Scalars['Float']>>;
  has?: Maybe<Scalars['Float']>;
  hasEvery?: Maybe<Array<Scalars['Float']>>;
  hasSome?: Maybe<Array<Scalars['Float']>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type Gyroscope = {
  __typename?: 'Gyroscope';
  id: Scalars['String'];
  metric?: Maybe<BioMetric>;
  samples: Array<GyroscopeSample>;
  createdAt: Scalars['DateTime'];
  startTime?: Maybe<Scalars['Float']>;
  samplingRate?: Maybe<Scalars['Float']>;
  bufferDuration: Scalars['Int'];
  bufferInterval: Scalars['Int'];
  packetLossIndex: Array<Scalars['Int']>;
  packetLossSkipped: Array<Scalars['Int']>;
};


export type GyroscopeSamplesArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<GyroscopeSampleWhereUniqueInput>;
};

export type GyroscopeCreateManyMetricInput = {
  id?: Maybe<Scalars['String']>;
  bufferDuration: Scalars['Int'];
  bufferInterval: Scalars['Int'];
  samplingRate?: Maybe<Scalars['Float']>;
  startTime?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  packetLossIndex?: Maybe<GyroscopeCreateManypacketLossIndexInput>;
  packetLossSkipped?: Maybe<GyroscopeCreateManypacketLossSkippedInput>;
};

export type GyroscopeCreateManyMetricInputEnvelope = {
  data?: Maybe<Array<GyroscopeCreateManyMetricInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type GyroscopeCreateManypacketLossIndexInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type GyroscopeCreateManypacketLossSkippedInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type GyroscopeCreateNestedManyWithoutMetricInput = {
  create?: Maybe<Array<GyroscopeCreateWithoutMetricInput>>;
  connectOrCreate?: Maybe<Array<GyroscopeCreateOrConnectWithoutMetricInput>>;
  createMany?: Maybe<GyroscopeCreateManyMetricInputEnvelope>;
  connect?: Maybe<Array<GyroscopeWhereUniqueInput>>;
};

export type GyroscopeCreateOrConnectWithoutMetricInput = {
  where: GyroscopeWhereUniqueInput;
  create: GyroscopeCreateWithoutMetricInput;
};

export type GyroscopeCreateWithoutMetricInput = {
  id?: Maybe<Scalars['String']>;
  bufferDuration: Scalars['Int'];
  bufferInterval: Scalars['Int'];
  samplingRate?: Maybe<Scalars['Float']>;
  startTime?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  packetLossIndex?: Maybe<GyroscopeCreatepacketLossIndexInput>;
  packetLossSkipped?: Maybe<GyroscopeCreatepacketLossSkippedInput>;
  samples?: Maybe<GyroscopeSampleCreateNestedManyWithoutGyroscopeInput>;
};

export type GyroscopeCreatepacketLossIndexInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type GyroscopeCreatepacketLossSkippedInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type GyroscopeListRelationFilter = {
  every?: Maybe<GyroscopeWhereInput>;
  some?: Maybe<GyroscopeWhereInput>;
  none?: Maybe<GyroscopeWhereInput>;
};

export type GyroscopeOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
};

export type GyroscopeOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  metricId?: Maybe<SortOrder>;
  bufferDuration?: Maybe<SortOrder>;
  bufferInterval?: Maybe<SortOrder>;
  packetLossIndex?: Maybe<SortOrder>;
  packetLossSkipped?: Maybe<SortOrder>;
  samplingRate?: Maybe<SortOrder>;
  startTime?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  metric?: Maybe<BioMetricOrderByWithRelationInput>;
  samples?: Maybe<GyroscopeSampleOrderByRelationAggregateInput>;
};

export type GyroscopeSample = {
  __typename?: 'GyroscopeSample';
  id: Scalars['String'];
  channel: Scalars['Int'];
  data: Array<Scalars['Float']>;
};

export type GyroscopeSampleCreateManyGyroscopeInput = {
  id?: Maybe<Scalars['String']>;
  channel: Scalars['Int'];
  data?: Maybe<GyroscopeSampleCreateManydataInput>;
};

export type GyroscopeSampleCreateManyGyroscopeInputEnvelope = {
  data?: Maybe<Array<GyroscopeSampleCreateManyGyroscopeInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type GyroscopeSampleCreateManydataInput = {
  set?: Maybe<Array<Scalars['Float']>>;
};

export type GyroscopeSampleCreateNestedManyWithoutGyroscopeInput = {
  create?: Maybe<Array<GyroscopeSampleCreateWithoutGyroscopeInput>>;
  connectOrCreate?: Maybe<Array<GyroscopeSampleCreateOrConnectWithoutGyroscopeInput>>;
  createMany?: Maybe<GyroscopeSampleCreateManyGyroscopeInputEnvelope>;
  connect?: Maybe<Array<GyroscopeSampleWhereUniqueInput>>;
};

export type GyroscopeSampleCreateOrConnectWithoutGyroscopeInput = {
  where: GyroscopeSampleWhereUniqueInput;
  create: GyroscopeSampleCreateWithoutGyroscopeInput;
};

export type GyroscopeSampleCreateWithoutGyroscopeInput = {
  id?: Maybe<Scalars['String']>;
  channel: Scalars['Int'];
  data?: Maybe<GyroscopeSampleCreatedataInput>;
};

export type GyroscopeSampleCreatedataInput = {
  set?: Maybe<Array<Scalars['Float']>>;
};

export type GyroscopeSampleListRelationFilter = {
  every?: Maybe<GyroscopeSampleWhereInput>;
  some?: Maybe<GyroscopeSampleWhereInput>;
  none?: Maybe<GyroscopeSampleWhereInput>;
};

export type GyroscopeSampleOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
};

export type GyroscopeSampleScalarWhereInput = {
  AND?: Maybe<Array<GyroscopeSampleScalarWhereInput>>;
  OR?: Maybe<Array<GyroscopeSampleScalarWhereInput>>;
  NOT?: Maybe<Array<GyroscopeSampleScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  channel?: Maybe<IntFilter>;
  gyroscopeId?: Maybe<StringNullableFilter>;
  data?: Maybe<FloatNullableListFilter>;
};

export type GyroscopeSampleUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  channel?: Maybe<IntFieldUpdateOperationsInput>;
  data?: Maybe<GyroscopeSampleUpdatedataInput>;
};

export type GyroscopeSampleUpdateManyWithWhereWithoutGyroscopeInput = {
  where: GyroscopeSampleScalarWhereInput;
  data: GyroscopeSampleUpdateManyMutationInput;
};

export type GyroscopeSampleUpdateManyWithoutGyroscopeInput = {
  create?: Maybe<Array<GyroscopeSampleCreateWithoutGyroscopeInput>>;
  connectOrCreate?: Maybe<Array<GyroscopeSampleCreateOrConnectWithoutGyroscopeInput>>;
  upsert?: Maybe<Array<GyroscopeSampleUpsertWithWhereUniqueWithoutGyroscopeInput>>;
  createMany?: Maybe<GyroscopeSampleCreateManyGyroscopeInputEnvelope>;
  connect?: Maybe<Array<GyroscopeSampleWhereUniqueInput>>;
  set?: Maybe<Array<GyroscopeSampleWhereUniqueInput>>;
  disconnect?: Maybe<Array<GyroscopeSampleWhereUniqueInput>>;
  delete?: Maybe<Array<GyroscopeSampleWhereUniqueInput>>;
  update?: Maybe<Array<GyroscopeSampleUpdateWithWhereUniqueWithoutGyroscopeInput>>;
  updateMany?: Maybe<Array<GyroscopeSampleUpdateManyWithWhereWithoutGyroscopeInput>>;
  deleteMany?: Maybe<Array<GyroscopeSampleScalarWhereInput>>;
};

export type GyroscopeSampleUpdateWithWhereUniqueWithoutGyroscopeInput = {
  where: GyroscopeSampleWhereUniqueInput;
  data: GyroscopeSampleUpdateWithoutGyroscopeInput;
};

export type GyroscopeSampleUpdateWithoutGyroscopeInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  channel?: Maybe<IntFieldUpdateOperationsInput>;
  data?: Maybe<GyroscopeSampleUpdatedataInput>;
};

export type GyroscopeSampleUpdatedataInput = {
  set?: Maybe<Array<Scalars['Float']>>;
  push?: Maybe<Scalars['Float']>;
};

export type GyroscopeSampleUpsertWithWhereUniqueWithoutGyroscopeInput = {
  where: GyroscopeSampleWhereUniqueInput;
  update: GyroscopeSampleUpdateWithoutGyroscopeInput;
  create: GyroscopeSampleCreateWithoutGyroscopeInput;
};

export type GyroscopeSampleWhereInput = {
  AND?: Maybe<Array<GyroscopeSampleWhereInput>>;
  OR?: Maybe<Array<GyroscopeSampleWhereInput>>;
  NOT?: Maybe<Array<GyroscopeSampleWhereInput>>;
  id?: Maybe<StringFilter>;
  channel?: Maybe<IntFilter>;
  gyroscopeId?: Maybe<StringNullableFilter>;
  data?: Maybe<FloatNullableListFilter>;
  gyroscope?: Maybe<GyroscopeWhereInput>;
};

export type GyroscopeSampleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type GyroscopeScalarWhereInput = {
  AND?: Maybe<Array<GyroscopeScalarWhereInput>>;
  OR?: Maybe<Array<GyroscopeScalarWhereInput>>;
  NOT?: Maybe<Array<GyroscopeScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  metricId?: Maybe<StringNullableFilter>;
  bufferDuration?: Maybe<IntFilter>;
  bufferInterval?: Maybe<IntFilter>;
  packetLossIndex?: Maybe<IntNullableListFilter>;
  packetLossSkipped?: Maybe<IntNullableListFilter>;
  samplingRate?: Maybe<FloatNullableFilter>;
  startTime?: Maybe<FloatNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type GyroscopeUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  bufferDuration?: Maybe<IntFieldUpdateOperationsInput>;
  bufferInterval?: Maybe<IntFieldUpdateOperationsInput>;
  samplingRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  startTime?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  packetLossIndex?: Maybe<GyroscopeUpdatepacketLossIndexInput>;
  packetLossSkipped?: Maybe<GyroscopeUpdatepacketLossSkippedInput>;
};

export type GyroscopeUpdateManyWithWhereWithoutMetricInput = {
  where: GyroscopeScalarWhereInput;
  data: GyroscopeUpdateManyMutationInput;
};

export type GyroscopeUpdateManyWithoutMetricInput = {
  create?: Maybe<Array<GyroscopeCreateWithoutMetricInput>>;
  connectOrCreate?: Maybe<Array<GyroscopeCreateOrConnectWithoutMetricInput>>;
  upsert?: Maybe<Array<GyroscopeUpsertWithWhereUniqueWithoutMetricInput>>;
  createMany?: Maybe<GyroscopeCreateManyMetricInputEnvelope>;
  connect?: Maybe<Array<GyroscopeWhereUniqueInput>>;
  set?: Maybe<Array<GyroscopeWhereUniqueInput>>;
  disconnect?: Maybe<Array<GyroscopeWhereUniqueInput>>;
  delete?: Maybe<Array<GyroscopeWhereUniqueInput>>;
  update?: Maybe<Array<GyroscopeUpdateWithWhereUniqueWithoutMetricInput>>;
  updateMany?: Maybe<Array<GyroscopeUpdateManyWithWhereWithoutMetricInput>>;
  deleteMany?: Maybe<Array<GyroscopeScalarWhereInput>>;
};

export type GyroscopeUpdateWithWhereUniqueWithoutMetricInput = {
  where: GyroscopeWhereUniqueInput;
  data: GyroscopeUpdateWithoutMetricInput;
};

export type GyroscopeUpdateWithoutMetricInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  bufferDuration?: Maybe<IntFieldUpdateOperationsInput>;
  bufferInterval?: Maybe<IntFieldUpdateOperationsInput>;
  samplingRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  startTime?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  packetLossIndex?: Maybe<GyroscopeUpdatepacketLossIndexInput>;
  packetLossSkipped?: Maybe<GyroscopeUpdatepacketLossSkippedInput>;
  samples?: Maybe<GyroscopeSampleUpdateManyWithoutGyroscopeInput>;
};

export type GyroscopeUpdatepacketLossIndexInput = {
  set?: Maybe<Array<Scalars['Int']>>;
  push?: Maybe<Scalars['Int']>;
};

export type GyroscopeUpdatepacketLossSkippedInput = {
  set?: Maybe<Array<Scalars['Int']>>;
  push?: Maybe<Scalars['Int']>;
};

export type GyroscopeUpsertWithWhereUniqueWithoutMetricInput = {
  where: GyroscopeWhereUniqueInput;
  update: GyroscopeUpdateWithoutMetricInput;
  create: GyroscopeCreateWithoutMetricInput;
};

export type GyroscopeWhereInput = {
  AND?: Maybe<Array<GyroscopeWhereInput>>;
  OR?: Maybe<Array<GyroscopeWhereInput>>;
  NOT?: Maybe<Array<GyroscopeWhereInput>>;
  id?: Maybe<StringFilter>;
  metricId?: Maybe<StringNullableFilter>;
  bufferDuration?: Maybe<IntFilter>;
  bufferInterval?: Maybe<IntFilter>;
  packetLossIndex?: Maybe<IntNullableListFilter>;
  packetLossSkipped?: Maybe<IntNullableListFilter>;
  samplingRate?: Maybe<FloatNullableFilter>;
  startTime?: Maybe<FloatNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  metric?: Maybe<BioMetricWhereInput>;
  samples?: Maybe<GyroscopeSampleListRelationFilter>;
};

export type GyroscopeWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type IntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export type IntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type IntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type IntNullableListFilter = {
  equals?: Maybe<Array<Scalars['Int']>>;
  has?: Maybe<Scalars['Int']>;
  hasEvery?: Maybe<Array<Scalars['Int']>>;
  hasSome?: Maybe<Array<Scalars['Int']>>;
  isEmpty?: Maybe<Scalars['Boolean']>;
};

export type MatchStatsComp = {
  __typename?: 'MatchStatsComp';
  kills?: Maybe<Scalars['Float']>;
  assists?: Maybe<Scalars['Float']>;
  deaths?: Maybe<Scalars['Float']>;
  mvps?: Maybe<Scalars['Float']>;
  score?: Maybe<Scalars['Float']>;
};

export type MetricSummary = {
  __typename?: 'MetricSummary';
  label?: Maybe<Scalars['String']>;
  mean?: Maybe<Scalars['Float']>;
  median?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  signup?: Maybe<AuthPayload>;
  login?: Maybe<AuthPayload>;
  resetPassword?: Maybe<Scalars['Boolean']>;
  changePassword?: Maybe<Scalars['Boolean']>;
  steamConnect: Steam;
  updateMe?: Maybe<User>;
  updateBioMetric?: Maybe<BioMetric>;
  createBioMetric?: Maybe<BioMetric>;
  deleteBioMetric?: Maybe<Scalars['Boolean']>;
  createCSGame: CsGame;
  updateCSGame?: Maybe<CsGame>;
  deleteEEG?: Maybe<CsGame>;
  createTask: Task;
  updateTask?: Maybe<Task>;
  deleteTask?: Maybe<Task>;
  createTaskBattery: TaskBattery;
  updateTaskBattery?: Maybe<TaskBattery>;
  deleteTaskBattery?: Maybe<TaskBattery>;
  initTest?: Maybe<Task>;
  initBlock?: Maybe<Task>;
  respondTask?: Maybe<TrialResponse>;
  endTask?: Maybe<Task>;
  createSession: Session;
  updateSession?: Maybe<Session>;
  deleteSession?: Maybe<Session>;
  startSession?: Maybe<Session>;
  endSession?: Maybe<Session>;
  createOrganization: Organization;
  updateOrganization?: Maybe<Organization>;
  createStimulation: Stimulation;
  updateStimulation?: Maybe<Stimulation>;
  deleteStimulation?: Maybe<Stimulation>;
  createStimulationProgram: StimulationProgram;
  updateStimulationProgram?: Maybe<StimulationProgram>;
  deleteStimulationProgram?: Maybe<StimulationProgram>;
};


export type MutationSignupArgs = {
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  password: Scalars['String'];
  organization?: Maybe<Scalars['String']>;
};


export type MutationLoginArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  email: Scalars['String'];
};


export type MutationChangePasswordArgs = {
  password: Scalars['String'];
};


export type MutationSteamConnectArgs = {
  where: SteamWhereUniqueInput;
  create: SteamCreateInput;
  update: SteamUpdateInput;
};


export type MutationUpdateMeArgs = {
  name: Scalars['String'];
  email: Scalars['String'];
};


export type MutationUpdateBioMetricArgs = {
  data: BioMetricUpdateInput;
  where: BioMetricWhereUniqueInput;
};


export type MutationCreateBioMetricArgs = {
  taskId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  organizationId?: Maybe<Scalars['String']>;
};


export type MutationDeleteBioMetricArgs = {
  metricId: Scalars['String'];
};


export type MutationCreateCsGameArgs = {
  data: CsGameCreateInput;
};


export type MutationUpdateCsGameArgs = {
  data: CsGameUpdateInput;
  where: CsGameWhereUniqueInput;
};


export type MutationDeleteEegArgs = {
  where: CsGameWhereUniqueInput;
};


export type MutationCreateTaskArgs = {
  data: TaskCreateInput;
};


export type MutationUpdateTaskArgs = {
  data: TaskUpdateInput;
  where: TaskWhereUniqueInput;
};


export type MutationDeleteTaskArgs = {
  where: TaskWhereUniqueInput;
};


export type MutationCreateTaskBatteryArgs = {
  data: TaskBatteryCreateInput;
};


export type MutationUpdateTaskBatteryArgs = {
  data: TaskBatteryUpdateInput;
  where: TaskBatteryWhereUniqueInput;
};


export type MutationDeleteTaskBatteryArgs = {
  where: TaskBatteryWhereUniqueInput;
};


export type MutationInitTestArgs = {
  type: Scalars['String'];
  trials: Scalars['Int'];
  window: Scalars['Int'];
  timed: Scalars['Boolean'];
  time: Scalars['Int'];
  metric?: Maybe<Scalars['String']>;
  stimulation?: Maybe<Scalars['String']>;
  battery?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
};


export type MutationInitBlockArgs = {
  type: Scalars['String'];
  name: Scalars['String'];
  duration?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  metric?: Maybe<Scalars['String']>;
  stimulation?: Maybe<Scalars['String']>;
  battery?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
};


export type MutationRespondTaskArgs = {
  trial: Scalars['ID'];
  response?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
  presentedAt: Scalars['String'];
  respondedAt: Scalars['String'];
};


export type MutationEndTaskArgs = {
  id: Scalars['ID'];
  completed?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
  endTime?: Maybe<Scalars['Int']>;
};


export type MutationCreateSessionArgs = {
  data: SessionCreateInput;
};


export type MutationUpdateSessionArgs = {
  data: SessionUpdateInput;
  where: SessionWhereUniqueInput;
};


export type MutationDeleteSessionArgs = {
  where: SessionWhereUniqueInput;
};


export type MutationStartSessionArgs = {
  type: Scalars['String'];
  startTime: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  program?: Maybe<Scalars['String']>;
  metric?: Maybe<Scalars['String']>;
  deviceType?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
};


export type MutationEndSessionArgs = {
  id: Scalars['String'];
  stopTime?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
};


export type MutationCreateOrganizationArgs = {
  data: OrganizationCreateInput;
};


export type MutationUpdateOrganizationArgs = {
  data: OrganizationUpdateInput;
  where: OrganizationWhereUniqueInput;
};


export type MutationCreateStimulationArgs = {
  data: StimulationCreateInput;
};


export type MutationUpdateStimulationArgs = {
  data: StimulationUpdateInput;
  where: StimulationWhereUniqueInput;
};


export type MutationDeleteStimulationArgs = {
  where: StimulationWhereUniqueInput;
};


export type MutationCreateStimulationProgramArgs = {
  data: StimulationProgramCreateInput;
};


export type MutationUpdateStimulationProgramArgs = {
  data: StimulationProgramUpdateInput;
  where: StimulationProgramWhereUniqueInput;
};


export type MutationDeleteStimulationProgramArgs = {
  where: StimulationProgramWhereUniqueInput;
};

export type NestedBoolFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolFilter>;
};

export type NestedBoolNullableFilter = {
  equals?: Maybe<Scalars['Boolean']>;
  not?: Maybe<NestedBoolNullableFilter>;
};

export type NestedDateTimeFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeFilter>;
};

export type NestedDateTimeNullableFilter = {
  equals?: Maybe<Scalars['DateTime']>;
  in?: Maybe<Array<Scalars['DateTime']>>;
  notIn?: Maybe<Array<Scalars['DateTime']>>;
  lt?: Maybe<Scalars['DateTime']>;
  lte?: Maybe<Scalars['DateTime']>;
  gt?: Maybe<Scalars['DateTime']>;
  gte?: Maybe<Scalars['DateTime']>;
  not?: Maybe<NestedDateTimeNullableFilter>;
};

export type NestedEnumBatteryTypeFilter = {
  equals?: Maybe<BatteryType>;
  in?: Maybe<Array<BatteryType>>;
  notIn?: Maybe<Array<BatteryType>>;
  not?: Maybe<NestedEnumBatteryTypeFilter>;
};

export type NestedEnumDeviceTypeFilter = {
  equals?: Maybe<DeviceType>;
  in?: Maybe<Array<DeviceType>>;
  notIn?: Maybe<Array<DeviceType>>;
  not?: Maybe<NestedEnumDeviceTypeFilter>;
};

export type NestedEnumRoleFilter = {
  equals?: Maybe<Role>;
  in?: Maybe<Array<Role>>;
  notIn?: Maybe<Array<Role>>;
  not?: Maybe<NestedEnumRoleFilter>;
};

export type NestedEnumTaskTypeFilter = {
  equals?: Maybe<TaskType>;
  in?: Maybe<Array<TaskType>>;
  notIn?: Maybe<Array<TaskType>>;
  not?: Maybe<NestedEnumTaskTypeFilter>;
};

export type NestedEnumTrialTypeNullableFilter = {
  equals?: Maybe<TrialType>;
  in?: Maybe<Array<TrialType>>;
  notIn?: Maybe<Array<TrialType>>;
  not?: Maybe<NestedEnumTrialTypeNullableFilter>;
};

export type NestedEnumTrialWidthNullableFilter = {
  equals?: Maybe<TrialWidth>;
  in?: Maybe<Array<TrialWidth>>;
  notIn?: Maybe<Array<TrialWidth>>;
  not?: Maybe<NestedEnumTrialWidthNullableFilter>;
};

export type NestedFloatFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatFilter>;
};

export type NestedFloatNullableFilter = {
  equals?: Maybe<Scalars['Float']>;
  in?: Maybe<Array<Scalars['Float']>>;
  notIn?: Maybe<Array<Scalars['Float']>>;
  lt?: Maybe<Scalars['Float']>;
  lte?: Maybe<Scalars['Float']>;
  gt?: Maybe<Scalars['Float']>;
  gte?: Maybe<Scalars['Float']>;
  not?: Maybe<NestedFloatNullableFilter>;
};

export type NestedIntFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntFilter>;
};

export type NestedIntNullableFilter = {
  equals?: Maybe<Scalars['Int']>;
  in?: Maybe<Array<Scalars['Int']>>;
  notIn?: Maybe<Array<Scalars['Int']>>;
  lt?: Maybe<Scalars['Int']>;
  lte?: Maybe<Scalars['Int']>;
  gt?: Maybe<Scalars['Int']>;
  gte?: Maybe<Scalars['Int']>;
  not?: Maybe<NestedIntNullableFilter>;
};

export type NestedStringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringFilter>;
};

export type NestedStringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type NullableBoolFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Boolean']>;
};

export type NullableDateTimeFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['DateTime']>;
};

export type NullableEnumTrialTypeFieldUpdateOperationsInput = {
  set?: Maybe<TrialType>;
};

export type NullableEnumTrialWidthFieldUpdateOperationsInput = {
  set?: Maybe<TrialWidth>;
};

export type NullableFloatFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Float']>;
  increment?: Maybe<Scalars['Float']>;
  decrement?: Maybe<Scalars['Float']>;
  multiply?: Maybe<Scalars['Float']>;
  divide?: Maybe<Scalars['Float']>;
};

export type NullableIntFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['Int']>;
  increment?: Maybe<Scalars['Int']>;
  decrement?: Maybe<Scalars['Int']>;
  multiply?: Maybe<Scalars['Int']>;
  divide?: Maybe<Scalars['Int']>;
};

export type NullableStringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type Organization = {
  __typename?: 'Organization';
  id: Scalars['String'];
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  users: Array<User>;
};


export type OrganizationUsersArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<UserWhereUniqueInput>;
};

export type OrganizationCreateInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  bioMetric?: Maybe<BioMetricCreateNestedManyWithoutOrganizationInput>;
  games?: Maybe<CsGameCreateNestedManyWithoutOrganizationInput>;
  sessions?: Maybe<SessionCreateNestedManyWithoutOrganizationInput>;
  tasks?: Maybe<TaskCreateNestedManyWithoutOrganizationInput>;
  TaskBattery?: Maybe<TaskBatteryCreateNestedManyWithoutOrganizationInput>;
  users?: Maybe<UserCreateNestedManyWithoutOrganizationInput>;
  stimulations?: Maybe<StimulationCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationCreateNestedManyWithoutUsersInput = {
  create?: Maybe<Array<OrganizationCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCreateOrConnectWithoutUsersInput>>;
  connect?: Maybe<Array<OrganizationWhereUniqueInput>>;
};

export type OrganizationCreateNestedOneWithoutBioMetricInput = {
  create?: Maybe<OrganizationCreateWithoutBioMetricInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutBioMetricInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationCreateNestedOneWithoutGamesInput = {
  create?: Maybe<OrganizationCreateWithoutGamesInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutGamesInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationCreateNestedOneWithoutSessionsInput = {
  create?: Maybe<OrganizationCreateWithoutSessionsInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutSessionsInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationCreateNestedOneWithoutStimulationsInput = {
  create?: Maybe<OrganizationCreateWithoutStimulationsInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutStimulationsInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationCreateNestedOneWithoutTaskBatteryInput = {
  create?: Maybe<OrganizationCreateWithoutTaskBatteryInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutTaskBatteryInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationCreateNestedOneWithoutTasksInput = {
  create?: Maybe<OrganizationCreateWithoutTasksInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutTasksInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
};

export type OrganizationCreateOrConnectWithoutBioMetricInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutBioMetricInput;
};

export type OrganizationCreateOrConnectWithoutGamesInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutGamesInput;
};

export type OrganizationCreateOrConnectWithoutSessionsInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutSessionsInput;
};

export type OrganizationCreateOrConnectWithoutStimulationsInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutStimulationsInput;
};

export type OrganizationCreateOrConnectWithoutTaskBatteryInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutTaskBatteryInput;
};

export type OrganizationCreateOrConnectWithoutTasksInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutTasksInput;
};

export type OrganizationCreateOrConnectWithoutUsersInput = {
  where: OrganizationWhereUniqueInput;
  create: OrganizationCreateWithoutUsersInput;
};

export type OrganizationCreateWithoutBioMetricInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  games?: Maybe<CsGameCreateNestedManyWithoutOrganizationInput>;
  sessions?: Maybe<SessionCreateNestedManyWithoutOrganizationInput>;
  tasks?: Maybe<TaskCreateNestedManyWithoutOrganizationInput>;
  TaskBattery?: Maybe<TaskBatteryCreateNestedManyWithoutOrganizationInput>;
  users?: Maybe<UserCreateNestedManyWithoutOrganizationInput>;
  stimulations?: Maybe<StimulationCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationCreateWithoutGamesInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  bioMetric?: Maybe<BioMetricCreateNestedManyWithoutOrganizationInput>;
  sessions?: Maybe<SessionCreateNestedManyWithoutOrganizationInput>;
  tasks?: Maybe<TaskCreateNestedManyWithoutOrganizationInput>;
  TaskBattery?: Maybe<TaskBatteryCreateNestedManyWithoutOrganizationInput>;
  users?: Maybe<UserCreateNestedManyWithoutOrganizationInput>;
  stimulations?: Maybe<StimulationCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationCreateWithoutSessionsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  bioMetric?: Maybe<BioMetricCreateNestedManyWithoutOrganizationInput>;
  games?: Maybe<CsGameCreateNestedManyWithoutOrganizationInput>;
  tasks?: Maybe<TaskCreateNestedManyWithoutOrganizationInput>;
  TaskBattery?: Maybe<TaskBatteryCreateNestedManyWithoutOrganizationInput>;
  users?: Maybe<UserCreateNestedManyWithoutOrganizationInput>;
  stimulations?: Maybe<StimulationCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationCreateWithoutStimulationsInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  bioMetric?: Maybe<BioMetricCreateNestedManyWithoutOrganizationInput>;
  games?: Maybe<CsGameCreateNestedManyWithoutOrganizationInput>;
  sessions?: Maybe<SessionCreateNestedManyWithoutOrganizationInput>;
  tasks?: Maybe<TaskCreateNestedManyWithoutOrganizationInput>;
  TaskBattery?: Maybe<TaskBatteryCreateNestedManyWithoutOrganizationInput>;
  users?: Maybe<UserCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationCreateWithoutTaskBatteryInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  bioMetric?: Maybe<BioMetricCreateNestedManyWithoutOrganizationInput>;
  games?: Maybe<CsGameCreateNestedManyWithoutOrganizationInput>;
  sessions?: Maybe<SessionCreateNestedManyWithoutOrganizationInput>;
  tasks?: Maybe<TaskCreateNestedManyWithoutOrganizationInput>;
  users?: Maybe<UserCreateNestedManyWithoutOrganizationInput>;
  stimulations?: Maybe<StimulationCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationCreateWithoutTasksInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  bioMetric?: Maybe<BioMetricCreateNestedManyWithoutOrganizationInput>;
  games?: Maybe<CsGameCreateNestedManyWithoutOrganizationInput>;
  sessions?: Maybe<SessionCreateNestedManyWithoutOrganizationInput>;
  TaskBattery?: Maybe<TaskBatteryCreateNestedManyWithoutOrganizationInput>;
  users?: Maybe<UserCreateNestedManyWithoutOrganizationInput>;
  stimulations?: Maybe<StimulationCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationCreateWithoutUsersInput = {
  id?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  bioMetric?: Maybe<BioMetricCreateNestedManyWithoutOrganizationInput>;
  games?: Maybe<CsGameCreateNestedManyWithoutOrganizationInput>;
  sessions?: Maybe<SessionCreateNestedManyWithoutOrganizationInput>;
  tasks?: Maybe<TaskCreateNestedManyWithoutOrganizationInput>;
  TaskBattery?: Maybe<TaskBatteryCreateNestedManyWithoutOrganizationInput>;
  stimulations?: Maybe<StimulationCreateNestedManyWithoutOrganizationInput>;
};

export type OrganizationListRelationFilter = {
  every?: Maybe<OrganizationWhereInput>;
  some?: Maybe<OrganizationWhereInput>;
  none?: Maybe<OrganizationWhereInput>;
};

export type OrganizationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
};

export type OrganizationOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  bioMetric?: Maybe<BioMetricOrderByRelationAggregateInput>;
  games?: Maybe<CsGameOrderByRelationAggregateInput>;
  sessions?: Maybe<SessionOrderByRelationAggregateInput>;
  tasks?: Maybe<TaskOrderByRelationAggregateInput>;
  TaskBattery?: Maybe<TaskBatteryOrderByRelationAggregateInput>;
  users?: Maybe<UserOrderByRelationAggregateInput>;
  stimulations?: Maybe<StimulationOrderByRelationAggregateInput>;
};

export type OrganizationScalarWhereInput = {
  AND?: Maybe<Array<OrganizationScalarWhereInput>>;
  OR?: Maybe<Array<OrganizationScalarWhereInput>>;
  NOT?: Maybe<Array<OrganizationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  email?: Maybe<StringNullableFilter>;
};

export type OrganizationUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bioMetric?: Maybe<BioMetricUpdateManyWithoutOrganizationInput>;
  games?: Maybe<CsGameUpdateManyWithoutOrganizationInput>;
  sessions?: Maybe<SessionUpdateManyWithoutOrganizationInput>;
  tasks?: Maybe<TaskUpdateManyWithoutOrganizationInput>;
  TaskBattery?: Maybe<TaskBatteryUpdateManyWithoutOrganizationInput>;
  users?: Maybe<UserUpdateManyWithoutOrganizationInput>;
  stimulations?: Maybe<StimulationUpdateManyWithoutOrganizationInput>;
};

export type OrganizationUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
};

export type OrganizationUpdateManyWithWhereWithoutUsersInput = {
  where: OrganizationScalarWhereInput;
  data: OrganizationUpdateManyMutationInput;
};

export type OrganizationUpdateManyWithoutUsersInput = {
  create?: Maybe<Array<OrganizationCreateWithoutUsersInput>>;
  connectOrCreate?: Maybe<Array<OrganizationCreateOrConnectWithoutUsersInput>>;
  upsert?: Maybe<Array<OrganizationUpsertWithWhereUniqueWithoutUsersInput>>;
  connect?: Maybe<Array<OrganizationWhereUniqueInput>>;
  set?: Maybe<Array<OrganizationWhereUniqueInput>>;
  disconnect?: Maybe<Array<OrganizationWhereUniqueInput>>;
  delete?: Maybe<Array<OrganizationWhereUniqueInput>>;
  update?: Maybe<Array<OrganizationUpdateWithWhereUniqueWithoutUsersInput>>;
  updateMany?: Maybe<Array<OrganizationUpdateManyWithWhereWithoutUsersInput>>;
  deleteMany?: Maybe<Array<OrganizationScalarWhereInput>>;
};

export type OrganizationUpdateOneRequiredWithoutBioMetricInput = {
  create?: Maybe<OrganizationCreateWithoutBioMetricInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutBioMetricInput>;
  upsert?: Maybe<OrganizationUpsertWithoutBioMetricInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  update?: Maybe<OrganizationUpdateWithoutBioMetricInput>;
};

export type OrganizationUpdateOneWithoutGamesInput = {
  create?: Maybe<OrganizationCreateWithoutGamesInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutGamesInput>;
  upsert?: Maybe<OrganizationUpsertWithoutGamesInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrganizationUpdateWithoutGamesInput>;
};

export type OrganizationUpdateOneWithoutSessionsInput = {
  create?: Maybe<OrganizationCreateWithoutSessionsInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutSessionsInput>;
  upsert?: Maybe<OrganizationUpsertWithoutSessionsInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrganizationUpdateWithoutSessionsInput>;
};

export type OrganizationUpdateOneWithoutStimulationsInput = {
  create?: Maybe<OrganizationCreateWithoutStimulationsInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutStimulationsInput>;
  upsert?: Maybe<OrganizationUpsertWithoutStimulationsInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrganizationUpdateWithoutStimulationsInput>;
};

export type OrganizationUpdateOneWithoutTaskBatteryInput = {
  create?: Maybe<OrganizationCreateWithoutTaskBatteryInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutTaskBatteryInput>;
  upsert?: Maybe<OrganizationUpsertWithoutTaskBatteryInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrganizationUpdateWithoutTaskBatteryInput>;
};

export type OrganizationUpdateOneWithoutTasksInput = {
  create?: Maybe<OrganizationCreateWithoutTasksInput>;
  connectOrCreate?: Maybe<OrganizationCreateOrConnectWithoutTasksInput>;
  upsert?: Maybe<OrganizationUpsertWithoutTasksInput>;
  connect?: Maybe<OrganizationWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<OrganizationUpdateWithoutTasksInput>;
};

export type OrganizationUpdateWithWhereUniqueWithoutUsersInput = {
  where: OrganizationWhereUniqueInput;
  data: OrganizationUpdateWithoutUsersInput;
};

export type OrganizationUpdateWithoutBioMetricInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  games?: Maybe<CsGameUpdateManyWithoutOrganizationInput>;
  sessions?: Maybe<SessionUpdateManyWithoutOrganizationInput>;
  tasks?: Maybe<TaskUpdateManyWithoutOrganizationInput>;
  TaskBattery?: Maybe<TaskBatteryUpdateManyWithoutOrganizationInput>;
  users?: Maybe<UserUpdateManyWithoutOrganizationInput>;
  stimulations?: Maybe<StimulationUpdateManyWithoutOrganizationInput>;
};

export type OrganizationUpdateWithoutGamesInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bioMetric?: Maybe<BioMetricUpdateManyWithoutOrganizationInput>;
  sessions?: Maybe<SessionUpdateManyWithoutOrganizationInput>;
  tasks?: Maybe<TaskUpdateManyWithoutOrganizationInput>;
  TaskBattery?: Maybe<TaskBatteryUpdateManyWithoutOrganizationInput>;
  users?: Maybe<UserUpdateManyWithoutOrganizationInput>;
  stimulations?: Maybe<StimulationUpdateManyWithoutOrganizationInput>;
};

export type OrganizationUpdateWithoutSessionsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bioMetric?: Maybe<BioMetricUpdateManyWithoutOrganizationInput>;
  games?: Maybe<CsGameUpdateManyWithoutOrganizationInput>;
  tasks?: Maybe<TaskUpdateManyWithoutOrganizationInput>;
  TaskBattery?: Maybe<TaskBatteryUpdateManyWithoutOrganizationInput>;
  users?: Maybe<UserUpdateManyWithoutOrganizationInput>;
  stimulations?: Maybe<StimulationUpdateManyWithoutOrganizationInput>;
};

export type OrganizationUpdateWithoutStimulationsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bioMetric?: Maybe<BioMetricUpdateManyWithoutOrganizationInput>;
  games?: Maybe<CsGameUpdateManyWithoutOrganizationInput>;
  sessions?: Maybe<SessionUpdateManyWithoutOrganizationInput>;
  tasks?: Maybe<TaskUpdateManyWithoutOrganizationInput>;
  TaskBattery?: Maybe<TaskBatteryUpdateManyWithoutOrganizationInput>;
  users?: Maybe<UserUpdateManyWithoutOrganizationInput>;
};

export type OrganizationUpdateWithoutTaskBatteryInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bioMetric?: Maybe<BioMetricUpdateManyWithoutOrganizationInput>;
  games?: Maybe<CsGameUpdateManyWithoutOrganizationInput>;
  sessions?: Maybe<SessionUpdateManyWithoutOrganizationInput>;
  tasks?: Maybe<TaskUpdateManyWithoutOrganizationInput>;
  users?: Maybe<UserUpdateManyWithoutOrganizationInput>;
  stimulations?: Maybe<StimulationUpdateManyWithoutOrganizationInput>;
};

export type OrganizationUpdateWithoutTasksInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bioMetric?: Maybe<BioMetricUpdateManyWithoutOrganizationInput>;
  games?: Maybe<CsGameUpdateManyWithoutOrganizationInput>;
  sessions?: Maybe<SessionUpdateManyWithoutOrganizationInput>;
  TaskBattery?: Maybe<TaskBatteryUpdateManyWithoutOrganizationInput>;
  users?: Maybe<UserUpdateManyWithoutOrganizationInput>;
  stimulations?: Maybe<StimulationUpdateManyWithoutOrganizationInput>;
};

export type OrganizationUpdateWithoutUsersInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  email?: Maybe<NullableStringFieldUpdateOperationsInput>;
  bioMetric?: Maybe<BioMetricUpdateManyWithoutOrganizationInput>;
  games?: Maybe<CsGameUpdateManyWithoutOrganizationInput>;
  sessions?: Maybe<SessionUpdateManyWithoutOrganizationInput>;
  tasks?: Maybe<TaskUpdateManyWithoutOrganizationInput>;
  TaskBattery?: Maybe<TaskBatteryUpdateManyWithoutOrganizationInput>;
  stimulations?: Maybe<StimulationUpdateManyWithoutOrganizationInput>;
};

export type OrganizationUpsertWithWhereUniqueWithoutUsersInput = {
  where: OrganizationWhereUniqueInput;
  update: OrganizationUpdateWithoutUsersInput;
  create: OrganizationCreateWithoutUsersInput;
};

export type OrganizationUpsertWithoutBioMetricInput = {
  update: OrganizationUpdateWithoutBioMetricInput;
  create: OrganizationCreateWithoutBioMetricInput;
};

export type OrganizationUpsertWithoutGamesInput = {
  update: OrganizationUpdateWithoutGamesInput;
  create: OrganizationCreateWithoutGamesInput;
};

export type OrganizationUpsertWithoutSessionsInput = {
  update: OrganizationUpdateWithoutSessionsInput;
  create: OrganizationCreateWithoutSessionsInput;
};

export type OrganizationUpsertWithoutStimulationsInput = {
  update: OrganizationUpdateWithoutStimulationsInput;
  create: OrganizationCreateWithoutStimulationsInput;
};

export type OrganizationUpsertWithoutTaskBatteryInput = {
  update: OrganizationUpdateWithoutTaskBatteryInput;
  create: OrganizationCreateWithoutTaskBatteryInput;
};

export type OrganizationUpsertWithoutTasksInput = {
  update: OrganizationUpdateWithoutTasksInput;
  create: OrganizationCreateWithoutTasksInput;
};

export type OrganizationWhereInput = {
  AND?: Maybe<Array<OrganizationWhereInput>>;
  OR?: Maybe<Array<OrganizationWhereInput>>;
  NOT?: Maybe<Array<OrganizationWhereInput>>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringFilter>;
  email?: Maybe<StringNullableFilter>;
  bioMetric?: Maybe<BioMetricListRelationFilter>;
  games?: Maybe<CsGameListRelationFilter>;
  sessions?: Maybe<SessionListRelationFilter>;
  tasks?: Maybe<TaskListRelationFilter>;
  TaskBattery?: Maybe<TaskBatteryListRelationFilter>;
  users?: Maybe<UserListRelationFilter>;
  stimulations?: Maybe<StimulationListRelationFilter>;
};

export type OrganizationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type Ppg = {
  __typename?: 'PPG';
  id: Scalars['String'];
  metric: BioMetric;
  samples: Array<PpgSample>;
  createdAt: Scalars['DateTime'];
  startTime?: Maybe<Scalars['Float']>;
  samplingRate?: Maybe<Scalars['Float']>;
  bufferDuration: Scalars['Int'];
  bufferInterval: Scalars['Int'];
  packetLossIndex: Array<Scalars['Int']>;
  packetLossSkipped: Array<Scalars['Int']>;
};


export type PpgSamplesArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<PpgSampleWhereUniqueInput>;
};

export type PpgCreateManyMetricInput = {
  id?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['Float']>;
  bufferDuration: Scalars['Int'];
  bufferInterval: Scalars['Int'];
  samplingRate?: Maybe<Scalars['Float']>;
  startTime?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  oldSamples?: Maybe<PpgCreateManyoldSamplesInput>;
  packetLossIndex?: Maybe<PpgCreateManypacketLossIndexInput>;
  packetLossSkipped?: Maybe<PpgCreateManypacketLossSkippedInput>;
};

export type PpgCreateManyMetricInputEnvelope = {
  data?: Maybe<Array<PpgCreateManyMetricInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PpgCreateManyoldSamplesInput = {
  set?: Maybe<Array<Scalars['Float']>>;
};

export type PpgCreateManypacketLossIndexInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type PpgCreateManypacketLossSkippedInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type PpgCreateNestedManyWithoutMetricInput = {
  create?: Maybe<Array<PpgCreateWithoutMetricInput>>;
  connectOrCreate?: Maybe<Array<PpgCreateOrConnectWithoutMetricInput>>;
  createMany?: Maybe<PpgCreateManyMetricInputEnvelope>;
  connect?: Maybe<Array<PpgWhereUniqueInput>>;
};

export type PpgCreateOrConnectWithoutMetricInput = {
  where: PpgWhereUniqueInput;
  create: PpgCreateWithoutMetricInput;
};

export type PpgCreateWithoutMetricInput = {
  id?: Maybe<Scalars['String']>;
  channel?: Maybe<Scalars['Int']>;
  index?: Maybe<Scalars['Int']>;
  timestamp?: Maybe<Scalars['Float']>;
  bufferDuration: Scalars['Int'];
  bufferInterval: Scalars['Int'];
  samplingRate?: Maybe<Scalars['Float']>;
  startTime?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  oldSamples?: Maybe<PpgCreateoldSamplesInput>;
  packetLossIndex?: Maybe<PpgCreatepacketLossIndexInput>;
  packetLossSkipped?: Maybe<PpgCreatepacketLossSkippedInput>;
  samples?: Maybe<PpgSampleCreateNestedManyWithoutPpgInput>;
};

export type PpgCreateoldSamplesInput = {
  set?: Maybe<Array<Scalars['Float']>>;
};

export type PpgCreatepacketLossIndexInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type PpgCreatepacketLossSkippedInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type PpgListRelationFilter = {
  every?: Maybe<PpgWhereInput>;
  some?: Maybe<PpgWhereInput>;
  none?: Maybe<PpgWhereInput>;
};

export type PpgOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
};

export type PpgOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  metricId?: Maybe<SortOrder>;
  channel?: Maybe<SortOrder>;
  index?: Maybe<SortOrder>;
  timestamp?: Maybe<SortOrder>;
  oldSamples?: Maybe<SortOrder>;
  bufferDuration?: Maybe<SortOrder>;
  bufferInterval?: Maybe<SortOrder>;
  packetLossIndex?: Maybe<SortOrder>;
  packetLossSkipped?: Maybe<SortOrder>;
  samplingRate?: Maybe<SortOrder>;
  startTime?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  metric?: Maybe<BioMetricOrderByWithRelationInput>;
  samples?: Maybe<PpgSampleOrderByRelationAggregateInput>;
};

export type PpgSample = {
  __typename?: 'PPGSample';
  id: Scalars['String'];
  channel: Scalars['Int'];
  data: Array<Scalars['Float']>;
};

export type PpgSampleCreateManyPpgInput = {
  id?: Maybe<Scalars['String']>;
  channel: Scalars['Int'];
  data?: Maybe<PpgSampleCreateManydataInput>;
};

export type PpgSampleCreateManyPpgInputEnvelope = {
  data?: Maybe<Array<PpgSampleCreateManyPpgInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type PpgSampleCreateManydataInput = {
  set?: Maybe<Array<Scalars['Float']>>;
};

export type PpgSampleCreateNestedManyWithoutPpgInput = {
  create?: Maybe<Array<PpgSampleCreateWithoutPpgInput>>;
  connectOrCreate?: Maybe<Array<PpgSampleCreateOrConnectWithoutPpgInput>>;
  createMany?: Maybe<PpgSampleCreateManyPpgInputEnvelope>;
  connect?: Maybe<Array<PpgSampleWhereUniqueInput>>;
};

export type PpgSampleCreateOrConnectWithoutPpgInput = {
  where: PpgSampleWhereUniqueInput;
  create: PpgSampleCreateWithoutPpgInput;
};

export type PpgSampleCreateWithoutPpgInput = {
  id?: Maybe<Scalars['String']>;
  channel: Scalars['Int'];
  data?: Maybe<PpgSampleCreatedataInput>;
};

export type PpgSampleCreatedataInput = {
  set?: Maybe<Array<Scalars['Float']>>;
};

export type PpgSampleListRelationFilter = {
  every?: Maybe<PpgSampleWhereInput>;
  some?: Maybe<PpgSampleWhereInput>;
  none?: Maybe<PpgSampleWhereInput>;
};

export type PpgSampleOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
};

export type PpgSampleScalarWhereInput = {
  AND?: Maybe<Array<PpgSampleScalarWhereInput>>;
  OR?: Maybe<Array<PpgSampleScalarWhereInput>>;
  NOT?: Maybe<Array<PpgSampleScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  channel?: Maybe<IntFilter>;
  ppgId?: Maybe<StringNullableFilter>;
  data?: Maybe<FloatNullableListFilter>;
};

export type PpgSampleUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  channel?: Maybe<IntFieldUpdateOperationsInput>;
  data?: Maybe<PpgSampleUpdatedataInput>;
};

export type PpgSampleUpdateManyWithWhereWithoutPpgInput = {
  where: PpgSampleScalarWhereInput;
  data: PpgSampleUpdateManyMutationInput;
};

export type PpgSampleUpdateManyWithoutPpgInput = {
  create?: Maybe<Array<PpgSampleCreateWithoutPpgInput>>;
  connectOrCreate?: Maybe<Array<PpgSampleCreateOrConnectWithoutPpgInput>>;
  upsert?: Maybe<Array<PpgSampleUpsertWithWhereUniqueWithoutPpgInput>>;
  createMany?: Maybe<PpgSampleCreateManyPpgInputEnvelope>;
  connect?: Maybe<Array<PpgSampleWhereUniqueInput>>;
  set?: Maybe<Array<PpgSampleWhereUniqueInput>>;
  disconnect?: Maybe<Array<PpgSampleWhereUniqueInput>>;
  delete?: Maybe<Array<PpgSampleWhereUniqueInput>>;
  update?: Maybe<Array<PpgSampleUpdateWithWhereUniqueWithoutPpgInput>>;
  updateMany?: Maybe<Array<PpgSampleUpdateManyWithWhereWithoutPpgInput>>;
  deleteMany?: Maybe<Array<PpgSampleScalarWhereInput>>;
};

export type PpgSampleUpdateWithWhereUniqueWithoutPpgInput = {
  where: PpgSampleWhereUniqueInput;
  data: PpgSampleUpdateWithoutPpgInput;
};

export type PpgSampleUpdateWithoutPpgInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  channel?: Maybe<IntFieldUpdateOperationsInput>;
  data?: Maybe<PpgSampleUpdatedataInput>;
};

export type PpgSampleUpdatedataInput = {
  set?: Maybe<Array<Scalars['Float']>>;
  push?: Maybe<Scalars['Float']>;
};

export type PpgSampleUpsertWithWhereUniqueWithoutPpgInput = {
  where: PpgSampleWhereUniqueInput;
  update: PpgSampleUpdateWithoutPpgInput;
  create: PpgSampleCreateWithoutPpgInput;
};

export type PpgSampleWhereInput = {
  AND?: Maybe<Array<PpgSampleWhereInput>>;
  OR?: Maybe<Array<PpgSampleWhereInput>>;
  NOT?: Maybe<Array<PpgSampleWhereInput>>;
  id?: Maybe<StringFilter>;
  channel?: Maybe<IntFilter>;
  ppgId?: Maybe<StringNullableFilter>;
  data?: Maybe<FloatNullableListFilter>;
  ppg?: Maybe<PpgWhereInput>;
};

export type PpgSampleWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type PpgScalarWhereInput = {
  AND?: Maybe<Array<PpgScalarWhereInput>>;
  OR?: Maybe<Array<PpgScalarWhereInput>>;
  NOT?: Maybe<Array<PpgScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  metricId?: Maybe<StringFilter>;
  channel?: Maybe<IntNullableFilter>;
  index?: Maybe<IntNullableFilter>;
  timestamp?: Maybe<FloatNullableFilter>;
  oldSamples?: Maybe<FloatNullableListFilter>;
  bufferDuration?: Maybe<IntFilter>;
  bufferInterval?: Maybe<IntFilter>;
  packetLossIndex?: Maybe<IntNullableListFilter>;
  packetLossSkipped?: Maybe<IntNullableListFilter>;
  samplingRate?: Maybe<FloatNullableFilter>;
  startTime?: Maybe<FloatNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type PpgUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  channel?: Maybe<NullableIntFieldUpdateOperationsInput>;
  index?: Maybe<NullableIntFieldUpdateOperationsInput>;
  timestamp?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bufferDuration?: Maybe<IntFieldUpdateOperationsInput>;
  bufferInterval?: Maybe<IntFieldUpdateOperationsInput>;
  samplingRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  startTime?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  oldSamples?: Maybe<PpgUpdateoldSamplesInput>;
  packetLossIndex?: Maybe<PpgUpdatepacketLossIndexInput>;
  packetLossSkipped?: Maybe<PpgUpdatepacketLossSkippedInput>;
};

export type PpgUpdateManyWithWhereWithoutMetricInput = {
  where: PpgScalarWhereInput;
  data: PpgUpdateManyMutationInput;
};

export type PpgUpdateManyWithoutMetricInput = {
  create?: Maybe<Array<PpgCreateWithoutMetricInput>>;
  connectOrCreate?: Maybe<Array<PpgCreateOrConnectWithoutMetricInput>>;
  upsert?: Maybe<Array<PpgUpsertWithWhereUniqueWithoutMetricInput>>;
  createMany?: Maybe<PpgCreateManyMetricInputEnvelope>;
  connect?: Maybe<Array<PpgWhereUniqueInput>>;
  set?: Maybe<Array<PpgWhereUniqueInput>>;
  disconnect?: Maybe<Array<PpgWhereUniqueInput>>;
  delete?: Maybe<Array<PpgWhereUniqueInput>>;
  update?: Maybe<Array<PpgUpdateWithWhereUniqueWithoutMetricInput>>;
  updateMany?: Maybe<Array<PpgUpdateManyWithWhereWithoutMetricInput>>;
  deleteMany?: Maybe<Array<PpgScalarWhereInput>>;
};

export type PpgUpdateWithWhereUniqueWithoutMetricInput = {
  where: PpgWhereUniqueInput;
  data: PpgUpdateWithoutMetricInput;
};

export type PpgUpdateWithoutMetricInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  channel?: Maybe<NullableIntFieldUpdateOperationsInput>;
  index?: Maybe<NullableIntFieldUpdateOperationsInput>;
  timestamp?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  bufferDuration?: Maybe<IntFieldUpdateOperationsInput>;
  bufferInterval?: Maybe<IntFieldUpdateOperationsInput>;
  samplingRate?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  startTime?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  oldSamples?: Maybe<PpgUpdateoldSamplesInput>;
  packetLossIndex?: Maybe<PpgUpdatepacketLossIndexInput>;
  packetLossSkipped?: Maybe<PpgUpdatepacketLossSkippedInput>;
  samples?: Maybe<PpgSampleUpdateManyWithoutPpgInput>;
};

export type PpgUpdateoldSamplesInput = {
  set?: Maybe<Array<Scalars['Float']>>;
  push?: Maybe<Scalars['Float']>;
};

export type PpgUpdatepacketLossIndexInput = {
  set?: Maybe<Array<Scalars['Int']>>;
  push?: Maybe<Scalars['Int']>;
};

export type PpgUpdatepacketLossSkippedInput = {
  set?: Maybe<Array<Scalars['Int']>>;
  push?: Maybe<Scalars['Int']>;
};

export type PpgUpsertWithWhereUniqueWithoutMetricInput = {
  where: PpgWhereUniqueInput;
  update: PpgUpdateWithoutMetricInput;
  create: PpgCreateWithoutMetricInput;
};

export type PpgWhereInput = {
  AND?: Maybe<Array<PpgWhereInput>>;
  OR?: Maybe<Array<PpgWhereInput>>;
  NOT?: Maybe<Array<PpgWhereInput>>;
  id?: Maybe<StringFilter>;
  metricId?: Maybe<StringFilter>;
  channel?: Maybe<IntNullableFilter>;
  index?: Maybe<IntNullableFilter>;
  timestamp?: Maybe<FloatNullableFilter>;
  oldSamples?: Maybe<FloatNullableListFilter>;
  bufferDuration?: Maybe<IntFilter>;
  bufferInterval?: Maybe<IntFilter>;
  packetLossIndex?: Maybe<IntNullableListFilter>;
  packetLossSkipped?: Maybe<IntNullableListFilter>;
  samplingRate?: Maybe<FloatNullableFilter>;
  startTime?: Maybe<FloatNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  metric?: Maybe<BioMetricWhereInput>;
  samples?: Maybe<PpgSampleListRelationFilter>;
};

export type PpgWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  me?: Maybe<User>;
  users: Array<User>;
  user?: Maybe<User>;
  bioMetrics: Array<BioMetric>;
  bioMetric?: Maybe<BioMetric>;
  eeg?: Maybe<Eeg>;
  eegs: Array<Eeg>;
  event?: Maybe<Event>;
  events: Array<Event>;
  bioMetricStats?: Maybe<BioMetricStats>;
  biometricExport?: Maybe<BiometricExport>;
  bioMetricsDescription?: Maybe<BioMetricsDescription>;
  csGames: Array<CsGame>;
  csGame?: Maybe<CsGame>;
  csPlayers: Array<CsPlayer>;
  csPlayer?: Maybe<CsPlayer>;
  csMetrics?: Maybe<CsMetrics>;
  csMetricsSummary?: Maybe<CsMetricsSummary>;
  csMetricsComparison?: Maybe<CsMetricComparison>;
  tasks: Array<Task>;
  task?: Maybe<Task>;
  taskBatteries: Array<TaskBattery>;
  taskBattery?: Maybe<TaskBattery>;
  getTaskConfig?: Maybe<TaskConfig>;
  sessions: Array<Session>;
  session?: Maybe<Session>;
  cognitive?: Maybe<Cognitive>;
  cognitives: Array<Cognitive>;
  organizations: Array<Organization>;
  organization?: Maybe<Organization>;
  accelerometers: Array<Accelerometer>;
  accelerometer?: Maybe<Accelerometer>;
  gyroscopes: Array<Gyroscope>;
  gyroscope?: Maybe<Gyroscope>;
  ppgs: Array<Ppg>;
  ppg?: Maybe<Ppg>;
  stimulations: Array<Stimulation>;
  stimulation?: Maybe<Stimulation>;
  stimulationPrograms: Array<StimulationProgram>;
  stimulationProgram?: Maybe<StimulationProgram>;
  device?: Maybe<Device>;
  devices: Array<Device>;
};


export type QueryUsersArgs = {
  where?: Maybe<UserWhereInput>;
  orderBy?: Maybe<Array<UserOrderByWithRelationInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<UserWhereUniqueInput>;
};


export type QueryUserArgs = {
  where: UserWhereUniqueInput;
};


export type QueryBioMetricsArgs = {
  where?: Maybe<BioMetricWhereInput>;
  orderBy?: Maybe<Array<BioMetricOrderByWithRelationInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<BioMetricWhereUniqueInput>;
};


export type QueryBioMetricArgs = {
  where: BioMetricWhereUniqueInput;
};


export type QueryEegArgs = {
  where: EegWhereUniqueInput;
};


export type QueryEegsArgs = {
  where?: Maybe<EegWhereInput>;
  orderBy?: Maybe<Array<EegOrderByWithRelationInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<EegWhereUniqueInput>;
};


export type QueryEventArgs = {
  where: EventWhereUniqueInput;
};


export type QueryEventsArgs = {
  where?: Maybe<EventWhereInput>;
  orderBy?: Maybe<Array<EventOrderByWithRelationInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<EventWhereUniqueInput>;
};


export type QueryBiometricExportArgs = {
  id: Scalars['String'];
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryBioMetricsDescriptionArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryCsGamesArgs = {
  where?: Maybe<CsGameWhereInput>;
  orderBy?: Maybe<Array<CsGameOrderByWithRelationInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<CsGameWhereUniqueInput>;
};


export type QueryCsGameArgs = {
  where: CsGameWhereUniqueInput;
};


export type QueryCsPlayersArgs = {
  where?: Maybe<CsPlayerWhereInput>;
  orderBy?: Maybe<Array<CsPlayerOrderByWithRelationInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<CsPlayerWhereUniqueInput>;
};


export type QueryCsPlayerArgs = {
  where: CsPlayerWhereUniqueInput;
};


export type QueryCsMetricsArgs = {
  id: Scalars['String'];
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
};


export type QueryCsMetricsSummaryArgs = {
  id: Scalars['String'];
};


export type QueryCsMetricsComparisonArgs = {
  id: Scalars['String'];
};


export type QueryTasksArgs = {
  where?: Maybe<TaskWhereInput>;
  orderBy?: Maybe<Array<TaskOrderByWithRelationInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<TaskWhereUniqueInput>;
};


export type QueryTaskArgs = {
  where: TaskWhereUniqueInput;
};


export type QueryTaskBatteriesArgs = {
  where?: Maybe<TaskBatteryWhereInput>;
  orderBy?: Maybe<Array<TaskBatteryOrderByWithRelationInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<TaskBatteryWhereUniqueInput>;
};


export type QueryTaskBatteryArgs = {
  where: TaskBatteryWhereUniqueInput;
};


export type QueryGetTaskConfigArgs = {
  type: Scalars['String'];
};


export type QuerySessionsArgs = {
  where?: Maybe<SessionWhereInput>;
  orderBy?: Maybe<Array<SessionOrderByWithRelationInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<SessionWhereUniqueInput>;
};


export type QuerySessionArgs = {
  where: SessionWhereUniqueInput;
};


export type QueryCognitiveArgs = {
  where: CognitiveWhereUniqueInput;
};


export type QueryCognitivesArgs = {
  where?: Maybe<CognitiveWhereInput>;
  orderBy?: Maybe<Array<CognitiveOrderByWithRelationInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<CognitiveWhereUniqueInput>;
};


export type QueryOrganizationsArgs = {
  where?: Maybe<OrganizationWhereInput>;
  orderBy?: Maybe<Array<OrganizationOrderByWithRelationInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<OrganizationWhereUniqueInput>;
};


export type QueryOrganizationArgs = {
  where: OrganizationWhereUniqueInput;
};


export type QueryAccelerometersArgs = {
  where?: Maybe<AccelerometerWhereInput>;
  orderBy?: Maybe<Array<AccelerometerOrderByWithRelationInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<AccelerometerWhereUniqueInput>;
};


export type QueryAccelerometerArgs = {
  where: AccelerometerWhereUniqueInput;
};


export type QueryGyroscopesArgs = {
  where?: Maybe<GyroscopeWhereInput>;
  orderBy?: Maybe<Array<GyroscopeOrderByWithRelationInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<GyroscopeWhereUniqueInput>;
};


export type QueryGyroscopeArgs = {
  where: GyroscopeWhereUniqueInput;
};


export type QueryPpgsArgs = {
  where?: Maybe<PpgWhereInput>;
  orderBy?: Maybe<Array<PpgOrderByWithRelationInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<PpgWhereUniqueInput>;
};


export type QueryPpgArgs = {
  where: PpgWhereUniqueInput;
};


export type QueryStimulationsArgs = {
  where?: Maybe<StimulationWhereInput>;
  orderBy?: Maybe<Array<StimulationOrderByWithRelationInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<StimulationWhereUniqueInput>;
};


export type QueryStimulationArgs = {
  where: StimulationWhereUniqueInput;
};


export type QueryStimulationProgramsArgs = {
  where?: Maybe<StimulationProgramWhereInput>;
  orderBy?: Maybe<Array<StimulationProgramOrderByWithRelationInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<StimulationProgramWhereUniqueInput>;
};


export type QueryStimulationProgramArgs = {
  where: StimulationProgramWhereUniqueInput;
};


export type QueryDeviceArgs = {
  where: DeviceWhereUniqueInput;
};


export type QueryDevicesArgs = {
  where?: Maybe<DeviceWhereInput>;
  orderBy?: Maybe<Array<DeviceOrderByWithRelationInput>>;
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<DeviceWhereUniqueInput>;
};

export enum QueryMode {
  Default = 'default',
  Insensitive = 'insensitive'
}

export enum Role {
  User = 'USER',
  Admin = 'ADMIN',
  Reviewer = 'REVIEWER',
  Owner = 'OWNER'
}

export type Score = {
  __typename?: 'Score';
  correct?: Maybe<Scalars['Int']>;
  wrong?: Maybe<Scalars['Int']>;
  accuracy?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['Int']>;
};

export type Session = {
  __typename?: 'Session';
  id: Scalars['String'];
  type: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  startTime: Scalars['String'];
  stopTime?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  metric?: Maybe<BioMetric>;
  stimulation?: Maybe<Stimulation>;
  user?: Maybe<User>;
  device?: Maybe<Device>;
};

export type SessionCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  startTime: Scalars['String'];
  stopTime?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  metric?: Maybe<BioMetricCreateNestedOneWithoutSessionInput>;
  stimulation?: Maybe<StimulationCreateNestedOneWithoutSessionInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutSessionsInput>;
  user?: Maybe<UserCreateNestedOneWithoutSessionsInput>;
  device?: Maybe<DeviceCreateNestedOneWithoutSessionInput>;
};

export type SessionCreateManyOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  startTime: Scalars['String'];
  stopTime?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  userId?: Maybe<Scalars['String']>;
  metricId?: Maybe<Scalars['String']>;
  stimulationId?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
};

export type SessionCreateManyOrganizationInputEnvelope = {
  data?: Maybe<Array<SessionCreateManyOrganizationInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SessionCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  startTime: Scalars['String'];
  stopTime?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  metricId?: Maybe<Scalars['String']>;
  stimulationId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  deviceId?: Maybe<Scalars['String']>;
};

export type SessionCreateManyUserInputEnvelope = {
  data?: Maybe<Array<SessionCreateManyUserInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type SessionCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<SessionCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<SessionCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<SessionCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<SessionWhereUniqueInput>>;
};

export type SessionCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<SessionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<SessionCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<SessionCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<SessionWhereUniqueInput>>;
};

export type SessionCreateNestedOneWithoutMetricInput = {
  create?: Maybe<SessionCreateWithoutMetricInput>;
  connectOrCreate?: Maybe<SessionCreateOrConnectWithoutMetricInput>;
  connect?: Maybe<SessionWhereUniqueInput>;
};

export type SessionCreateNestedOneWithoutStimulationInput = {
  create?: Maybe<SessionCreateWithoutStimulationInput>;
  connectOrCreate?: Maybe<SessionCreateOrConnectWithoutStimulationInput>;
  connect?: Maybe<SessionWhereUniqueInput>;
};

export type SessionCreateOrConnectWithoutMetricInput = {
  where: SessionWhereUniqueInput;
  create: SessionCreateWithoutMetricInput;
};

export type SessionCreateOrConnectWithoutOrganizationInput = {
  where: SessionWhereUniqueInput;
  create: SessionCreateWithoutOrganizationInput;
};

export type SessionCreateOrConnectWithoutStimulationInput = {
  where: SessionWhereUniqueInput;
  create: SessionCreateWithoutStimulationInput;
};

export type SessionCreateOrConnectWithoutUserInput = {
  where: SessionWhereUniqueInput;
  create: SessionCreateWithoutUserInput;
};

export type SessionCreateWithoutMetricInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  startTime: Scalars['String'];
  stopTime?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  stimulation?: Maybe<StimulationCreateNestedOneWithoutSessionInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutSessionsInput>;
  user?: Maybe<UserCreateNestedOneWithoutSessionsInput>;
  device?: Maybe<DeviceCreateNestedOneWithoutSessionInput>;
};

export type SessionCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  startTime: Scalars['String'];
  stopTime?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  metric?: Maybe<BioMetricCreateNestedOneWithoutSessionInput>;
  stimulation?: Maybe<StimulationCreateNestedOneWithoutSessionInput>;
  user?: Maybe<UserCreateNestedOneWithoutSessionsInput>;
  device?: Maybe<DeviceCreateNestedOneWithoutSessionInput>;
};

export type SessionCreateWithoutStimulationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  startTime: Scalars['String'];
  stopTime?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  metric?: Maybe<BioMetricCreateNestedOneWithoutSessionInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutSessionsInput>;
  user?: Maybe<UserCreateNestedOneWithoutSessionsInput>;
  device?: Maybe<DeviceCreateNestedOneWithoutSessionInput>;
};

export type SessionCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  startTime: Scalars['String'];
  stopTime?: Maybe<Scalars['String']>;
  tags?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  metric?: Maybe<BioMetricCreateNestedOneWithoutSessionInput>;
  stimulation?: Maybe<StimulationCreateNestedOneWithoutSessionInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutSessionsInput>;
  device?: Maybe<DeviceCreateNestedOneWithoutSessionInput>;
};

export type SessionListRelationFilter = {
  every?: Maybe<SessionWhereInput>;
  some?: Maybe<SessionWhereInput>;
  none?: Maybe<SessionWhereInput>;
};

export type SessionOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
};

export type SessionOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  startTime?: Maybe<SortOrder>;
  stopTime?: Maybe<SortOrder>;
  tags?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  metricId?: Maybe<SortOrder>;
  metric?: Maybe<BioMetricOrderByWithRelationInput>;
  stimulationId?: Maybe<SortOrder>;
  stimulation?: Maybe<StimulationOrderByWithRelationInput>;
  organizationId?: Maybe<SortOrder>;
  organization?: Maybe<OrganizationOrderByWithRelationInput>;
  user?: Maybe<UserOrderByWithRelationInput>;
  device?: Maybe<DeviceOrderByWithRelationInput>;
  deviceId?: Maybe<SortOrder>;
};

export type SessionScalarWhereInput = {
  AND?: Maybe<Array<SessionScalarWhereInput>>;
  OR?: Maybe<Array<SessionScalarWhereInput>>;
  NOT?: Maybe<Array<SessionScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  startTime?: Maybe<StringFilter>;
  stopTime?: Maybe<StringNullableFilter>;
  tags?: Maybe<StringNullableFilter>;
  type?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringNullableFilter>;
  metricId?: Maybe<StringNullableFilter>;
  stimulationId?: Maybe<StringNullableFilter>;
  organizationId?: Maybe<StringNullableFilter>;
  deviceId?: Maybe<StringNullableFilter>;
};

export type SessionUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  startTime?: Maybe<StringFieldUpdateOperationsInput>;
  stopTime?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tags?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  metric?: Maybe<BioMetricUpdateOneWithoutSessionInput>;
  stimulation?: Maybe<StimulationUpdateOneWithoutSessionInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutSessionsInput>;
  user?: Maybe<UserUpdateOneWithoutSessionsInput>;
  device?: Maybe<DeviceUpdateOneWithoutSessionInput>;
};

export type SessionUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  startTime?: Maybe<StringFieldUpdateOperationsInput>;
  stopTime?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tags?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type SessionUpdateManyWithWhereWithoutOrganizationInput = {
  where: SessionScalarWhereInput;
  data: SessionUpdateManyMutationInput;
};

export type SessionUpdateManyWithWhereWithoutUserInput = {
  where: SessionScalarWhereInput;
  data: SessionUpdateManyMutationInput;
};

export type SessionUpdateManyWithoutOrganizationInput = {
  create?: Maybe<Array<SessionCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<SessionCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<SessionUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<SessionCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<SessionWhereUniqueInput>>;
  set?: Maybe<Array<SessionWhereUniqueInput>>;
  disconnect?: Maybe<Array<SessionWhereUniqueInput>>;
  delete?: Maybe<Array<SessionWhereUniqueInput>>;
  update?: Maybe<Array<SessionUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<SessionUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<SessionScalarWhereInput>>;
};

export type SessionUpdateManyWithoutUserInput = {
  create?: Maybe<Array<SessionCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<SessionCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<SessionUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<SessionCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<SessionWhereUniqueInput>>;
  set?: Maybe<Array<SessionWhereUniqueInput>>;
  disconnect?: Maybe<Array<SessionWhereUniqueInput>>;
  delete?: Maybe<Array<SessionWhereUniqueInput>>;
  update?: Maybe<Array<SessionUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<SessionUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<SessionScalarWhereInput>>;
};

export type SessionUpdateOneWithoutMetricInput = {
  create?: Maybe<SessionCreateWithoutMetricInput>;
  connectOrCreate?: Maybe<SessionCreateOrConnectWithoutMetricInput>;
  upsert?: Maybe<SessionUpsertWithoutMetricInput>;
  connect?: Maybe<SessionWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<SessionUpdateWithoutMetricInput>;
};

export type SessionUpdateOneWithoutStimulationInput = {
  create?: Maybe<SessionCreateWithoutStimulationInput>;
  connectOrCreate?: Maybe<SessionCreateOrConnectWithoutStimulationInput>;
  upsert?: Maybe<SessionUpsertWithoutStimulationInput>;
  connect?: Maybe<SessionWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<SessionUpdateWithoutStimulationInput>;
};

export type SessionUpdateWithWhereUniqueWithoutOrganizationInput = {
  where: SessionWhereUniqueInput;
  data: SessionUpdateWithoutOrganizationInput;
};

export type SessionUpdateWithWhereUniqueWithoutUserInput = {
  where: SessionWhereUniqueInput;
  data: SessionUpdateWithoutUserInput;
};

export type SessionUpdateWithoutMetricInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  startTime?: Maybe<StringFieldUpdateOperationsInput>;
  stopTime?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tags?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  stimulation?: Maybe<StimulationUpdateOneWithoutSessionInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutSessionsInput>;
  user?: Maybe<UserUpdateOneWithoutSessionsInput>;
  device?: Maybe<DeviceUpdateOneWithoutSessionInput>;
};

export type SessionUpdateWithoutOrganizationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  startTime?: Maybe<StringFieldUpdateOperationsInput>;
  stopTime?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tags?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  metric?: Maybe<BioMetricUpdateOneWithoutSessionInput>;
  stimulation?: Maybe<StimulationUpdateOneWithoutSessionInput>;
  user?: Maybe<UserUpdateOneWithoutSessionsInput>;
  device?: Maybe<DeviceUpdateOneWithoutSessionInput>;
};

export type SessionUpdateWithoutStimulationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  startTime?: Maybe<StringFieldUpdateOperationsInput>;
  stopTime?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tags?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  metric?: Maybe<BioMetricUpdateOneWithoutSessionInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutSessionsInput>;
  user?: Maybe<UserUpdateOneWithoutSessionsInput>;
  device?: Maybe<DeviceUpdateOneWithoutSessionInput>;
};

export type SessionUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  startTime?: Maybe<StringFieldUpdateOperationsInput>;
  stopTime?: Maybe<NullableStringFieldUpdateOperationsInput>;
  tags?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  metric?: Maybe<BioMetricUpdateOneWithoutSessionInput>;
  stimulation?: Maybe<StimulationUpdateOneWithoutSessionInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutSessionsInput>;
  device?: Maybe<DeviceUpdateOneWithoutSessionInput>;
};

export type SessionUpsertWithWhereUniqueWithoutOrganizationInput = {
  where: SessionWhereUniqueInput;
  update: SessionUpdateWithoutOrganizationInput;
  create: SessionCreateWithoutOrganizationInput;
};

export type SessionUpsertWithWhereUniqueWithoutUserInput = {
  where: SessionWhereUniqueInput;
  update: SessionUpdateWithoutUserInput;
  create: SessionCreateWithoutUserInput;
};

export type SessionUpsertWithoutMetricInput = {
  update: SessionUpdateWithoutMetricInput;
  create: SessionCreateWithoutMetricInput;
};

export type SessionUpsertWithoutStimulationInput = {
  update: SessionUpdateWithoutStimulationInput;
  create: SessionCreateWithoutStimulationInput;
};

export type SessionWhereInput = {
  AND?: Maybe<Array<SessionWhereInput>>;
  OR?: Maybe<Array<SessionWhereInput>>;
  NOT?: Maybe<Array<SessionWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  description?: Maybe<StringNullableFilter>;
  startTime?: Maybe<StringFilter>;
  stopTime?: Maybe<StringNullableFilter>;
  tags?: Maybe<StringNullableFilter>;
  type?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  userId?: Maybe<StringNullableFilter>;
  metricId?: Maybe<StringNullableFilter>;
  metric?: Maybe<BioMetricWhereInput>;
  stimulationId?: Maybe<StringNullableFilter>;
  stimulation?: Maybe<StimulationWhereInput>;
  organizationId?: Maybe<StringNullableFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  user?: Maybe<UserWhereInput>;
  device?: Maybe<DeviceWhereInput>;
  deviceId?: Maybe<StringNullableFilter>;
};

export type SessionWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  metricId?: Maybe<Scalars['String']>;
  stimulationId?: Maybe<Scalars['String']>;
};

export enum SortOrder {
  Asc = 'asc',
  Desc = 'desc'
}

export type Steam = {
  __typename?: 'Steam';
  id: Scalars['String'];
  personaName: Scalars['String'];
  avatar: Scalars['String'];
  profileUrl: Scalars['String'];
  user: Array<User>;
};


export type SteamUserArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<UserWhereUniqueInput>;
};

export type SteamCreateInput = {
  id: Scalars['String'];
  personaName: Scalars['String'];
  avatar: Scalars['String'];
  profileUrl: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedManyWithoutSteamInput>;
};

export type SteamCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<SteamCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<SteamCreateOrConnectWithoutUserInput>>;
  connect?: Maybe<Array<SteamWhereUniqueInput>>;
};

export type SteamCreateOrConnectWithoutUserInput = {
  where: SteamWhereUniqueInput;
  create: SteamCreateWithoutUserInput;
};

export type SteamCreateWithoutUserInput = {
  id: Scalars['String'];
  personaName: Scalars['String'];
  avatar: Scalars['String'];
  profileUrl: Scalars['String'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type SteamListRelationFilter = {
  every?: Maybe<SteamWhereInput>;
  some?: Maybe<SteamWhereInput>;
  none?: Maybe<SteamWhereInput>;
};

export type SteamOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
};

export type SteamScalarWhereInput = {
  AND?: Maybe<Array<SteamScalarWhereInput>>;
  OR?: Maybe<Array<SteamScalarWhereInput>>;
  NOT?: Maybe<Array<SteamScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  personaName?: Maybe<StringFilter>;
  avatar?: Maybe<StringFilter>;
  profileUrl?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type SteamUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  personaName?: Maybe<StringFieldUpdateOperationsInput>;
  avatar?: Maybe<StringFieldUpdateOperationsInput>;
  profileUrl?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateManyWithoutSteamInput>;
};

export type SteamUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  personaName?: Maybe<StringFieldUpdateOperationsInput>;
  avatar?: Maybe<StringFieldUpdateOperationsInput>;
  profileUrl?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type SteamUpdateManyWithWhereWithoutUserInput = {
  where: SteamScalarWhereInput;
  data: SteamUpdateManyMutationInput;
};

export type SteamUpdateManyWithoutUserInput = {
  create?: Maybe<Array<SteamCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<SteamCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<SteamUpsertWithWhereUniqueWithoutUserInput>>;
  connect?: Maybe<Array<SteamWhereUniqueInput>>;
  set?: Maybe<Array<SteamWhereUniqueInput>>;
  disconnect?: Maybe<Array<SteamWhereUniqueInput>>;
  delete?: Maybe<Array<SteamWhereUniqueInput>>;
  update?: Maybe<Array<SteamUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<SteamUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<SteamScalarWhereInput>>;
};

export type SteamUpdateWithWhereUniqueWithoutUserInput = {
  where: SteamWhereUniqueInput;
  data: SteamUpdateWithoutUserInput;
};

export type SteamUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  personaName?: Maybe<StringFieldUpdateOperationsInput>;
  avatar?: Maybe<StringFieldUpdateOperationsInput>;
  profileUrl?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type SteamUpsertWithWhereUniqueWithoutUserInput = {
  where: SteamWhereUniqueInput;
  update: SteamUpdateWithoutUserInput;
  create: SteamCreateWithoutUserInput;
};

export type SteamWhereInput = {
  AND?: Maybe<Array<SteamWhereInput>>;
  OR?: Maybe<Array<SteamWhereInput>>;
  NOT?: Maybe<Array<SteamWhereInput>>;
  id?: Maybe<StringFilter>;
  personaName?: Maybe<StringFilter>;
  avatar?: Maybe<StringFilter>;
  profileUrl?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  user?: Maybe<UserListRelationFilter>;
};

export type SteamWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Stimulation = {
  __typename?: 'Stimulation';
  id: Scalars['String'];
  user?: Maybe<User>;
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  organization?: Maybe<Organization>;
  program?: Maybe<StimulationProgram>;
  session?: Maybe<Session>;
};

export type StimulationCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutStimulationsInput>;
  user?: Maybe<UserCreateNestedOneWithoutStimulationInput>;
  program?: Maybe<StimulationProgramCreateNestedOneWithoutStimulationsInput>;
  session?: Maybe<SessionCreateNestedOneWithoutStimulationInput>;
  Task?: Maybe<TaskCreateNestedManyWithoutStimulationInput>;
};

export type StimulationCreateManyOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  programId: Scalars['String'];
};

export type StimulationCreateManyOrganizationInputEnvelope = {
  data?: Maybe<Array<StimulationCreateManyOrganizationInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StimulationCreateManyProgramInput = {
  id?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
};

export type StimulationCreateManyProgramInputEnvelope = {
  data?: Maybe<Array<StimulationCreateManyProgramInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StimulationCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organizationId?: Maybe<Scalars['String']>;
  programId: Scalars['String'];
};

export type StimulationCreateManyUserInputEnvelope = {
  data?: Maybe<Array<StimulationCreateManyUserInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type StimulationCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<StimulationCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<StimulationCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<StimulationCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<StimulationWhereUniqueInput>>;
};

export type StimulationCreateNestedManyWithoutProgramInput = {
  create?: Maybe<Array<StimulationCreateWithoutProgramInput>>;
  connectOrCreate?: Maybe<Array<StimulationCreateOrConnectWithoutProgramInput>>;
  createMany?: Maybe<StimulationCreateManyProgramInputEnvelope>;
  connect?: Maybe<Array<StimulationWhereUniqueInput>>;
};

export type StimulationCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<StimulationCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<StimulationCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<StimulationCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<StimulationWhereUniqueInput>>;
};

export type StimulationCreateNestedOneWithoutSessionInput = {
  create?: Maybe<StimulationCreateWithoutSessionInput>;
  connectOrCreate?: Maybe<StimulationCreateOrConnectWithoutSessionInput>;
  connect?: Maybe<StimulationWhereUniqueInput>;
};

export type StimulationCreateNestedOneWithoutTaskInput = {
  create?: Maybe<StimulationCreateWithoutTaskInput>;
  connectOrCreate?: Maybe<StimulationCreateOrConnectWithoutTaskInput>;
  connect?: Maybe<StimulationWhereUniqueInput>;
};

export type StimulationCreateOrConnectWithoutOrganizationInput = {
  where: StimulationWhereUniqueInput;
  create: StimulationCreateWithoutOrganizationInput;
};

export type StimulationCreateOrConnectWithoutProgramInput = {
  where: StimulationWhereUniqueInput;
  create: StimulationCreateWithoutProgramInput;
};

export type StimulationCreateOrConnectWithoutSessionInput = {
  where: StimulationWhereUniqueInput;
  create: StimulationCreateWithoutSessionInput;
};

export type StimulationCreateOrConnectWithoutTaskInput = {
  where: StimulationWhereUniqueInput;
  create: StimulationCreateWithoutTaskInput;
};

export type StimulationCreateOrConnectWithoutUserInput = {
  where: StimulationWhereUniqueInput;
  create: StimulationCreateWithoutUserInput;
};

export type StimulationCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  user?: Maybe<UserCreateNestedOneWithoutStimulationInput>;
  program?: Maybe<StimulationProgramCreateNestedOneWithoutStimulationsInput>;
  session?: Maybe<SessionCreateNestedOneWithoutStimulationInput>;
  Task?: Maybe<TaskCreateNestedManyWithoutStimulationInput>;
};

export type StimulationCreateWithoutProgramInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutStimulationsInput>;
  user?: Maybe<UserCreateNestedOneWithoutStimulationInput>;
  session?: Maybe<SessionCreateNestedOneWithoutStimulationInput>;
  Task?: Maybe<TaskCreateNestedManyWithoutStimulationInput>;
};

export type StimulationCreateWithoutSessionInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutStimulationsInput>;
  user?: Maybe<UserCreateNestedOneWithoutStimulationInput>;
  program?: Maybe<StimulationProgramCreateNestedOneWithoutStimulationsInput>;
  Task?: Maybe<TaskCreateNestedManyWithoutStimulationInput>;
};

export type StimulationCreateWithoutTaskInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutStimulationsInput>;
  user?: Maybe<UserCreateNestedOneWithoutStimulationInput>;
  program?: Maybe<StimulationProgramCreateNestedOneWithoutStimulationsInput>;
  session?: Maybe<SessionCreateNestedOneWithoutStimulationInput>;
};

export type StimulationCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutStimulationsInput>;
  program?: Maybe<StimulationProgramCreateNestedOneWithoutStimulationsInput>;
  session?: Maybe<SessionCreateNestedOneWithoutStimulationInput>;
  Task?: Maybe<TaskCreateNestedManyWithoutStimulationInput>;
};

export type StimulationListRelationFilter = {
  every?: Maybe<StimulationWhereInput>;
  some?: Maybe<StimulationWhereInput>;
  none?: Maybe<StimulationWhereInput>;
};

export type StimulationOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
};

export type StimulationOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  organization?: Maybe<OrganizationOrderByWithRelationInput>;
  user?: Maybe<UserOrderByWithRelationInput>;
  program?: Maybe<StimulationProgramOrderByWithRelationInput>;
  programId?: Maybe<SortOrder>;
  session?: Maybe<SessionOrderByWithRelationInput>;
  Task?: Maybe<TaskOrderByRelationAggregateInput>;
};

export type StimulationProgram = {
  __typename?: 'StimulationProgram';
  id: Scalars['String'];
  stimulations: Array<Stimulation>;
  coils: Array<Scalars['Int']>;
  frequency1: Array<Scalars['Float']>;
  frequency2: Array<Scalars['Float']>;
  intensity: Array<Scalars['Float']>;
  duration?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  usage?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  device?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
};


export type StimulationProgramStimulationsArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<StimulationWhereUniqueInput>;
};

export type StimulationProgramCreateInput = {
  id?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  usage?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  device?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  coils?: Maybe<StimulationProgramCreatecoilsInput>;
  frequency1?: Maybe<StimulationProgramCreatefrequency1Input>;
  frequency2?: Maybe<StimulationProgramCreatefrequency2Input>;
  intensity?: Maybe<StimulationProgramCreateintensityInput>;
  stimulations?: Maybe<StimulationCreateNestedManyWithoutProgramInput>;
};

export type StimulationProgramCreateNestedOneWithoutStimulationsInput = {
  create?: Maybe<StimulationProgramCreateWithoutStimulationsInput>;
  connectOrCreate?: Maybe<StimulationProgramCreateOrConnectWithoutStimulationsInput>;
  connect?: Maybe<StimulationProgramWhereUniqueInput>;
};

export type StimulationProgramCreateOrConnectWithoutStimulationsInput = {
  where: StimulationProgramWhereUniqueInput;
  create: StimulationProgramCreateWithoutStimulationsInput;
};

export type StimulationProgramCreateWithoutStimulationsInput = {
  id?: Maybe<Scalars['String']>;
  duration?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  usage?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  device?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  coils?: Maybe<StimulationProgramCreatecoilsInput>;
  frequency1?: Maybe<StimulationProgramCreatefrequency1Input>;
  frequency2?: Maybe<StimulationProgramCreatefrequency2Input>;
  intensity?: Maybe<StimulationProgramCreateintensityInput>;
};

export type StimulationProgramCreatecoilsInput = {
  set?: Maybe<Array<Scalars['Int']>>;
};

export type StimulationProgramCreatefrequency1Input = {
  set?: Maybe<Array<Scalars['Float']>>;
};

export type StimulationProgramCreatefrequency2Input = {
  set?: Maybe<Array<Scalars['Float']>>;
};

export type StimulationProgramCreateintensityInput = {
  set?: Maybe<Array<Scalars['Float']>>;
};

export type StimulationProgramOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  stimulations?: Maybe<StimulationOrderByRelationAggregateInput>;
  coils?: Maybe<SortOrder>;
  frequency1?: Maybe<SortOrder>;
  frequency2?: Maybe<SortOrder>;
  intensity?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  description?: Maybe<SortOrder>;
  usage?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  device?: Maybe<SortOrder>;
  code?: Maybe<SortOrder>;
};

export type StimulationProgramUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  duration?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  usage?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  device?: Maybe<NullableStringFieldUpdateOperationsInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  coils?: Maybe<StimulationProgramUpdatecoilsInput>;
  frequency1?: Maybe<StimulationProgramUpdatefrequency1Input>;
  frequency2?: Maybe<StimulationProgramUpdatefrequency2Input>;
  intensity?: Maybe<StimulationProgramUpdateintensityInput>;
  stimulations?: Maybe<StimulationUpdateManyWithoutProgramInput>;
};

export type StimulationProgramUpdateOneWithoutStimulationsInput = {
  create?: Maybe<StimulationProgramCreateWithoutStimulationsInput>;
  connectOrCreate?: Maybe<StimulationProgramCreateOrConnectWithoutStimulationsInput>;
  upsert?: Maybe<StimulationProgramUpsertWithoutStimulationsInput>;
  connect?: Maybe<StimulationProgramWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StimulationProgramUpdateWithoutStimulationsInput>;
};

export type StimulationProgramUpdateWithoutStimulationsInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  duration?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  description?: Maybe<NullableStringFieldUpdateOperationsInput>;
  usage?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<NullableStringFieldUpdateOperationsInput>;
  device?: Maybe<NullableStringFieldUpdateOperationsInput>;
  code?: Maybe<NullableStringFieldUpdateOperationsInput>;
  coils?: Maybe<StimulationProgramUpdatecoilsInput>;
  frequency1?: Maybe<StimulationProgramUpdatefrequency1Input>;
  frequency2?: Maybe<StimulationProgramUpdatefrequency2Input>;
  intensity?: Maybe<StimulationProgramUpdateintensityInput>;
};

export type StimulationProgramUpdatecoilsInput = {
  set?: Maybe<Array<Scalars['Int']>>;
  push?: Maybe<Scalars['Int']>;
};

export type StimulationProgramUpdatefrequency1Input = {
  set?: Maybe<Array<Scalars['Float']>>;
  push?: Maybe<Scalars['Float']>;
};

export type StimulationProgramUpdatefrequency2Input = {
  set?: Maybe<Array<Scalars['Float']>>;
  push?: Maybe<Scalars['Float']>;
};

export type StimulationProgramUpdateintensityInput = {
  set?: Maybe<Array<Scalars['Float']>>;
  push?: Maybe<Scalars['Float']>;
};

export type StimulationProgramUpsertWithoutStimulationsInput = {
  update: StimulationProgramUpdateWithoutStimulationsInput;
  create: StimulationProgramCreateWithoutStimulationsInput;
};

export type StimulationProgramWhereInput = {
  AND?: Maybe<Array<StimulationProgramWhereInput>>;
  OR?: Maybe<Array<StimulationProgramWhereInput>>;
  NOT?: Maybe<Array<StimulationProgramWhereInput>>;
  id?: Maybe<StringFilter>;
  stimulations?: Maybe<StimulationListRelationFilter>;
  coils?: Maybe<IntNullableListFilter>;
  frequency1?: Maybe<FloatNullableListFilter>;
  frequency2?: Maybe<FloatNullableListFilter>;
  intensity?: Maybe<FloatNullableListFilter>;
  duration?: Maybe<IntNullableFilter>;
  name?: Maybe<StringNullableFilter>;
  description?: Maybe<StringNullableFilter>;
  usage?: Maybe<StringNullableFilter>;
  type?: Maybe<StringNullableFilter>;
  device?: Maybe<StringNullableFilter>;
  code?: Maybe<StringNullableFilter>;
};

export type StimulationProgramWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type StimulationScalarWhereInput = {
  AND?: Maybe<Array<StimulationScalarWhereInput>>;
  OR?: Maybe<Array<StimulationScalarWhereInput>>;
  NOT?: Maybe<Array<StimulationScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  userId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  organizationId?: Maybe<StringNullableFilter>;
  programId?: Maybe<StringFilter>;
};

export type StimulationUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutStimulationsInput>;
  user?: Maybe<UserUpdateOneWithoutStimulationInput>;
  program?: Maybe<StimulationProgramUpdateOneWithoutStimulationsInput>;
  session?: Maybe<SessionUpdateOneWithoutStimulationInput>;
  Task?: Maybe<TaskUpdateManyWithoutStimulationInput>;
};

export type StimulationUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type StimulationUpdateManyWithWhereWithoutOrganizationInput = {
  where: StimulationScalarWhereInput;
  data: StimulationUpdateManyMutationInput;
};

export type StimulationUpdateManyWithWhereWithoutProgramInput = {
  where: StimulationScalarWhereInput;
  data: StimulationUpdateManyMutationInput;
};

export type StimulationUpdateManyWithWhereWithoutUserInput = {
  where: StimulationScalarWhereInput;
  data: StimulationUpdateManyMutationInput;
};

export type StimulationUpdateManyWithoutOrganizationInput = {
  create?: Maybe<Array<StimulationCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<StimulationCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<StimulationUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<StimulationCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<StimulationWhereUniqueInput>>;
  set?: Maybe<Array<StimulationWhereUniqueInput>>;
  disconnect?: Maybe<Array<StimulationWhereUniqueInput>>;
  delete?: Maybe<Array<StimulationWhereUniqueInput>>;
  update?: Maybe<Array<StimulationUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<StimulationUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<StimulationScalarWhereInput>>;
};

export type StimulationUpdateManyWithoutProgramInput = {
  create?: Maybe<Array<StimulationCreateWithoutProgramInput>>;
  connectOrCreate?: Maybe<Array<StimulationCreateOrConnectWithoutProgramInput>>;
  upsert?: Maybe<Array<StimulationUpsertWithWhereUniqueWithoutProgramInput>>;
  createMany?: Maybe<StimulationCreateManyProgramInputEnvelope>;
  connect?: Maybe<Array<StimulationWhereUniqueInput>>;
  set?: Maybe<Array<StimulationWhereUniqueInput>>;
  disconnect?: Maybe<Array<StimulationWhereUniqueInput>>;
  delete?: Maybe<Array<StimulationWhereUniqueInput>>;
  update?: Maybe<Array<StimulationUpdateWithWhereUniqueWithoutProgramInput>>;
  updateMany?: Maybe<Array<StimulationUpdateManyWithWhereWithoutProgramInput>>;
  deleteMany?: Maybe<Array<StimulationScalarWhereInput>>;
};

export type StimulationUpdateManyWithoutUserInput = {
  create?: Maybe<Array<StimulationCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<StimulationCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<StimulationUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<StimulationCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<StimulationWhereUniqueInput>>;
  set?: Maybe<Array<StimulationWhereUniqueInput>>;
  disconnect?: Maybe<Array<StimulationWhereUniqueInput>>;
  delete?: Maybe<Array<StimulationWhereUniqueInput>>;
  update?: Maybe<Array<StimulationUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<StimulationUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<StimulationScalarWhereInput>>;
};

export type StimulationUpdateOneWithoutSessionInput = {
  create?: Maybe<StimulationCreateWithoutSessionInput>;
  connectOrCreate?: Maybe<StimulationCreateOrConnectWithoutSessionInput>;
  upsert?: Maybe<StimulationUpsertWithoutSessionInput>;
  connect?: Maybe<StimulationWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StimulationUpdateWithoutSessionInput>;
};

export type StimulationUpdateOneWithoutTaskInput = {
  create?: Maybe<StimulationCreateWithoutTaskInput>;
  connectOrCreate?: Maybe<StimulationCreateOrConnectWithoutTaskInput>;
  upsert?: Maybe<StimulationUpsertWithoutTaskInput>;
  connect?: Maybe<StimulationWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<StimulationUpdateWithoutTaskInput>;
};

export type StimulationUpdateWithWhereUniqueWithoutOrganizationInput = {
  where: StimulationWhereUniqueInput;
  data: StimulationUpdateWithoutOrganizationInput;
};

export type StimulationUpdateWithWhereUniqueWithoutProgramInput = {
  where: StimulationWhereUniqueInput;
  data: StimulationUpdateWithoutProgramInput;
};

export type StimulationUpdateWithWhereUniqueWithoutUserInput = {
  where: StimulationWhereUniqueInput;
  data: StimulationUpdateWithoutUserInput;
};

export type StimulationUpdateWithoutOrganizationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  user?: Maybe<UserUpdateOneWithoutStimulationInput>;
  program?: Maybe<StimulationProgramUpdateOneWithoutStimulationsInput>;
  session?: Maybe<SessionUpdateOneWithoutStimulationInput>;
  Task?: Maybe<TaskUpdateManyWithoutStimulationInput>;
};

export type StimulationUpdateWithoutProgramInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutStimulationsInput>;
  user?: Maybe<UserUpdateOneWithoutStimulationInput>;
  session?: Maybe<SessionUpdateOneWithoutStimulationInput>;
  Task?: Maybe<TaskUpdateManyWithoutStimulationInput>;
};

export type StimulationUpdateWithoutSessionInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutStimulationsInput>;
  user?: Maybe<UserUpdateOneWithoutStimulationInput>;
  program?: Maybe<StimulationProgramUpdateOneWithoutStimulationsInput>;
  Task?: Maybe<TaskUpdateManyWithoutStimulationInput>;
};

export type StimulationUpdateWithoutTaskInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutStimulationsInput>;
  user?: Maybe<UserUpdateOneWithoutStimulationInput>;
  program?: Maybe<StimulationProgramUpdateOneWithoutStimulationsInput>;
  session?: Maybe<SessionUpdateOneWithoutStimulationInput>;
};

export type StimulationUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutStimulationsInput>;
  program?: Maybe<StimulationProgramUpdateOneWithoutStimulationsInput>;
  session?: Maybe<SessionUpdateOneWithoutStimulationInput>;
  Task?: Maybe<TaskUpdateManyWithoutStimulationInput>;
};

export type StimulationUpsertWithWhereUniqueWithoutOrganizationInput = {
  where: StimulationWhereUniqueInput;
  update: StimulationUpdateWithoutOrganizationInput;
  create: StimulationCreateWithoutOrganizationInput;
};

export type StimulationUpsertWithWhereUniqueWithoutProgramInput = {
  where: StimulationWhereUniqueInput;
  update: StimulationUpdateWithoutProgramInput;
  create: StimulationCreateWithoutProgramInput;
};

export type StimulationUpsertWithWhereUniqueWithoutUserInput = {
  where: StimulationWhereUniqueInput;
  update: StimulationUpdateWithoutUserInput;
  create: StimulationCreateWithoutUserInput;
};

export type StimulationUpsertWithoutSessionInput = {
  update: StimulationUpdateWithoutSessionInput;
  create: StimulationCreateWithoutSessionInput;
};

export type StimulationUpsertWithoutTaskInput = {
  update: StimulationUpdateWithoutTaskInput;
  create: StimulationCreateWithoutTaskInput;
};

export type StimulationWhereInput = {
  AND?: Maybe<Array<StimulationWhereInput>>;
  OR?: Maybe<Array<StimulationWhereInput>>;
  NOT?: Maybe<Array<StimulationWhereInput>>;
  id?: Maybe<StringFilter>;
  userId?: Maybe<StringNullableFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  organizationId?: Maybe<StringNullableFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  user?: Maybe<UserWhereInput>;
  program?: Maybe<StimulationProgramWhereInput>;
  programId?: Maybe<StringFilter>;
  session?: Maybe<SessionWhereInput>;
  Task?: Maybe<TaskListRelationFilter>;
};

export type StimulationWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type StringFieldUpdateOperationsInput = {
  set?: Maybe<Scalars['String']>;
};

export type StringFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringFilter>;
};

export type StringNullableFilter = {
  equals?: Maybe<Scalars['String']>;
  in?: Maybe<Array<Scalars['String']>>;
  notIn?: Maybe<Array<Scalars['String']>>;
  lt?: Maybe<Scalars['String']>;
  lte?: Maybe<Scalars['String']>;
  gt?: Maybe<Scalars['String']>;
  gte?: Maybe<Scalars['String']>;
  contains?: Maybe<Scalars['String']>;
  startsWith?: Maybe<Scalars['String']>;
  endsWith?: Maybe<Scalars['String']>;
  mode?: Maybe<QueryMode>;
  not?: Maybe<NestedStringNullableFilter>;
};

export type Task = {
  __typename?: 'Task';
  id: Scalars['String'];
  index?: Maybe<Scalars['Int']>;
  type: TaskType;
  name: Scalars['String'];
  config?: Maybe<TaskConfig>;
  trials: Array<Trial>;
  metric?: Maybe<BioMetric>;
  user?: Maybe<User>;
  comment?: Maybe<Scalars['String']>;
  completed: Scalars['Boolean'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  battery?: Maybe<TaskBattery>;
  startTime?: Maybe<Scalars['Float']>;
  endTime?: Maybe<Scalars['Float']>;
  stimulation?: Maybe<Stimulation>;
  duration?: Maybe<Scalars['Float']>;
  score?: Maybe<Score>;
};


export type TaskTrialsArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<TrialWhereUniqueInput>;
};

export type TaskBattery = {
  __typename?: 'TaskBattery';
  id: Scalars['String'];
  createdAt: Scalars['DateTime'];
  updatedAt: Scalars['DateTime'];
  type: BatteryType;
  tasks: Array<Task>;
  comment?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  active: Array<TaskBattery>;
  template?: Maybe<TaskBattery>;
  sham?: Maybe<Scalars['Boolean']>;
};


export type TaskBatteryTasksArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<TaskWhereUniqueInput>;
};


export type TaskBatteryActiveArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<TaskBatteryWhereUniqueInput>;
};

export type TaskBatteryCreateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<BatteryType>;
  sham?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutTaskBatteryInput>;
  template?: Maybe<TaskBatteryCreateNestedOneWithoutActiveInput>;
  user?: Maybe<UserCreateNestedOneWithoutTaskBatteryInput>;
  tasks?: Maybe<TaskCreateNestedManyWithoutBatteryInput>;
  active?: Maybe<TaskBatteryCreateNestedManyWithoutTemplateInput>;
};

export type TaskBatteryCreateManyOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  type?: Maybe<BatteryType>;
  templateId?: Maybe<Scalars['String']>;
  sham?: Maybe<Scalars['Boolean']>;
};

export type TaskBatteryCreateManyOrganizationInputEnvelope = {
  data?: Maybe<Array<TaskBatteryCreateManyOrganizationInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TaskBatteryCreateManyTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
  type?: Maybe<BatteryType>;
  organizationId?: Maybe<Scalars['String']>;
  sham?: Maybe<Scalars['Boolean']>;
};

export type TaskBatteryCreateManyTemplateInputEnvelope = {
  data?: Maybe<Array<TaskBatteryCreateManyTemplateInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TaskBatteryCreateManyUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<BatteryType>;
  organizationId?: Maybe<Scalars['String']>;
  templateId?: Maybe<Scalars['String']>;
  sham?: Maybe<Scalars['Boolean']>;
};

export type TaskBatteryCreateManyUserInputEnvelope = {
  data?: Maybe<Array<TaskBatteryCreateManyUserInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TaskBatteryCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<TaskBatteryCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<TaskBatteryCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<TaskBatteryCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<TaskBatteryWhereUniqueInput>>;
};

export type TaskBatteryCreateNestedManyWithoutTemplateInput = {
  create?: Maybe<Array<TaskBatteryCreateWithoutTemplateInput>>;
  connectOrCreate?: Maybe<Array<TaskBatteryCreateOrConnectWithoutTemplateInput>>;
  createMany?: Maybe<TaskBatteryCreateManyTemplateInputEnvelope>;
  connect?: Maybe<Array<TaskBatteryWhereUniqueInput>>;
};

export type TaskBatteryCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<TaskBatteryCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<TaskBatteryCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<TaskBatteryCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<TaskBatteryWhereUniqueInput>>;
};

export type TaskBatteryCreateNestedOneWithoutActiveInput = {
  create?: Maybe<TaskBatteryCreateWithoutActiveInput>;
  connectOrCreate?: Maybe<TaskBatteryCreateOrConnectWithoutActiveInput>;
  connect?: Maybe<TaskBatteryWhereUniqueInput>;
};

export type TaskBatteryCreateNestedOneWithoutTasksInput = {
  create?: Maybe<TaskBatteryCreateWithoutTasksInput>;
  connectOrCreate?: Maybe<TaskBatteryCreateOrConnectWithoutTasksInput>;
  connect?: Maybe<TaskBatteryWhereUniqueInput>;
};

export type TaskBatteryCreateOrConnectWithoutActiveInput = {
  where: TaskBatteryWhereUniqueInput;
  create: TaskBatteryCreateWithoutActiveInput;
};

export type TaskBatteryCreateOrConnectWithoutOrganizationInput = {
  where: TaskBatteryWhereUniqueInput;
  create: TaskBatteryCreateWithoutOrganizationInput;
};

export type TaskBatteryCreateOrConnectWithoutTasksInput = {
  where: TaskBatteryWhereUniqueInput;
  create: TaskBatteryCreateWithoutTasksInput;
};

export type TaskBatteryCreateOrConnectWithoutTemplateInput = {
  where: TaskBatteryWhereUniqueInput;
  create: TaskBatteryCreateWithoutTemplateInput;
};

export type TaskBatteryCreateOrConnectWithoutUserInput = {
  where: TaskBatteryWhereUniqueInput;
  create: TaskBatteryCreateWithoutUserInput;
};

export type TaskBatteryCreateWithoutActiveInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<BatteryType>;
  sham?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutTaskBatteryInput>;
  template?: Maybe<TaskBatteryCreateNestedOneWithoutActiveInput>;
  user?: Maybe<UserCreateNestedOneWithoutTaskBatteryInput>;
  tasks?: Maybe<TaskCreateNestedManyWithoutBatteryInput>;
};

export type TaskBatteryCreateWithoutOrganizationInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<BatteryType>;
  sham?: Maybe<Scalars['Boolean']>;
  template?: Maybe<TaskBatteryCreateNestedOneWithoutActiveInput>;
  user?: Maybe<UserCreateNestedOneWithoutTaskBatteryInput>;
  tasks?: Maybe<TaskCreateNestedManyWithoutBatteryInput>;
  active?: Maybe<TaskBatteryCreateNestedManyWithoutTemplateInput>;
};

export type TaskBatteryCreateWithoutTasksInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<BatteryType>;
  sham?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutTaskBatteryInput>;
  template?: Maybe<TaskBatteryCreateNestedOneWithoutActiveInput>;
  user?: Maybe<UserCreateNestedOneWithoutTaskBatteryInput>;
  active?: Maybe<TaskBatteryCreateNestedManyWithoutTemplateInput>;
};

export type TaskBatteryCreateWithoutTemplateInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<BatteryType>;
  sham?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutTaskBatteryInput>;
  user?: Maybe<UserCreateNestedOneWithoutTaskBatteryInput>;
  tasks?: Maybe<TaskCreateNestedManyWithoutBatteryInput>;
  active?: Maybe<TaskBatteryCreateNestedManyWithoutTemplateInput>;
};

export type TaskBatteryCreateWithoutUserInput = {
  id?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  comment?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<BatteryType>;
  sham?: Maybe<Scalars['Boolean']>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutTaskBatteryInput>;
  template?: Maybe<TaskBatteryCreateNestedOneWithoutActiveInput>;
  tasks?: Maybe<TaskCreateNestedManyWithoutBatteryInput>;
  active?: Maybe<TaskBatteryCreateNestedManyWithoutTemplateInput>;
};

export type TaskBatteryListRelationFilter = {
  every?: Maybe<TaskBatteryWhereInput>;
  some?: Maybe<TaskBatteryWhereInput>;
  none?: Maybe<TaskBatteryWhereInput>;
};

export type TaskBatteryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
};

export type TaskBatteryOrderByWithRelationInput = {
  id?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  comment?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  userId?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  organizationId?: Maybe<SortOrder>;
  templateId?: Maybe<SortOrder>;
  organization?: Maybe<OrganizationOrderByWithRelationInput>;
  template?: Maybe<TaskBatteryOrderByWithRelationInput>;
  user?: Maybe<UserOrderByWithRelationInput>;
  tasks?: Maybe<TaskOrderByRelationAggregateInput>;
  active?: Maybe<TaskBatteryOrderByRelationAggregateInput>;
  sham?: Maybe<SortOrder>;
};

export type TaskBatteryScalarWhereInput = {
  AND?: Maybe<Array<TaskBatteryScalarWhereInput>>;
  OR?: Maybe<Array<TaskBatteryScalarWhereInput>>;
  NOT?: Maybe<Array<TaskBatteryScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  comment?: Maybe<StringNullableFilter>;
  name?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumBatteryTypeFilter>;
  organizationId?: Maybe<StringNullableFilter>;
  templateId?: Maybe<StringNullableFilter>;
  sham?: Maybe<BoolNullableFilter>;
};

export type TaskBatteryUpdateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumBatteryTypeFieldUpdateOperationsInput>;
  sham?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutTaskBatteryInput>;
  template?: Maybe<TaskBatteryUpdateOneWithoutActiveInput>;
  user?: Maybe<UserUpdateOneWithoutTaskBatteryInput>;
  tasks?: Maybe<TaskUpdateManyWithoutBatteryInput>;
  active?: Maybe<TaskBatteryUpdateManyWithoutTemplateInput>;
};

export type TaskBatteryUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumBatteryTypeFieldUpdateOperationsInput>;
  sham?: Maybe<NullableBoolFieldUpdateOperationsInput>;
};

export type TaskBatteryUpdateManyWithWhereWithoutOrganizationInput = {
  where: TaskBatteryScalarWhereInput;
  data: TaskBatteryUpdateManyMutationInput;
};

export type TaskBatteryUpdateManyWithWhereWithoutTemplateInput = {
  where: TaskBatteryScalarWhereInput;
  data: TaskBatteryUpdateManyMutationInput;
};

export type TaskBatteryUpdateManyWithWhereWithoutUserInput = {
  where: TaskBatteryScalarWhereInput;
  data: TaskBatteryUpdateManyMutationInput;
};

export type TaskBatteryUpdateManyWithoutOrganizationInput = {
  create?: Maybe<Array<TaskBatteryCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<TaskBatteryCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<TaskBatteryUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<TaskBatteryCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<TaskBatteryWhereUniqueInput>>;
  set?: Maybe<Array<TaskBatteryWhereUniqueInput>>;
  disconnect?: Maybe<Array<TaskBatteryWhereUniqueInput>>;
  delete?: Maybe<Array<TaskBatteryWhereUniqueInput>>;
  update?: Maybe<Array<TaskBatteryUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<TaskBatteryUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<TaskBatteryScalarWhereInput>>;
};

export type TaskBatteryUpdateManyWithoutTemplateInput = {
  create?: Maybe<Array<TaskBatteryCreateWithoutTemplateInput>>;
  connectOrCreate?: Maybe<Array<TaskBatteryCreateOrConnectWithoutTemplateInput>>;
  upsert?: Maybe<Array<TaskBatteryUpsertWithWhereUniqueWithoutTemplateInput>>;
  createMany?: Maybe<TaskBatteryCreateManyTemplateInputEnvelope>;
  connect?: Maybe<Array<TaskBatteryWhereUniqueInput>>;
  set?: Maybe<Array<TaskBatteryWhereUniqueInput>>;
  disconnect?: Maybe<Array<TaskBatteryWhereUniqueInput>>;
  delete?: Maybe<Array<TaskBatteryWhereUniqueInput>>;
  update?: Maybe<Array<TaskBatteryUpdateWithWhereUniqueWithoutTemplateInput>>;
  updateMany?: Maybe<Array<TaskBatteryUpdateManyWithWhereWithoutTemplateInput>>;
  deleteMany?: Maybe<Array<TaskBatteryScalarWhereInput>>;
};

export type TaskBatteryUpdateManyWithoutUserInput = {
  create?: Maybe<Array<TaskBatteryCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<TaskBatteryCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<TaskBatteryUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<TaskBatteryCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<TaskBatteryWhereUniqueInput>>;
  set?: Maybe<Array<TaskBatteryWhereUniqueInput>>;
  disconnect?: Maybe<Array<TaskBatteryWhereUniqueInput>>;
  delete?: Maybe<Array<TaskBatteryWhereUniqueInput>>;
  update?: Maybe<Array<TaskBatteryUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<TaskBatteryUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<TaskBatteryScalarWhereInput>>;
};

export type TaskBatteryUpdateOneWithoutActiveInput = {
  create?: Maybe<TaskBatteryCreateWithoutActiveInput>;
  connectOrCreate?: Maybe<TaskBatteryCreateOrConnectWithoutActiveInput>;
  upsert?: Maybe<TaskBatteryUpsertWithoutActiveInput>;
  connect?: Maybe<TaskBatteryWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<TaskBatteryUpdateWithoutActiveInput>;
};

export type TaskBatteryUpdateOneWithoutTasksInput = {
  create?: Maybe<TaskBatteryCreateWithoutTasksInput>;
  connectOrCreate?: Maybe<TaskBatteryCreateOrConnectWithoutTasksInput>;
  upsert?: Maybe<TaskBatteryUpsertWithoutTasksInput>;
  connect?: Maybe<TaskBatteryWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<TaskBatteryUpdateWithoutTasksInput>;
};

export type TaskBatteryUpdateWithWhereUniqueWithoutOrganizationInput = {
  where: TaskBatteryWhereUniqueInput;
  data: TaskBatteryUpdateWithoutOrganizationInput;
};

export type TaskBatteryUpdateWithWhereUniqueWithoutTemplateInput = {
  where: TaskBatteryWhereUniqueInput;
  data: TaskBatteryUpdateWithoutTemplateInput;
};

export type TaskBatteryUpdateWithWhereUniqueWithoutUserInput = {
  where: TaskBatteryWhereUniqueInput;
  data: TaskBatteryUpdateWithoutUserInput;
};

export type TaskBatteryUpdateWithoutActiveInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumBatteryTypeFieldUpdateOperationsInput>;
  sham?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutTaskBatteryInput>;
  template?: Maybe<TaskBatteryUpdateOneWithoutActiveInput>;
  user?: Maybe<UserUpdateOneWithoutTaskBatteryInput>;
  tasks?: Maybe<TaskUpdateManyWithoutBatteryInput>;
};

export type TaskBatteryUpdateWithoutOrganizationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumBatteryTypeFieldUpdateOperationsInput>;
  sham?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  template?: Maybe<TaskBatteryUpdateOneWithoutActiveInput>;
  user?: Maybe<UserUpdateOneWithoutTaskBatteryInput>;
  tasks?: Maybe<TaskUpdateManyWithoutBatteryInput>;
  active?: Maybe<TaskBatteryUpdateManyWithoutTemplateInput>;
};

export type TaskBatteryUpdateWithoutTasksInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumBatteryTypeFieldUpdateOperationsInput>;
  sham?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutTaskBatteryInput>;
  template?: Maybe<TaskBatteryUpdateOneWithoutActiveInput>;
  user?: Maybe<UserUpdateOneWithoutTaskBatteryInput>;
  active?: Maybe<TaskBatteryUpdateManyWithoutTemplateInput>;
};

export type TaskBatteryUpdateWithoutTemplateInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumBatteryTypeFieldUpdateOperationsInput>;
  sham?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutTaskBatteryInput>;
  user?: Maybe<UserUpdateOneWithoutTaskBatteryInput>;
  tasks?: Maybe<TaskUpdateManyWithoutBatteryInput>;
  active?: Maybe<TaskBatteryUpdateManyWithoutTemplateInput>;
};

export type TaskBatteryUpdateWithoutUserInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  type?: Maybe<EnumBatteryTypeFieldUpdateOperationsInput>;
  sham?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutTaskBatteryInput>;
  template?: Maybe<TaskBatteryUpdateOneWithoutActiveInput>;
  tasks?: Maybe<TaskUpdateManyWithoutBatteryInput>;
  active?: Maybe<TaskBatteryUpdateManyWithoutTemplateInput>;
};

export type TaskBatteryUpsertWithWhereUniqueWithoutOrganizationInput = {
  where: TaskBatteryWhereUniqueInput;
  update: TaskBatteryUpdateWithoutOrganizationInput;
  create: TaskBatteryCreateWithoutOrganizationInput;
};

export type TaskBatteryUpsertWithWhereUniqueWithoutTemplateInput = {
  where: TaskBatteryWhereUniqueInput;
  update: TaskBatteryUpdateWithoutTemplateInput;
  create: TaskBatteryCreateWithoutTemplateInput;
};

export type TaskBatteryUpsertWithWhereUniqueWithoutUserInput = {
  where: TaskBatteryWhereUniqueInput;
  update: TaskBatteryUpdateWithoutUserInput;
  create: TaskBatteryCreateWithoutUserInput;
};

export type TaskBatteryUpsertWithoutActiveInput = {
  update: TaskBatteryUpdateWithoutActiveInput;
  create: TaskBatteryCreateWithoutActiveInput;
};

export type TaskBatteryUpsertWithoutTasksInput = {
  update: TaskBatteryUpdateWithoutTasksInput;
  create: TaskBatteryCreateWithoutTasksInput;
};

export type TaskBatteryWhereInput = {
  AND?: Maybe<Array<TaskBatteryWhereInput>>;
  OR?: Maybe<Array<TaskBatteryWhereInput>>;
  NOT?: Maybe<Array<TaskBatteryWhereInput>>;
  id?: Maybe<StringFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  comment?: Maybe<StringNullableFilter>;
  name?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
  type?: Maybe<EnumBatteryTypeFilter>;
  organizationId?: Maybe<StringNullableFilter>;
  templateId?: Maybe<StringNullableFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  template?: Maybe<TaskBatteryWhereInput>;
  user?: Maybe<UserWhereInput>;
  tasks?: Maybe<TaskListRelationFilter>;
  active?: Maybe<TaskBatteryListRelationFilter>;
  sham?: Maybe<BoolNullableFilter>;
};

export type TaskBatteryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type TaskConfig = {
  __typename?: 'TaskConfig';
  id: Scalars['String'];
  task?: Maybe<Task>;
  window?: Maybe<Scalars['Int']>;
  trials?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['Int']>;
  timed?: Maybe<Scalars['Boolean']>;
  duration?: Maybe<Scalars['Int']>;
};

export type TaskConfigCreateNestedOneWithoutTaskInput = {
  create?: Maybe<TaskConfigCreateWithoutTaskInput>;
  connectOrCreate?: Maybe<TaskConfigCreateOrConnectWithoutTaskInput>;
  connect?: Maybe<TaskConfigWhereUniqueInput>;
};

export type TaskConfigCreateOrConnectWithoutTaskInput = {
  where: TaskConfigWhereUniqueInput;
  create: TaskConfigCreateWithoutTaskInput;
};

export type TaskConfigCreateWithoutTaskInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['Int']>;
  timed?: Maybe<Scalars['Boolean']>;
  trials?: Maybe<Scalars['Int']>;
  window?: Maybe<Scalars['Int']>;
  duration?: Maybe<Scalars['Int']>;
};

export type TaskConfigOrderByWithRelationInput = {
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  time?: Maybe<SortOrder>;
  timed?: Maybe<SortOrder>;
  trials?: Maybe<SortOrder>;
  window?: Maybe<SortOrder>;
  duration?: Maybe<SortOrder>;
  taskId?: Maybe<SortOrder>;
  task?: Maybe<TaskOrderByWithRelationInput>;
};

export type TaskConfigUpdateOneWithoutTaskInput = {
  create?: Maybe<TaskConfigCreateWithoutTaskInput>;
  connectOrCreate?: Maybe<TaskConfigCreateOrConnectWithoutTaskInput>;
  upsert?: Maybe<TaskConfigUpsertWithoutTaskInput>;
  connect?: Maybe<TaskConfigWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<TaskConfigUpdateWithoutTaskInput>;
};

export type TaskConfigUpdateWithoutTaskInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  time?: Maybe<NullableIntFieldUpdateOperationsInput>;
  timed?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  trials?: Maybe<NullableIntFieldUpdateOperationsInput>;
  window?: Maybe<NullableIntFieldUpdateOperationsInput>;
  duration?: Maybe<NullableIntFieldUpdateOperationsInput>;
};

export type TaskConfigUpsertWithoutTaskInput = {
  update: TaskConfigUpdateWithoutTaskInput;
  create: TaskConfigCreateWithoutTaskInput;
};

export type TaskConfigWhereInput = {
  AND?: Maybe<Array<TaskConfigWhereInput>>;
  OR?: Maybe<Array<TaskConfigWhereInput>>;
  NOT?: Maybe<Array<TaskConfigWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  time?: Maybe<IntNullableFilter>;
  timed?: Maybe<BoolNullableFilter>;
  trials?: Maybe<IntNullableFilter>;
  window?: Maybe<IntNullableFilter>;
  duration?: Maybe<IntNullableFilter>;
  taskId?: Maybe<StringNullableFilter>;
  task?: Maybe<TaskWhereInput>;
};

export type TaskConfigWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  taskId?: Maybe<Scalars['String']>;
};

export type TaskCreateInput = {
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: TaskType;
  comment?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Float']>;
  endTime?: Maybe<Scalars['Float']>;
  battery?: Maybe<TaskBatteryCreateNestedOneWithoutTasksInput>;
  metric?: Maybe<BioMetricCreateNestedOneWithoutTaskInput>;
  stimulation?: Maybe<StimulationCreateNestedOneWithoutTaskInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutTasksInput>;
  user?: Maybe<UserCreateNestedOneWithoutTaskInput>;
  config?: Maybe<TaskConfigCreateNestedOneWithoutTaskInput>;
  trials?: Maybe<TrialCreateNestedManyWithoutTaskInput>;
};

export type TaskCreateManyBatteryInput = {
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: TaskType;
  comment?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Float']>;
  endTime?: Maybe<Scalars['Float']>;
  metricId?: Maybe<Scalars['String']>;
  stimulationId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type TaskCreateManyBatteryInputEnvelope = {
  data?: Maybe<Array<TaskCreateManyBatteryInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TaskCreateManyOrganizationInput = {
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: TaskType;
  comment?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Float']>;
  endTime?: Maybe<Scalars['Float']>;
  batteryId?: Maybe<Scalars['String']>;
  metricId?: Maybe<Scalars['String']>;
  stimulationId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type TaskCreateManyOrganizationInputEnvelope = {
  data?: Maybe<Array<TaskCreateManyOrganizationInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TaskCreateManyStimulationInput = {
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: TaskType;
  comment?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Float']>;
  endTime?: Maybe<Scalars['Float']>;
  batteryId?: Maybe<Scalars['String']>;
  metricId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['String']>;
};

export type TaskCreateManyStimulationInputEnvelope = {
  data?: Maybe<Array<TaskCreateManyStimulationInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TaskCreateManyUserInput = {
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: TaskType;
  comment?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Float']>;
  endTime?: Maybe<Scalars['Float']>;
  batteryId?: Maybe<Scalars['String']>;
  metricId?: Maybe<Scalars['String']>;
  stimulationId?: Maybe<Scalars['String']>;
  organizationId?: Maybe<Scalars['String']>;
};

export type TaskCreateManyUserInputEnvelope = {
  data?: Maybe<Array<TaskCreateManyUserInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TaskCreateNestedManyWithoutBatteryInput = {
  create?: Maybe<Array<TaskCreateWithoutBatteryInput>>;
  connectOrCreate?: Maybe<Array<TaskCreateOrConnectWithoutBatteryInput>>;
  createMany?: Maybe<TaskCreateManyBatteryInputEnvelope>;
  connect?: Maybe<Array<TaskWhereUniqueInput>>;
};

export type TaskCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<TaskCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<TaskCreateOrConnectWithoutOrganizationInput>>;
  createMany?: Maybe<TaskCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<TaskWhereUniqueInput>>;
};

export type TaskCreateNestedManyWithoutStimulationInput = {
  create?: Maybe<Array<TaskCreateWithoutStimulationInput>>;
  connectOrCreate?: Maybe<Array<TaskCreateOrConnectWithoutStimulationInput>>;
  createMany?: Maybe<TaskCreateManyStimulationInputEnvelope>;
  connect?: Maybe<Array<TaskWhereUniqueInput>>;
};

export type TaskCreateNestedManyWithoutUserInput = {
  create?: Maybe<Array<TaskCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<TaskCreateOrConnectWithoutUserInput>>;
  createMany?: Maybe<TaskCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<TaskWhereUniqueInput>>;
};

export type TaskCreateNestedOneWithoutMetricInput = {
  create?: Maybe<TaskCreateWithoutMetricInput>;
  connectOrCreate?: Maybe<TaskCreateOrConnectWithoutMetricInput>;
  connect?: Maybe<TaskWhereUniqueInput>;
};

export type TaskCreateOrConnectWithoutBatteryInput = {
  where: TaskWhereUniqueInput;
  create: TaskCreateWithoutBatteryInput;
};

export type TaskCreateOrConnectWithoutMetricInput = {
  where: TaskWhereUniqueInput;
  create: TaskCreateWithoutMetricInput;
};

export type TaskCreateOrConnectWithoutOrganizationInput = {
  where: TaskWhereUniqueInput;
  create: TaskCreateWithoutOrganizationInput;
};

export type TaskCreateOrConnectWithoutStimulationInput = {
  where: TaskWhereUniqueInput;
  create: TaskCreateWithoutStimulationInput;
};

export type TaskCreateOrConnectWithoutUserInput = {
  where: TaskWhereUniqueInput;
  create: TaskCreateWithoutUserInput;
};

export type TaskCreateWithoutBatteryInput = {
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: TaskType;
  comment?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Float']>;
  endTime?: Maybe<Scalars['Float']>;
  metric?: Maybe<BioMetricCreateNestedOneWithoutTaskInput>;
  stimulation?: Maybe<StimulationCreateNestedOneWithoutTaskInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutTasksInput>;
  user?: Maybe<UserCreateNestedOneWithoutTaskInput>;
  config?: Maybe<TaskConfigCreateNestedOneWithoutTaskInput>;
  trials?: Maybe<TrialCreateNestedManyWithoutTaskInput>;
};

export type TaskCreateWithoutMetricInput = {
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: TaskType;
  comment?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Float']>;
  endTime?: Maybe<Scalars['Float']>;
  battery?: Maybe<TaskBatteryCreateNestedOneWithoutTasksInput>;
  stimulation?: Maybe<StimulationCreateNestedOneWithoutTaskInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutTasksInput>;
  user?: Maybe<UserCreateNestedOneWithoutTaskInput>;
  config?: Maybe<TaskConfigCreateNestedOneWithoutTaskInput>;
  trials?: Maybe<TrialCreateNestedManyWithoutTaskInput>;
};

export type TaskCreateWithoutOrganizationInput = {
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: TaskType;
  comment?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Float']>;
  endTime?: Maybe<Scalars['Float']>;
  battery?: Maybe<TaskBatteryCreateNestedOneWithoutTasksInput>;
  metric?: Maybe<BioMetricCreateNestedOneWithoutTaskInput>;
  stimulation?: Maybe<StimulationCreateNestedOneWithoutTaskInput>;
  user?: Maybe<UserCreateNestedOneWithoutTaskInput>;
  config?: Maybe<TaskConfigCreateNestedOneWithoutTaskInput>;
  trials?: Maybe<TrialCreateNestedManyWithoutTaskInput>;
};

export type TaskCreateWithoutStimulationInput = {
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: TaskType;
  comment?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Float']>;
  endTime?: Maybe<Scalars['Float']>;
  battery?: Maybe<TaskBatteryCreateNestedOneWithoutTasksInput>;
  metric?: Maybe<BioMetricCreateNestedOneWithoutTaskInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutTasksInput>;
  user?: Maybe<UserCreateNestedOneWithoutTaskInput>;
  config?: Maybe<TaskConfigCreateNestedOneWithoutTaskInput>;
  trials?: Maybe<TrialCreateNestedManyWithoutTaskInput>;
};

export type TaskCreateWithoutUserInput = {
  completed?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  id?: Maybe<Scalars['String']>;
  index?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  type: TaskType;
  comment?: Maybe<Scalars['String']>;
  startTime?: Maybe<Scalars['Float']>;
  endTime?: Maybe<Scalars['Float']>;
  battery?: Maybe<TaskBatteryCreateNestedOneWithoutTasksInput>;
  metric?: Maybe<BioMetricCreateNestedOneWithoutTaskInput>;
  stimulation?: Maybe<StimulationCreateNestedOneWithoutTaskInput>;
  organization?: Maybe<OrganizationCreateNestedOneWithoutTasksInput>;
  config?: Maybe<TaskConfigCreateNestedOneWithoutTaskInput>;
  trials?: Maybe<TrialCreateNestedManyWithoutTaskInput>;
};

export type TaskListRelationFilter = {
  every?: Maybe<TaskWhereInput>;
  some?: Maybe<TaskWhereInput>;
  none?: Maybe<TaskWhereInput>;
};

export type TaskOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
};

export type TaskOrderByWithRelationInput = {
  completed?: Maybe<SortOrder>;
  createdAt?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  index?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  type?: Maybe<SortOrder>;
  comment?: Maybe<SortOrder>;
  startTime?: Maybe<SortOrder>;
  endTime?: Maybe<SortOrder>;
  batteryId?: Maybe<SortOrder>;
  battery?: Maybe<TaskBatteryOrderByWithRelationInput>;
  metricId?: Maybe<SortOrder>;
  metric?: Maybe<BioMetricOrderByWithRelationInput>;
  stimulationId?: Maybe<SortOrder>;
  stimulation?: Maybe<StimulationOrderByWithRelationInput>;
  organizationId?: Maybe<SortOrder>;
  organization?: Maybe<OrganizationOrderByWithRelationInput>;
  userId?: Maybe<SortOrder>;
  user?: Maybe<UserOrderByWithRelationInput>;
  config?: Maybe<TaskConfigOrderByWithRelationInput>;
  trials?: Maybe<TrialOrderByRelationAggregateInput>;
};

export type TaskScalarWhereInput = {
  AND?: Maybe<Array<TaskScalarWhereInput>>;
  OR?: Maybe<Array<TaskScalarWhereInput>>;
  NOT?: Maybe<Array<TaskScalarWhereInput>>;
  completed?: Maybe<BoolFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  index?: Maybe<IntNullableFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumTaskTypeFilter>;
  comment?: Maybe<StringNullableFilter>;
  startTime?: Maybe<FloatNullableFilter>;
  endTime?: Maybe<FloatNullableFilter>;
  batteryId?: Maybe<StringNullableFilter>;
  metricId?: Maybe<StringNullableFilter>;
  stimulationId?: Maybe<StringNullableFilter>;
  organizationId?: Maybe<StringNullableFilter>;
  userId?: Maybe<StringNullableFilter>;
};

export enum TaskType {
  Nback = 'NBACK',
  Digitspan = 'DIGITSPAN',
  Sternberg = 'STERNBERG',
  Flanker = 'FLANKER',
  Rest = 'REST',
  Info = 'INFO'
}

export type TaskUpdateInput = {
  completed?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  index?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumTaskTypeFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  startTime?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  endTime?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  battery?: Maybe<TaskBatteryUpdateOneWithoutTasksInput>;
  metric?: Maybe<BioMetricUpdateOneWithoutTaskInput>;
  stimulation?: Maybe<StimulationUpdateOneWithoutTaskInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutTasksInput>;
  user?: Maybe<UserUpdateOneWithoutTaskInput>;
  config?: Maybe<TaskConfigUpdateOneWithoutTaskInput>;
  trials?: Maybe<TrialUpdateManyWithoutTaskInput>;
};

export type TaskUpdateManyMutationInput = {
  completed?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  index?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumTaskTypeFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  startTime?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  endTime?: Maybe<NullableFloatFieldUpdateOperationsInput>;
};

export type TaskUpdateManyWithWhereWithoutBatteryInput = {
  where: TaskScalarWhereInput;
  data: TaskUpdateManyMutationInput;
};

export type TaskUpdateManyWithWhereWithoutOrganizationInput = {
  where: TaskScalarWhereInput;
  data: TaskUpdateManyMutationInput;
};

export type TaskUpdateManyWithWhereWithoutStimulationInput = {
  where: TaskScalarWhereInput;
  data: TaskUpdateManyMutationInput;
};

export type TaskUpdateManyWithWhereWithoutUserInput = {
  where: TaskScalarWhereInput;
  data: TaskUpdateManyMutationInput;
};

export type TaskUpdateManyWithoutBatteryInput = {
  create?: Maybe<Array<TaskCreateWithoutBatteryInput>>;
  connectOrCreate?: Maybe<Array<TaskCreateOrConnectWithoutBatteryInput>>;
  upsert?: Maybe<Array<TaskUpsertWithWhereUniqueWithoutBatteryInput>>;
  createMany?: Maybe<TaskCreateManyBatteryInputEnvelope>;
  connect?: Maybe<Array<TaskWhereUniqueInput>>;
  set?: Maybe<Array<TaskWhereUniqueInput>>;
  disconnect?: Maybe<Array<TaskWhereUniqueInput>>;
  delete?: Maybe<Array<TaskWhereUniqueInput>>;
  update?: Maybe<Array<TaskUpdateWithWhereUniqueWithoutBatteryInput>>;
  updateMany?: Maybe<Array<TaskUpdateManyWithWhereWithoutBatteryInput>>;
  deleteMany?: Maybe<Array<TaskScalarWhereInput>>;
};

export type TaskUpdateManyWithoutOrganizationInput = {
  create?: Maybe<Array<TaskCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<TaskCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<TaskUpsertWithWhereUniqueWithoutOrganizationInput>>;
  createMany?: Maybe<TaskCreateManyOrganizationInputEnvelope>;
  connect?: Maybe<Array<TaskWhereUniqueInput>>;
  set?: Maybe<Array<TaskWhereUniqueInput>>;
  disconnect?: Maybe<Array<TaskWhereUniqueInput>>;
  delete?: Maybe<Array<TaskWhereUniqueInput>>;
  update?: Maybe<Array<TaskUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<TaskUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<TaskScalarWhereInput>>;
};

export type TaskUpdateManyWithoutStimulationInput = {
  create?: Maybe<Array<TaskCreateWithoutStimulationInput>>;
  connectOrCreate?: Maybe<Array<TaskCreateOrConnectWithoutStimulationInput>>;
  upsert?: Maybe<Array<TaskUpsertWithWhereUniqueWithoutStimulationInput>>;
  createMany?: Maybe<TaskCreateManyStimulationInputEnvelope>;
  connect?: Maybe<Array<TaskWhereUniqueInput>>;
  set?: Maybe<Array<TaskWhereUniqueInput>>;
  disconnect?: Maybe<Array<TaskWhereUniqueInput>>;
  delete?: Maybe<Array<TaskWhereUniqueInput>>;
  update?: Maybe<Array<TaskUpdateWithWhereUniqueWithoutStimulationInput>>;
  updateMany?: Maybe<Array<TaskUpdateManyWithWhereWithoutStimulationInput>>;
  deleteMany?: Maybe<Array<TaskScalarWhereInput>>;
};

export type TaskUpdateManyWithoutUserInput = {
  create?: Maybe<Array<TaskCreateWithoutUserInput>>;
  connectOrCreate?: Maybe<Array<TaskCreateOrConnectWithoutUserInput>>;
  upsert?: Maybe<Array<TaskUpsertWithWhereUniqueWithoutUserInput>>;
  createMany?: Maybe<TaskCreateManyUserInputEnvelope>;
  connect?: Maybe<Array<TaskWhereUniqueInput>>;
  set?: Maybe<Array<TaskWhereUniqueInput>>;
  disconnect?: Maybe<Array<TaskWhereUniqueInput>>;
  delete?: Maybe<Array<TaskWhereUniqueInput>>;
  update?: Maybe<Array<TaskUpdateWithWhereUniqueWithoutUserInput>>;
  updateMany?: Maybe<Array<TaskUpdateManyWithWhereWithoutUserInput>>;
  deleteMany?: Maybe<Array<TaskScalarWhereInput>>;
};

export type TaskUpdateOneWithoutMetricInput = {
  create?: Maybe<TaskCreateWithoutMetricInput>;
  connectOrCreate?: Maybe<TaskCreateOrConnectWithoutMetricInput>;
  upsert?: Maybe<TaskUpsertWithoutMetricInput>;
  connect?: Maybe<TaskWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<TaskUpdateWithoutMetricInput>;
};

export type TaskUpdateWithWhereUniqueWithoutBatteryInput = {
  where: TaskWhereUniqueInput;
  data: TaskUpdateWithoutBatteryInput;
};

export type TaskUpdateWithWhereUniqueWithoutOrganizationInput = {
  where: TaskWhereUniqueInput;
  data: TaskUpdateWithoutOrganizationInput;
};

export type TaskUpdateWithWhereUniqueWithoutStimulationInput = {
  where: TaskWhereUniqueInput;
  data: TaskUpdateWithoutStimulationInput;
};

export type TaskUpdateWithWhereUniqueWithoutUserInput = {
  where: TaskWhereUniqueInput;
  data: TaskUpdateWithoutUserInput;
};

export type TaskUpdateWithoutBatteryInput = {
  completed?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  index?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumTaskTypeFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  startTime?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  endTime?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  metric?: Maybe<BioMetricUpdateOneWithoutTaskInput>;
  stimulation?: Maybe<StimulationUpdateOneWithoutTaskInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutTasksInput>;
  user?: Maybe<UserUpdateOneWithoutTaskInput>;
  config?: Maybe<TaskConfigUpdateOneWithoutTaskInput>;
  trials?: Maybe<TrialUpdateManyWithoutTaskInput>;
};

export type TaskUpdateWithoutMetricInput = {
  completed?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  index?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumTaskTypeFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  startTime?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  endTime?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  battery?: Maybe<TaskBatteryUpdateOneWithoutTasksInput>;
  stimulation?: Maybe<StimulationUpdateOneWithoutTaskInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutTasksInput>;
  user?: Maybe<UserUpdateOneWithoutTaskInput>;
  config?: Maybe<TaskConfigUpdateOneWithoutTaskInput>;
  trials?: Maybe<TrialUpdateManyWithoutTaskInput>;
};

export type TaskUpdateWithoutOrganizationInput = {
  completed?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  index?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumTaskTypeFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  startTime?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  endTime?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  battery?: Maybe<TaskBatteryUpdateOneWithoutTasksInput>;
  metric?: Maybe<BioMetricUpdateOneWithoutTaskInput>;
  stimulation?: Maybe<StimulationUpdateOneWithoutTaskInput>;
  user?: Maybe<UserUpdateOneWithoutTaskInput>;
  config?: Maybe<TaskConfigUpdateOneWithoutTaskInput>;
  trials?: Maybe<TrialUpdateManyWithoutTaskInput>;
};

export type TaskUpdateWithoutStimulationInput = {
  completed?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  index?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumTaskTypeFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  startTime?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  endTime?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  battery?: Maybe<TaskBatteryUpdateOneWithoutTasksInput>;
  metric?: Maybe<BioMetricUpdateOneWithoutTaskInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutTasksInput>;
  user?: Maybe<UserUpdateOneWithoutTaskInput>;
  config?: Maybe<TaskConfigUpdateOneWithoutTaskInput>;
  trials?: Maybe<TrialUpdateManyWithoutTaskInput>;
};

export type TaskUpdateWithoutUserInput = {
  completed?: Maybe<BoolFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  index?: Maybe<NullableIntFieldUpdateOperationsInput>;
  name?: Maybe<StringFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  type?: Maybe<EnumTaskTypeFieldUpdateOperationsInput>;
  comment?: Maybe<NullableStringFieldUpdateOperationsInput>;
  startTime?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  endTime?: Maybe<NullableFloatFieldUpdateOperationsInput>;
  battery?: Maybe<TaskBatteryUpdateOneWithoutTasksInput>;
  metric?: Maybe<BioMetricUpdateOneWithoutTaskInput>;
  stimulation?: Maybe<StimulationUpdateOneWithoutTaskInput>;
  organization?: Maybe<OrganizationUpdateOneWithoutTasksInput>;
  config?: Maybe<TaskConfigUpdateOneWithoutTaskInput>;
  trials?: Maybe<TrialUpdateManyWithoutTaskInput>;
};

export type TaskUpsertWithWhereUniqueWithoutBatteryInput = {
  where: TaskWhereUniqueInput;
  update: TaskUpdateWithoutBatteryInput;
  create: TaskCreateWithoutBatteryInput;
};

export type TaskUpsertWithWhereUniqueWithoutOrganizationInput = {
  where: TaskWhereUniqueInput;
  update: TaskUpdateWithoutOrganizationInput;
  create: TaskCreateWithoutOrganizationInput;
};

export type TaskUpsertWithWhereUniqueWithoutStimulationInput = {
  where: TaskWhereUniqueInput;
  update: TaskUpdateWithoutStimulationInput;
  create: TaskCreateWithoutStimulationInput;
};

export type TaskUpsertWithWhereUniqueWithoutUserInput = {
  where: TaskWhereUniqueInput;
  update: TaskUpdateWithoutUserInput;
  create: TaskCreateWithoutUserInput;
};

export type TaskUpsertWithoutMetricInput = {
  update: TaskUpdateWithoutMetricInput;
  create: TaskCreateWithoutMetricInput;
};

export type TaskWhereInput = {
  AND?: Maybe<Array<TaskWhereInput>>;
  OR?: Maybe<Array<TaskWhereInput>>;
  NOT?: Maybe<Array<TaskWhereInput>>;
  completed?: Maybe<BoolFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  id?: Maybe<StringFilter>;
  index?: Maybe<IntNullableFilter>;
  name?: Maybe<StringFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  type?: Maybe<EnumTaskTypeFilter>;
  comment?: Maybe<StringNullableFilter>;
  startTime?: Maybe<FloatNullableFilter>;
  endTime?: Maybe<FloatNullableFilter>;
  batteryId?: Maybe<StringNullableFilter>;
  battery?: Maybe<TaskBatteryWhereInput>;
  metricId?: Maybe<StringNullableFilter>;
  metric?: Maybe<BioMetricWhereInput>;
  stimulationId?: Maybe<StringNullableFilter>;
  stimulation?: Maybe<StimulationWhereInput>;
  organizationId?: Maybe<StringNullableFilter>;
  organization?: Maybe<OrganizationWhereInput>;
  userId?: Maybe<StringNullableFilter>;
  user?: Maybe<UserWhereInput>;
  config?: Maybe<TaskConfigWhereInput>;
  trials?: Maybe<TrialListRelationFilter>;
};

export type TaskWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  metricId?: Maybe<Scalars['String']>;
};

export type Telemetry = {
  __typename?: 'Telemetry';
  id: Scalars['String'];
  metric?: Maybe<BioMetric>;
  sequenceId: Scalars['Int'];
  batteryLevel: Scalars['Float'];
  fuelGaugeVoltage: Scalars['Float'];
  temperature: Scalars['Float'];
};

export type TelemetryCreateManyMetricInput = {
  id?: Maybe<Scalars['String']>;
  sequenceId: Scalars['Int'];
  timestamp: Scalars['Float'];
  batteryLevel: Scalars['Float'];
  fuelGaugeVoltage: Scalars['Float'];
  temperature: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TelemetryCreateManyMetricInputEnvelope = {
  data?: Maybe<Array<TelemetryCreateManyMetricInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TelemetryCreateNestedManyWithoutMetricInput = {
  create?: Maybe<Array<TelemetryCreateWithoutMetricInput>>;
  connectOrCreate?: Maybe<Array<TelemetryCreateOrConnectWithoutMetricInput>>;
  createMany?: Maybe<TelemetryCreateManyMetricInputEnvelope>;
  connect?: Maybe<Array<TelemetryWhereUniqueInput>>;
};

export type TelemetryCreateOrConnectWithoutMetricInput = {
  where: TelemetryWhereUniqueInput;
  create: TelemetryCreateWithoutMetricInput;
};

export type TelemetryCreateWithoutMetricInput = {
  id?: Maybe<Scalars['String']>;
  sequenceId: Scalars['Int'];
  timestamp: Scalars['Float'];
  batteryLevel: Scalars['Float'];
  fuelGaugeVoltage: Scalars['Float'];
  temperature: Scalars['Float'];
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
};

export type TelemetryListRelationFilter = {
  every?: Maybe<TelemetryWhereInput>;
  some?: Maybe<TelemetryWhereInput>;
  none?: Maybe<TelemetryWhereInput>;
};

export type TelemetryOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
};

export type TelemetryScalarWhereInput = {
  AND?: Maybe<Array<TelemetryScalarWhereInput>>;
  OR?: Maybe<Array<TelemetryScalarWhereInput>>;
  NOT?: Maybe<Array<TelemetryScalarWhereInput>>;
  id?: Maybe<StringFilter>;
  sequenceId?: Maybe<IntFilter>;
  metricId?: Maybe<StringNullableFilter>;
  timestamp?: Maybe<FloatFilter>;
  batteryLevel?: Maybe<FloatFilter>;
  fuelGaugeVoltage?: Maybe<FloatFilter>;
  temperature?: Maybe<FloatFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
};

export type TelemetryUpdateManyMutationInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  sequenceId?: Maybe<IntFieldUpdateOperationsInput>;
  timestamp?: Maybe<FloatFieldUpdateOperationsInput>;
  batteryLevel?: Maybe<FloatFieldUpdateOperationsInput>;
  fuelGaugeVoltage?: Maybe<FloatFieldUpdateOperationsInput>;
  temperature?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type TelemetryUpdateManyWithWhereWithoutMetricInput = {
  where: TelemetryScalarWhereInput;
  data: TelemetryUpdateManyMutationInput;
};

export type TelemetryUpdateManyWithoutMetricInput = {
  create?: Maybe<Array<TelemetryCreateWithoutMetricInput>>;
  connectOrCreate?: Maybe<Array<TelemetryCreateOrConnectWithoutMetricInput>>;
  upsert?: Maybe<Array<TelemetryUpsertWithWhereUniqueWithoutMetricInput>>;
  createMany?: Maybe<TelemetryCreateManyMetricInputEnvelope>;
  connect?: Maybe<Array<TelemetryWhereUniqueInput>>;
  set?: Maybe<Array<TelemetryWhereUniqueInput>>;
  disconnect?: Maybe<Array<TelemetryWhereUniqueInput>>;
  delete?: Maybe<Array<TelemetryWhereUniqueInput>>;
  update?: Maybe<Array<TelemetryUpdateWithWhereUniqueWithoutMetricInput>>;
  updateMany?: Maybe<Array<TelemetryUpdateManyWithWhereWithoutMetricInput>>;
  deleteMany?: Maybe<Array<TelemetryScalarWhereInput>>;
};

export type TelemetryUpdateWithWhereUniqueWithoutMetricInput = {
  where: TelemetryWhereUniqueInput;
  data: TelemetryUpdateWithoutMetricInput;
};

export type TelemetryUpdateWithoutMetricInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  sequenceId?: Maybe<IntFieldUpdateOperationsInput>;
  timestamp?: Maybe<FloatFieldUpdateOperationsInput>;
  batteryLevel?: Maybe<FloatFieldUpdateOperationsInput>;
  fuelGaugeVoltage?: Maybe<FloatFieldUpdateOperationsInput>;
  temperature?: Maybe<FloatFieldUpdateOperationsInput>;
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
};

export type TelemetryUpsertWithWhereUniqueWithoutMetricInput = {
  where: TelemetryWhereUniqueInput;
  update: TelemetryUpdateWithoutMetricInput;
  create: TelemetryCreateWithoutMetricInput;
};

export type TelemetryWhereInput = {
  AND?: Maybe<Array<TelemetryWhereInput>>;
  OR?: Maybe<Array<TelemetryWhereInput>>;
  NOT?: Maybe<Array<TelemetryWhereInput>>;
  id?: Maybe<StringFilter>;
  sequenceId?: Maybe<IntFilter>;
  metricId?: Maybe<StringNullableFilter>;
  timestamp?: Maybe<FloatFilter>;
  batteryLevel?: Maybe<FloatFilter>;
  fuelGaugeVoltage?: Maybe<FloatFilter>;
  temperature?: Maybe<FloatFilter>;
  createdAt?: Maybe<DateTimeFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  metric?: Maybe<BioMetricWhereInput>;
};

export type TelemetryWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export type Trial = {
  __typename?: 'Trial';
  id: Scalars['String'];
  task: Task;
  response?: Maybe<TrialResponse>;
  index: Scalars['Int'];
  stimulus: Scalars['String'];
  expected?: Maybe<Scalars['String']>;
  critical?: Maybe<Scalars['Boolean']>;
  interval?: Maybe<Scalars['Int']>;
  type?: Maybe<TrialType>;
  width?: Maybe<TrialWidth>;
};

export type TrialCreateManyTaskInput = {
  critical?: Maybe<Scalars['Boolean']>;
  expected?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  interval?: Maybe<Scalars['Int']>;
  stimulus: Scalars['String'];
  type?: Maybe<TrialType>;
  width?: Maybe<TrialWidth>;
};

export type TrialCreateManyTaskInputEnvelope = {
  data?: Maybe<Array<TrialCreateManyTaskInput>>;
  skipDuplicates?: Maybe<Scalars['Boolean']>;
};

export type TrialCreateNestedManyWithoutTaskInput = {
  create?: Maybe<Array<TrialCreateWithoutTaskInput>>;
  connectOrCreate?: Maybe<Array<TrialCreateOrConnectWithoutTaskInput>>;
  createMany?: Maybe<TrialCreateManyTaskInputEnvelope>;
  connect?: Maybe<Array<TrialWhereUniqueInput>>;
};

export type TrialCreateOrConnectWithoutTaskInput = {
  where: TrialWhereUniqueInput;
  create: TrialCreateWithoutTaskInput;
};

export type TrialCreateWithoutTaskInput = {
  critical?: Maybe<Scalars['Boolean']>;
  expected?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  index: Scalars['Int'];
  interval?: Maybe<Scalars['Int']>;
  stimulus: Scalars['String'];
  type?: Maybe<TrialType>;
  width?: Maybe<TrialWidth>;
  response?: Maybe<TrialResponseCreateNestedOneWithoutTrialInput>;
};

export type TrialListRelationFilter = {
  every?: Maybe<TrialWhereInput>;
  some?: Maybe<TrialWhereInput>;
  none?: Maybe<TrialWhereInput>;
};

export type TrialOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
};

export type TrialResponse = {
  __typename?: 'TrialResponse';
  id: Scalars['String'];
  trial?: Maybe<Trial>;
  response?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
  presentedAt: Scalars['String'];
  respondedAt: Scalars['String'];
};

export type TrialResponseCreateNestedOneWithoutTrialInput = {
  create?: Maybe<TrialResponseCreateWithoutTrialInput>;
  connectOrCreate?: Maybe<TrialResponseCreateOrConnectWithoutTrialInput>;
  connect?: Maybe<TrialResponseWhereUniqueInput>;
};

export type TrialResponseCreateOrConnectWithoutTrialInput = {
  where: TrialResponseWhereUniqueInput;
  create: TrialResponseCreateWithoutTrialInput;
};

export type TrialResponseCreateWithoutTrialInput = {
  id?: Maybe<Scalars['String']>;
  presentedAt: Scalars['String'];
  respondedAt: Scalars['String'];
  response?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
};

export type TrialResponseUpdateOneWithoutTrialInput = {
  create?: Maybe<TrialResponseCreateWithoutTrialInput>;
  connectOrCreate?: Maybe<TrialResponseCreateOrConnectWithoutTrialInput>;
  upsert?: Maybe<TrialResponseUpsertWithoutTrialInput>;
  connect?: Maybe<TrialResponseWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<TrialResponseUpdateWithoutTrialInput>;
};

export type TrialResponseUpdateWithoutTrialInput = {
  id?: Maybe<StringFieldUpdateOperationsInput>;
  presentedAt?: Maybe<StringFieldUpdateOperationsInput>;
  respondedAt?: Maybe<StringFieldUpdateOperationsInput>;
  response?: Maybe<NullableStringFieldUpdateOperationsInput>;
  result?: Maybe<BoolFieldUpdateOperationsInput>;
};

export type TrialResponseUpsertWithoutTrialInput = {
  update: TrialResponseUpdateWithoutTrialInput;
  create: TrialResponseCreateWithoutTrialInput;
};

export type TrialResponseWhereInput = {
  AND?: Maybe<Array<TrialResponseWhereInput>>;
  OR?: Maybe<Array<TrialResponseWhereInput>>;
  NOT?: Maybe<Array<TrialResponseWhereInput>>;
  id?: Maybe<StringFilter>;
  presentedAt?: Maybe<StringFilter>;
  respondedAt?: Maybe<StringFilter>;
  response?: Maybe<StringNullableFilter>;
  result?: Maybe<BoolFilter>;
  trialId?: Maybe<StringNullableFilter>;
  trial?: Maybe<TrialWhereInput>;
};

export type TrialResponseWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
  trialId?: Maybe<Scalars['String']>;
};

export type TrialScalarWhereInput = {
  AND?: Maybe<Array<TrialScalarWhereInput>>;
  OR?: Maybe<Array<TrialScalarWhereInput>>;
  NOT?: Maybe<Array<TrialScalarWhereInput>>;
  critical?: Maybe<BoolNullableFilter>;
  expected?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  index?: Maybe<IntFilter>;
  interval?: Maybe<IntNullableFilter>;
  stimulus?: Maybe<StringFilter>;
  taskId?: Maybe<StringFilter>;
  type?: Maybe<EnumTrialTypeNullableFilter>;
  width?: Maybe<EnumTrialWidthNullableFilter>;
};

export enum TrialType {
  Compatible = 'COMPATIBLE',
  Incompatible = 'INCOMPATIBLE',
  Neutral = 'NEUTRAL'
}

export type TrialUpdateManyMutationInput = {
  critical?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  expected?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  index?: Maybe<IntFieldUpdateOperationsInput>;
  interval?: Maybe<NullableIntFieldUpdateOperationsInput>;
  stimulus?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<NullableEnumTrialTypeFieldUpdateOperationsInput>;
  width?: Maybe<NullableEnumTrialWidthFieldUpdateOperationsInput>;
};

export type TrialUpdateManyWithWhereWithoutTaskInput = {
  where: TrialScalarWhereInput;
  data: TrialUpdateManyMutationInput;
};

export type TrialUpdateManyWithoutTaskInput = {
  create?: Maybe<Array<TrialCreateWithoutTaskInput>>;
  connectOrCreate?: Maybe<Array<TrialCreateOrConnectWithoutTaskInput>>;
  upsert?: Maybe<Array<TrialUpsertWithWhereUniqueWithoutTaskInput>>;
  createMany?: Maybe<TrialCreateManyTaskInputEnvelope>;
  connect?: Maybe<Array<TrialWhereUniqueInput>>;
  set?: Maybe<Array<TrialWhereUniqueInput>>;
  disconnect?: Maybe<Array<TrialWhereUniqueInput>>;
  delete?: Maybe<Array<TrialWhereUniqueInput>>;
  update?: Maybe<Array<TrialUpdateWithWhereUniqueWithoutTaskInput>>;
  updateMany?: Maybe<Array<TrialUpdateManyWithWhereWithoutTaskInput>>;
  deleteMany?: Maybe<Array<TrialScalarWhereInput>>;
};

export type TrialUpdateWithWhereUniqueWithoutTaskInput = {
  where: TrialWhereUniqueInput;
  data: TrialUpdateWithoutTaskInput;
};

export type TrialUpdateWithoutTaskInput = {
  critical?: Maybe<NullableBoolFieldUpdateOperationsInput>;
  expected?: Maybe<NullableStringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  index?: Maybe<IntFieldUpdateOperationsInput>;
  interval?: Maybe<NullableIntFieldUpdateOperationsInput>;
  stimulus?: Maybe<StringFieldUpdateOperationsInput>;
  type?: Maybe<NullableEnumTrialTypeFieldUpdateOperationsInput>;
  width?: Maybe<NullableEnumTrialWidthFieldUpdateOperationsInput>;
  response?: Maybe<TrialResponseUpdateOneWithoutTrialInput>;
};

export type TrialUpsertWithWhereUniqueWithoutTaskInput = {
  where: TrialWhereUniqueInput;
  update: TrialUpdateWithoutTaskInput;
  create: TrialCreateWithoutTaskInput;
};

export type TrialWhereInput = {
  AND?: Maybe<Array<TrialWhereInput>>;
  OR?: Maybe<Array<TrialWhereInput>>;
  NOT?: Maybe<Array<TrialWhereInput>>;
  critical?: Maybe<BoolNullableFilter>;
  expected?: Maybe<StringNullableFilter>;
  id?: Maybe<StringFilter>;
  index?: Maybe<IntFilter>;
  interval?: Maybe<IntNullableFilter>;
  stimulus?: Maybe<StringFilter>;
  taskId?: Maybe<StringFilter>;
  type?: Maybe<EnumTrialTypeNullableFilter>;
  width?: Maybe<EnumTrialWidthNullableFilter>;
  task?: Maybe<TaskWhereInput>;
  response?: Maybe<TrialResponseWhereInput>;
};

export type TrialWhereUniqueInput = {
  id?: Maybe<Scalars['String']>;
};

export enum TrialWidth {
  Near = 'NEAR',
  Far = 'FAR'
}

export type User = {
  __typename?: 'User';
  id: Scalars['String'];
  updatedAt: Scalars['DateTime'];
  name?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  uid: Scalars['String'];
  steam: Array<Steam>;
  role: Role;
  organization: Array<Organization>;
  metric: Array<BioMetric>;
  stimulation: Array<Stimulation>;
  task: Array<Task>;
};


export type UserSteamArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<SteamWhereUniqueInput>;
};


export type UserOrganizationArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<OrganizationWhereUniqueInput>;
};


export type UserMetricArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<BioMetricWhereUniqueInput>;
};


export type UserStimulationArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<StimulationWhereUniqueInput>;
};


export type UserTaskArgs = {
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  cursor?: Maybe<TaskWhereUniqueInput>;
};

export type UserCreateNestedManyWithoutOrganizationInput = {
  create?: Maybe<Array<UserCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutOrganizationInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedManyWithoutSteamInput = {
  create?: Maybe<Array<UserCreateWithoutSteamInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutSteamInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
};

export type UserCreateNestedOneWithoutGameInput = {
  create?: Maybe<UserCreateWithoutGameInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutGameInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutGameStateInput = {
  create?: Maybe<UserCreateWithoutGameStateInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutGameStateInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutMetricInput = {
  create?: Maybe<UserCreateWithoutMetricInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutMetricInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutSessionsInput = {
  create?: Maybe<UserCreateWithoutSessionsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSessionsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutStimulationInput = {
  create?: Maybe<UserCreateWithoutStimulationInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutStimulationInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutTaskBatteryInput = {
  create?: Maybe<UserCreateWithoutTaskBatteryInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutTaskBatteryInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateNestedOneWithoutTaskInput = {
  create?: Maybe<UserCreateWithoutTaskInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutTaskInput>;
  connect?: Maybe<UserWhereUniqueInput>;
};

export type UserCreateOrConnectWithoutGameInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutGameInput;
};

export type UserCreateOrConnectWithoutGameStateInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutGameStateInput;
};

export type UserCreateOrConnectWithoutMetricInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutMetricInput;
};

export type UserCreateOrConnectWithoutOrganizationInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutOrganizationInput;
};

export type UserCreateOrConnectWithoutSessionsInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutSessionsInput;
};

export type UserCreateOrConnectWithoutSteamInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutSteamInput;
};

export type UserCreateOrConnectWithoutStimulationInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutStimulationInput;
};

export type UserCreateOrConnectWithoutTaskBatteryInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutTaskBatteryInput;
};

export type UserCreateOrConnectWithoutTaskInput = {
  where: UserWhereUniqueInput;
  create: UserCreateWithoutTaskInput;
};

export type UserCreateWithoutGameInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uid: Scalars['String'];
  metric?: Maybe<BioMetricCreateNestedManyWithoutUserInput>;
  stimulation?: Maybe<StimulationCreateNestedManyWithoutUserInput>;
  gameState?: Maybe<CsGameStateCreateNestedManyWithoutUserInput>;
  sessions?: Maybe<SessionCreateNestedManyWithoutUserInput>;
  task?: Maybe<TaskCreateNestedManyWithoutUserInput>;
  TaskBattery?: Maybe<TaskBatteryCreateNestedManyWithoutUserInput>;
  organization?: Maybe<OrganizationCreateNestedManyWithoutUsersInput>;
  steam?: Maybe<SteamCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutGameStateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uid: Scalars['String'];
  metric?: Maybe<BioMetricCreateNestedManyWithoutUserInput>;
  stimulation?: Maybe<StimulationCreateNestedManyWithoutUserInput>;
  game?: Maybe<CsGameCreateNestedManyWithoutUserInput>;
  sessions?: Maybe<SessionCreateNestedManyWithoutUserInput>;
  task?: Maybe<TaskCreateNestedManyWithoutUserInput>;
  TaskBattery?: Maybe<TaskBatteryCreateNestedManyWithoutUserInput>;
  organization?: Maybe<OrganizationCreateNestedManyWithoutUsersInput>;
  steam?: Maybe<SteamCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutMetricInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uid: Scalars['String'];
  stimulation?: Maybe<StimulationCreateNestedManyWithoutUserInput>;
  game?: Maybe<CsGameCreateNestedManyWithoutUserInput>;
  gameState?: Maybe<CsGameStateCreateNestedManyWithoutUserInput>;
  sessions?: Maybe<SessionCreateNestedManyWithoutUserInput>;
  task?: Maybe<TaskCreateNestedManyWithoutUserInput>;
  TaskBattery?: Maybe<TaskBatteryCreateNestedManyWithoutUserInput>;
  organization?: Maybe<OrganizationCreateNestedManyWithoutUsersInput>;
  steam?: Maybe<SteamCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutOrganizationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uid: Scalars['String'];
  metric?: Maybe<BioMetricCreateNestedManyWithoutUserInput>;
  stimulation?: Maybe<StimulationCreateNestedManyWithoutUserInput>;
  game?: Maybe<CsGameCreateNestedManyWithoutUserInput>;
  gameState?: Maybe<CsGameStateCreateNestedManyWithoutUserInput>;
  sessions?: Maybe<SessionCreateNestedManyWithoutUserInput>;
  task?: Maybe<TaskCreateNestedManyWithoutUserInput>;
  TaskBattery?: Maybe<TaskBatteryCreateNestedManyWithoutUserInput>;
  steam?: Maybe<SteamCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutSessionsInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uid: Scalars['String'];
  metric?: Maybe<BioMetricCreateNestedManyWithoutUserInput>;
  stimulation?: Maybe<StimulationCreateNestedManyWithoutUserInput>;
  game?: Maybe<CsGameCreateNestedManyWithoutUserInput>;
  gameState?: Maybe<CsGameStateCreateNestedManyWithoutUserInput>;
  task?: Maybe<TaskCreateNestedManyWithoutUserInput>;
  TaskBattery?: Maybe<TaskBatteryCreateNestedManyWithoutUserInput>;
  organization?: Maybe<OrganizationCreateNestedManyWithoutUsersInput>;
  steam?: Maybe<SteamCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutSteamInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uid: Scalars['String'];
  metric?: Maybe<BioMetricCreateNestedManyWithoutUserInput>;
  stimulation?: Maybe<StimulationCreateNestedManyWithoutUserInput>;
  game?: Maybe<CsGameCreateNestedManyWithoutUserInput>;
  gameState?: Maybe<CsGameStateCreateNestedManyWithoutUserInput>;
  sessions?: Maybe<SessionCreateNestedManyWithoutUserInput>;
  task?: Maybe<TaskCreateNestedManyWithoutUserInput>;
  TaskBattery?: Maybe<TaskBatteryCreateNestedManyWithoutUserInput>;
  organization?: Maybe<OrganizationCreateNestedManyWithoutUsersInput>;
};

export type UserCreateWithoutStimulationInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uid: Scalars['String'];
  metric?: Maybe<BioMetricCreateNestedManyWithoutUserInput>;
  game?: Maybe<CsGameCreateNestedManyWithoutUserInput>;
  gameState?: Maybe<CsGameStateCreateNestedManyWithoutUserInput>;
  sessions?: Maybe<SessionCreateNestedManyWithoutUserInput>;
  task?: Maybe<TaskCreateNestedManyWithoutUserInput>;
  TaskBattery?: Maybe<TaskBatteryCreateNestedManyWithoutUserInput>;
  organization?: Maybe<OrganizationCreateNestedManyWithoutUsersInput>;
  steam?: Maybe<SteamCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutTaskBatteryInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uid: Scalars['String'];
  metric?: Maybe<BioMetricCreateNestedManyWithoutUserInput>;
  stimulation?: Maybe<StimulationCreateNestedManyWithoutUserInput>;
  game?: Maybe<CsGameCreateNestedManyWithoutUserInput>;
  gameState?: Maybe<CsGameStateCreateNestedManyWithoutUserInput>;
  sessions?: Maybe<SessionCreateNestedManyWithoutUserInput>;
  task?: Maybe<TaskCreateNestedManyWithoutUserInput>;
  organization?: Maybe<OrganizationCreateNestedManyWithoutUsersInput>;
  steam?: Maybe<SteamCreateNestedManyWithoutUserInput>;
};

export type UserCreateWithoutTaskInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  uid: Scalars['String'];
  metric?: Maybe<BioMetricCreateNestedManyWithoutUserInput>;
  stimulation?: Maybe<StimulationCreateNestedManyWithoutUserInput>;
  game?: Maybe<CsGameCreateNestedManyWithoutUserInput>;
  gameState?: Maybe<CsGameStateCreateNestedManyWithoutUserInput>;
  sessions?: Maybe<SessionCreateNestedManyWithoutUserInput>;
  TaskBattery?: Maybe<TaskBatteryCreateNestedManyWithoutUserInput>;
  organization?: Maybe<OrganizationCreateNestedManyWithoutUsersInput>;
  steam?: Maybe<SteamCreateNestedManyWithoutUserInput>;
};

export type UserListRelationFilter = {
  every?: Maybe<UserWhereInput>;
  some?: Maybe<UserWhereInput>;
  none?: Maybe<UserWhereInput>;
};

export type UserOrderByRelationAggregateInput = {
  _count?: Maybe<SortOrder>;
  count?: Maybe<SortOrder>;
};

export type UserOrderByWithRelationInput = {
  createdAt?: Maybe<SortOrder>;
  email?: Maybe<SortOrder>;
  id?: Maybe<SortOrder>;
  name?: Maybe<SortOrder>;
  role?: Maybe<SortOrder>;
  updatedAt?: Maybe<SortOrder>;
  uid?: Maybe<SortOrder>;
  metric?: Maybe<BioMetricOrderByRelationAggregateInput>;
  stimulation?: Maybe<StimulationOrderByRelationAggregateInput>;
  game?: Maybe<CsGameOrderByRelationAggregateInput>;
  gameState?: Maybe<CsGameStateOrderByRelationAggregateInput>;
  sessions?: Maybe<SessionOrderByRelationAggregateInput>;
  task?: Maybe<TaskOrderByRelationAggregateInput>;
  TaskBattery?: Maybe<TaskBatteryOrderByRelationAggregateInput>;
  organization?: Maybe<OrganizationOrderByRelationAggregateInput>;
  steam?: Maybe<SteamOrderByRelationAggregateInput>;
};

export type UserScalarWhereInput = {
  AND?: Maybe<Array<UserScalarWhereInput>>;
  OR?: Maybe<Array<UserScalarWhereInput>>;
  NOT?: Maybe<Array<UserScalarWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringNullableFilter>;
  role?: Maybe<EnumRoleFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  uid?: Maybe<StringFilter>;
};

export type UserUpdateManyMutationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  uid?: Maybe<StringFieldUpdateOperationsInput>;
};

export type UserUpdateManyWithWhereWithoutOrganizationInput = {
  where: UserScalarWhereInput;
  data: UserUpdateManyMutationInput;
};

export type UserUpdateManyWithWhereWithoutSteamInput = {
  where: UserScalarWhereInput;
  data: UserUpdateManyMutationInput;
};

export type UserUpdateManyWithoutOrganizationInput = {
  create?: Maybe<Array<UserCreateWithoutOrganizationInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutOrganizationInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutOrganizationInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutOrganizationInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutOrganizationInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateManyWithoutSteamInput = {
  create?: Maybe<Array<UserCreateWithoutSteamInput>>;
  connectOrCreate?: Maybe<Array<UserCreateOrConnectWithoutSteamInput>>;
  upsert?: Maybe<Array<UserUpsertWithWhereUniqueWithoutSteamInput>>;
  connect?: Maybe<Array<UserWhereUniqueInput>>;
  set?: Maybe<Array<UserWhereUniqueInput>>;
  disconnect?: Maybe<Array<UserWhereUniqueInput>>;
  delete?: Maybe<Array<UserWhereUniqueInput>>;
  update?: Maybe<Array<UserUpdateWithWhereUniqueWithoutSteamInput>>;
  updateMany?: Maybe<Array<UserUpdateManyWithWhereWithoutSteamInput>>;
  deleteMany?: Maybe<Array<UserScalarWhereInput>>;
};

export type UserUpdateOneRequiredWithoutMetricInput = {
  create?: Maybe<UserCreateWithoutMetricInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutMetricInput>;
  upsert?: Maybe<UserUpsertWithoutMetricInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  update?: Maybe<UserUpdateWithoutMetricInput>;
};

export type UserUpdateOneWithoutGameInput = {
  create?: Maybe<UserCreateWithoutGameInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutGameInput>;
  upsert?: Maybe<UserUpsertWithoutGameInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUpdateWithoutGameInput>;
};

export type UserUpdateOneWithoutGameStateInput = {
  create?: Maybe<UserCreateWithoutGameStateInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutGameStateInput>;
  upsert?: Maybe<UserUpsertWithoutGameStateInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUpdateWithoutGameStateInput>;
};

export type UserUpdateOneWithoutSessionsInput = {
  create?: Maybe<UserCreateWithoutSessionsInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutSessionsInput>;
  upsert?: Maybe<UserUpsertWithoutSessionsInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUpdateWithoutSessionsInput>;
};

export type UserUpdateOneWithoutStimulationInput = {
  create?: Maybe<UserCreateWithoutStimulationInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutStimulationInput>;
  upsert?: Maybe<UserUpsertWithoutStimulationInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUpdateWithoutStimulationInput>;
};

export type UserUpdateOneWithoutTaskBatteryInput = {
  create?: Maybe<UserCreateWithoutTaskBatteryInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutTaskBatteryInput>;
  upsert?: Maybe<UserUpsertWithoutTaskBatteryInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUpdateWithoutTaskBatteryInput>;
};

export type UserUpdateOneWithoutTaskInput = {
  create?: Maybe<UserCreateWithoutTaskInput>;
  connectOrCreate?: Maybe<UserCreateOrConnectWithoutTaskInput>;
  upsert?: Maybe<UserUpsertWithoutTaskInput>;
  connect?: Maybe<UserWhereUniqueInput>;
  disconnect?: Maybe<Scalars['Boolean']>;
  delete?: Maybe<Scalars['Boolean']>;
  update?: Maybe<UserUpdateWithoutTaskInput>;
};

export type UserUpdateWithWhereUniqueWithoutOrganizationInput = {
  where: UserWhereUniqueInput;
  data: UserUpdateWithoutOrganizationInput;
};

export type UserUpdateWithWhereUniqueWithoutSteamInput = {
  where: UserWhereUniqueInput;
  data: UserUpdateWithoutSteamInput;
};

export type UserUpdateWithoutGameInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  uid?: Maybe<StringFieldUpdateOperationsInput>;
  metric?: Maybe<BioMetricUpdateManyWithoutUserInput>;
  stimulation?: Maybe<StimulationUpdateManyWithoutUserInput>;
  gameState?: Maybe<CsGameStateUpdateManyWithoutUserInput>;
  sessions?: Maybe<SessionUpdateManyWithoutUserInput>;
  task?: Maybe<TaskUpdateManyWithoutUserInput>;
  TaskBattery?: Maybe<TaskBatteryUpdateManyWithoutUserInput>;
  organization?: Maybe<OrganizationUpdateManyWithoutUsersInput>;
  steam?: Maybe<SteamUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutGameStateInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  uid?: Maybe<StringFieldUpdateOperationsInput>;
  metric?: Maybe<BioMetricUpdateManyWithoutUserInput>;
  stimulation?: Maybe<StimulationUpdateManyWithoutUserInput>;
  game?: Maybe<CsGameUpdateManyWithoutUserInput>;
  sessions?: Maybe<SessionUpdateManyWithoutUserInput>;
  task?: Maybe<TaskUpdateManyWithoutUserInput>;
  TaskBattery?: Maybe<TaskBatteryUpdateManyWithoutUserInput>;
  organization?: Maybe<OrganizationUpdateManyWithoutUsersInput>;
  steam?: Maybe<SteamUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutMetricInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  uid?: Maybe<StringFieldUpdateOperationsInput>;
  stimulation?: Maybe<StimulationUpdateManyWithoutUserInput>;
  game?: Maybe<CsGameUpdateManyWithoutUserInput>;
  gameState?: Maybe<CsGameStateUpdateManyWithoutUserInput>;
  sessions?: Maybe<SessionUpdateManyWithoutUserInput>;
  task?: Maybe<TaskUpdateManyWithoutUserInput>;
  TaskBattery?: Maybe<TaskBatteryUpdateManyWithoutUserInput>;
  organization?: Maybe<OrganizationUpdateManyWithoutUsersInput>;
  steam?: Maybe<SteamUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutOrganizationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  uid?: Maybe<StringFieldUpdateOperationsInput>;
  metric?: Maybe<BioMetricUpdateManyWithoutUserInput>;
  stimulation?: Maybe<StimulationUpdateManyWithoutUserInput>;
  game?: Maybe<CsGameUpdateManyWithoutUserInput>;
  gameState?: Maybe<CsGameStateUpdateManyWithoutUserInput>;
  sessions?: Maybe<SessionUpdateManyWithoutUserInput>;
  task?: Maybe<TaskUpdateManyWithoutUserInput>;
  TaskBattery?: Maybe<TaskBatteryUpdateManyWithoutUserInput>;
  steam?: Maybe<SteamUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutSessionsInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  uid?: Maybe<StringFieldUpdateOperationsInput>;
  metric?: Maybe<BioMetricUpdateManyWithoutUserInput>;
  stimulation?: Maybe<StimulationUpdateManyWithoutUserInput>;
  game?: Maybe<CsGameUpdateManyWithoutUserInput>;
  gameState?: Maybe<CsGameStateUpdateManyWithoutUserInput>;
  task?: Maybe<TaskUpdateManyWithoutUserInput>;
  TaskBattery?: Maybe<TaskBatteryUpdateManyWithoutUserInput>;
  organization?: Maybe<OrganizationUpdateManyWithoutUsersInput>;
  steam?: Maybe<SteamUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutSteamInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  uid?: Maybe<StringFieldUpdateOperationsInput>;
  metric?: Maybe<BioMetricUpdateManyWithoutUserInput>;
  stimulation?: Maybe<StimulationUpdateManyWithoutUserInput>;
  game?: Maybe<CsGameUpdateManyWithoutUserInput>;
  gameState?: Maybe<CsGameStateUpdateManyWithoutUserInput>;
  sessions?: Maybe<SessionUpdateManyWithoutUserInput>;
  task?: Maybe<TaskUpdateManyWithoutUserInput>;
  TaskBattery?: Maybe<TaskBatteryUpdateManyWithoutUserInput>;
  organization?: Maybe<OrganizationUpdateManyWithoutUsersInput>;
};

export type UserUpdateWithoutStimulationInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  uid?: Maybe<StringFieldUpdateOperationsInput>;
  metric?: Maybe<BioMetricUpdateManyWithoutUserInput>;
  game?: Maybe<CsGameUpdateManyWithoutUserInput>;
  gameState?: Maybe<CsGameStateUpdateManyWithoutUserInput>;
  sessions?: Maybe<SessionUpdateManyWithoutUserInput>;
  task?: Maybe<TaskUpdateManyWithoutUserInput>;
  TaskBattery?: Maybe<TaskBatteryUpdateManyWithoutUserInput>;
  organization?: Maybe<OrganizationUpdateManyWithoutUsersInput>;
  steam?: Maybe<SteamUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutTaskBatteryInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  uid?: Maybe<StringFieldUpdateOperationsInput>;
  metric?: Maybe<BioMetricUpdateManyWithoutUserInput>;
  stimulation?: Maybe<StimulationUpdateManyWithoutUserInput>;
  game?: Maybe<CsGameUpdateManyWithoutUserInput>;
  gameState?: Maybe<CsGameStateUpdateManyWithoutUserInput>;
  sessions?: Maybe<SessionUpdateManyWithoutUserInput>;
  task?: Maybe<TaskUpdateManyWithoutUserInput>;
  organization?: Maybe<OrganizationUpdateManyWithoutUsersInput>;
  steam?: Maybe<SteamUpdateManyWithoutUserInput>;
};

export type UserUpdateWithoutTaskInput = {
  createdAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  email?: Maybe<StringFieldUpdateOperationsInput>;
  id?: Maybe<StringFieldUpdateOperationsInput>;
  name?: Maybe<NullableStringFieldUpdateOperationsInput>;
  role?: Maybe<EnumRoleFieldUpdateOperationsInput>;
  updatedAt?: Maybe<DateTimeFieldUpdateOperationsInput>;
  uid?: Maybe<StringFieldUpdateOperationsInput>;
  metric?: Maybe<BioMetricUpdateManyWithoutUserInput>;
  stimulation?: Maybe<StimulationUpdateManyWithoutUserInput>;
  game?: Maybe<CsGameUpdateManyWithoutUserInput>;
  gameState?: Maybe<CsGameStateUpdateManyWithoutUserInput>;
  sessions?: Maybe<SessionUpdateManyWithoutUserInput>;
  TaskBattery?: Maybe<TaskBatteryUpdateManyWithoutUserInput>;
  organization?: Maybe<OrganizationUpdateManyWithoutUsersInput>;
  steam?: Maybe<SteamUpdateManyWithoutUserInput>;
};

export type UserUpsertWithWhereUniqueWithoutOrganizationInput = {
  where: UserWhereUniqueInput;
  update: UserUpdateWithoutOrganizationInput;
  create: UserCreateWithoutOrganizationInput;
};

export type UserUpsertWithWhereUniqueWithoutSteamInput = {
  where: UserWhereUniqueInput;
  update: UserUpdateWithoutSteamInput;
  create: UserCreateWithoutSteamInput;
};

export type UserUpsertWithoutGameInput = {
  update: UserUpdateWithoutGameInput;
  create: UserCreateWithoutGameInput;
};

export type UserUpsertWithoutGameStateInput = {
  update: UserUpdateWithoutGameStateInput;
  create: UserCreateWithoutGameStateInput;
};

export type UserUpsertWithoutMetricInput = {
  update: UserUpdateWithoutMetricInput;
  create: UserCreateWithoutMetricInput;
};

export type UserUpsertWithoutSessionsInput = {
  update: UserUpdateWithoutSessionsInput;
  create: UserCreateWithoutSessionsInput;
};

export type UserUpsertWithoutStimulationInput = {
  update: UserUpdateWithoutStimulationInput;
  create: UserCreateWithoutStimulationInput;
};

export type UserUpsertWithoutTaskBatteryInput = {
  update: UserUpdateWithoutTaskBatteryInput;
  create: UserCreateWithoutTaskBatteryInput;
};

export type UserUpsertWithoutTaskInput = {
  update: UserUpdateWithoutTaskInput;
  create: UserCreateWithoutTaskInput;
};

export type UserWhereInput = {
  AND?: Maybe<Array<UserWhereInput>>;
  OR?: Maybe<Array<UserWhereInput>>;
  NOT?: Maybe<Array<UserWhereInput>>;
  createdAt?: Maybe<DateTimeFilter>;
  email?: Maybe<StringFilter>;
  id?: Maybe<StringFilter>;
  name?: Maybe<StringNullableFilter>;
  role?: Maybe<EnumRoleFilter>;
  updatedAt?: Maybe<DateTimeFilter>;
  uid?: Maybe<StringFilter>;
  metric?: Maybe<BioMetricListRelationFilter>;
  stimulation?: Maybe<StimulationListRelationFilter>;
  game?: Maybe<CsGameListRelationFilter>;
  gameState?: Maybe<CsGameStateListRelationFilter>;
  sessions?: Maybe<SessionListRelationFilter>;
  task?: Maybe<TaskListRelationFilter>;
  TaskBattery?: Maybe<TaskBatteryListRelationFilter>;
  organization?: Maybe<OrganizationListRelationFilter>;
  steam?: Maybe<SteamListRelationFilter>;
};

export type UserWhereUniqueInput = {
  email?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
};

export type BioMetricStats = {
  __typename?: 'bioMetricStats';
  users?: Maybe<Scalars['Int']>;
  avgDuration?: Maybe<Scalars['Float']>;
  totalDuration?: Maybe<Scalars['Float']>;
  avgEpochs?: Maybe<Scalars['Float']>;
  countBioMetrics?: Maybe<Scalars['Int']>;
  avgEvents?: Maybe<Scalars['Float']>;
};

export type BioMetricsDescription = {
  __typename?: 'bioMetricsDescription';
  bioMetrics?: Maybe<Array<Maybe<MetricDescription>>>;
};

export type BiometricExport = {
  __typename?: 'biometricExport';
  exportedData?: Maybe<Array<Maybe<Array<Maybe<Scalars['Float']>>>>>;
};

export type MetricDescription = {
  __typename?: 'metricDescription';
  id?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  task?: Maybe<Scalars['String']>;
  epochs?: Maybe<Scalars['Int']>;
  events?: Maybe<Scalars['Int']>;
  startTime?: Maybe<Scalars['String']>;
};

export type SignupMutationVariables = Exact<{
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
  organization?: Maybe<Scalars['String']>;
}>;


export type SignupMutation = (
  { __typename?: 'Mutation' }
  & { signup?: Maybe<(
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'accessToken'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'email' | 'uid'>
      & { organization: Array<(
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type LoginMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type LoginMutation = (
  { __typename?: 'Mutation' }
  & { login?: Maybe<(
    { __typename?: 'AuthPayload' }
    & Pick<AuthPayload, 'accessToken'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name' | 'email' | 'uid'>
      & { organization: Array<(
        { __typename?: 'Organization' }
        & Pick<Organization, 'id' | 'name'>
      )> }
    )> }
  )> }
);

export type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type ResetPasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'resetPassword'>
);

export type GetTaskBatteryQueryVariables = Exact<{
  battery: Scalars['String'];
}>;


export type GetTaskBatteryQuery = (
  { __typename?: 'Query' }
  & { taskBattery?: Maybe<(
    { __typename?: 'TaskBattery' }
    & Pick<TaskBattery, 'id'>
    & { tasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'index' | 'completed' | 'startTime' | 'endTime'>
      & { score?: Maybe<(
        { __typename?: 'Score' }
        & Pick<Score, 'accuracy' | 'time'>
      )> }
    )>, template?: Maybe<(
      { __typename?: 'TaskBattery' }
      & Pick<TaskBattery, 'id' | 'name'>
      & { tasks: Array<(
        { __typename?: 'Task' }
        & Pick<Task, 'id' | 'index' | 'type' | 'duration' | 'startTime' | 'endTime' | 'completed'>
        & { stimulation?: Maybe<(
          { __typename?: 'Stimulation' }
          & Pick<Stimulation, 'id'>
        )>, config?: Maybe<(
          { __typename?: 'TaskConfig' }
          & Pick<TaskConfig, 'id' | 'time' | 'timed' | 'duration' | 'trials' | 'window'>
        )> }
      )> }
    )> }
  )> }
);

export type GetBatteryTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBatteryTemplatesQuery = (
  { __typename?: 'Query' }
  & { taskBatteries: Array<(
    { __typename?: 'TaskBattery' }
    & Pick<TaskBattery, 'id' | 'name'>
    & { tasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'type' | 'duration' | 'name'>
      & { config?: Maybe<(
        { __typename?: 'TaskConfig' }
        & Pick<TaskConfig, 'id' | 'time' | 'timed' | 'trials' | 'window' | 'duration'>
      )> }
    )> }
  )> }
);

export type AddBatteryTestMutationVariables = Exact<{
  battery: Scalars['String'];
  name: Scalars['String'];
  type: TaskType;
  trials: Scalars['Int'];
  window: Scalars['Int'];
  timed: Scalars['Boolean'];
  time: Scalars['Int'];
  index: Scalars['Int'];
}>;


export type AddBatteryTestMutation = (
  { __typename?: 'Mutation' }
  & { createTask: (
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  ) }
);

export type AddBatteryBlockMutationVariables = Exact<{
  battery: Scalars['String'];
  name: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
  duration: Scalars['Int'];
  type: TaskType;
  index: Scalars['Int'];
}>;


export type AddBatteryBlockMutation = (
  { __typename?: 'Mutation' }
  & { createTask: (
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  ) }
);

export type InitBatteryMutationVariables = Exact<{
  template: Scalars['String'];
}>;


export type InitBatteryMutation = (
  { __typename?: 'Mutation' }
  & { createTaskBattery: (
    { __typename?: 'TaskBattery' }
    & Pick<TaskBattery, 'id'>
  ) }
);

export type GetBatteryTemplateQueryVariables = Exact<{
  battery: Scalars['String'];
}>;


export type GetBatteryTemplateQuery = (
  { __typename?: 'Query' }
  & { taskBattery?: Maybe<(
    { __typename?: 'TaskBattery' }
    & Pick<TaskBattery, 'id' | 'type'>
    & { tasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'index' | 'type' | 'duration' | 'name'>
      & { stimulation?: Maybe<(
        { __typename?: 'Stimulation' }
        & Pick<Stimulation, 'id'>
        & { program?: Maybe<(
          { __typename?: 'StimulationProgram' }
          & Pick<StimulationProgram, 'name'>
        )> }
      )>, config?: Maybe<(
        { __typename?: 'TaskConfig' }
        & Pick<TaskConfig, 'id' | 'time' | 'timed' | 'duration' | 'trials' | 'window'>
      )> }
    )>, active: Array<(
      { __typename?: 'TaskBattery' }
      & Pick<TaskBattery, 'id'>
      & { tasks: Array<(
        { __typename?: 'Task' }
        & Pick<Task, 'id'>
      )> }
    )> }
  )> }
);

export type CreateBatteryTemplateMutationVariables = Exact<{
  name: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
}>;


export type CreateBatteryTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createTaskBattery: (
    { __typename?: 'TaskBattery' }
    & Pick<TaskBattery, 'id'>
  ) }
);

export type GetBioMetricListQueryVariables = Exact<{
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type GetBioMetricListQuery = (
  { __typename?: 'Query' }
  & { bioMetrics: Array<(
    { __typename?: 'BioMetric' }
    & Pick<BioMetric, 'id' | 'createdAt'>
    & { session?: Maybe<(
      { __typename?: 'Session' }
      & Pick<Session, 'id' | 'type' | 'description'>
      & { device?: Maybe<(
        { __typename?: 'Device' }
        & Pick<Device, 'type'>
      )> }
    )>, user: (
      { __typename?: 'User' }
      & Pick<User, 'email'>
    ), events: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id'>
    )>, epochs: Array<(
      { __typename?: 'EEG' }
      & Pick<Eeg, 'id' | 'startTime'>
    )>, task?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'type'>
    )> }
  )> }
);

export type GetBioMetricIdsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBioMetricIdsQuery = (
  { __typename?: 'Query' }
  & { bioMetrics: Array<(
    { __typename?: 'BioMetric' }
    & Pick<BioMetric, 'id'>
  )> }
);

export type GetEegQueryVariables = Exact<{
  id: Scalars['String'];
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  order: SortOrder;
}>;


export type GetEegQuery = (
  { __typename?: 'Query' }
  & { eegs: Array<(
    { __typename?: 'EEG' }
    & Pick<Eeg, 'id' | 'samplingRate' | 'startTime' | 'bufferDuration' | 'bufferInterval' | 'packetLossIndex' | 'packetLossSkipped'>
    & { metric: (
      { __typename?: 'BioMetric' }
      & Pick<BioMetric, 'id'>
      & { session?: Maybe<(
        { __typename?: 'Session' }
        & Pick<Session, 'id' | 'type' | 'startTime'>
        & { device?: Maybe<(
          { __typename?: 'Device' }
          & Pick<Device, 'id' | 'type'>
        )> }
      )> }
    ), samples: Array<(
      { __typename?: 'EEGSample' }
      & Pick<EegSample, 'id' | 'channel' | 'data'>
    )> }
  )> }
);

export type CreateBioMetricMutationVariables = Exact<{ [key: string]: never; }>;


export type CreateBioMetricMutation = (
  { __typename?: 'Mutation' }
  & { createBioMetric?: Maybe<(
    { __typename?: 'BioMetric' }
    & Pick<BioMetric, 'id'>
  )> }
);

export type GetEpochsIdQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetEpochsIdQuery = (
  { __typename?: 'Query' }
  & { eegs: Array<(
    { __typename?: 'EEG' }
    & Pick<Eeg, 'id'>
  )>, ppgs: Array<(
    { __typename?: 'PPG' }
    & Pick<Ppg, 'id'>
  )>, accelerometers: Array<(
    { __typename?: 'Accelerometer' }
    & Pick<Accelerometer, 'id'>
  )>, gyroscopes: Array<(
    { __typename?: 'Gyroscope' }
    & Pick<Gyroscope, 'id'>
  )> }
);

export type GetPpgQueryVariables = Exact<{
  id: Scalars['String'];
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type GetPpgQuery = (
  { __typename?: 'Query' }
  & { ppgs: Array<(
    { __typename?: 'PPG' }
    & Pick<Ppg, 'id' | 'samplingRate' | 'startTime' | 'bufferDuration' | 'bufferInterval' | 'packetLossIndex' | 'packetLossSkipped'>
    & { metric: (
      { __typename?: 'BioMetric' }
      & Pick<BioMetric, 'id'>
    ), samples: Array<(
      { __typename?: 'PPGSample' }
      & Pick<PpgSample, 'id' | 'channel' | 'data'>
    )> }
  )> }
);

export type GetAccelQueryVariables = Exact<{
  id: Scalars['String'];
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type GetAccelQuery = (
  { __typename?: 'Query' }
  & { accelerometers: Array<(
    { __typename?: 'Accelerometer' }
    & Pick<Accelerometer, 'id' | 'samplingRate' | 'startTime' | 'bufferDuration' | 'bufferInterval' | 'packetLossIndex' | 'packetLossSkipped'>
    & { metric?: Maybe<(
      { __typename?: 'BioMetric' }
      & Pick<BioMetric, 'id'>
    )>, samples: Array<(
      { __typename?: 'AccelerometerSample' }
      & Pick<AccelerometerSample, 'id' | 'channel' | 'data'>
    )> }
  )> }
);

export type GetGyroQueryVariables = Exact<{
  id: Scalars['String'];
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type GetGyroQuery = (
  { __typename?: 'Query' }
  & { gyroscopes: Array<(
    { __typename?: 'Gyroscope' }
    & Pick<Gyroscope, 'id' | 'samplingRate' | 'startTime' | 'bufferDuration' | 'bufferInterval' | 'packetLossIndex' | 'packetLossSkipped'>
    & { metric?: Maybe<(
      { __typename?: 'BioMetric' }
      & Pick<BioMetric, 'id'>
    )>, samples: Array<(
      { __typename?: 'GyroscopeSample' }
      & Pick<GyroscopeSample, 'id' | 'channel' | 'data'>
    )> }
  )> }
);

export type GetTelemetryQueryVariables = Exact<{
  metricId: Scalars['String'];
}>;


export type GetTelemetryQuery = (
  { __typename?: 'Query' }
  & { bioMetric?: Maybe<(
    { __typename?: 'BioMetric' }
    & { telemetry: Array<(
      { __typename?: 'Telemetry' }
      & Pick<Telemetry, 'batteryLevel'>
    )> }
  )> }
);

export type GetDisconnectsQueryVariables = Exact<{
  metricId: Scalars['String'];
}>;


export type GetDisconnectsQuery = (
  { __typename?: 'Query' }
  & { bioMetric?: Maybe<(
    { __typename?: 'BioMetric' }
    & { events: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'value' | 'timestamp'>
    )> }
  )> }
);

export type BioMetricStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type BioMetricStatsQuery = (
  { __typename?: 'Query' }
  & { bioMetricStats?: Maybe<(
    { __typename?: 'bioMetricStats' }
    & Pick<BioMetricStats, 'users' | 'avgDuration' | 'totalDuration' | 'avgEpochs' | 'countBioMetrics' | 'avgEvents'>
  )> }
);

export type GetExportedDataQueryVariables = Exact<{
  metricId: Scalars['String'];
}>;


export type GetExportedDataQuery = (
  { __typename?: 'Query' }
  & { biometricExport?: Maybe<(
    { __typename?: 'biometricExport' }
    & Pick<BiometricExport, 'exportedData'>
  )> }
);

export type GetLatestBiometricQueryVariables = Exact<{ [key: string]: never; }>;


export type GetLatestBiometricQuery = (
  { __typename?: 'Query' }
  & { bioMetrics: Array<(
    { __typename?: 'BioMetric' }
    & Pick<BioMetric, 'id'>
  )> }
);

export type GetCognitiveQueryVariables = Exact<{
  metricId: Scalars['String'];
}>;


export type GetCognitiveQuery = (
  { __typename?: 'Query' }
  & { bioMetric?: Maybe<(
    { __typename?: 'BioMetric' }
    & Pick<BioMetric, 'id'>
    & { cognitive?: Maybe<Array<Maybe<(
      { __typename?: 'Cognitive' }
      & Pick<Cognitive, 'id' | 'calm' | 'focus' | 'fatigue' | 'engagement' | 'timestamp'>
    )>>> }
  )> }
);

export type GetGamesQueryVariables = Exact<{
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type GetGamesQuery = (
  { __typename?: 'Query' }
  & { csGames: Array<(
    { __typename?: 'CSGame' }
    & Pick<CsGame, 'id' | 'createdAt' | 'mapName' | 'gameMode'>
    & { player: (
      { __typename?: 'CSPlayer' }
      & Pick<CsPlayer, 'id' | 'name'>
    ), states: Array<(
      { __typename?: 'CSGameState' }
      & Pick<CsGameState, 'id'>
      & { stats?: Maybe<(
        { __typename?: 'CSMatchStats' }
        & Pick<CsMatchStats, 'kills' | 'assists' | 'deaths' | 'mvps' | 'score'>
      )> }
    )> }
  )> }
);

export type GetGameIdsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGameIdsQuery = (
  { __typename?: 'Query' }
  & { csGames: Array<(
    { __typename?: 'CSGame' }
    & Pick<CsGame, 'id'>
  )> }
);

export type GetGameQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetGameQuery = (
  { __typename?: 'Query' }
  & { csGame?: Maybe<(
    { __typename?: 'CSGame' }
    & Pick<CsGame, 'id' | 'mapName' | 'gameMode'>
    & { player: (
      { __typename?: 'CSPlayer' }
      & Pick<CsPlayer, 'id' | 'steamId' | 'name'>
    ), states: Array<(
      { __typename?: 'CSGameState' }
      & Pick<CsGameState, 'id' | 'timestamp' | 'team'>
      & { state?: Maybe<(
        { __typename?: 'CSPlayerState' }
        & Pick<CsPlayerState, 'id' | 'health' | 'armor' | 'roundKills' | 'roundKillsHeadshot'>
      )>, stats?: Maybe<(
        { __typename?: 'CSMatchStats' }
        & Pick<CsMatchStats, 'id' | 'kills' | 'assists' | 'deaths' | 'mvps' | 'score'>
      )> }
    )> }
  )> }
);

export type GetGameMetricsQueryVariables = Exact<{
  id: Scalars['String'];
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type GetGameMetricsQuery = (
  { __typename?: 'Query' }
  & { csMetrics?: Maybe<(
    { __typename?: 'CSMetrics' }
    & { cognitive?: Maybe<Array<Maybe<(
      { __typename?: 'Cognitive' }
      & Pick<Cognitive, 'calm' | 'focus' | 'engagement' | 'fatigue' | 'timestamp'>
    )>>>, endState?: Maybe<(
      { __typename?: 'CSGameState' }
      & { stats?: Maybe<(
        { __typename?: 'CSMatchStats' }
        & Pick<CsMatchStats, 'kills' | 'deaths' | 'mvps' | 'assists' | 'score'>
      )> }
    )>, states?: Maybe<Array<Maybe<(
      { __typename?: 'CSGameState' }
      & Pick<CsGameState, 'id' | 'timestamp'>
      & { state?: Maybe<(
        { __typename?: 'CSPlayerState' }
        & Pick<CsPlayerState, 'id' | 'health' | 'armor' | 'roundKills' | 'roundKillsHeadshot'>
      )>, stats?: Maybe<(
        { __typename?: 'CSMatchStats' }
        & Pick<CsMatchStats, 'kills' | 'assists' | 'deaths' | 'mvps' | 'score'>
      )> }
    )>>> }
  )> }
);

export type GetCsGameSummaryQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetCsGameSummaryQuery = (
  { __typename?: 'Query' }
  & { csMetricsSummary?: Maybe<(
    { __typename?: 'CSMetricsSummary' }
    & Pick<CsMetricsSummary, 'startTime' | 'endTime'>
    & { player?: Maybe<(
      { __typename?: 'CSPlayer' }
      & Pick<CsPlayer, 'name' | 'steamId'>
    )>, summaries?: Maybe<Array<Maybe<(
      { __typename?: 'MetricSummary' }
      & Pick<MetricSummary, 'label' | 'min' | 'max' | 'mean' | 'median'>
    )>>>, endState?: Maybe<(
      { __typename?: 'CSGameState' }
      & { stats?: Maybe<(
        { __typename?: 'CSMatchStats' }
        & Pick<CsMatchStats, 'kills' | 'mvps' | 'assists' | 'deaths' | 'score'>
      )> }
    )> }
  )>, csMetricsComparison?: Maybe<(
    { __typename?: 'CSMetricComparison' }
    & { cognitive?: Maybe<(
      { __typename?: 'CognitiveComparison' }
      & Pick<CognitiveComparison, 'n'>
      & { cognitive?: Maybe<(
        { __typename?: 'Cognitive' }
        & Pick<Cognitive, 'calm' | 'focus' | 'engagement' | 'fatigue'>
      )> }
    )>, stats?: Maybe<(
      { __typename?: 'CSStatsComparison' }
      & Pick<CsStatsComparison, 'n'>
      & { stats?: Maybe<(
        { __typename?: 'MatchStatsComp' }
        & Pick<MatchStatsComp, 'kills' | 'assists' | 'deaths' | 'mvps' | 'score'>
      )> }
    )> }
  )> }
);

export type GetGameStatesQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetGameStatesQuery = (
  { __typename?: 'Query' }
  & { csGame?: Maybe<(
    { __typename?: 'CSGame' }
    & { states: Array<(
      { __typename?: 'CSGameState' }
      & Pick<CsGameState, 'id'>
    )> }
  )> }
);

export type GetGameRecordingsQueryVariables = Exact<{
  id: Scalars['String'];
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
}>;


export type GetGameRecordingsQuery = (
  { __typename?: 'Query' }
  & { csMetrics?: Maybe<(
    { __typename?: 'CSMetrics' }
    & { states?: Maybe<Array<Maybe<(
      { __typename?: 'CSGameState' }
      & Pick<CsGameState, 'id' | 'timestamp'>
      & { state?: Maybe<(
        { __typename?: 'CSPlayerState' }
        & Pick<CsPlayerState, 'id' | 'health' | 'armor' | 'roundKills' | 'roundKillsHeadshot'>
      )>, stats?: Maybe<(
        { __typename?: 'CSMatchStats' }
        & Pick<CsMatchStats, 'kills' | 'assists' | 'deaths' | 'mvps' | 'score'>
      )> }
    )>>> }
  )> }
);

export type GetDevicesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDevicesQuery = (
  { __typename?: 'Query' }
  & { devices: Array<(
    { __typename?: 'Device' }
    & Pick<Device, 'id' | 'name' | 'type' | 'firmware'>
  )> }
);

export type GetDigitSpanQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetDigitSpanQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'name'>
    & { config?: Maybe<(
      { __typename?: 'TaskConfig' }
      & Pick<TaskConfig, 'window' | 'trials' | 'timed' | 'time'>
    )>, trials: Array<(
      { __typename?: 'Trial' }
      & Pick<Trial, 'id' | 'index' | 'stimulus' | 'interval'>
      & { response?: Maybe<(
        { __typename?: 'TrialResponse' }
        & Pick<TrialResponse, 'id' | 'result'>
      )> }
    )> }
  )> }
);

export type RespondDigitSpanMutationVariables = Exact<{
  trial: Scalars['ID'];
  response?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
  presentedAt: Scalars['String'];
  respondedAt: Scalars['String'];
}>;


export type RespondDigitSpanMutation = (
  { __typename?: 'Mutation' }
  & { respondTask?: Maybe<(
    { __typename?: 'TrialResponse' }
    & Pick<TrialResponse, 'id'>
  )> }
);

export type GetDigitSpanScoreQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetDigitSpanScoreQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { score?: Maybe<(
      { __typename?: 'Score' }
      & Pick<Score, 'accuracy' | 'correct' | 'wrong' | 'time'>
    )> }
  )> }
);

export type GetDigitSpanDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDigitSpanDashboardQuery = (
  { __typename?: 'Query' }
  & { tasks: Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type' | 'name' | 'duration' | 'createdAt'>
    & { config?: Maybe<(
      { __typename?: 'TaskConfig' }
      & Pick<TaskConfig, 'id' | 'window' | 'trials' | 'time' | 'timed'>
    )>, score?: Maybe<(
      { __typename?: 'Score' }
      & Pick<Score, 'accuracy' | 'time'>
    )>, metric?: Maybe<(
      { __typename?: 'BioMetric' }
      & Pick<BioMetric, 'id'>
      & { epochs: Array<(
        { __typename?: 'EEG' }
        & Pick<Eeg, 'id'>
      )>, events: Array<(
        { __typename?: 'Event' }
        & Pick<Event, 'id'>
      )> }
    )> }
  )> }
);

export type GetDigitSpanConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDigitSpanConfigQuery = (
  { __typename?: 'Query' }
  & { getTaskConfig?: Maybe<(
    { __typename?: 'TaskConfig' }
    & Pick<TaskConfig, 'time' | 'timed' | 'window' | 'trials'>
  )> }
);

export type GetNBackQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetNBackQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'name'>
    & { config?: Maybe<(
      { __typename?: 'TaskConfig' }
      & Pick<TaskConfig, 'window' | 'trials' | 'timed' | 'time'>
    )>, stimulation?: Maybe<(
      { __typename?: 'Stimulation' }
      & Pick<Stimulation, 'id'>
      & { program?: Maybe<(
        { __typename?: 'StimulationProgram' }
        & Pick<StimulationProgram, 'id'>
      )> }
    )>, trials: Array<(
      { __typename?: 'Trial' }
      & Pick<Trial, 'id' | 'index' | 'stimulus' | 'expected' | 'interval'>
      & { response?: Maybe<(
        { __typename?: 'TrialResponse' }
        & Pick<TrialResponse, 'id' | 'result'>
      )> }
    )> }
  )> }
);

export type RespondNBackMutationVariables = Exact<{
  trial: Scalars['ID'];
  response?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
  presentedAt: Scalars['String'];
  respondedAt: Scalars['String'];
}>;


export type RespondNBackMutation = (
  { __typename?: 'Mutation' }
  & { respondTask?: Maybe<(
    { __typename?: 'TrialResponse' }
    & Pick<TrialResponse, 'id'>
  )> }
);

export type GetNBackScoreQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetNBackScoreQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { score?: Maybe<(
      { __typename?: 'Score' }
      & Pick<Score, 'time' | 'accuracy'>
    )> }
  )> }
);

export type GetNBackDashboardQueryVariables = Exact<{
  take?: Maybe<Scalars['Int']>;
}>;


export type GetNBackDashboardQuery = (
  { __typename?: 'Query' }
  & { tasks: Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type' | 'name' | 'createdAt'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'email'>
    )>, config?: Maybe<(
      { __typename?: 'TaskConfig' }
      & Pick<TaskConfig, 'id' | 'window' | 'trials' | 'time' | 'timed'>
    )>, score?: Maybe<(
      { __typename?: 'Score' }
      & Pick<Score, 'accuracy' | 'time'>
    )>, metric?: Maybe<(
      { __typename?: 'BioMetric' }
      & Pick<BioMetric, 'id'>
      & { epochs: Array<(
        { __typename?: 'EEG' }
        & Pick<Eeg, 'id'>
      )>, events: Array<(
        { __typename?: 'Event' }
        & Pick<Event, 'id'>
      )> }
    )> }
  )> }
);

export type GetNBackConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNBackConfigQuery = (
  { __typename?: 'Query' }
  & { getTaskConfig?: Maybe<(
    { __typename?: 'TaskConfig' }
    & Pick<TaskConfig, 'time' | 'timed' | 'trials' | 'window'>
  )> }
);

export type GetEegIdsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetEegIdsQuery = (
  { __typename?: 'Query' }
  & { eegs: Array<(
    { __typename?: 'EEG' }
    & Pick<Eeg, 'id' | 'startTime'>
  )> }
);

export type GetEegPlayBackQueryVariables = Exact<{
  id: Scalars['String'];
  take?: Maybe<Scalars['Int']>;
  skip?: Maybe<Scalars['Int']>;
  order: SortOrder;
}>;


export type GetEegPlayBackQuery = (
  { __typename?: 'Query' }
  & { eegs: Array<(
    { __typename?: 'EEG' }
    & Pick<Eeg, 'id' | 'samplingRate' | 'startTime' | 'bufferDuration' | 'bufferInterval' | 'packetLossIndex' | 'packetLossSkipped'>
    & { metric: (
      { __typename?: 'BioMetric' }
      & Pick<BioMetric, 'id'>
      & { session?: Maybe<(
        { __typename?: 'Session' }
        & Pick<Session, 'id' | 'type' | 'startTime'>
        & { device?: Maybe<(
          { __typename?: 'Device' }
          & Pick<Device, 'id' | 'type'>
        )> }
      )> }
    ), samples: Array<(
      { __typename?: 'EEGSample' }
      & Pick<EegSample, 'id' | 'channel' | 'data'>
    )> }
  )> }
);

export type StartRecordingMutationVariables = Exact<{
  type: Scalars['String'];
  startTime: Scalars['String'];
  metric?: Maybe<Scalars['String']>;
  deviceType?: Maybe<Scalars['String']>;
}>;


export type StartRecordingMutation = (
  { __typename?: 'Mutation' }
  & { startSession?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id' | 'type'>
    & { metric?: Maybe<(
      { __typename?: 'BioMetric' }
      & Pick<BioMetric, 'id'>
    )>, device?: Maybe<(
      { __typename?: 'Device' }
      & Pick<Device, 'id'>
    )> }
  )> }
);

export type StopRecordingMutationVariables = Exact<{
  id: Scalars['String'];
  stopTime: Scalars['String'];
}>;


export type StopRecordingMutation = (
  { __typename?: 'Mutation' }
  & { endSession?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  )> }
);

export type GetRecordingQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetRecordingQuery = (
  { __typename?: 'Query' }
  & { session?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id' | 'startTime' | 'type'>
  )> }
);

export type DeleteRecordingMutationVariables = Exact<{
  where: SessionWhereUniqueInput;
}>;


export type DeleteRecordingMutation = (
  { __typename?: 'Mutation' }
  & { deleteSession?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id' | 'startTime' | 'type'>
  )> }
);

export type GetRecordingsQueryVariables = Exact<{
  take?: Maybe<Scalars['Int']>;
}>;


export type GetRecordingsQuery = (
  { __typename?: 'Query' }
  & { sessions: Array<(
    { __typename?: 'Session' }
    & Pick<Session, 'id' | 'startTime'>
    & { metric?: Maybe<(
      { __typename?: 'BioMetric' }
      & Pick<BioMetric, 'id'>
    )> }
  )> }
);

export type GetSessionInfoQueryVariables = Exact<{
  metricId: Scalars['String'];
}>;


export type GetSessionInfoQuery = (
  { __typename?: 'Query' }
  & { bioMetric?: Maybe<(
    { __typename?: 'BioMetric' }
    & Pick<BioMetric, 'id' | 'createdAt'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email'>
    ), session?: Maybe<(
      { __typename?: 'Session' }
      & Pick<Session, 'id' | 'type' | 'description' | 'tags'>
      & { device?: Maybe<(
        { __typename?: 'Device' }
        & Pick<Device, 'type' | 'name'>
      )> }
    )>, task?: Maybe<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'completed' | 'comment' | 'duration'>
      & { score?: Maybe<(
        { __typename?: 'Score' }
        & Pick<Score, 'correct' | 'wrong' | 'accuracy' | 'time'>
      )>, config?: Maybe<(
        { __typename?: 'TaskConfig' }
        & Pick<TaskConfig, 'id' | 'window' | 'trials' | 'time' | 'timed'>
      )> }
    )>, epochs: Array<(
      { __typename?: 'EEG' }
      & Pick<Eeg, 'id' | 'startTime' | 'packetLossIndex' | 'samplingRate'>
    )>, events: Array<(
      { __typename?: 'Event' }
      & Pick<Event, 'id'>
    )> }
  )> }
);

export type UpdateSessionInfoMutationVariables = Exact<{
  id: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
}>;


export type UpdateSessionInfoMutation = (
  { __typename?: 'Mutation' }
  & { updateBioMetric?: Maybe<(
    { __typename?: 'BioMetric' }
    & Pick<BioMetric, 'id'>
    & { session?: Maybe<(
      { __typename?: 'Session' }
      & Pick<Session, 'id' | 'description'>
    )> }
  )> }
);

export type GetSessionDynamicsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetSessionDynamicsQuery = (
  { __typename?: 'Query' }
  & { eegs: Array<(
    { __typename?: 'EEG' }
    & Pick<Eeg, 'startTime'>
  )> }
);

export type DeleteSessionMutationVariables = Exact<{
  metricId: Scalars['String'];
}>;


export type DeleteSessionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'deleteBioMetric'>
);

export type StartSessionMutationVariables = Exact<{
  type: Scalars['String'];
  program: Scalars['String'];
  startTime: Scalars['String'];
  metric?: Maybe<Scalars['String']>;
  deviceType?: Maybe<Scalars['String']>;
}>;


export type StartSessionMutation = (
  { __typename?: 'Mutation' }
  & { startSession?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
    & { metric?: Maybe<(
      { __typename?: 'BioMetric' }
      & Pick<BioMetric, 'id'>
    )> }
  )> }
);

export type GetSternbergQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetSternbergQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'name'>
    & { config?: Maybe<(
      { __typename?: 'TaskConfig' }
      & Pick<TaskConfig, 'window' | 'trials' | 'timed' | 'time'>
    )>, trials: Array<(
      { __typename?: 'Trial' }
      & Pick<Trial, 'id' | 'index' | 'stimulus' | 'critical' | 'interval'>
      & { response?: Maybe<(
        { __typename?: 'TrialResponse' }
        & Pick<TrialResponse, 'id' | 'result'>
      )> }
    )> }
  )> }
);

export type RespondSternbergMutationVariables = Exact<{
  trial: Scalars['ID'];
  response?: Maybe<Scalars['String']>;
  result: Scalars['Boolean'];
  presentedAt: Scalars['String'];
  respondedAt: Scalars['String'];
}>;


export type RespondSternbergMutation = (
  { __typename?: 'Mutation' }
  & { respondTask?: Maybe<(
    { __typename?: 'TrialResponse' }
    & Pick<TrialResponse, 'id'>
  )> }
);

export type GetSternbergScoreQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetSternbergScoreQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { score?: Maybe<(
      { __typename?: 'Score' }
      & Pick<Score, 'accuracy' | 'correct' | 'wrong' | 'time'>
    )> }
  )> }
);

export type GetSternbergDashboardQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSternbergDashboardQuery = (
  { __typename?: 'Query' }
  & { tasks: Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type' | 'name' | 'duration' | 'createdAt'>
    & { config?: Maybe<(
      { __typename?: 'TaskConfig' }
      & Pick<TaskConfig, 'id' | 'window' | 'trials' | 'time' | 'timed'>
    )>, score?: Maybe<(
      { __typename?: 'Score' }
      & Pick<Score, 'accuracy' | 'time'>
    )>, metric?: Maybe<(
      { __typename?: 'BioMetric' }
      & Pick<BioMetric, 'id'>
      & { epochs: Array<(
        { __typename?: 'EEG' }
        & Pick<Eeg, 'id'>
      )>, events: Array<(
        { __typename?: 'Event' }
        & Pick<Event, 'id'>
      )> }
    )> }
  )> }
);

export type GetSternbergConfigQueryVariables = Exact<{ [key: string]: never; }>;


export type GetSternbergConfigQuery = (
  { __typename?: 'Query' }
  & { getTaskConfig?: Maybe<(
    { __typename?: 'TaskConfig' }
    & Pick<TaskConfig, 'time' | 'timed' | 'trials' | 'window'>
  )> }
);

export type GetStimulationProgramsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStimulationProgramsQuery = (
  { __typename?: 'Query' }
  & { stimulationPrograms: Array<(
    { __typename?: 'StimulationProgram' }
    & Pick<StimulationProgram, 'id' | 'frequency1' | 'frequency2' | 'coils' | 'intensity' | 'code' | 'name' | 'description' | 'usage'>
  )> }
);

export type GetStimulationProgramQueryVariables = Exact<{
  id?: Maybe<Scalars['String']>;
}>;


export type GetStimulationProgramQuery = (
  { __typename?: 'Query' }
  & { stimulationProgram?: Maybe<(
    { __typename?: 'StimulationProgram' }
    & Pick<StimulationProgram, 'id' | 'name' | 'description' | 'coils' | 'frequency1' | 'frequency2' | 'intensity' | 'usage'>
    & { stimulations: Array<(
      { __typename?: 'Stimulation' }
      & Pick<Stimulation, 'id'>
    )> }
  )> }
);

export type StartStimulationMutationVariables = Exact<{
  type: Scalars['String'];
  program: Scalars['String'];
  startTime: Scalars['String'];
  deviceType?: Maybe<Scalars['String']>;
}>;


export type StartStimulationMutation = (
  { __typename?: 'Mutation' }
  & { startSession?: Maybe<(
    { __typename?: 'Session' }
    & Pick<Session, 'id'>
  )> }
);

export type CreateStimulationMutationVariables = Exact<{
  program: Scalars['String'];
}>;


export type CreateStimulationMutation = (
  { __typename?: 'Mutation' }
  & { createStimulation: (
    { __typename?: 'Stimulation' }
    & Pick<Stimulation, 'id'>
  ) }
);

export type AddTaskStimulationMutationVariables = Exact<{
  task: Scalars['String'];
  stimulation: Scalars['String'];
}>;


export type AddTaskStimulationMutation = (
  { __typename?: 'Mutation' }
  & { updateTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  )> }
);

export type CreateStimulationProgramMutationVariables = Exact<{
  name: Scalars['String'];
  description: Scalars['String'];
}>;


export type CreateStimulationProgramMutation = (
  { __typename?: 'Mutation' }
  & { createStimulationProgram: (
    { __typename?: 'StimulationProgram' }
    & Pick<StimulationProgram, 'id'>
  ) }
);

export type UpdateStimulationProgramMutationVariables = Exact<{
  id: Scalars['String'];
  coils?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  frequency1?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  frequency2?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
  intensity?: Maybe<Array<Scalars['Float']> | Scalars['Float']>;
}>;


export type UpdateStimulationProgramMutation = (
  { __typename?: 'Mutation' }
  & { updateStimulationProgram?: Maybe<(
    { __typename?: 'StimulationProgram' }
    & Pick<StimulationProgram, 'id'>
  )> }
);

export type EndTaskMutationVariables = Exact<{
  id: Scalars['ID'];
  completed: Scalars['Boolean'];
  comment?: Maybe<Scalars['String']>;
}>;


export type EndTaskMutation = (
  { __typename?: 'Mutation' }
  & { endTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  )> }
);

export type DeleteTaskMutationVariables = Exact<{
  taskId: Scalars['String'];
}>;


export type DeleteTaskMutation = (
  { __typename?: 'Mutation' }
  & { deleteTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  )> }
);

export type GetTaskInfoQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetTaskInfoQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type' | 'completed'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'email'>
    )>, score?: Maybe<(
      { __typename?: 'Score' }
      & Pick<Score, 'correct' | 'wrong' | 'accuracy' | 'time'>
    )>, config?: Maybe<(
      { __typename?: 'TaskConfig' }
      & Pick<TaskConfig, 'window' | 'trials' | 'time' | 'timed'>
    )>, metric?: Maybe<(
      { __typename?: 'BioMetric' }
      & Pick<BioMetric, 'id'>
    )> }
  )> }
);

export type GetTaskTrialsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetTaskTrialsQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { trials: Array<(
      { __typename?: 'Trial' }
      & Pick<Trial, 'id' | 'index' | 'interval' | 'stimulus'>
      & { response?: Maybe<(
        { __typename?: 'TrialResponse' }
        & Pick<TrialResponse, 'response' | 'result' | 'presentedAt' | 'respondedAt'>
      )> }
    )> }
  )> }
);

export type GetRestQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetRestQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type' | 'duration'>
  )> }
);

export type UpdateTaskMutationVariables = Exact<{
  id: Scalars['String'];
  startTime?: Maybe<Scalars['Float']>;
  endTime?: Maybe<Scalars['Float']>;
}>;


export type UpdateTaskMutation = (
  { __typename?: 'Mutation' }
  & { updateTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  )> }
);

export type StartTaskMutationVariables = Exact<{
  id: Scalars['String'];
  startTime: Scalars['Float'];
}>;


export type StartTaskMutation = (
  { __typename?: 'Mutation' }
  & { updateTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  )> }
);

export type StartTaskWithMetricMutationVariables = Exact<{
  id: Scalars['String'];
  startTime: Scalars['Float'];
  metricId: Scalars['String'];
}>;


export type StartTaskWithMetricMutation = (
  { __typename?: 'Mutation' }
  & { updateTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  )> }
);

export type InitTestMutationVariables = Exact<{
  type: Scalars['String'];
  trials: Scalars['Int'];
  window: Scalars['Int'];
  timed: Scalars['Boolean'];
  time: Scalars['Int'];
  metric?: Maybe<Scalars['String']>;
  battery?: Maybe<Scalars['String']>;
  stimulation?: Maybe<Scalars['String']>;
}>;


export type InitTestMutation = (
  { __typename?: 'Mutation' }
  & { initTest?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  )> }
);

export type InitBlockMutationVariables = Exact<{
  type: Scalars['String'];
  name: Scalars['String'];
  duration?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  metric?: Maybe<Scalars['String']>;
  battery?: Maybe<Scalars['String']>;
  stimulation?: Maybe<Scalars['String']>;
}>;


export type InitBlockMutation = (
  { __typename?: 'Mutation' }
  & { initBlock?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  )> }
);

export type GetTaskStimulationQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetTaskStimulationQuery = (
  { __typename?: 'Query' }
  & { task?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
    & { battery?: Maybe<(
      { __typename?: 'TaskBattery' }
      & Pick<TaskBattery, 'id' | 'type' | 'sham'>
    )>, stimulation?: Maybe<(
      { __typename?: 'Stimulation' }
      & Pick<Stimulation, 'id'>
      & { program?: Maybe<(
        { __typename?: 'StimulationProgram' }
        & Pick<StimulationProgram, 'id' | 'type' | 'code'>
      )> }
    )> }
  )> }
);

export type RemoveStimulationMutationVariables = Exact<{
  taskId: Scalars['String'];
}>;


export type RemoveStimulationMutation = (
  { __typename?: 'Mutation' }
  & { updateTask?: Maybe<(
    { __typename?: 'Task' }
    & Pick<Task, 'id'>
  )> }
);

export type GetBatteryUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBatteryUsersQuery = (
  { __typename?: 'Query' }
  & { taskBatteries: Array<(
    { __typename?: 'TaskBattery' }
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'email'>
    )>, tasks: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id' | 'startTime'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email'>
      )> }
    )> }
  )> }
);

export type GetUserTasksQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserTasksQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'role'>
    & { organization: Array<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name'>
    )> }
  )>, tasks: Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type' | 'completed' | 'startTime' | 'createdAt'>
    & { battery?: Maybe<(
      { __typename?: 'TaskBattery' }
      & Pick<TaskBattery, 'id'>
      & { template?: Maybe<(
        { __typename?: 'TaskBattery' }
        & Pick<TaskBattery, 'id' | 'name'>
      )> }
    )>, stimulation?: Maybe<(
      { __typename?: 'Stimulation' }
      & Pick<Stimulation, 'id'>
      & { program?: Maybe<(
        { __typename?: 'StimulationProgram' }
        & Pick<StimulationProgram, 'name'>
      )> }
    )>, metric?: Maybe<(
      { __typename?: 'BioMetric' }
      & Pick<BioMetric, 'id'>
    )>, score?: Maybe<(
      { __typename?: 'Score' }
      & Pick<Score, 'accuracy' | 'time'>
    )> }
  )> }
);

export type CreateUserBatteryMutationVariables = Exact<{
  template: Scalars['String'];
  userId: Scalars['String'];
  sham: Scalars['Boolean'];
}>;


export type CreateUserBatteryMutation = (
  { __typename?: 'Mutation' }
  & { createTaskBattery: (
    { __typename?: 'TaskBattery' }
    & Pick<TaskBattery, 'id' | 'sham'>
  ) }
);

export type GetUserInfoQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserInfoQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'name' | 'email'>
  )>, bioMetrics: Array<(
    { __typename?: 'BioMetric' }
    & Pick<BioMetric, 'id' | 'createdAt'>
  )>, stimulations: Array<(
    { __typename?: 'Stimulation' }
    & Pick<Stimulation, 'id' | 'createdAt'>
    & { program?: Maybe<(
      { __typename?: 'StimulationProgram' }
      & Pick<StimulationProgram, 'name'>
    )> }
  )>, tasks: Array<(
    { __typename?: 'Task' }
    & Pick<Task, 'id' | 'type' | 'createdAt'>
    & { score?: Maybe<(
      { __typename?: 'Score' }
      & Pick<Score, 'time' | 'accuracy'>
    )> }
  )> }
);

export type GetUserStimulationsQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserStimulationsQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )>, stimulations: Array<(
    { __typename?: 'Stimulation' }
    & Pick<Stimulation, 'id' | 'createdAt'>
    & { program?: Maybe<(
      { __typename?: 'StimulationProgram' }
      & Pick<StimulationProgram, 'name'>
    )> }
  )> }
);

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = (
  { __typename?: 'Query' }
  & { users: Array<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'role' | 'updatedAt'>
    & { organization: Array<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'name'>
    )>, task: Array<(
      { __typename?: 'Task' }
      & Pick<Task, 'id'>
    )>, metric: Array<(
      { __typename?: 'BioMetric' }
      & Pick<BioMetric, 'id'>
    )>, stimulation: Array<(
      { __typename?: 'Stimulation' }
      & Pick<Stimulation, 'id'>
    )> }
  )> }
);

export type GetUserActivityQueryVariables = Exact<{
  id: Scalars['String'];
}>;


export type GetUserActivityQuery = (
  { __typename?: 'Query' }
  & { user?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )>, stimulations: Array<(
    { __typename?: 'Stimulation' }
    & Pick<Stimulation, 'id' | 'createdAt'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'name'>
    )>, program?: Maybe<(
      { __typename?: 'StimulationProgram' }
      & Pick<StimulationProgram, 'name'>
    )> }
  )>, bioMetrics: Array<(
    { __typename?: 'BioMetric' }
    & Pick<BioMetric, 'id' | 'createdAt'>
    & { user: (
      { __typename?: 'User' }
      & Pick<User, 'id'>
    ) }
  )> }
);


export const SignupDocument = gql`
    mutation signup($email: String!, $name: String!, $password: String!, $organization: String) {
  signup(
    email: $email
    name: $name
    password: $password
    organization: $organization
  ) {
    user {
      id
      name
      email
      uid
      organization {
        id
        name
      }
    }
    accessToken
  }
}
    `;
export type SignupMutationFn = ApolloReactCommon.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      email: // value for 'email'
 *      name: // value for 'name'
 *      password: // value for 'password'
 *      organization: // value for 'organization'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = ApolloReactCommon.MutationResult<SignupMutation>;
export type SignupMutationOptions = ApolloReactCommon.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const LoginDocument = gql`
    mutation login($email: String!, $password: String!) {
  login(email: $email, password: $password) {
    user {
      id
      name
      email
      uid
      organization {
        id
        name
      }
    }
    accessToken
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($email: String!) {
  resetPassword(email: $email)
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<ResetPasswordMutation, ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<ResetPasswordMutation, ResetPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<ResetPasswordMutation, ResetPasswordMutationVariables>(ResetPasswordDocument, options);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<ResetPasswordMutation, ResetPasswordMutationVariables>;
export const GetTaskBatteryDocument = gql`
    query getTaskBattery($battery: String!) {
  taskBattery(where: {id: $battery}) {
    id
    tasks {
      id
      index
      completed
      startTime
      endTime
      score {
        accuracy
        time
      }
    }
    template {
      id
      name
      tasks {
        id
        index
        type
        duration
        startTime
        endTime
        completed
        stimulation {
          id
        }
        config {
          id
          time
          timed
          duration
          trials
          window
        }
      }
    }
  }
}
    `;

/**
 * __useGetTaskBatteryQuery__
 *
 * To run a query within a React component, call `useGetTaskBatteryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskBatteryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskBatteryQuery({
 *   variables: {
 *      battery: // value for 'battery'
 *   },
 * });
 */
export function useGetTaskBatteryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetTaskBatteryQuery, GetTaskBatteryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetTaskBatteryQuery, GetTaskBatteryQueryVariables>(GetTaskBatteryDocument, options);
      }
export function useGetTaskBatteryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaskBatteryQuery, GetTaskBatteryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetTaskBatteryQuery, GetTaskBatteryQueryVariables>(GetTaskBatteryDocument, options);
        }
export type GetTaskBatteryQueryHookResult = ReturnType<typeof useGetTaskBatteryQuery>;
export type GetTaskBatteryLazyQueryHookResult = ReturnType<typeof useGetTaskBatteryLazyQuery>;
export type GetTaskBatteryQueryResult = ApolloReactCommon.QueryResult<GetTaskBatteryQuery, GetTaskBatteryQueryVariables>;
export const GetBatteryTemplatesDocument = gql`
    query getBatteryTemplates {
  taskBatteries(where: {type: {equals: TEMPLATE}}) {
    id
    name
    tasks {
      id
      type
      duration
      name
      config {
        id
        time
        timed
        trials
        window
        duration
      }
    }
  }
}
    `;

/**
 * __useGetBatteryTemplatesQuery__
 *
 * To run a query within a React component, call `useGetBatteryTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBatteryTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatteryTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBatteryTemplatesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBatteryTemplatesQuery, GetBatteryTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetBatteryTemplatesQuery, GetBatteryTemplatesQueryVariables>(GetBatteryTemplatesDocument, options);
      }
export function useGetBatteryTemplatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBatteryTemplatesQuery, GetBatteryTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetBatteryTemplatesQuery, GetBatteryTemplatesQueryVariables>(GetBatteryTemplatesDocument, options);
        }
export type GetBatteryTemplatesQueryHookResult = ReturnType<typeof useGetBatteryTemplatesQuery>;
export type GetBatteryTemplatesLazyQueryHookResult = ReturnType<typeof useGetBatteryTemplatesLazyQuery>;
export type GetBatteryTemplatesQueryResult = ApolloReactCommon.QueryResult<GetBatteryTemplatesQuery, GetBatteryTemplatesQueryVariables>;
export const AddBatteryTestDocument = gql`
    mutation addBatteryTest($battery: String!, $name: String!, $type: TaskType!, $trials: Int!, $window: Int!, $timed: Boolean!, $time: Int!, $index: Int!) {
  createTask(
    data: {type: $type, name: $name, index: $index, battery: {connect: {id: $battery}}, config: {create: {trials: $trials, window: $window, timed: $timed, time: $time}}}
  ) {
    id
  }
}
    `;
export type AddBatteryTestMutationFn = ApolloReactCommon.MutationFunction<AddBatteryTestMutation, AddBatteryTestMutationVariables>;

/**
 * __useAddBatteryTestMutation__
 *
 * To run a mutation, you first call `useAddBatteryTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBatteryTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBatteryTestMutation, { data, loading, error }] = useAddBatteryTestMutation({
 *   variables: {
 *      battery: // value for 'battery'
 *      name: // value for 'name'
 *      type: // value for 'type'
 *      trials: // value for 'trials'
 *      window: // value for 'window'
 *      timed: // value for 'timed'
 *      time: // value for 'time'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useAddBatteryTestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddBatteryTestMutation, AddBatteryTestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddBatteryTestMutation, AddBatteryTestMutationVariables>(AddBatteryTestDocument, options);
      }
export type AddBatteryTestMutationHookResult = ReturnType<typeof useAddBatteryTestMutation>;
export type AddBatteryTestMutationResult = ApolloReactCommon.MutationResult<AddBatteryTestMutation>;
export type AddBatteryTestMutationOptions = ApolloReactCommon.BaseMutationOptions<AddBatteryTestMutation, AddBatteryTestMutationVariables>;
export const AddBatteryBlockDocument = gql`
    mutation addBatteryBlock($battery: String!, $name: String!, $comment: String, $duration: Int!, $type: TaskType!, $index: Int!) {
  createTask(
    data: {type: $type, name: $name, comment: $comment, index: $index, battery: {connect: {id: $battery}}, config: {create: {duration: $duration}}}
  ) {
    id
  }
}
    `;
export type AddBatteryBlockMutationFn = ApolloReactCommon.MutationFunction<AddBatteryBlockMutation, AddBatteryBlockMutationVariables>;

/**
 * __useAddBatteryBlockMutation__
 *
 * To run a mutation, you first call `useAddBatteryBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddBatteryBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addBatteryBlockMutation, { data, loading, error }] = useAddBatteryBlockMutation({
 *   variables: {
 *      battery: // value for 'battery'
 *      name: // value for 'name'
 *      comment: // value for 'comment'
 *      duration: // value for 'duration'
 *      type: // value for 'type'
 *      index: // value for 'index'
 *   },
 * });
 */
export function useAddBatteryBlockMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddBatteryBlockMutation, AddBatteryBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddBatteryBlockMutation, AddBatteryBlockMutationVariables>(AddBatteryBlockDocument, options);
      }
export type AddBatteryBlockMutationHookResult = ReturnType<typeof useAddBatteryBlockMutation>;
export type AddBatteryBlockMutationResult = ApolloReactCommon.MutationResult<AddBatteryBlockMutation>;
export type AddBatteryBlockMutationOptions = ApolloReactCommon.BaseMutationOptions<AddBatteryBlockMutation, AddBatteryBlockMutationVariables>;
export const InitBatteryDocument = gql`
    mutation initBattery($template: String!) {
  createTaskBattery(data: {template: {connect: {id: $template}}}) {
    id
  }
}
    `;
export type InitBatteryMutationFn = ApolloReactCommon.MutationFunction<InitBatteryMutation, InitBatteryMutationVariables>;

/**
 * __useInitBatteryMutation__
 *
 * To run a mutation, you first call `useInitBatteryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitBatteryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initBatteryMutation, { data, loading, error }] = useInitBatteryMutation({
 *   variables: {
 *      template: // value for 'template'
 *   },
 * });
 */
export function useInitBatteryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InitBatteryMutation, InitBatteryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<InitBatteryMutation, InitBatteryMutationVariables>(InitBatteryDocument, options);
      }
export type InitBatteryMutationHookResult = ReturnType<typeof useInitBatteryMutation>;
export type InitBatteryMutationResult = ApolloReactCommon.MutationResult<InitBatteryMutation>;
export type InitBatteryMutationOptions = ApolloReactCommon.BaseMutationOptions<InitBatteryMutation, InitBatteryMutationVariables>;
export const GetBatteryTemplateDocument = gql`
    query getBatteryTemplate($battery: String!) {
  taskBattery(where: {id: $battery}) {
    id
    type
    tasks {
      id
      index
      type
      duration
      name
      stimulation {
        id
        program {
          name
        }
      }
      config {
        id
        time
        timed
        duration
        trials
        window
      }
    }
    active {
      id
      tasks {
        id
      }
    }
  }
}
    `;

/**
 * __useGetBatteryTemplateQuery__
 *
 * To run a query within a React component, call `useGetBatteryTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBatteryTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatteryTemplateQuery({
 *   variables: {
 *      battery: // value for 'battery'
 *   },
 * });
 */
export function useGetBatteryTemplateQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetBatteryTemplateQuery, GetBatteryTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetBatteryTemplateQuery, GetBatteryTemplateQueryVariables>(GetBatteryTemplateDocument, options);
      }
export function useGetBatteryTemplateLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBatteryTemplateQuery, GetBatteryTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetBatteryTemplateQuery, GetBatteryTemplateQueryVariables>(GetBatteryTemplateDocument, options);
        }
export type GetBatteryTemplateQueryHookResult = ReturnType<typeof useGetBatteryTemplateQuery>;
export type GetBatteryTemplateLazyQueryHookResult = ReturnType<typeof useGetBatteryTemplateLazyQuery>;
export type GetBatteryTemplateQueryResult = ApolloReactCommon.QueryResult<GetBatteryTemplateQuery, GetBatteryTemplateQueryVariables>;
export const CreateBatteryTemplateDocument = gql`
    mutation createBatteryTemplate($name: String!, $comment: String) {
  createTaskBattery(data: {name: $name, comment: $comment, type: TEMPLATE}) {
    id
  }
}
    `;
export type CreateBatteryTemplateMutationFn = ApolloReactCommon.MutationFunction<CreateBatteryTemplateMutation, CreateBatteryTemplateMutationVariables>;

/**
 * __useCreateBatteryTemplateMutation__
 *
 * To run a mutation, you first call `useCreateBatteryTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBatteryTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBatteryTemplateMutation, { data, loading, error }] = useCreateBatteryTemplateMutation({
 *   variables: {
 *      name: // value for 'name'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreateBatteryTemplateMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBatteryTemplateMutation, CreateBatteryTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateBatteryTemplateMutation, CreateBatteryTemplateMutationVariables>(CreateBatteryTemplateDocument, options);
      }
export type CreateBatteryTemplateMutationHookResult = ReturnType<typeof useCreateBatteryTemplateMutation>;
export type CreateBatteryTemplateMutationResult = ApolloReactCommon.MutationResult<CreateBatteryTemplateMutation>;
export type CreateBatteryTemplateMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBatteryTemplateMutation, CreateBatteryTemplateMutationVariables>;
export const GetBioMetricListDocument = gql`
    query getBioMetricList($take: Int, $skip: Int) {
  bioMetrics(orderBy: {createdAt: desc}, take: $take, skip: $skip) {
    id
    createdAt
    session {
      id
      type
      description
      device {
        type
      }
    }
    user {
      email
    }
    events {
      id
    }
    epochs {
      id
      startTime
    }
    task {
      type
    }
  }
}
    `;

/**
 * __useGetBioMetricListQuery__
 *
 * To run a query within a React component, call `useGetBioMetricListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBioMetricListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBioMetricListQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetBioMetricListQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBioMetricListQuery, GetBioMetricListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetBioMetricListQuery, GetBioMetricListQueryVariables>(GetBioMetricListDocument, options);
      }
export function useGetBioMetricListLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBioMetricListQuery, GetBioMetricListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetBioMetricListQuery, GetBioMetricListQueryVariables>(GetBioMetricListDocument, options);
        }
export type GetBioMetricListQueryHookResult = ReturnType<typeof useGetBioMetricListQuery>;
export type GetBioMetricListLazyQueryHookResult = ReturnType<typeof useGetBioMetricListLazyQuery>;
export type GetBioMetricListQueryResult = ApolloReactCommon.QueryResult<GetBioMetricListQuery, GetBioMetricListQueryVariables>;
export const GetBioMetricIdsDocument = gql`
    query getBioMetricIds {
  bioMetrics {
    id
  }
}
    `;

/**
 * __useGetBioMetricIdsQuery__
 *
 * To run a query within a React component, call `useGetBioMetricIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBioMetricIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBioMetricIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBioMetricIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBioMetricIdsQuery, GetBioMetricIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetBioMetricIdsQuery, GetBioMetricIdsQueryVariables>(GetBioMetricIdsDocument, options);
      }
export function useGetBioMetricIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBioMetricIdsQuery, GetBioMetricIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetBioMetricIdsQuery, GetBioMetricIdsQueryVariables>(GetBioMetricIdsDocument, options);
        }
export type GetBioMetricIdsQueryHookResult = ReturnType<typeof useGetBioMetricIdsQuery>;
export type GetBioMetricIdsLazyQueryHookResult = ReturnType<typeof useGetBioMetricIdsLazyQuery>;
export type GetBioMetricIdsQueryResult = ApolloReactCommon.QueryResult<GetBioMetricIdsQuery, GetBioMetricIdsQueryVariables>;
export const GetEegDocument = gql`
    query getEEG($id: String!, $take: Int, $skip: Int, $order: SortOrder!) {
  eegs(
    where: {metricId: {equals: $id}}
    take: $take
    skip: $skip
    orderBy: {startTime: $order}
  ) {
    id
    metric {
      id
      session {
        id
        type
        startTime
        device {
          id
          type
        }
      }
    }
    samplingRate
    startTime
    bufferDuration
    bufferInterval
    packetLossIndex
    packetLossSkipped
    samples {
      id
      channel
      data
    }
  }
}
    `;

/**
 * __useGetEegQuery__
 *
 * To run a query within a React component, call `useGetEegQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEegQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEegQuery({
 *   variables: {
 *      id: // value for 'id'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetEegQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetEegQuery, GetEegQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetEegQuery, GetEegQueryVariables>(GetEegDocument, options);
      }
export function useGetEegLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEegQuery, GetEegQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetEegQuery, GetEegQueryVariables>(GetEegDocument, options);
        }
export type GetEegQueryHookResult = ReturnType<typeof useGetEegQuery>;
export type GetEegLazyQueryHookResult = ReturnType<typeof useGetEegLazyQuery>;
export type GetEegQueryResult = ApolloReactCommon.QueryResult<GetEegQuery, GetEegQueryVariables>;
export const CreateBioMetricDocument = gql`
    mutation createBioMetric {
  createBioMetric {
    id
  }
}
    `;
export type CreateBioMetricMutationFn = ApolloReactCommon.MutationFunction<CreateBioMetricMutation, CreateBioMetricMutationVariables>;

/**
 * __useCreateBioMetricMutation__
 *
 * To run a mutation, you first call `useCreateBioMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateBioMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createBioMetricMutation, { data, loading, error }] = useCreateBioMetricMutation({
 *   variables: {
 *   },
 * });
 */
export function useCreateBioMetricMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateBioMetricMutation, CreateBioMetricMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateBioMetricMutation, CreateBioMetricMutationVariables>(CreateBioMetricDocument, options);
      }
export type CreateBioMetricMutationHookResult = ReturnType<typeof useCreateBioMetricMutation>;
export type CreateBioMetricMutationResult = ApolloReactCommon.MutationResult<CreateBioMetricMutation>;
export type CreateBioMetricMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateBioMetricMutation, CreateBioMetricMutationVariables>;
export const GetEpochsIdDocument = gql`
    query getEpochsId($id: String!) {
  eegs(where: {metricId: {equals: $id}}) {
    id
  }
  ppgs(where: {metricId: {equals: $id}}) {
    id
  }
  accelerometers(where: {metricId: {equals: $id}}) {
    id
  }
  gyroscopes(where: {metricId: {equals: $id}}) {
    id
  }
}
    `;

/**
 * __useGetEpochsIdQuery__
 *
 * To run a query within a React component, call `useGetEpochsIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEpochsIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEpochsIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEpochsIdQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetEpochsIdQuery, GetEpochsIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetEpochsIdQuery, GetEpochsIdQueryVariables>(GetEpochsIdDocument, options);
      }
export function useGetEpochsIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEpochsIdQuery, GetEpochsIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetEpochsIdQuery, GetEpochsIdQueryVariables>(GetEpochsIdDocument, options);
        }
export type GetEpochsIdQueryHookResult = ReturnType<typeof useGetEpochsIdQuery>;
export type GetEpochsIdLazyQueryHookResult = ReturnType<typeof useGetEpochsIdLazyQuery>;
export type GetEpochsIdQueryResult = ApolloReactCommon.QueryResult<GetEpochsIdQuery, GetEpochsIdQueryVariables>;
export const GetPpgDocument = gql`
    query getPPG($id: String!, $take: Int, $skip: Int) {
  ppgs(
    where: {metricId: {equals: $id}}
    take: $take
    skip: $skip
    orderBy: {startTime: asc}
  ) {
    id
    metric {
      id
    }
    samplingRate
    startTime
    bufferDuration
    bufferInterval
    packetLossIndex
    packetLossSkipped
    samples {
      id
      channel
      data
    }
  }
}
    `;

/**
 * __useGetPpgQuery__
 *
 * To run a query within a React component, call `useGetPpgQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPpgQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPpgQuery({
 *   variables: {
 *      id: // value for 'id'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetPpgQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetPpgQuery, GetPpgQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetPpgQuery, GetPpgQueryVariables>(GetPpgDocument, options);
      }
export function useGetPpgLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPpgQuery, GetPpgQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetPpgQuery, GetPpgQueryVariables>(GetPpgDocument, options);
        }
export type GetPpgQueryHookResult = ReturnType<typeof useGetPpgQuery>;
export type GetPpgLazyQueryHookResult = ReturnType<typeof useGetPpgLazyQuery>;
export type GetPpgQueryResult = ApolloReactCommon.QueryResult<GetPpgQuery, GetPpgQueryVariables>;
export const GetAccelDocument = gql`
    query getAccel($id: String!, $take: Int, $skip: Int) {
  accelerometers(
    where: {metricId: {equals: $id}}
    take: $take
    skip: $skip
    orderBy: {startTime: asc}
  ) {
    id
    metric {
      id
    }
    samplingRate
    startTime
    bufferDuration
    bufferInterval
    packetLossIndex
    packetLossSkipped
    samples {
      id
      channel
      data
    }
  }
}
    `;

/**
 * __useGetAccelQuery__
 *
 * To run a query within a React component, call `useGetAccelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccelQuery({
 *   variables: {
 *      id: // value for 'id'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetAccelQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetAccelQuery, GetAccelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetAccelQuery, GetAccelQueryVariables>(GetAccelDocument, options);
      }
export function useGetAccelLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetAccelQuery, GetAccelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetAccelQuery, GetAccelQueryVariables>(GetAccelDocument, options);
        }
export type GetAccelQueryHookResult = ReturnType<typeof useGetAccelQuery>;
export type GetAccelLazyQueryHookResult = ReturnType<typeof useGetAccelLazyQuery>;
export type GetAccelQueryResult = ApolloReactCommon.QueryResult<GetAccelQuery, GetAccelQueryVariables>;
export const GetGyroDocument = gql`
    query getGyro($id: String!, $take: Int, $skip: Int) {
  gyroscopes(
    where: {metricId: {equals: $id}}
    take: $take
    skip: $skip
    orderBy: {startTime: asc}
  ) {
    id
    metric {
      id
    }
    samplingRate
    startTime
    bufferDuration
    bufferInterval
    packetLossIndex
    packetLossSkipped
    samples {
      id
      channel
      data
    }
  }
}
    `;

/**
 * __useGetGyroQuery__
 *
 * To run a query within a React component, call `useGetGyroQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGyroQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGyroQuery({
 *   variables: {
 *      id: // value for 'id'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetGyroQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetGyroQuery, GetGyroQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetGyroQuery, GetGyroQueryVariables>(GetGyroDocument, options);
      }
export function useGetGyroLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGyroQuery, GetGyroQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetGyroQuery, GetGyroQueryVariables>(GetGyroDocument, options);
        }
export type GetGyroQueryHookResult = ReturnType<typeof useGetGyroQuery>;
export type GetGyroLazyQueryHookResult = ReturnType<typeof useGetGyroLazyQuery>;
export type GetGyroQueryResult = ApolloReactCommon.QueryResult<GetGyroQuery, GetGyroQueryVariables>;
export const GetTelemetryDocument = gql`
    query getTelemetry($metricId: String!) {
  bioMetric(where: {id: $metricId}) {
    telemetry {
      batteryLevel
    }
  }
}
    `;

/**
 * __useGetTelemetryQuery__
 *
 * To run a query within a React component, call `useGetTelemetryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTelemetryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTelemetryQuery({
 *   variables: {
 *      metricId: // value for 'metricId'
 *   },
 * });
 */
export function useGetTelemetryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetTelemetryQuery, GetTelemetryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetTelemetryQuery, GetTelemetryQueryVariables>(GetTelemetryDocument, options);
      }
export function useGetTelemetryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTelemetryQuery, GetTelemetryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetTelemetryQuery, GetTelemetryQueryVariables>(GetTelemetryDocument, options);
        }
export type GetTelemetryQueryHookResult = ReturnType<typeof useGetTelemetryQuery>;
export type GetTelemetryLazyQueryHookResult = ReturnType<typeof useGetTelemetryLazyQuery>;
export type GetTelemetryQueryResult = ApolloReactCommon.QueryResult<GetTelemetryQuery, GetTelemetryQueryVariables>;
export const GetDisconnectsDocument = gql`
    query getDisconnects($metricId: String!) {
  bioMetric(where: {id: $metricId}) {
    events {
      value
      timestamp
    }
  }
}
    `;

/**
 * __useGetDisconnectsQuery__
 *
 * To run a query within a React component, call `useGetDisconnectsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDisconnectsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDisconnectsQuery({
 *   variables: {
 *      metricId: // value for 'metricId'
 *   },
 * });
 */
export function useGetDisconnectsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetDisconnectsQuery, GetDisconnectsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDisconnectsQuery, GetDisconnectsQueryVariables>(GetDisconnectsDocument, options);
      }
export function useGetDisconnectsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDisconnectsQuery, GetDisconnectsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDisconnectsQuery, GetDisconnectsQueryVariables>(GetDisconnectsDocument, options);
        }
export type GetDisconnectsQueryHookResult = ReturnType<typeof useGetDisconnectsQuery>;
export type GetDisconnectsLazyQueryHookResult = ReturnType<typeof useGetDisconnectsLazyQuery>;
export type GetDisconnectsQueryResult = ApolloReactCommon.QueryResult<GetDisconnectsQuery, GetDisconnectsQueryVariables>;
export const BioMetricStatsDocument = gql`
    query bioMetricStats {
  bioMetricStats {
    users
    avgDuration
    totalDuration
    avgEpochs
    countBioMetrics
    avgEvents
  }
}
    `;

/**
 * __useBioMetricStatsQuery__
 *
 * To run a query within a React component, call `useBioMetricStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useBioMetricStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useBioMetricStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useBioMetricStatsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<BioMetricStatsQuery, BioMetricStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<BioMetricStatsQuery, BioMetricStatsQueryVariables>(BioMetricStatsDocument, options);
      }
export function useBioMetricStatsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<BioMetricStatsQuery, BioMetricStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<BioMetricStatsQuery, BioMetricStatsQueryVariables>(BioMetricStatsDocument, options);
        }
export type BioMetricStatsQueryHookResult = ReturnType<typeof useBioMetricStatsQuery>;
export type BioMetricStatsLazyQueryHookResult = ReturnType<typeof useBioMetricStatsLazyQuery>;
export type BioMetricStatsQueryResult = ApolloReactCommon.QueryResult<BioMetricStatsQuery, BioMetricStatsQueryVariables>;
export const GetExportedDataDocument = gql`
    query getExportedData($metricId: String!) {
  biometricExport(id: $metricId) {
    exportedData
  }
}
    `;

/**
 * __useGetExportedDataQuery__
 *
 * To run a query within a React component, call `useGetExportedDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetExportedDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetExportedDataQuery({
 *   variables: {
 *      metricId: // value for 'metricId'
 *   },
 * });
 */
export function useGetExportedDataQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetExportedDataQuery, GetExportedDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetExportedDataQuery, GetExportedDataQueryVariables>(GetExportedDataDocument, options);
      }
export function useGetExportedDataLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetExportedDataQuery, GetExportedDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetExportedDataQuery, GetExportedDataQueryVariables>(GetExportedDataDocument, options);
        }
export type GetExportedDataQueryHookResult = ReturnType<typeof useGetExportedDataQuery>;
export type GetExportedDataLazyQueryHookResult = ReturnType<typeof useGetExportedDataLazyQuery>;
export type GetExportedDataQueryResult = ApolloReactCommon.QueryResult<GetExportedDataQuery, GetExportedDataQueryVariables>;
export const GetLatestBiometricDocument = gql`
    query getLatestBiometric {
  bioMetrics(orderBy: {createdAt: desc}, take: 1) {
    id
  }
}
    `;

/**
 * __useGetLatestBiometricQuery__
 *
 * To run a query within a React component, call `useGetLatestBiometricQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLatestBiometricQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLatestBiometricQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLatestBiometricQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetLatestBiometricQuery, GetLatestBiometricQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetLatestBiometricQuery, GetLatestBiometricQueryVariables>(GetLatestBiometricDocument, options);
      }
export function useGetLatestBiometricLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetLatestBiometricQuery, GetLatestBiometricQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetLatestBiometricQuery, GetLatestBiometricQueryVariables>(GetLatestBiometricDocument, options);
        }
export type GetLatestBiometricQueryHookResult = ReturnType<typeof useGetLatestBiometricQuery>;
export type GetLatestBiometricLazyQueryHookResult = ReturnType<typeof useGetLatestBiometricLazyQuery>;
export type GetLatestBiometricQueryResult = ApolloReactCommon.QueryResult<GetLatestBiometricQuery, GetLatestBiometricQueryVariables>;
export const GetCognitiveDocument = gql`
    query getCognitive($metricId: String!) {
  bioMetric(where: {id: $metricId}) {
    id
    cognitive {
      id
      calm
      focus
      fatigue
      engagement
      timestamp
    }
  }
}
    `;

/**
 * __useGetCognitiveQuery__
 *
 * To run a query within a React component, call `useGetCognitiveQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCognitiveQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCognitiveQuery({
 *   variables: {
 *      metricId: // value for 'metricId'
 *   },
 * });
 */
export function useGetCognitiveQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCognitiveQuery, GetCognitiveQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCognitiveQuery, GetCognitiveQueryVariables>(GetCognitiveDocument, options);
      }
export function useGetCognitiveLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCognitiveQuery, GetCognitiveQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCognitiveQuery, GetCognitiveQueryVariables>(GetCognitiveDocument, options);
        }
export type GetCognitiveQueryHookResult = ReturnType<typeof useGetCognitiveQuery>;
export type GetCognitiveLazyQueryHookResult = ReturnType<typeof useGetCognitiveLazyQuery>;
export type GetCognitiveQueryResult = ApolloReactCommon.QueryResult<GetCognitiveQuery, GetCognitiveQueryVariables>;
export const GetGamesDocument = gql`
    query getGames($take: Int, $skip: Int) {
  csGames(orderBy: {createdAt: desc}, take: $take, skip: $skip) {
    id
    createdAt
    mapName
    gameMode
    player {
      id
      name
    }
    states {
      id
      stats {
        kills
        assists
        deaths
        mvps
        score
      }
    }
  }
}
    `;

/**
 * __useGetGamesQuery__
 *
 * To run a query within a React component, call `useGetGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGamesQuery({
 *   variables: {
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetGamesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGamesQuery, GetGamesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetGamesQuery, GetGamesQueryVariables>(GetGamesDocument, options);
      }
export function useGetGamesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGamesQuery, GetGamesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetGamesQuery, GetGamesQueryVariables>(GetGamesDocument, options);
        }
export type GetGamesQueryHookResult = ReturnType<typeof useGetGamesQuery>;
export type GetGamesLazyQueryHookResult = ReturnType<typeof useGetGamesLazyQuery>;
export type GetGamesQueryResult = ApolloReactCommon.QueryResult<GetGamesQuery, GetGamesQueryVariables>;
export const GetGameIdsDocument = gql`
    query getGameIds {
  csGames {
    id
  }
}
    `;

/**
 * __useGetGameIdsQuery__
 *
 * To run a query within a React component, call `useGetGameIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameIdsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGameIdsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetGameIdsQuery, GetGameIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetGameIdsQuery, GetGameIdsQueryVariables>(GetGameIdsDocument, options);
      }
export function useGetGameIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGameIdsQuery, GetGameIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetGameIdsQuery, GetGameIdsQueryVariables>(GetGameIdsDocument, options);
        }
export type GetGameIdsQueryHookResult = ReturnType<typeof useGetGameIdsQuery>;
export type GetGameIdsLazyQueryHookResult = ReturnType<typeof useGetGameIdsLazyQuery>;
export type GetGameIdsQueryResult = ApolloReactCommon.QueryResult<GetGameIdsQuery, GetGameIdsQueryVariables>;
export const GetGameDocument = gql`
    query getGame($id: String!) {
  csGame(where: {id: $id}) {
    id
    mapName
    gameMode
    player {
      id
      steamId
      name
    }
    states {
      id
      timestamp
      team
      state {
        id
        health
        armor
        roundKills
        roundKillsHeadshot
      }
      stats {
        id
        kills
        assists
        deaths
        mvps
        score
      }
    }
  }
}
    `;

/**
 * __useGetGameQuery__
 *
 * To run a query within a React component, call `useGetGameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGameQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetGameQuery, GetGameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetGameQuery, GetGameQueryVariables>(GetGameDocument, options);
      }
export function useGetGameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGameQuery, GetGameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetGameQuery, GetGameQueryVariables>(GetGameDocument, options);
        }
export type GetGameQueryHookResult = ReturnType<typeof useGetGameQuery>;
export type GetGameLazyQueryHookResult = ReturnType<typeof useGetGameLazyQuery>;
export type GetGameQueryResult = ApolloReactCommon.QueryResult<GetGameQuery, GetGameQueryVariables>;
export const GetGameMetricsDocument = gql`
    query getGameMetrics($id: String!, $take: Int, $skip: Int) {
  csMetrics(id: $id, take: $take, skip: $skip) {
    cognitive {
      calm
      focus
      engagement
      fatigue
      timestamp
    }
    endState {
      stats {
        kills
        deaths
        mvps
        assists
        score
      }
    }
    states {
      id
      timestamp
      state {
        id
        health
        armor
        roundKills
        roundKillsHeadshot
      }
      stats {
        kills
        assists
        deaths
        mvps
        score
      }
    }
  }
}
    `;

/**
 * __useGetGameMetricsQuery__
 *
 * To run a query within a React component, call `useGetGameMetricsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameMetricsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameMetricsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetGameMetricsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetGameMetricsQuery, GetGameMetricsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetGameMetricsQuery, GetGameMetricsQueryVariables>(GetGameMetricsDocument, options);
      }
export function useGetGameMetricsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGameMetricsQuery, GetGameMetricsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetGameMetricsQuery, GetGameMetricsQueryVariables>(GetGameMetricsDocument, options);
        }
export type GetGameMetricsQueryHookResult = ReturnType<typeof useGetGameMetricsQuery>;
export type GetGameMetricsLazyQueryHookResult = ReturnType<typeof useGetGameMetricsLazyQuery>;
export type GetGameMetricsQueryResult = ApolloReactCommon.QueryResult<GetGameMetricsQuery, GetGameMetricsQueryVariables>;
export const GetCsGameSummaryDocument = gql`
    query getCSGameSummary($id: String!) {
  csMetricsSummary(id: $id) {
    player {
      name
      steamId
    }
    summaries {
      label
      min
      max
      mean
      median
    }
    endState {
      stats {
        kills
        mvps
        assists
        deaths
        score
      }
    }
    startTime
    endTime
  }
  csMetricsComparison(id: $id) {
    cognitive {
      n
      cognitive {
        calm
        focus
        engagement
        fatigue
      }
    }
    stats {
      n
      stats {
        kills
        assists
        deaths
        mvps
        score
      }
    }
  }
}
    `;

/**
 * __useGetCsGameSummaryQuery__
 *
 * To run a query within a React component, call `useGetCsGameSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCsGameSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCsGameSummaryQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetCsGameSummaryQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetCsGameSummaryQuery, GetCsGameSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetCsGameSummaryQuery, GetCsGameSummaryQueryVariables>(GetCsGameSummaryDocument, options);
      }
export function useGetCsGameSummaryLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetCsGameSummaryQuery, GetCsGameSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetCsGameSummaryQuery, GetCsGameSummaryQueryVariables>(GetCsGameSummaryDocument, options);
        }
export type GetCsGameSummaryQueryHookResult = ReturnType<typeof useGetCsGameSummaryQuery>;
export type GetCsGameSummaryLazyQueryHookResult = ReturnType<typeof useGetCsGameSummaryLazyQuery>;
export type GetCsGameSummaryQueryResult = ApolloReactCommon.QueryResult<GetCsGameSummaryQuery, GetCsGameSummaryQueryVariables>;
export const GetGameStatesDocument = gql`
    query getGameStates($id: String!) {
  csGame(where: {id: $id}) {
    states {
      id
    }
  }
}
    `;

/**
 * __useGetGameStatesQuery__
 *
 * To run a query within a React component, call `useGetGameStatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameStatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameStatesQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGameStatesQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetGameStatesQuery, GetGameStatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetGameStatesQuery, GetGameStatesQueryVariables>(GetGameStatesDocument, options);
      }
export function useGetGameStatesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGameStatesQuery, GetGameStatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetGameStatesQuery, GetGameStatesQueryVariables>(GetGameStatesDocument, options);
        }
export type GetGameStatesQueryHookResult = ReturnType<typeof useGetGameStatesQuery>;
export type GetGameStatesLazyQueryHookResult = ReturnType<typeof useGetGameStatesLazyQuery>;
export type GetGameStatesQueryResult = ApolloReactCommon.QueryResult<GetGameStatesQuery, GetGameStatesQueryVariables>;
export const GetGameRecordingsDocument = gql`
    query getGameRecordings($id: String!, $take: Int, $skip: Int) {
  csMetrics(id: $id, take: $take, skip: $skip) {
    states {
      id
      timestamp
      state {
        id
        health
        armor
        roundKills
        roundKillsHeadshot
      }
      stats {
        kills
        assists
        deaths
        mvps
        score
      }
    }
  }
}
    `;

/**
 * __useGetGameRecordingsQuery__
 *
 * To run a query within a React component, call `useGetGameRecordingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGameRecordingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGameRecordingsQuery({
 *   variables: {
 *      id: // value for 'id'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *   },
 * });
 */
export function useGetGameRecordingsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetGameRecordingsQuery, GetGameRecordingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetGameRecordingsQuery, GetGameRecordingsQueryVariables>(GetGameRecordingsDocument, options);
      }
export function useGetGameRecordingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetGameRecordingsQuery, GetGameRecordingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetGameRecordingsQuery, GetGameRecordingsQueryVariables>(GetGameRecordingsDocument, options);
        }
export type GetGameRecordingsQueryHookResult = ReturnType<typeof useGetGameRecordingsQuery>;
export type GetGameRecordingsLazyQueryHookResult = ReturnType<typeof useGetGameRecordingsLazyQuery>;
export type GetGameRecordingsQueryResult = ApolloReactCommon.QueryResult<GetGameRecordingsQuery, GetGameRecordingsQueryVariables>;
export const GetDevicesDocument = gql`
    query getDevices {
  devices {
    id
    name
    type
    firmware
  }
}
    `;

/**
 * __useGetDevicesQuery__
 *
 * To run a query within a React component, call `useGetDevicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDevicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDevicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDevicesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDevicesQuery, GetDevicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDevicesQuery, GetDevicesQueryVariables>(GetDevicesDocument, options);
      }
export function useGetDevicesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDevicesQuery, GetDevicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDevicesQuery, GetDevicesQueryVariables>(GetDevicesDocument, options);
        }
export type GetDevicesQueryHookResult = ReturnType<typeof useGetDevicesQuery>;
export type GetDevicesLazyQueryHookResult = ReturnType<typeof useGetDevicesLazyQuery>;
export type GetDevicesQueryResult = ApolloReactCommon.QueryResult<GetDevicesQuery, GetDevicesQueryVariables>;
export const GetDigitSpanDocument = gql`
    query getDigitSpan($id: String!) {
  task(where: {id: $id}) {
    id
    name
    config {
      window
      trials
      timed
      time
    }
    trials {
      id
      index
      stimulus
      interval
      response {
        id
        result
      }
    }
  }
}
    `;

/**
 * __useGetDigitSpanQuery__
 *
 * To run a query within a React component, call `useGetDigitSpanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDigitSpanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDigitSpanQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDigitSpanQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetDigitSpanQuery, GetDigitSpanQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDigitSpanQuery, GetDigitSpanQueryVariables>(GetDigitSpanDocument, options);
      }
export function useGetDigitSpanLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDigitSpanQuery, GetDigitSpanQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDigitSpanQuery, GetDigitSpanQueryVariables>(GetDigitSpanDocument, options);
        }
export type GetDigitSpanQueryHookResult = ReturnType<typeof useGetDigitSpanQuery>;
export type GetDigitSpanLazyQueryHookResult = ReturnType<typeof useGetDigitSpanLazyQuery>;
export type GetDigitSpanQueryResult = ApolloReactCommon.QueryResult<GetDigitSpanQuery, GetDigitSpanQueryVariables>;
export const RespondDigitSpanDocument = gql`
    mutation respondDigitSpan($trial: ID!, $response: String, $result: Boolean!, $presentedAt: String!, $respondedAt: String!) {
  respondTask(
    trial: $trial
    response: $response
    result: $result
    presentedAt: $presentedAt
    respondedAt: $respondedAt
  ) {
    id
  }
}
    `;
export type RespondDigitSpanMutationFn = ApolloReactCommon.MutationFunction<RespondDigitSpanMutation, RespondDigitSpanMutationVariables>;

/**
 * __useRespondDigitSpanMutation__
 *
 * To run a mutation, you first call `useRespondDigitSpanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRespondDigitSpanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [respondDigitSpanMutation, { data, loading, error }] = useRespondDigitSpanMutation({
 *   variables: {
 *      trial: // value for 'trial'
 *      response: // value for 'response'
 *      result: // value for 'result'
 *      presentedAt: // value for 'presentedAt'
 *      respondedAt: // value for 'respondedAt'
 *   },
 * });
 */
export function useRespondDigitSpanMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RespondDigitSpanMutation, RespondDigitSpanMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RespondDigitSpanMutation, RespondDigitSpanMutationVariables>(RespondDigitSpanDocument, options);
      }
export type RespondDigitSpanMutationHookResult = ReturnType<typeof useRespondDigitSpanMutation>;
export type RespondDigitSpanMutationResult = ApolloReactCommon.MutationResult<RespondDigitSpanMutation>;
export type RespondDigitSpanMutationOptions = ApolloReactCommon.BaseMutationOptions<RespondDigitSpanMutation, RespondDigitSpanMutationVariables>;
export const GetDigitSpanScoreDocument = gql`
    query getDigitSpanScore($id: String!) {
  task(where: {id: $id}) {
    id
    score {
      accuracy
      correct
      wrong
      time
    }
  }
}
    `;

/**
 * __useGetDigitSpanScoreQuery__
 *
 * To run a query within a React component, call `useGetDigitSpanScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDigitSpanScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDigitSpanScoreQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetDigitSpanScoreQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetDigitSpanScoreQuery, GetDigitSpanScoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDigitSpanScoreQuery, GetDigitSpanScoreQueryVariables>(GetDigitSpanScoreDocument, options);
      }
export function useGetDigitSpanScoreLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDigitSpanScoreQuery, GetDigitSpanScoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDigitSpanScoreQuery, GetDigitSpanScoreQueryVariables>(GetDigitSpanScoreDocument, options);
        }
export type GetDigitSpanScoreQueryHookResult = ReturnType<typeof useGetDigitSpanScoreQuery>;
export type GetDigitSpanScoreLazyQueryHookResult = ReturnType<typeof useGetDigitSpanScoreLazyQuery>;
export type GetDigitSpanScoreQueryResult = ApolloReactCommon.QueryResult<GetDigitSpanScoreQuery, GetDigitSpanScoreQueryVariables>;
export const GetDigitSpanDashboardDocument = gql`
    query getDigitSpanDashboard {
  tasks(
    where: {type: {equals: DIGITSPAN}, completed: {equals: true}}
    orderBy: {createdAt: desc}
  ) {
    id
    type
    name
    duration
    createdAt
    config {
      id
      window
      trials
      time
      timed
    }
    score {
      accuracy
      time
    }
    metric {
      id
      epochs {
        id
      }
      events {
        id
      }
    }
  }
}
    `;

/**
 * __useGetDigitSpanDashboardQuery__
 *
 * To run a query within a React component, call `useGetDigitSpanDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDigitSpanDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDigitSpanDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDigitSpanDashboardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDigitSpanDashboardQuery, GetDigitSpanDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDigitSpanDashboardQuery, GetDigitSpanDashboardQueryVariables>(GetDigitSpanDashboardDocument, options);
      }
export function useGetDigitSpanDashboardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDigitSpanDashboardQuery, GetDigitSpanDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDigitSpanDashboardQuery, GetDigitSpanDashboardQueryVariables>(GetDigitSpanDashboardDocument, options);
        }
export type GetDigitSpanDashboardQueryHookResult = ReturnType<typeof useGetDigitSpanDashboardQuery>;
export type GetDigitSpanDashboardLazyQueryHookResult = ReturnType<typeof useGetDigitSpanDashboardLazyQuery>;
export type GetDigitSpanDashboardQueryResult = ApolloReactCommon.QueryResult<GetDigitSpanDashboardQuery, GetDigitSpanDashboardQueryVariables>;
export const GetDigitSpanConfigDocument = gql`
    query getDigitSpanConfig {
  getTaskConfig(type: "DIGITSPAN") {
    time
    timed
    window
    trials
  }
}
    `;

/**
 * __useGetDigitSpanConfigQuery__
 *
 * To run a query within a React component, call `useGetDigitSpanConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDigitSpanConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDigitSpanConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDigitSpanConfigQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetDigitSpanConfigQuery, GetDigitSpanConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetDigitSpanConfigQuery, GetDigitSpanConfigQueryVariables>(GetDigitSpanConfigDocument, options);
      }
export function useGetDigitSpanConfigLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetDigitSpanConfigQuery, GetDigitSpanConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetDigitSpanConfigQuery, GetDigitSpanConfigQueryVariables>(GetDigitSpanConfigDocument, options);
        }
export type GetDigitSpanConfigQueryHookResult = ReturnType<typeof useGetDigitSpanConfigQuery>;
export type GetDigitSpanConfigLazyQueryHookResult = ReturnType<typeof useGetDigitSpanConfigLazyQuery>;
export type GetDigitSpanConfigQueryResult = ApolloReactCommon.QueryResult<GetDigitSpanConfigQuery, GetDigitSpanConfigQueryVariables>;
export const GetNBackDocument = gql`
    query getNBack($id: String!) {
  task(where: {id: $id}) {
    id
    name
    config {
      window
      trials
      timed
      time
    }
    stimulation {
      id
      program {
        id
      }
    }
    trials {
      id
      index
      stimulus
      expected
      interval
      response {
        id
        result
      }
    }
  }
}
    `;

/**
 * __useGetNBackQuery__
 *
 * To run a query within a React component, call `useGetNBackQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNBackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNBackQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNBackQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetNBackQuery, GetNBackQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetNBackQuery, GetNBackQueryVariables>(GetNBackDocument, options);
      }
export function useGetNBackLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNBackQuery, GetNBackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetNBackQuery, GetNBackQueryVariables>(GetNBackDocument, options);
        }
export type GetNBackQueryHookResult = ReturnType<typeof useGetNBackQuery>;
export type GetNBackLazyQueryHookResult = ReturnType<typeof useGetNBackLazyQuery>;
export type GetNBackQueryResult = ApolloReactCommon.QueryResult<GetNBackQuery, GetNBackQueryVariables>;
export const RespondNBackDocument = gql`
    mutation respondNBack($trial: ID!, $response: String, $result: Boolean!, $presentedAt: String!, $respondedAt: String!) {
  respondTask(
    trial: $trial
    response: $response
    result: $result
    presentedAt: $presentedAt
    respondedAt: $respondedAt
  ) {
    id
  }
}
    `;
export type RespondNBackMutationFn = ApolloReactCommon.MutationFunction<RespondNBackMutation, RespondNBackMutationVariables>;

/**
 * __useRespondNBackMutation__
 *
 * To run a mutation, you first call `useRespondNBackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRespondNBackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [respondNBackMutation, { data, loading, error }] = useRespondNBackMutation({
 *   variables: {
 *      trial: // value for 'trial'
 *      response: // value for 'response'
 *      result: // value for 'result'
 *      presentedAt: // value for 'presentedAt'
 *      respondedAt: // value for 'respondedAt'
 *   },
 * });
 */
export function useRespondNBackMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RespondNBackMutation, RespondNBackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RespondNBackMutation, RespondNBackMutationVariables>(RespondNBackDocument, options);
      }
export type RespondNBackMutationHookResult = ReturnType<typeof useRespondNBackMutation>;
export type RespondNBackMutationResult = ApolloReactCommon.MutationResult<RespondNBackMutation>;
export type RespondNBackMutationOptions = ApolloReactCommon.BaseMutationOptions<RespondNBackMutation, RespondNBackMutationVariables>;
export const GetNBackScoreDocument = gql`
    query getNBackScore($id: String!) {
  task(where: {id: $id}) {
    id
    score {
      time
      accuracy
    }
  }
}
    `;

/**
 * __useGetNBackScoreQuery__
 *
 * To run a query within a React component, call `useGetNBackScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNBackScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNBackScoreQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetNBackScoreQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetNBackScoreQuery, GetNBackScoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetNBackScoreQuery, GetNBackScoreQueryVariables>(GetNBackScoreDocument, options);
      }
export function useGetNBackScoreLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNBackScoreQuery, GetNBackScoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetNBackScoreQuery, GetNBackScoreQueryVariables>(GetNBackScoreDocument, options);
        }
export type GetNBackScoreQueryHookResult = ReturnType<typeof useGetNBackScoreQuery>;
export type GetNBackScoreLazyQueryHookResult = ReturnType<typeof useGetNBackScoreLazyQuery>;
export type GetNBackScoreQueryResult = ApolloReactCommon.QueryResult<GetNBackScoreQuery, GetNBackScoreQueryVariables>;
export const GetNBackDashboardDocument = gql`
    query getNBackDashboard($take: Int) {
  tasks(
    where: {type: {equals: NBACK}, completed: {equals: true}}
    orderBy: {createdAt: desc}
    take: $take
  ) {
    id
    type
    name
    createdAt
    user {
      email
    }
    config {
      id
      window
      trials
      time
      timed
    }
    score {
      accuracy
      time
    }
    metric {
      id
      epochs {
        id
      }
      events {
        id
      }
    }
  }
}
    `;

/**
 * __useGetNBackDashboardQuery__
 *
 * To run a query within a React component, call `useGetNBackDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNBackDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNBackDashboardQuery({
 *   variables: {
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetNBackDashboardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNBackDashboardQuery, GetNBackDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetNBackDashboardQuery, GetNBackDashboardQueryVariables>(GetNBackDashboardDocument, options);
      }
export function useGetNBackDashboardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNBackDashboardQuery, GetNBackDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetNBackDashboardQuery, GetNBackDashboardQueryVariables>(GetNBackDashboardDocument, options);
        }
export type GetNBackDashboardQueryHookResult = ReturnType<typeof useGetNBackDashboardQuery>;
export type GetNBackDashboardLazyQueryHookResult = ReturnType<typeof useGetNBackDashboardLazyQuery>;
export type GetNBackDashboardQueryResult = ApolloReactCommon.QueryResult<GetNBackDashboardQuery, GetNBackDashboardQueryVariables>;
export const GetNBackConfigDocument = gql`
    query getNBackConfig {
  getTaskConfig(type: "NBACK") {
    time
    timed
    trials
    window
  }
}
    `;

/**
 * __useGetNBackConfigQuery__
 *
 * To run a query within a React component, call `useGetNBackConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNBackConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNBackConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNBackConfigQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetNBackConfigQuery, GetNBackConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetNBackConfigQuery, GetNBackConfigQueryVariables>(GetNBackConfigDocument, options);
      }
export function useGetNBackConfigLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetNBackConfigQuery, GetNBackConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetNBackConfigQuery, GetNBackConfigQueryVariables>(GetNBackConfigDocument, options);
        }
export type GetNBackConfigQueryHookResult = ReturnType<typeof useGetNBackConfigQuery>;
export type GetNBackConfigLazyQueryHookResult = ReturnType<typeof useGetNBackConfigLazyQuery>;
export type GetNBackConfigQueryResult = ApolloReactCommon.QueryResult<GetNBackConfigQuery, GetNBackConfigQueryVariables>;
export const GetEegIdsDocument = gql`
    query getEEGIds($id: String!) {
  eegs(where: {metricId: {equals: $id}}, orderBy: {startTime: asc}) {
    id
    startTime
  }
}
    `;

/**
 * __useGetEegIdsQuery__
 *
 * To run a query within a React component, call `useGetEegIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEegIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEegIdsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetEegIdsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetEegIdsQuery, GetEegIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetEegIdsQuery, GetEegIdsQueryVariables>(GetEegIdsDocument, options);
      }
export function useGetEegIdsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEegIdsQuery, GetEegIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetEegIdsQuery, GetEegIdsQueryVariables>(GetEegIdsDocument, options);
        }
export type GetEegIdsQueryHookResult = ReturnType<typeof useGetEegIdsQuery>;
export type GetEegIdsLazyQueryHookResult = ReturnType<typeof useGetEegIdsLazyQuery>;
export type GetEegIdsQueryResult = ApolloReactCommon.QueryResult<GetEegIdsQuery, GetEegIdsQueryVariables>;
export const GetEegPlayBackDocument = gql`
    query getEEGPlayBack($id: String!, $take: Int, $skip: Int, $order: SortOrder!) {
  eegs(
    where: {metricId: {equals: $id}}
    take: $take
    skip: $skip
    orderBy: {startTime: $order}
  ) {
    id
    metric {
      id
      session {
        id
        type
        startTime
        device {
          id
          type
        }
      }
    }
    samplingRate
    startTime
    bufferDuration
    bufferInterval
    packetLossIndex
    packetLossSkipped
    samples {
      id
      channel
      data
    }
  }
}
    `;

/**
 * __useGetEegPlayBackQuery__
 *
 * To run a query within a React component, call `useGetEegPlayBackQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEegPlayBackQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEegPlayBackQuery({
 *   variables: {
 *      id: // value for 'id'
 *      take: // value for 'take'
 *      skip: // value for 'skip'
 *      order: // value for 'order'
 *   },
 * });
 */
export function useGetEegPlayBackQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetEegPlayBackQuery, GetEegPlayBackQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetEegPlayBackQuery, GetEegPlayBackQueryVariables>(GetEegPlayBackDocument, options);
      }
export function useGetEegPlayBackLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetEegPlayBackQuery, GetEegPlayBackQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetEegPlayBackQuery, GetEegPlayBackQueryVariables>(GetEegPlayBackDocument, options);
        }
export type GetEegPlayBackQueryHookResult = ReturnType<typeof useGetEegPlayBackQuery>;
export type GetEegPlayBackLazyQueryHookResult = ReturnType<typeof useGetEegPlayBackLazyQuery>;
export type GetEegPlayBackQueryResult = ApolloReactCommon.QueryResult<GetEegPlayBackQuery, GetEegPlayBackQueryVariables>;
export const StartRecordingDocument = gql`
    mutation startRecording($type: String!, $startTime: String!, $metric: String, $deviceType: String) {
  startSession(
    type: $type
    startTime: $startTime
    metric: $metric
    deviceType: $deviceType
  ) {
    id
    type
    metric {
      id
    }
    device {
      id
    }
  }
}
    `;
export type StartRecordingMutationFn = ApolloReactCommon.MutationFunction<StartRecordingMutation, StartRecordingMutationVariables>;

/**
 * __useStartRecordingMutation__
 *
 * To run a mutation, you first call `useStartRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startRecordingMutation, { data, loading, error }] = useStartRecordingMutation({
 *   variables: {
 *      type: // value for 'type'
 *      startTime: // value for 'startTime'
 *      metric: // value for 'metric'
 *      deviceType: // value for 'deviceType'
 *   },
 * });
 */
export function useStartRecordingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StartRecordingMutation, StartRecordingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<StartRecordingMutation, StartRecordingMutationVariables>(StartRecordingDocument, options);
      }
export type StartRecordingMutationHookResult = ReturnType<typeof useStartRecordingMutation>;
export type StartRecordingMutationResult = ApolloReactCommon.MutationResult<StartRecordingMutation>;
export type StartRecordingMutationOptions = ApolloReactCommon.BaseMutationOptions<StartRecordingMutation, StartRecordingMutationVariables>;
export const StopRecordingDocument = gql`
    mutation stopRecording($id: String!, $stopTime: String!) {
  endSession(id: $id, stopTime: $stopTime) {
    id
  }
}
    `;
export type StopRecordingMutationFn = ApolloReactCommon.MutationFunction<StopRecordingMutation, StopRecordingMutationVariables>;

/**
 * __useStopRecordingMutation__
 *
 * To run a mutation, you first call `useStopRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStopRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [stopRecordingMutation, { data, loading, error }] = useStopRecordingMutation({
 *   variables: {
 *      id: // value for 'id'
 *      stopTime: // value for 'stopTime'
 *   },
 * });
 */
export function useStopRecordingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StopRecordingMutation, StopRecordingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<StopRecordingMutation, StopRecordingMutationVariables>(StopRecordingDocument, options);
      }
export type StopRecordingMutationHookResult = ReturnType<typeof useStopRecordingMutation>;
export type StopRecordingMutationResult = ApolloReactCommon.MutationResult<StopRecordingMutation>;
export type StopRecordingMutationOptions = ApolloReactCommon.BaseMutationOptions<StopRecordingMutation, StopRecordingMutationVariables>;
export const GetRecordingDocument = gql`
    query getRecording($id: String!) {
  session(where: {id: $id}) {
    id
    startTime
    type
  }
}
    `;

/**
 * __useGetRecordingQuery__
 *
 * To run a query within a React component, call `useGetRecordingQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecordingQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecordingQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRecordingQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetRecordingQuery, GetRecordingQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetRecordingQuery, GetRecordingQueryVariables>(GetRecordingDocument, options);
      }
export function useGetRecordingLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRecordingQuery, GetRecordingQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetRecordingQuery, GetRecordingQueryVariables>(GetRecordingDocument, options);
        }
export type GetRecordingQueryHookResult = ReturnType<typeof useGetRecordingQuery>;
export type GetRecordingLazyQueryHookResult = ReturnType<typeof useGetRecordingLazyQuery>;
export type GetRecordingQueryResult = ApolloReactCommon.QueryResult<GetRecordingQuery, GetRecordingQueryVariables>;
export const DeleteRecordingDocument = gql`
    mutation deleteRecording($where: SessionWhereUniqueInput!) {
  deleteSession(where: $where) {
    id
    startTime
    type
  }
}
    `;
export type DeleteRecordingMutationFn = ApolloReactCommon.MutationFunction<DeleteRecordingMutation, DeleteRecordingMutationVariables>;

/**
 * __useDeleteRecordingMutation__
 *
 * To run a mutation, you first call `useDeleteRecordingMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteRecordingMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteRecordingMutation, { data, loading, error }] = useDeleteRecordingMutation({
 *   variables: {
 *      where: // value for 'where'
 *   },
 * });
 */
export function useDeleteRecordingMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteRecordingMutation, DeleteRecordingMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteRecordingMutation, DeleteRecordingMutationVariables>(DeleteRecordingDocument, options);
      }
export type DeleteRecordingMutationHookResult = ReturnType<typeof useDeleteRecordingMutation>;
export type DeleteRecordingMutationResult = ApolloReactCommon.MutationResult<DeleteRecordingMutation>;
export type DeleteRecordingMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteRecordingMutation, DeleteRecordingMutationVariables>;
export const GetRecordingsDocument = gql`
    query getRecordings($take: Int) {
  sessions(orderBy: {createdAt: desc}, take: $take) {
    id
    startTime
    metric {
      id
    }
  }
}
    `;

/**
 * __useGetRecordingsQuery__
 *
 * To run a query within a React component, call `useGetRecordingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecordingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecordingsQuery({
 *   variables: {
 *      take: // value for 'take'
 *   },
 * });
 */
export function useGetRecordingsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetRecordingsQuery, GetRecordingsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetRecordingsQuery, GetRecordingsQueryVariables>(GetRecordingsDocument, options);
      }
export function useGetRecordingsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRecordingsQuery, GetRecordingsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetRecordingsQuery, GetRecordingsQueryVariables>(GetRecordingsDocument, options);
        }
export type GetRecordingsQueryHookResult = ReturnType<typeof useGetRecordingsQuery>;
export type GetRecordingsLazyQueryHookResult = ReturnType<typeof useGetRecordingsLazyQuery>;
export type GetRecordingsQueryResult = ApolloReactCommon.QueryResult<GetRecordingsQuery, GetRecordingsQueryVariables>;
export const GetSessionInfoDocument = gql`
    query getSessionInfo($metricId: String!) {
  bioMetric(where: {id: $metricId}) {
    id
    user {
      id
      email
    }
    session {
      id
      type
      description
      tags
      device {
        type
        name
      }
    }
    task {
      id
      completed
      comment
      score {
        correct
        wrong
        accuracy
        time
      }
      duration
      config {
        id
        window
        trials
        time
        timed
      }
    }
    epochs {
      id
      startTime
      packetLossIndex
      samplingRate
    }
    events {
      id
    }
    createdAt
  }
}
    `;

/**
 * __useGetSessionInfoQuery__
 *
 * To run a query within a React component, call `useGetSessionInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionInfoQuery({
 *   variables: {
 *      metricId: // value for 'metricId'
 *   },
 * });
 */
export function useGetSessionInfoQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetSessionInfoQuery, GetSessionInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetSessionInfoQuery, GetSessionInfoQueryVariables>(GetSessionInfoDocument, options);
      }
export function useGetSessionInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSessionInfoQuery, GetSessionInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetSessionInfoQuery, GetSessionInfoQueryVariables>(GetSessionInfoDocument, options);
        }
export type GetSessionInfoQueryHookResult = ReturnType<typeof useGetSessionInfoQuery>;
export type GetSessionInfoLazyQueryHookResult = ReturnType<typeof useGetSessionInfoLazyQuery>;
export type GetSessionInfoQueryResult = ApolloReactCommon.QueryResult<GetSessionInfoQuery, GetSessionInfoQueryVariables>;
export const UpdateSessionInfoDocument = gql`
    mutation updateSessionInfo($id: String!, $comment: String) {
  updateBioMetric(
    where: {id: $id}
    data: {session: {update: {description: {set: $comment}}}}
  ) {
    id
    session {
      id
      description
    }
  }
}
    `;
export type UpdateSessionInfoMutationFn = ApolloReactCommon.MutationFunction<UpdateSessionInfoMutation, UpdateSessionInfoMutationVariables>;

/**
 * __useUpdateSessionInfoMutation__
 *
 * To run a mutation, you first call `useUpdateSessionInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSessionInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSessionInfoMutation, { data, loading, error }] = useUpdateSessionInfoMutation({
 *   variables: {
 *      id: // value for 'id'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useUpdateSessionInfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateSessionInfoMutation, UpdateSessionInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateSessionInfoMutation, UpdateSessionInfoMutationVariables>(UpdateSessionInfoDocument, options);
      }
export type UpdateSessionInfoMutationHookResult = ReturnType<typeof useUpdateSessionInfoMutation>;
export type UpdateSessionInfoMutationResult = ApolloReactCommon.MutationResult<UpdateSessionInfoMutation>;
export type UpdateSessionInfoMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateSessionInfoMutation, UpdateSessionInfoMutationVariables>;
export const GetSessionDynamicsDocument = gql`
    query getSessionDynamics($id: String!) {
  eegs(where: {metricId: {equals: $id}}, orderBy: {startTime: asc}) {
    startTime
  }
}
    `;

/**
 * __useGetSessionDynamicsQuery__
 *
 * To run a query within a React component, call `useGetSessionDynamicsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSessionDynamicsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSessionDynamicsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSessionDynamicsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetSessionDynamicsQuery, GetSessionDynamicsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetSessionDynamicsQuery, GetSessionDynamicsQueryVariables>(GetSessionDynamicsDocument, options);
      }
export function useGetSessionDynamicsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSessionDynamicsQuery, GetSessionDynamicsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetSessionDynamicsQuery, GetSessionDynamicsQueryVariables>(GetSessionDynamicsDocument, options);
        }
export type GetSessionDynamicsQueryHookResult = ReturnType<typeof useGetSessionDynamicsQuery>;
export type GetSessionDynamicsLazyQueryHookResult = ReturnType<typeof useGetSessionDynamicsLazyQuery>;
export type GetSessionDynamicsQueryResult = ApolloReactCommon.QueryResult<GetSessionDynamicsQuery, GetSessionDynamicsQueryVariables>;
export const DeleteSessionDocument = gql`
    mutation deleteSession($metricId: String!) {
  deleteBioMetric(metricId: $metricId)
}
    `;
export type DeleteSessionMutationFn = ApolloReactCommon.MutationFunction<DeleteSessionMutation, DeleteSessionMutationVariables>;

/**
 * __useDeleteSessionMutation__
 *
 * To run a mutation, you first call `useDeleteSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSessionMutation, { data, loading, error }] = useDeleteSessionMutation({
 *   variables: {
 *      metricId: // value for 'metricId'
 *   },
 * });
 */
export function useDeleteSessionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteSessionMutation, DeleteSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteSessionMutation, DeleteSessionMutationVariables>(DeleteSessionDocument, options);
      }
export type DeleteSessionMutationHookResult = ReturnType<typeof useDeleteSessionMutation>;
export type DeleteSessionMutationResult = ApolloReactCommon.MutationResult<DeleteSessionMutation>;
export type DeleteSessionMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteSessionMutation, DeleteSessionMutationVariables>;
export const StartSessionDocument = gql`
    mutation startSession($type: String!, $program: String!, $startTime: String!, $metric: String, $deviceType: String) {
  startSession(
    type: $type
    program: $program
    startTime: $startTime
    metric: $metric
    deviceType: $deviceType
  ) {
    id
    metric {
      id
    }
  }
}
    `;
export type StartSessionMutationFn = ApolloReactCommon.MutationFunction<StartSessionMutation, StartSessionMutationVariables>;

/**
 * __useStartSessionMutation__
 *
 * To run a mutation, you first call `useStartSessionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartSessionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startSessionMutation, { data, loading, error }] = useStartSessionMutation({
 *   variables: {
 *      type: // value for 'type'
 *      program: // value for 'program'
 *      startTime: // value for 'startTime'
 *      metric: // value for 'metric'
 *      deviceType: // value for 'deviceType'
 *   },
 * });
 */
export function useStartSessionMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StartSessionMutation, StartSessionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<StartSessionMutation, StartSessionMutationVariables>(StartSessionDocument, options);
      }
export type StartSessionMutationHookResult = ReturnType<typeof useStartSessionMutation>;
export type StartSessionMutationResult = ApolloReactCommon.MutationResult<StartSessionMutation>;
export type StartSessionMutationOptions = ApolloReactCommon.BaseMutationOptions<StartSessionMutation, StartSessionMutationVariables>;
export const GetSternbergDocument = gql`
    query getSternberg($id: String!) {
  task(where: {id: $id}) {
    id
    name
    config {
      window
      trials
      timed
      time
    }
    trials {
      id
      index
      stimulus
      critical
      interval
      response {
        id
        result
      }
    }
  }
}
    `;

/**
 * __useGetSternbergQuery__
 *
 * To run a query within a React component, call `useGetSternbergQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSternbergQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSternbergQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSternbergQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetSternbergQuery, GetSternbergQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetSternbergQuery, GetSternbergQueryVariables>(GetSternbergDocument, options);
      }
export function useGetSternbergLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSternbergQuery, GetSternbergQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetSternbergQuery, GetSternbergQueryVariables>(GetSternbergDocument, options);
        }
export type GetSternbergQueryHookResult = ReturnType<typeof useGetSternbergQuery>;
export type GetSternbergLazyQueryHookResult = ReturnType<typeof useGetSternbergLazyQuery>;
export type GetSternbergQueryResult = ApolloReactCommon.QueryResult<GetSternbergQuery, GetSternbergQueryVariables>;
export const RespondSternbergDocument = gql`
    mutation respondSternberg($trial: ID!, $response: String, $result: Boolean!, $presentedAt: String!, $respondedAt: String!) {
  respondTask(
    trial: $trial
    response: $response
    result: $result
    presentedAt: $presentedAt
    respondedAt: $respondedAt
  ) {
    id
  }
}
    `;
export type RespondSternbergMutationFn = ApolloReactCommon.MutationFunction<RespondSternbergMutation, RespondSternbergMutationVariables>;

/**
 * __useRespondSternbergMutation__
 *
 * To run a mutation, you first call `useRespondSternbergMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRespondSternbergMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [respondSternbergMutation, { data, loading, error }] = useRespondSternbergMutation({
 *   variables: {
 *      trial: // value for 'trial'
 *      response: // value for 'response'
 *      result: // value for 'result'
 *      presentedAt: // value for 'presentedAt'
 *      respondedAt: // value for 'respondedAt'
 *   },
 * });
 */
export function useRespondSternbergMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RespondSternbergMutation, RespondSternbergMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RespondSternbergMutation, RespondSternbergMutationVariables>(RespondSternbergDocument, options);
      }
export type RespondSternbergMutationHookResult = ReturnType<typeof useRespondSternbergMutation>;
export type RespondSternbergMutationResult = ApolloReactCommon.MutationResult<RespondSternbergMutation>;
export type RespondSternbergMutationOptions = ApolloReactCommon.BaseMutationOptions<RespondSternbergMutation, RespondSternbergMutationVariables>;
export const GetSternbergScoreDocument = gql`
    query getSternbergScore($id: String!) {
  task(where: {id: $id}) {
    id
    score {
      accuracy
      correct
      wrong
      time
    }
  }
}
    `;

/**
 * __useGetSternbergScoreQuery__
 *
 * To run a query within a React component, call `useGetSternbergScoreQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSternbergScoreQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSternbergScoreQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSternbergScoreQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetSternbergScoreQuery, GetSternbergScoreQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetSternbergScoreQuery, GetSternbergScoreQueryVariables>(GetSternbergScoreDocument, options);
      }
export function useGetSternbergScoreLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSternbergScoreQuery, GetSternbergScoreQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetSternbergScoreQuery, GetSternbergScoreQueryVariables>(GetSternbergScoreDocument, options);
        }
export type GetSternbergScoreQueryHookResult = ReturnType<typeof useGetSternbergScoreQuery>;
export type GetSternbergScoreLazyQueryHookResult = ReturnType<typeof useGetSternbergScoreLazyQuery>;
export type GetSternbergScoreQueryResult = ApolloReactCommon.QueryResult<GetSternbergScoreQuery, GetSternbergScoreQueryVariables>;
export const GetSternbergDashboardDocument = gql`
    query getSternbergDashboard {
  tasks(
    where: {type: {equals: STERNBERG}, completed: {equals: true}}
    orderBy: {createdAt: desc}
  ) {
    id
    type
    name
    duration
    createdAt
    config {
      id
      window
      trials
      time
      timed
    }
    score {
      accuracy
      time
    }
    metric {
      id
      epochs {
        id
      }
      events {
        id
      }
    }
  }
}
    `;

/**
 * __useGetSternbergDashboardQuery__
 *
 * To run a query within a React component, call `useGetSternbergDashboardQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSternbergDashboardQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSternbergDashboardQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSternbergDashboardQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSternbergDashboardQuery, GetSternbergDashboardQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetSternbergDashboardQuery, GetSternbergDashboardQueryVariables>(GetSternbergDashboardDocument, options);
      }
export function useGetSternbergDashboardLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSternbergDashboardQuery, GetSternbergDashboardQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetSternbergDashboardQuery, GetSternbergDashboardQueryVariables>(GetSternbergDashboardDocument, options);
        }
export type GetSternbergDashboardQueryHookResult = ReturnType<typeof useGetSternbergDashboardQuery>;
export type GetSternbergDashboardLazyQueryHookResult = ReturnType<typeof useGetSternbergDashboardLazyQuery>;
export type GetSternbergDashboardQueryResult = ApolloReactCommon.QueryResult<GetSternbergDashboardQuery, GetSternbergDashboardQueryVariables>;
export const GetSternbergConfigDocument = gql`
    query getSternbergConfig {
  getTaskConfig(type: "STERNBERG") {
    time
    timed
    trials
    window
  }
}
    `;

/**
 * __useGetSternbergConfigQuery__
 *
 * To run a query within a React component, call `useGetSternbergConfigQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSternbergConfigQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSternbergConfigQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSternbergConfigQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetSternbergConfigQuery, GetSternbergConfigQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetSternbergConfigQuery, GetSternbergConfigQueryVariables>(GetSternbergConfigDocument, options);
      }
export function useGetSternbergConfigLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetSternbergConfigQuery, GetSternbergConfigQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetSternbergConfigQuery, GetSternbergConfigQueryVariables>(GetSternbergConfigDocument, options);
        }
export type GetSternbergConfigQueryHookResult = ReturnType<typeof useGetSternbergConfigQuery>;
export type GetSternbergConfigLazyQueryHookResult = ReturnType<typeof useGetSternbergConfigLazyQuery>;
export type GetSternbergConfigQueryResult = ApolloReactCommon.QueryResult<GetSternbergConfigQuery, GetSternbergConfigQueryVariables>;
export const GetStimulationProgramsDocument = gql`
    query getStimulationPrograms {
  stimulationPrograms {
    id
    frequency1
    frequency2
    coils
    intensity
    code
    name
    description
    usage
  }
}
    `;

/**
 * __useGetStimulationProgramsQuery__
 *
 * To run a query within a React component, call `useGetStimulationProgramsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStimulationProgramsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStimulationProgramsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStimulationProgramsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStimulationProgramsQuery, GetStimulationProgramsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetStimulationProgramsQuery, GetStimulationProgramsQueryVariables>(GetStimulationProgramsDocument, options);
      }
export function useGetStimulationProgramsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStimulationProgramsQuery, GetStimulationProgramsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetStimulationProgramsQuery, GetStimulationProgramsQueryVariables>(GetStimulationProgramsDocument, options);
        }
export type GetStimulationProgramsQueryHookResult = ReturnType<typeof useGetStimulationProgramsQuery>;
export type GetStimulationProgramsLazyQueryHookResult = ReturnType<typeof useGetStimulationProgramsLazyQuery>;
export type GetStimulationProgramsQueryResult = ApolloReactCommon.QueryResult<GetStimulationProgramsQuery, GetStimulationProgramsQueryVariables>;
export const GetStimulationProgramDocument = gql`
    query getStimulationProgram($id: String) {
  stimulationProgram(where: {id: $id}) {
    id
    name
    description
    coils
    frequency1
    frequency2
    intensity
    usage
    stimulations {
      id
    }
  }
}
    `;

/**
 * __useGetStimulationProgramQuery__
 *
 * To run a query within a React component, call `useGetStimulationProgramQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStimulationProgramQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStimulationProgramQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetStimulationProgramQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetStimulationProgramQuery, GetStimulationProgramQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetStimulationProgramQuery, GetStimulationProgramQueryVariables>(GetStimulationProgramDocument, options);
      }
export function useGetStimulationProgramLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetStimulationProgramQuery, GetStimulationProgramQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetStimulationProgramQuery, GetStimulationProgramQueryVariables>(GetStimulationProgramDocument, options);
        }
export type GetStimulationProgramQueryHookResult = ReturnType<typeof useGetStimulationProgramQuery>;
export type GetStimulationProgramLazyQueryHookResult = ReturnType<typeof useGetStimulationProgramLazyQuery>;
export type GetStimulationProgramQueryResult = ApolloReactCommon.QueryResult<GetStimulationProgramQuery, GetStimulationProgramQueryVariables>;
export const StartStimulationDocument = gql`
    mutation startStimulation($type: String!, $program: String!, $startTime: String!, $deviceType: String) {
  startSession(
    type: $type
    program: $program
    startTime: $startTime
    deviceType: $deviceType
  ) {
    id
  }
}
    `;
export type StartStimulationMutationFn = ApolloReactCommon.MutationFunction<StartStimulationMutation, StartStimulationMutationVariables>;

/**
 * __useStartStimulationMutation__
 *
 * To run a mutation, you first call `useStartStimulationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartStimulationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startStimulationMutation, { data, loading, error }] = useStartStimulationMutation({
 *   variables: {
 *      type: // value for 'type'
 *      program: // value for 'program'
 *      startTime: // value for 'startTime'
 *      deviceType: // value for 'deviceType'
 *   },
 * });
 */
export function useStartStimulationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StartStimulationMutation, StartStimulationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<StartStimulationMutation, StartStimulationMutationVariables>(StartStimulationDocument, options);
      }
export type StartStimulationMutationHookResult = ReturnType<typeof useStartStimulationMutation>;
export type StartStimulationMutationResult = ApolloReactCommon.MutationResult<StartStimulationMutation>;
export type StartStimulationMutationOptions = ApolloReactCommon.BaseMutationOptions<StartStimulationMutation, StartStimulationMutationVariables>;
export const CreateStimulationDocument = gql`
    mutation createStimulation($program: String!) {
  createStimulation(data: {program: {connect: {id: $program}}}) {
    id
  }
}
    `;
export type CreateStimulationMutationFn = ApolloReactCommon.MutationFunction<CreateStimulationMutation, CreateStimulationMutationVariables>;

/**
 * __useCreateStimulationMutation__
 *
 * To run a mutation, you first call `useCreateStimulationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStimulationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStimulationMutation, { data, loading, error }] = useCreateStimulationMutation({
 *   variables: {
 *      program: // value for 'program'
 *   },
 * });
 */
export function useCreateStimulationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStimulationMutation, CreateStimulationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateStimulationMutation, CreateStimulationMutationVariables>(CreateStimulationDocument, options);
      }
export type CreateStimulationMutationHookResult = ReturnType<typeof useCreateStimulationMutation>;
export type CreateStimulationMutationResult = ApolloReactCommon.MutationResult<CreateStimulationMutation>;
export type CreateStimulationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStimulationMutation, CreateStimulationMutationVariables>;
export const AddTaskStimulationDocument = gql`
    mutation addTaskStimulation($task: String!, $stimulation: String!) {
  updateTask(
    data: {stimulation: {connect: {id: $stimulation}}}
    where: {id: $task}
  ) {
    id
  }
}
    `;
export type AddTaskStimulationMutationFn = ApolloReactCommon.MutationFunction<AddTaskStimulationMutation, AddTaskStimulationMutationVariables>;

/**
 * __useAddTaskStimulationMutation__
 *
 * To run a mutation, you first call `useAddTaskStimulationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTaskStimulationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTaskStimulationMutation, { data, loading, error }] = useAddTaskStimulationMutation({
 *   variables: {
 *      task: // value for 'task'
 *      stimulation: // value for 'stimulation'
 *   },
 * });
 */
export function useAddTaskStimulationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<AddTaskStimulationMutation, AddTaskStimulationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<AddTaskStimulationMutation, AddTaskStimulationMutationVariables>(AddTaskStimulationDocument, options);
      }
export type AddTaskStimulationMutationHookResult = ReturnType<typeof useAddTaskStimulationMutation>;
export type AddTaskStimulationMutationResult = ApolloReactCommon.MutationResult<AddTaskStimulationMutation>;
export type AddTaskStimulationMutationOptions = ApolloReactCommon.BaseMutationOptions<AddTaskStimulationMutation, AddTaskStimulationMutationVariables>;
export const CreateStimulationProgramDocument = gql`
    mutation createStimulationProgram($name: String!, $description: String!) {
  createStimulationProgram(
    data: {name: $name, description: $description, coils: {set: [0, 0, 0, 0, 0]}}
  ) {
    id
  }
}
    `;
export type CreateStimulationProgramMutationFn = ApolloReactCommon.MutationFunction<CreateStimulationProgramMutation, CreateStimulationProgramMutationVariables>;

/**
 * __useCreateStimulationProgramMutation__
 *
 * To run a mutation, you first call `useCreateStimulationProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateStimulationProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createStimulationProgramMutation, { data, loading, error }] = useCreateStimulationProgramMutation({
 *   variables: {
 *      name: // value for 'name'
 *      description: // value for 'description'
 *   },
 * });
 */
export function useCreateStimulationProgramMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateStimulationProgramMutation, CreateStimulationProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateStimulationProgramMutation, CreateStimulationProgramMutationVariables>(CreateStimulationProgramDocument, options);
      }
export type CreateStimulationProgramMutationHookResult = ReturnType<typeof useCreateStimulationProgramMutation>;
export type CreateStimulationProgramMutationResult = ApolloReactCommon.MutationResult<CreateStimulationProgramMutation>;
export type CreateStimulationProgramMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateStimulationProgramMutation, CreateStimulationProgramMutationVariables>;
export const UpdateStimulationProgramDocument = gql`
    mutation updateStimulationProgram($id: String!, $coils: [Int!], $frequency1: [Float!], $frequency2: [Float!], $intensity: [Float!]) {
  updateStimulationProgram(
    data: {coils: {set: $coils}, frequency1: {set: $frequency1}, frequency2: {set: $frequency2}, intensity: {set: $intensity}}
    where: {id: $id}
  ) {
    id
  }
}
    `;
export type UpdateStimulationProgramMutationFn = ApolloReactCommon.MutationFunction<UpdateStimulationProgramMutation, UpdateStimulationProgramMutationVariables>;

/**
 * __useUpdateStimulationProgramMutation__
 *
 * To run a mutation, you first call `useUpdateStimulationProgramMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStimulationProgramMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStimulationProgramMutation, { data, loading, error }] = useUpdateStimulationProgramMutation({
 *   variables: {
 *      id: // value for 'id'
 *      coils: // value for 'coils'
 *      frequency1: // value for 'frequency1'
 *      frequency2: // value for 'frequency2'
 *      intensity: // value for 'intensity'
 *   },
 * });
 */
export function useUpdateStimulationProgramMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateStimulationProgramMutation, UpdateStimulationProgramMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateStimulationProgramMutation, UpdateStimulationProgramMutationVariables>(UpdateStimulationProgramDocument, options);
      }
export type UpdateStimulationProgramMutationHookResult = ReturnType<typeof useUpdateStimulationProgramMutation>;
export type UpdateStimulationProgramMutationResult = ApolloReactCommon.MutationResult<UpdateStimulationProgramMutation>;
export type UpdateStimulationProgramMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateStimulationProgramMutation, UpdateStimulationProgramMutationVariables>;
export const EndTaskDocument = gql`
    mutation endTask($id: ID!, $completed: Boolean!, $comment: String) {
  endTask(id: $id, completed: $completed, comment: $comment) {
    id
  }
}
    `;
export type EndTaskMutationFn = ApolloReactCommon.MutationFunction<EndTaskMutation, EndTaskMutationVariables>;

/**
 * __useEndTaskMutation__
 *
 * To run a mutation, you first call `useEndTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEndTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [endTaskMutation, { data, loading, error }] = useEndTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      completed: // value for 'completed'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useEndTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<EndTaskMutation, EndTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<EndTaskMutation, EndTaskMutationVariables>(EndTaskDocument, options);
      }
export type EndTaskMutationHookResult = ReturnType<typeof useEndTaskMutation>;
export type EndTaskMutationResult = ApolloReactCommon.MutationResult<EndTaskMutation>;
export type EndTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<EndTaskMutation, EndTaskMutationVariables>;
export const DeleteTaskDocument = gql`
    mutation deleteTask($taskId: String!) {
  deleteTask(where: {id: $taskId}) {
    id
  }
}
    `;
export type DeleteTaskMutationFn = ApolloReactCommon.MutationFunction<DeleteTaskMutation, DeleteTaskMutationVariables>;

/**
 * __useDeleteTaskMutation__
 *
 * To run a mutation, you first call `useDeleteTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskMutation, { data, loading, error }] = useDeleteTaskMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useDeleteTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<DeleteTaskMutation, DeleteTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<DeleteTaskMutation, DeleteTaskMutationVariables>(DeleteTaskDocument, options);
      }
export type DeleteTaskMutationHookResult = ReturnType<typeof useDeleteTaskMutation>;
export type DeleteTaskMutationResult = ApolloReactCommon.MutationResult<DeleteTaskMutation>;
export type DeleteTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<DeleteTaskMutation, DeleteTaskMutationVariables>;
export const GetTaskInfoDocument = gql`
    query getTaskInfo($id: String!) {
  task(where: {id: $id}) {
    id
    type
    user {
      email
    }
    score {
      correct
      wrong
      accuracy
      time
    }
    config {
      window
      trials
      time
      timed
    }
    metric {
      id
    }
    completed
  }
}
    `;

/**
 * __useGetTaskInfoQuery__
 *
 * To run a query within a React component, call `useGetTaskInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskInfoQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetTaskInfoQuery, GetTaskInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetTaskInfoQuery, GetTaskInfoQueryVariables>(GetTaskInfoDocument, options);
      }
export function useGetTaskInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaskInfoQuery, GetTaskInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetTaskInfoQuery, GetTaskInfoQueryVariables>(GetTaskInfoDocument, options);
        }
export type GetTaskInfoQueryHookResult = ReturnType<typeof useGetTaskInfoQuery>;
export type GetTaskInfoLazyQueryHookResult = ReturnType<typeof useGetTaskInfoLazyQuery>;
export type GetTaskInfoQueryResult = ApolloReactCommon.QueryResult<GetTaskInfoQuery, GetTaskInfoQueryVariables>;
export const GetTaskTrialsDocument = gql`
    query getTaskTrials($id: String!) {
  task(where: {id: $id}) {
    id
    trials {
      id
      index
      interval
      response {
        response
        result
        presentedAt
        respondedAt
      }
      stimulus
    }
  }
}
    `;

/**
 * __useGetTaskTrialsQuery__
 *
 * To run a query within a React component, call `useGetTaskTrialsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskTrialsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskTrialsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskTrialsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetTaskTrialsQuery, GetTaskTrialsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetTaskTrialsQuery, GetTaskTrialsQueryVariables>(GetTaskTrialsDocument, options);
      }
export function useGetTaskTrialsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaskTrialsQuery, GetTaskTrialsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetTaskTrialsQuery, GetTaskTrialsQueryVariables>(GetTaskTrialsDocument, options);
        }
export type GetTaskTrialsQueryHookResult = ReturnType<typeof useGetTaskTrialsQuery>;
export type GetTaskTrialsLazyQueryHookResult = ReturnType<typeof useGetTaskTrialsLazyQuery>;
export type GetTaskTrialsQueryResult = ApolloReactCommon.QueryResult<GetTaskTrialsQuery, GetTaskTrialsQueryVariables>;
export const GetRestDocument = gql`
    query getRest($id: String!) {
  task(where: {id: $id}) {
    id
    type
    duration
  }
}
    `;

/**
 * __useGetRestQuery__
 *
 * To run a query within a React component, call `useGetRestQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRestQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRestQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetRestQuery, GetRestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetRestQuery, GetRestQueryVariables>(GetRestDocument, options);
      }
export function useGetRestLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetRestQuery, GetRestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetRestQuery, GetRestQueryVariables>(GetRestDocument, options);
        }
export type GetRestQueryHookResult = ReturnType<typeof useGetRestQuery>;
export type GetRestLazyQueryHookResult = ReturnType<typeof useGetRestLazyQuery>;
export type GetRestQueryResult = ApolloReactCommon.QueryResult<GetRestQuery, GetRestQueryVariables>;
export const UpdateTaskDocument = gql`
    mutation updateTask($id: String!, $startTime: Float, $endTime: Float) {
  updateTask(
    data: {startTime: {set: $startTime}, endTime: {set: $endTime}}
    where: {id: $id}
  ) {
    id
  }
}
    `;
export type UpdateTaskMutationFn = ApolloReactCommon.MutationFunction<UpdateTaskMutation, UpdateTaskMutationVariables>;

/**
 * __useUpdateTaskMutation__
 *
 * To run a mutation, you first call `useUpdateTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskMutation, { data, loading, error }] = useUpdateTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      startTime: // value for 'startTime'
 *      endTime: // value for 'endTime'
 *   },
 * });
 */
export function useUpdateTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateTaskMutation, UpdateTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<UpdateTaskMutation, UpdateTaskMutationVariables>(UpdateTaskDocument, options);
      }
export type UpdateTaskMutationHookResult = ReturnType<typeof useUpdateTaskMutation>;
export type UpdateTaskMutationResult = ApolloReactCommon.MutationResult<UpdateTaskMutation>;
export type UpdateTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateTaskMutation, UpdateTaskMutationVariables>;
export const StartTaskDocument = gql`
    mutation startTask($id: String!, $startTime: Float!) {
  updateTask(data: {startTime: {set: $startTime}}, where: {id: $id}) {
    id
  }
}
    `;
export type StartTaskMutationFn = ApolloReactCommon.MutationFunction<StartTaskMutation, StartTaskMutationVariables>;

/**
 * __useStartTaskMutation__
 *
 * To run a mutation, you first call `useStartTaskMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartTaskMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startTaskMutation, { data, loading, error }] = useStartTaskMutation({
 *   variables: {
 *      id: // value for 'id'
 *      startTime: // value for 'startTime'
 *   },
 * });
 */
export function useStartTaskMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StartTaskMutation, StartTaskMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<StartTaskMutation, StartTaskMutationVariables>(StartTaskDocument, options);
      }
export type StartTaskMutationHookResult = ReturnType<typeof useStartTaskMutation>;
export type StartTaskMutationResult = ApolloReactCommon.MutationResult<StartTaskMutation>;
export type StartTaskMutationOptions = ApolloReactCommon.BaseMutationOptions<StartTaskMutation, StartTaskMutationVariables>;
export const StartTaskWithMetricDocument = gql`
    mutation startTaskWithMetric($id: String!, $startTime: Float!, $metricId: String!) {
  updateTask(
    data: {startTime: {set: $startTime}, metric: {connect: {id: $metricId}}}
    where: {id: $id}
  ) {
    id
  }
}
    `;
export type StartTaskWithMetricMutationFn = ApolloReactCommon.MutationFunction<StartTaskWithMetricMutation, StartTaskWithMetricMutationVariables>;

/**
 * __useStartTaskWithMetricMutation__
 *
 * To run a mutation, you first call `useStartTaskWithMetricMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useStartTaskWithMetricMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [startTaskWithMetricMutation, { data, loading, error }] = useStartTaskWithMetricMutation({
 *   variables: {
 *      id: // value for 'id'
 *      startTime: // value for 'startTime'
 *      metricId: // value for 'metricId'
 *   },
 * });
 */
export function useStartTaskWithMetricMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<StartTaskWithMetricMutation, StartTaskWithMetricMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<StartTaskWithMetricMutation, StartTaskWithMetricMutationVariables>(StartTaskWithMetricDocument, options);
      }
export type StartTaskWithMetricMutationHookResult = ReturnType<typeof useStartTaskWithMetricMutation>;
export type StartTaskWithMetricMutationResult = ApolloReactCommon.MutationResult<StartTaskWithMetricMutation>;
export type StartTaskWithMetricMutationOptions = ApolloReactCommon.BaseMutationOptions<StartTaskWithMetricMutation, StartTaskWithMetricMutationVariables>;
export const InitTestDocument = gql`
    mutation initTest($type: String!, $trials: Int!, $window: Int!, $timed: Boolean!, $time: Int!, $metric: String, $battery: String, $stimulation: String) {
  initTest(
    type: $type
    trials: $trials
    window: $window
    timed: $timed
    time: $time
    metric: $metric
    battery: $battery
    stimulation: $stimulation
  ) {
    id
  }
}
    `;
export type InitTestMutationFn = ApolloReactCommon.MutationFunction<InitTestMutation, InitTestMutationVariables>;

/**
 * __useInitTestMutation__
 *
 * To run a mutation, you first call `useInitTestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitTestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initTestMutation, { data, loading, error }] = useInitTestMutation({
 *   variables: {
 *      type: // value for 'type'
 *      trials: // value for 'trials'
 *      window: // value for 'window'
 *      timed: // value for 'timed'
 *      time: // value for 'time'
 *      metric: // value for 'metric'
 *      battery: // value for 'battery'
 *      stimulation: // value for 'stimulation'
 *   },
 * });
 */
export function useInitTestMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InitTestMutation, InitTestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<InitTestMutation, InitTestMutationVariables>(InitTestDocument, options);
      }
export type InitTestMutationHookResult = ReturnType<typeof useInitTestMutation>;
export type InitTestMutationResult = ApolloReactCommon.MutationResult<InitTestMutation>;
export type InitTestMutationOptions = ApolloReactCommon.BaseMutationOptions<InitTestMutation, InitTestMutationVariables>;
export const InitBlockDocument = gql`
    mutation initBlock($type: String!, $name: String!, $duration: Int, $comment: String, $metric: String, $battery: String, $stimulation: String) {
  initBlock(
    type: $type
    name: $name
    duration: $duration
    comment: $comment
    metric: $metric
    battery: $battery
    stimulation: $stimulation
  ) {
    id
  }
}
    `;
export type InitBlockMutationFn = ApolloReactCommon.MutationFunction<InitBlockMutation, InitBlockMutationVariables>;

/**
 * __useInitBlockMutation__
 *
 * To run a mutation, you first call `useInitBlockMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInitBlockMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [initBlockMutation, { data, loading, error }] = useInitBlockMutation({
 *   variables: {
 *      type: // value for 'type'
 *      name: // value for 'name'
 *      duration: // value for 'duration'
 *      comment: // value for 'comment'
 *      metric: // value for 'metric'
 *      battery: // value for 'battery'
 *      stimulation: // value for 'stimulation'
 *   },
 * });
 */
export function useInitBlockMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<InitBlockMutation, InitBlockMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<InitBlockMutation, InitBlockMutationVariables>(InitBlockDocument, options);
      }
export type InitBlockMutationHookResult = ReturnType<typeof useInitBlockMutation>;
export type InitBlockMutationResult = ApolloReactCommon.MutationResult<InitBlockMutation>;
export type InitBlockMutationOptions = ApolloReactCommon.BaseMutationOptions<InitBlockMutation, InitBlockMutationVariables>;
export const GetTaskStimulationDocument = gql`
    query getTaskStimulation($id: String!) {
  task(where: {id: $id}) {
    id
    battery {
      id
      type
      sham
    }
    stimulation {
      id
      program {
        id
        type
        code
      }
    }
  }
}
    `;

/**
 * __useGetTaskStimulationQuery__
 *
 * To run a query within a React component, call `useGetTaskStimulationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTaskStimulationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTaskStimulationQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetTaskStimulationQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetTaskStimulationQuery, GetTaskStimulationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetTaskStimulationQuery, GetTaskStimulationQueryVariables>(GetTaskStimulationDocument, options);
      }
export function useGetTaskStimulationLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetTaskStimulationQuery, GetTaskStimulationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetTaskStimulationQuery, GetTaskStimulationQueryVariables>(GetTaskStimulationDocument, options);
        }
export type GetTaskStimulationQueryHookResult = ReturnType<typeof useGetTaskStimulationQuery>;
export type GetTaskStimulationLazyQueryHookResult = ReturnType<typeof useGetTaskStimulationLazyQuery>;
export type GetTaskStimulationQueryResult = ApolloReactCommon.QueryResult<GetTaskStimulationQuery, GetTaskStimulationQueryVariables>;
export const RemoveStimulationDocument = gql`
    mutation removeStimulation($taskId: String!) {
  updateTask(data: {stimulation: {disconnect: true}}, where: {id: $taskId}) {
    id
  }
}
    `;
export type RemoveStimulationMutationFn = ApolloReactCommon.MutationFunction<RemoveStimulationMutation, RemoveStimulationMutationVariables>;

/**
 * __useRemoveStimulationMutation__
 *
 * To run a mutation, you first call `useRemoveStimulationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStimulationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStimulationMutation, { data, loading, error }] = useRemoveStimulationMutation({
 *   variables: {
 *      taskId: // value for 'taskId'
 *   },
 * });
 */
export function useRemoveStimulationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RemoveStimulationMutation, RemoveStimulationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<RemoveStimulationMutation, RemoveStimulationMutationVariables>(RemoveStimulationDocument, options);
      }
export type RemoveStimulationMutationHookResult = ReturnType<typeof useRemoveStimulationMutation>;
export type RemoveStimulationMutationResult = ApolloReactCommon.MutationResult<RemoveStimulationMutation>;
export type RemoveStimulationMutationOptions = ApolloReactCommon.BaseMutationOptions<RemoveStimulationMutation, RemoveStimulationMutationVariables>;
export const GetBatteryUsersDocument = gql`
    query getBatteryUsers {
  taskBatteries(where: {type: {equals: ACTIVE}}, orderBy: {createdAt: desc}) {
    user {
      email
    }
    tasks {
      id
      startTime
      user {
        id
        email
      }
    }
  }
}
    `;

/**
 * __useGetBatteryUsersQuery__
 *
 * To run a query within a React component, call `useGetBatteryUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBatteryUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBatteryUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBatteryUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetBatteryUsersQuery, GetBatteryUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetBatteryUsersQuery, GetBatteryUsersQueryVariables>(GetBatteryUsersDocument, options);
      }
export function useGetBatteryUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetBatteryUsersQuery, GetBatteryUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetBatteryUsersQuery, GetBatteryUsersQueryVariables>(GetBatteryUsersDocument, options);
        }
export type GetBatteryUsersQueryHookResult = ReturnType<typeof useGetBatteryUsersQuery>;
export type GetBatteryUsersLazyQueryHookResult = ReturnType<typeof useGetBatteryUsersLazyQuery>;
export type GetBatteryUsersQueryResult = ApolloReactCommon.QueryResult<GetBatteryUsersQuery, GetBatteryUsersQueryVariables>;
export const GetUserTasksDocument = gql`
    query getUserTasks($id: String!) {
  user(where: {id: $id}) {
    id
    email
    role
    organization {
      id
      name
    }
  }
  tasks(where: {userId: {equals: $id}}, orderBy: {createdAt: desc}, take: 20) {
    id
    type
    completed
    startTime
    createdAt
    battery {
      id
      template {
        id
        name
      }
    }
    stimulation {
      id
      program {
        name
      }
    }
    metric {
      id
    }
    score {
      accuracy
      time
    }
  }
}
    `;

/**
 * __useGetUserTasksQuery__
 *
 * To run a query within a React component, call `useGetUserTasksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserTasksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserTasksQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserTasksQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetUserTasksQuery, GetUserTasksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetUserTasksQuery, GetUserTasksQueryVariables>(GetUserTasksDocument, options);
      }
export function useGetUserTasksLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserTasksQuery, GetUserTasksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetUserTasksQuery, GetUserTasksQueryVariables>(GetUserTasksDocument, options);
        }
export type GetUserTasksQueryHookResult = ReturnType<typeof useGetUserTasksQuery>;
export type GetUserTasksLazyQueryHookResult = ReturnType<typeof useGetUserTasksLazyQuery>;
export type GetUserTasksQueryResult = ApolloReactCommon.QueryResult<GetUserTasksQuery, GetUserTasksQueryVariables>;
export const CreateUserBatteryDocument = gql`
    mutation createUserBattery($template: String!, $userId: String!, $sham: Boolean!) {
  createTaskBattery(
    data: {template: {connect: {id: $template}}, user: {connect: {id: $userId}}, sham: $sham}
  ) {
    id
    sham
  }
}
    `;
export type CreateUserBatteryMutationFn = ApolloReactCommon.MutationFunction<CreateUserBatteryMutation, CreateUserBatteryMutationVariables>;

/**
 * __useCreateUserBatteryMutation__
 *
 * To run a mutation, you first call `useCreateUserBatteryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserBatteryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserBatteryMutation, { data, loading, error }] = useCreateUserBatteryMutation({
 *   variables: {
 *      template: // value for 'template'
 *      userId: // value for 'userId'
 *      sham: // value for 'sham'
 *   },
 * });
 */
export function useCreateUserBatteryMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserBatteryMutation, CreateUserBatteryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useMutation<CreateUserBatteryMutation, CreateUserBatteryMutationVariables>(CreateUserBatteryDocument, options);
      }
export type CreateUserBatteryMutationHookResult = ReturnType<typeof useCreateUserBatteryMutation>;
export type CreateUserBatteryMutationResult = ApolloReactCommon.MutationResult<CreateUserBatteryMutation>;
export type CreateUserBatteryMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserBatteryMutation, CreateUserBatteryMutationVariables>;
export const GetUserInfoDocument = gql`
    query getUserInfo($id: String!) {
  user(where: {id: $id}) {
    name
    email
  }
  bioMetrics(where: {userId: {equals: $id}}, orderBy: {createdAt: desc}, take: 10) {
    id
    createdAt
  }
  stimulations(take: 20) {
    id
    createdAt
    program {
      name
    }
  }
  tasks(
    where: {userId: {equals: $id}, completed: {equals: true}}
    orderBy: {createdAt: asc}
  ) {
    id
    type
    createdAt
    score {
      time
      accuracy
    }
  }
}
    `;

/**
 * __useGetUserInfoQuery__
 *
 * To run a query within a React component, call `useGetUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserInfoQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserInfoQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
      }
export function useGetUserInfoLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserInfoQuery, GetUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GetUserInfoDocument, options);
        }
export type GetUserInfoQueryHookResult = ReturnType<typeof useGetUserInfoQuery>;
export type GetUserInfoLazyQueryHookResult = ReturnType<typeof useGetUserInfoLazyQuery>;
export type GetUserInfoQueryResult = ApolloReactCommon.QueryResult<GetUserInfoQuery, GetUserInfoQueryVariables>;
export const GetUserStimulationsDocument = gql`
    query getUserStimulations($id: String!) {
  user(where: {id: $id}) {
    id
  }
  stimulations {
    id
    createdAt
    program {
      name
    }
  }
}
    `;

/**
 * __useGetUserStimulationsQuery__
 *
 * To run a query within a React component, call `useGetUserStimulationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserStimulationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserStimulationsQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserStimulationsQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetUserStimulationsQuery, GetUserStimulationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetUserStimulationsQuery, GetUserStimulationsQueryVariables>(GetUserStimulationsDocument, options);
      }
export function useGetUserStimulationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserStimulationsQuery, GetUserStimulationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetUserStimulationsQuery, GetUserStimulationsQueryVariables>(GetUserStimulationsDocument, options);
        }
export type GetUserStimulationsQueryHookResult = ReturnType<typeof useGetUserStimulationsQuery>;
export type GetUserStimulationsLazyQueryHookResult = ReturnType<typeof useGetUserStimulationsLazyQuery>;
export type GetUserStimulationsQueryResult = ApolloReactCommon.QueryResult<GetUserStimulationsQuery, GetUserStimulationsQueryVariables>;
export const GetUsersDocument = gql`
    query getUsers {
  users(orderBy: {updatedAt: desc}) {
    id
    email
    role
    updatedAt
    organization {
      name
    }
    task {
      id
    }
    metric {
      id
    }
    stimulation {
      id
    }
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = ApolloReactCommon.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetUserActivityDocument = gql`
    query getUserActivity($id: String!) {
  user(where: {id: $id}) {
    id
  }
  stimulations(orderBy: {createdAt: desc}) {
    id
    createdAt
    user {
      id
      name
    }
    createdAt
    program {
      name
    }
  }
  bioMetrics(where: {userId: {equals: $id}}, orderBy: {createdAt: desc}) {
    id
    createdAt
    user {
      id
    }
  }
}
    `;

/**
 * __useGetUserActivityQuery__
 *
 * To run a query within a React component, call `useGetUserActivityQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserActivityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserActivityQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetUserActivityQuery(baseOptions: ApolloReactHooks.QueryHookOptions<GetUserActivityQuery, GetUserActivityQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return ApolloReactHooks.useQuery<GetUserActivityQuery, GetUserActivityQueryVariables>(GetUserActivityDocument, options);
      }
export function useGetUserActivityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetUserActivityQuery, GetUserActivityQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return ApolloReactHooks.useLazyQuery<GetUserActivityQuery, GetUserActivityQueryVariables>(GetUserActivityDocument, options);
        }
export type GetUserActivityQueryHookResult = ReturnType<typeof useGetUserActivityQuery>;
export type GetUserActivityLazyQueryHookResult = ReturnType<typeof useGetUserActivityLazyQuery>;
export type GetUserActivityQueryResult = ApolloReactCommon.QueryResult<GetUserActivityQuery, GetUserActivityQueryVariables>;