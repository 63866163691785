import async from '../components/MaterialUI/Async'
import { Monitor } from 'react-feather'
import Waves from '@material-ui/icons/Waves'
import Assignment from '@material-ui/icons/Assignment'

import StimulationIcon from '@material-ui/icons/BlurOn'
import UsersIcon from '@material-ui/icons/People'

import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import SchoolIcon from '@material-ui/icons/School'
import StorageIcon from '@material-ui/icons/Storage'
import {
  assessmentRoutes,
  nbackRoutes,
  nbackSessionRoutes,
  digitspanRoutes,
  digitspanSessionRoutes,
  sternbergRoutes,
  sternbergSessionRoutes,
  restSessionRoutes,
  taskDetailRoutes,
  batteryRoutes,
  batterySessionRoutes,
  batteryTemplateRoutes,
  sharedBatteryRoutes,
} from './tasks'
import { authRoutes } from './auth'
import { gameRoutes, gameDetailRoutes } from './csgo'

// Guards
const AuthGuard = async(() => import('../components/MaterialUI/AuthGuard'))

// Protected routes
const ProtectedPage = async(() => import('../pages/protected/ProtectedPage'))

// Pages
const SessionsPage = async(() => import('../pages/protected/SessionsPage'))
const SessionDetailPage = async(() => import('../pages/protected/SessionDetailPage'))
const LivePage = async(() => import('../pages/protected/LivePage'))
const PlayBackPage = async(() => import('../pages/protected/PlayBackPage'))
const StimulationsPage = async(() => import('../pages/protected/StimulationsPage'))
const StimulationDetailPage = async(() => import('../pages/protected/StimulationDetailPage'))
const TutorialPage = async(() => import('../pages/protected/TutorialPage'))
const TutorialGuidesPage = async(() => import('../pages/protected/TutorialGuidesPage'))

const UserDetailPage = async(() => import('../pages/protected/UserDetailPage'))
const UsersPage = async(() => import('../pages/protected/UsersPage'))

const landingRoutes = {
  id: 'Landing Page',
  path: '/',
  header: 'Docs',
  icon: <Monitor />,
  component: ProtectedPage,
  children: null,
  guard: AuthGuard,
}

// Custom pages
const sessionRoutes = {
  id: 'Sessions',
  path: '/sessions',
  icon: <StorageIcon />,
  component: SessionsPage,
  children: null,
}

const sessionDetailRoutes = {
  id: 'SessionDetail',
  path: '/sessions/:id',
  icon: <StorageIcon />,
  component: SessionDetailPage,
  children: null,
}

const playbackRoutes = {
  id: 'SessionPlayBack',
  path: '/sessions/:id/playback',
  icon: null,
  component: PlayBackPage,
  children: null,
}

const liveRoutes = {
  id: 'Live',
  path: '/live',
  icon: <Waves />,
  component: LivePage,
  children: null,
}

const stimulationRoutes = {
  id: 'Stimulation',
  path: '/stimulation',
  icon: <StimulationIcon />,
  component: StimulationsPage,
  children: null,
}

const stimulationDetailRoutes = {
  id: 'StimulationDetail',
  path: '/stimulation/:id',
  icon: <StimulationIcon />,
  component: StimulationDetailPage,
  children: null,
}

const tutorialRoutes = {
  id: 'Tutorials',
  path: '/tutorial',
  icon: <SchoolIcon />,
  component: TutorialPage,
  children: null,
}

const tutorialGuidesRoutes = {
  id: 'Tutorial Guides',
  path: '/tutorial/:id?',
  icon: <QuestionAnswerIcon />,
  component: TutorialGuidesPage,
  children: null,
}

const userDetailRoutes = {
  id: 'User Detail',
  path: '/user/:id',
  icon: <Assignment />,
  component: UserDetailPage,
  children: null,
}

const usersRoutes = {
  id: 'Users',
  path: '/users',
  icon: null,
  component: UsersPage,
  children: null,
}

// This route is only visible while signed in
const protectedPageRoutes = {
  id: 'Private',
  path: '/private',
  component: ProtectedPage,
  children: null,
  guard: AuthGuard,
}

// Sidebar
const deviceRoutes = {
  id: 'Nexus',
  path: '/device',
  icon: <StimulationIcon />,
  component: null,
  children: [
    {
      name: 'Live',
      path: '/live',
      component: LivePage,
    },
    {
      name: 'Protocols',
      path: '/stimulation',
      component: StimulationsPage,
    },
  ],
}

const userRoutes = {
  id: 'Users',
  path: '/users',
  icon: <UsersIcon />,
  component: null,
  children: [
    {
      name: 'Users',
      path: '/users',
      component: UsersPage,
    },
    {
      name: 'Sessions',
      path: '/sessions',
      component: SessionsPage,
    },
  ],
}

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  landingRoutes,
  sessionRoutes,
  sessionDetailRoutes,
  liveRoutes,
  playbackRoutes,
  stimulationRoutes,
  stimulationDetailRoutes,
  tutorialRoutes,
  tutorialGuidesRoutes,
  assessmentRoutes,
  nbackRoutes,
  nbackSessionRoutes,
  digitspanRoutes,
  digitspanSessionRoutes,
  sternbergRoutes,
  sternbergSessionRoutes,
  restSessionRoutes,
  taskDetailRoutes,
  gameRoutes,
  gameDetailRoutes,
  batteryRoutes,
  batterySessionRoutes,
  batteryTemplateRoutes,
  usersRoutes,
  userDetailRoutes,
]

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes]

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes]

// Routes used for shared views
export const sharedLayoutRoutes = [sharedBatteryRoutes]

// Routes that are protected
export const protectedRoutes = [protectedPageRoutes]

// Routes visible in the sidebar
export const sidebarRoutes = [deviceRoutes, userRoutes, assessmentRoutes, tutorialRoutes]
