import { useContext, useState } from 'react'
import {
  Button,
  LinearProgress,
  Card,
  CardHeader,
  CardContent,
  Select,
  MenuItem,
  Slider,
  FormControlLabel,
  Checkbox,
  CardActions,
} from '@material-ui/core'
import { NeoRhythmPredefined, neoRhythmToStandard } from 'biolink'
import { DeviceContext } from 'store/device-context'
import { useGetStimulationProgramsQuery } from 'generated/graphql'

interface Protocol {
  sham: boolean
  blind: boolean
}

export function StimulationCounter() {
  const { active, program, clock } = useContext(DeviceContext)!
  const blind = false

  function renderTimer() {
    if (!program?.duration) return null
    // TODO: handle sham/blind in context instead
    // TODO: get fake sham duration from real duration
    const duration = blind ? 30 : program.duration
    const percentage = Math.round((clock! / (duration * 60)) * 100)
    return (
      <div>
        {`Duration: ${duration} min.`}
        {` - ${Math.floor(clock! / 60)} min. ${clock! % 60} sec. `}
        {` (${percentage}%)`}
        <LinearProgress variant="determinate" value={percentage} />
        {!blind && <div>{`Active: ${active}`}</div>}
      </div>
    )
  }
  return <>{renderTimer()}</>
}

export function Stimulation() {
  const { data, loading, error } = useGetStimulationProgramsQuery()
  const { program, loadProgram } = useContext(DeviceContext)!

  const [select, setSelect] = useState<string>('')
  const [duration, setDuration] = useState(10)
  const [protocol, setProtocol] = useState<Protocol>({ sham: false, blind: false })

  function handleProtocolChange(event: React.ChangeEvent<HTMLInputElement>) {
    setProtocol({ ...protocol, [event.target.name]: event.target.checked })
  }

  function handleProgramChange(event: React.ChangeEvent<{ value: unknown }>) {
    setSelect(event.target.value as string)
  }
  function handleSetProgram() {
    const { sham, blind } = protocol
    if (program) {
      console.log('Set Program')
      // if (blind) setBlind(true)
      // client.sendPredefinedProgram({ program: program.program, duration: sham ? 1 : duration })
      loadProgram!(program, sham ? 1 : duration)
    }
  }

  function renderSelect() {
    if (loading) return <div>Loading</div>
    if (!data) return
    return (
      <Select value={select} displayEmpty onChange={handleProgramChange}>
        <MenuItem value="">
          <em>None</em>
        </MenuItem>
        {data.stimulationPrograms.map((p) => (
          <MenuItem value={p.id} key={p.id}>
            {p.name}
          </MenuItem>
        ))}
      </Select>
    )
  }

  return (
    <div>
      <Card>
        <CardHeader title="Program Settings" />
        <CardContent>
          {renderSelect()}
          {NeoRhythmPredefined.find((p) => p.id === select)?.description}
          <div style={{ width: '200px' }}>
            {`Duration: ${duration} min.`}
            <Slider
              min={10}
              max={120}
              step={10}
              value={duration}
              onChange={(_, v) => setDuration(v as number)}
              valueLabelDisplay="auto"
              marks={true}
            />
          </div>
          <FormControlLabel control={<Checkbox checked={protocol.sham} onChange={handleProtocolChange} name="sham" />} label="sham" />
          <FormControlLabel control={<Checkbox checked={protocol.blind} onChange={handleProtocolChange} name="blind" />} label="blind" />
          <CardActions>
            <Button disabled={!select} variant="contained" color="secondary" onClick={handleSetProgram}>
              Set Program
            </Button>
          </CardActions>
        </CardContent>
      </Card>
      <Card>
        <CardHeader title={'Active Program'} subheader={program?.name} />
        <CardContent>
          <StimulationCounter />
        </CardContent>
      </Card>
    </div>
  )
}

export default Stimulation
