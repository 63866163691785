import { useState, useEffect } from 'react'
import { NeoRhythmDevice } from 'biolink'
import { Device } from '../types'

// TODO: fix import/export
import { StimulationProgram } from 'biolink/dist/utils/stimulation'
import { useStartStimulationMutation } from 'generated/graphql'

export interface StimulationContextProps {
  program?: StimulationProgram
  clock: number
  telemetry?: number
  active: boolean
  loadProgram?: (program: StimulationProgram, duration?: number) => Promise<void>
  startStimulation?: () => void
  saveSession: (type: string, program?: string) => Promise<void>
}

const neo = new NeoRhythmDevice()
export function NeoRhythmStore(device: Device | null): Partial<StimulationContextProps> {
  const [saveStimulation, { loading }] = useStartStimulationMutation()
  const [clock, setClock] = useState(0)
  const [telemetry, setTelemetry] = useState<number | undefined>(undefined)
  const [program, setProgram] = useState<StimulationProgram | undefined>(undefined)
  const [active, setActive] = useState(false)

  async function loadProgram(program: StimulationProgram, duration?: number) {
    if (!device) return
    await neo.setProgram(device, program, duration || 30)
  }
  async function startStimulation() {
    if (!device) return
    neo.startStimulation(device)
  }
  async function saveSession(type: string, program?: string) {
    const startTime = JSON.stringify(new Date())
    if (!program) throw Error('No Program')
    const { data } = await saveStimulation({
      variables: {
        type,
        program: program,
        startTime,
        deviceType: device?.type,
      },
    })
  }
  async function stopStimulation() {
    // hardware tap
  }
  function endStimulation() {
    // TODO: implement
  }

  // Component states
  useEffect(() => {
    const $clock = neo.clock.subscribe((c) => setClock(c))
    const $active = neo.active?.subscribe((a) => setActive(a))

    return () => {
      $clock.unsubscribe()
      $active.unsubscribe()
    }
  })
  useEffect(() => {
    // if (!active) return
    const $telemetry = neo.telemetry?.subscribe((p) => p.then((t) => setTelemetry(t.batteryLevel)))
    const $program = neo.program?.subscribe((p) => p.then((t) => setProgram(t)))
    return () => {
      $telemetry?.unsubscribe()
      $program?.unsubscribe()
    }
  }, [active])

  return { clock, telemetry, active, loadProgram, startStimulation, program, saveSession }
}
